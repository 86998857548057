/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import PopOutMarkLink from '../../popOutMarkLink';
const ReactMarkdown = require('react-markdown/with-html');

export default function HeaderAbstract ({ text }) {
    return (
      <div className="dd-header-abstract">
        <ReactMarkdown
          source={text}
          escapeHtml={false}
          renderers={{
              link: PopOutMarkLink,
          }}
        />
      </div>
    );
}
