/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';

export default function DCFSA (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Understanding the Dependent Care Flexible Spending Account Benefit
`}
        />
        <ReactMarkdown
          source={`
If you have dependents, you already know that dependent care can be expensive! The Dependent Care Flexible Spending Account is a benefit that may provide some relief for you or those you lead or support. As part of its obligation to take care of service members and families, the Department of Defense is implementing DCFSAs to support families with expenses such as child care, preschool, elder care, summer camp and more. The resources on this page provide additional information to help service members, military leaders and service providers understand DCFSAs and how these accounts can support military families.

You can sign up for a DCFSA during the annual Federal Benefits Open Season or after experiencing a qualifying life event. Visit [FSAFEDS](https://www.fsafeds.com/explore/usmdcfsa) for more information and to enroll.

Sign up to receive [Office of Financial Readiness eNewsletters](https://public.govdelivery.com/accounts/USDODFINRED/subscriber/new)  — or watch your inbox if you’re already a subscriber — for updates about DCFSA.

### Service Member and Family Resources
`}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            title="What To Know for Service Members"
            img="/assets/img/FINRED-WebsiteImage-DCFSA-UniformedMembers-FS.jpg"
            alt="Dependent care FSA for Uniformed Service Members fact sheet"
            href="/assets/downloads/FINRED-DCFSA-UniformedMembers-FS.pdf"
            link="Explore Here"
          >
            <p>
            Service members who have eligible dependent care expenses may qualify to enroll in a DCFSA. Learn more in this flyer.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-FAQ-R.jpg"
            alt="Service member and son looking at each other"
            title="Frequently Asked Questions"
            href={`${root}/FAQ`}
            link="Find Answers"
          >
            <p>
            Looking for military-specific information about DCFSAs? Check out the most frequently asked questions and answers.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSAvideo-GC.jpg"
            alt="Dependent Care Flexible Spending Account Video Series"
            title="Four-Part Video Series"
            href={`${root}/Videos`}
            link="Watch Now"
          >
            <p>
            Check out these short, informational videos to learn about the basics and benefits of a Dependent Care Flexible Spending Account.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-Eligibility-G.jpg"
            alt="Is a DCFSA Right For Your Family fact sheet"
            title="Service Member Eligibility Guide"
            href={`${root}/EligibilityGuide`}
            link="Get Started"
          >
            <p>
            Trying to determine whether a DCFSA is right for your family? This user-friendly tool can help simplify the decision-making process.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-WS.jpg"
            alt="Dependent Care Flexible Spending Account webinar series"
            title="Webinars and Virtual Benefits Info"
            href={`${root}/Webinars`}
            link="Get the Details"
          >
            <p>
            Learn more about the DCFSA benefit during these interactive online sessions.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-MilFamiliesSpouses-FS.jpg"
            alt="Dependent care FSA for Military Families and Spouses fact sheet"
            href="/assets/downloads/FINRED-DCFSA-MilFamiliesSpouses-FS.pdf"
            title="What To Know for Military Families and Spouses"
            link="Get Started"
          >
            <p>
            Check out this fact sheet to learn more about DCFSA benefits and enrollment options from the military spouse perspective.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
### Leader and Service Provider Resources
`}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-LC.jpg"
            alt="Talking to Service Members About Dependent Care Flexible Spending Account Leader Card"
            title="Talking to Service Members About DCFSA"
            href="/assets/downloads/FINRED-DCFSA-LC.pdf"
            link="Start Here"
          >
            <p>
            Help yourself and your service members better understand DCFSAs and where to find support and resources. Use this card to start the conversation.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-P.jpg"
            alt="Dependent Care Flexible Spending Account Poster"
            title="Spread the Word With This Poster"
            href="/assets/downloads/FINRED-DCFSA-P.pdf"
            link="Preview Here"
          >
            <p>
            Share the news that DCFSA is now available for service members! View the poster by clicking the button below.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-DCFSA-Additional-R.jpg"
            alt="Secretary of Defense Lloyd J. Austin III"
            title="Strengthening Our Support: Resources "
            href={`${root}/StrengtheningOurSupport`}
            link="Access Now"
          >
            <p>
            Stay informed about DCFSA — read the latest official articles, press releases and foundational documents here.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
