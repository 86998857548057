/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        if (!isVisible && window.pageYOffset > 400) {
            setIsVisible(true);
        } else if (isVisible && window.pageYOffset <= 400) {
            setIsVisible(false);
        }
    };

    window.addEventListener('scroll', toggleVisibility);

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
      <button
        onClick={scrollTop}
        className="usa-button scroll-to-top"
        style={{ height: 40, display: isVisible ? 'block' : 'none' }}
      >
          Return to Top   <i className="fa fa-arrow-up fa-lg" />
      </button>
    );
}

