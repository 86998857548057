/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import HeaderTitle from './headerTitle';
import HeaderAbstract from './headerAbstract';

const ImageContainer = ({ image }) => {
    const style = {
        clipPath: image.shape,
        shapeOutside: image.shape,
    };

    return (
      <img className="dd-image" style={style} src={image.src} alt={image.alt} title={image.alt} />
    );
};

export default function T1Header ({ header }) {
    const style = header.border ? {
        borderBottom: `5px solid ${header.border}`,
    } : { };

    return (
      <div className="clearfix dd-t1-header" style={style}>
        <ImageContainer image={header.image} />
        <div style={{ }}>
          <HeaderTitle text={header.title.text} />
          <HeaderAbstract text={header.abstract.text} />
        </div>
      </div>
    );
}
