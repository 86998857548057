/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/


import React from 'react';

export default class TOCEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded,
        };

        this.selected = this.selected.bind(this);
    }

    selected() {
        if (window.location.pathname.indexOf(this.props.text) > -1 || window.location.hash.indexOf(this.props.text) > -1 ) {
            return 'toc-entry selected';
        }
        if (this.state.expanded || this.props.expanded) { return 'toc-entry selected'; }
        return 'toc-entry';
    }

    render() {
        return (
          <div className={this.selected()}>{this.props.children}</div>
        );
    }
}
