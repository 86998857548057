/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Link from './link';



export default class LinkModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity: 0,
        };
        this.dismiss = this.dismiss.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState(() => ({
                opacity: 1,
            }));
        }, 100);
    }

    dismiss() {
        /* global gtag */
        gtag('event', 'outgoing_click', {
            event_label: this.props.href,
            event_category: 'resources',
            value: 1,
        });

        if (this.props.isSimulation) {
            gtag('event', 'simulation', {
                event_label: this.props.href,
                event_category: 'resources',
                value: 1,
            });
        }

        this.setState(() => ({
            opacity: 0,
        }));

        setTimeout(() => {
            this.props.dismiss();
        }, 100);
    }

    render() {
        return (

          <div className="modal fade in" id={this.props.href || 'myModal'} role="dialog" style={{ opacity: this.state.opacity }}>
            <div className="modal-dialog">

              <div className="modal-content">
                <div className="modal-header">
                  {/* <button type="button" class="close" data-dismiss="modal">&times;</button> */}
                  <h4>Leaving FINRED Site</h4>
                </div>
                <div className="modal-body">
                  <p>You are navigating to:</p>
                  <p className="link">{this.props.href}</p>
                  <p>The link may lead to a non-federal site, but it provides additional information that is consistent with the intended purpose of a federal site.
                    Linking to a non-federal site does not constitute an endorsement by FINRED or any of its employees of the sponsors or the information and products presented on the site.
                  </p>
                  <p>
                    You will be subject to the destination site’s privacy policy when you follow the link.
                  </p>
                </div>
                <div className="modal-footer">
                  <a onClick={this.dismiss} href={this.props.href || '/'} target="_blank"><button type="button" className="usa-button">Continue to site...</button></a>
                  <button type="button" className="usa-button" data-dismiss="modal" onClick={this.props.dismiss}>Cancel</button>
                </div>
              </div>

            </div>
          </div>

        );
    }
}


