/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';

export default function PolicyAndLaw (props) {
    return (
      <Page
        crumbs={`${props.crumbs},Policy and Law`}
        toc={props.toc}
        articleClass="launch-page policy"
      >
        <ReactMarkdown
          source={`
## Policy, Law and Reports
Stay informed through one-stop access to the latest policy, laws and reports concerning financial readiness. Keep abreast of financial policies and laws and be ready to share your knowledge and advice with service members to help them make positive financial decisions today that will benefit them in the future.

### POLICY

* [Secretary of Defense Memorandum, "Strengthening Our Support to Service Members and Their Families," (March 22, 2023).](https://media.defense.gov/2023/Mar/22/2003184739/-1/-1/1/STRENGTHENING-OUR-SUPPORT-TO-SERVICE-MEMBERS-AND-THEIR-FAMILIES.PDF)
Secretary Austin’s memorandum, “Strengthening Our Support to Service Members and Their Families,” outlines actions DoD is taking to continue improving the lives of Service members and their families. Learn more about resources from DoD by reading **[Six New Measures to Enhance Well-Being of Military Force and Their Families](https://www.defense.gov/News/Releases/Release/Article/3338023/dod-announces-six-new-measures-to-enhance-well-being-of-military-force-and-thei/)** and the **[Taking Care of Our Service Members and Families](https://media.defense.gov/2023/Mar/23/2003184887/-1/-1/1/2023-TAKING-CARE-OF-OUR-SERVICE-MEMBERS-AND-FAMILIES-INFOGRAPHIC-FINAL-1.1.PDF)** infographic.
* [Secretary of Defense Memorandum, “Strengthening Economic Security in the Force,” (Nov. 17, 2021).](https://media.defense.gov/2021/Nov/17/2002894808/-1/-1/1/STRENGTHENING-ECONOMIC-SECURITY-IN-THE-FORCE.PDF)
This memorandum, “Strengthening Economic Security in the Force,” recognizes that service members and families are experiencing economic challenges related to current events. It announces department-wide efforts to provide immediate relief, increase stability and expand financial readiness resources.
* [Department of Defense Instruction 1322.34, "Financial Readiness of Service Members," (Nov. 5, 2021).](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/132234p.PDF?ver=u0YgEsvJCqYz_G6zP6g5vw%3D%3D)
Department of Defense Instruction 1322.34, “Financial Readiness of Service Members,” sets policy, assigns responsibilities and outlines procedures and requirements for promoting and sustaining military members’ personal financial readiness.
* [Department of Defense Instruction 1342.22,  “Military Family Readiness,” (Aug. 5, 2021).](http://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/134222p.pdf)
Department of Defense Instruction 1342.22, “Military Family Readiness,” sets requirements for financial education and counseling and more. Look at it here.
* [Department of Defense Instruction 1322.31, “Common Military Training,” (Feb. 20, 2020).](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/132231p.pdf?ver=2020-02-20-114040-493)
Department of Defense Instruction 1322.31, “Common Military Training,” provides guidance for required military training policy, responsibilities and procedures.
* [Department of Defense Instruction 1332.35, “Transition Assistance Program (TAP) for Military Personnel,” (Sept. 26, 2019).](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/133235p.pdf)
Find policy, responsibilities and direction for service members transitioning from active duty in Department of Defense Instruction 1332.35, “Transition Assistance Program (TAP) for Military Personnel.”
* [Directive-type Memorandum 19-009, “Financial Readiness Common Military Training Requirements,” (Aug. 13, 2019).](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dtm/DTM-19-009.pdf?ver=2019-08-14-130331-710)
Department of Defense Instruction 1322.34, “Financial Readiness of Service Members,” incorporates and cancels Directive-type Memorandum 19-009, “Financial Readiness Common Military Training Requirements,” as of Nov. 5, 2021.
* [Department of Defense Instruction 1344.09, “Indebtedness of Military Personnel,” (Dec. 8, 2008).](http://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/134409p.pdf)
You’ll find policy governing service members' delinquent debt here, in Department of Defense Instruction 1344.09, “Indebtedness of Military Personnel.”
* [Department of Defense Instruction 1344.07, “Personal Commercial Solicitation,” (March 30, 2006).](http://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/134407p.pdf)
Department of Defense Instruction 1344.07, “Personal Commercial Solicitation on DoD Installations,” outlines financial education program guidelines and more.

### LAW

* [National Defense Authorization Act for Fiscal Year 2016. Pub. L. 114-92 (Nov. 25, 2015).](https://www.congress.gov/114/plaws/publ92/PLAW-114publ92.pdf)
National Defense Authorization Act for Fiscal Year 2016 updates and changes financial literacy services for military members and families. Take a look now.
* [National Defense Authorization Act for Fiscal Year 2006. Pub. L. 109-163 (Jan. 6, 2006). ](https://www.congress.gov/109/plaws/publ163/PLAW-109publ163.pdf)
The National Defense Authorization Act for Fiscal Year 2006 outlines the service requirement for financial services education and training. View it here.
* [Title 10, U.S. Code, Section 992.  “Financial Literacy Training: Financial Services.”](https://www.gpo.gov/fdsys/pkg/USCODE-2015-title10/pdf/USCODE-2015-title10-subtitleA-partII-chap50-sec992.pdf)
The “Financial literacy training: financial services” section of this U.S. Code contains updates and amendments to previous financial literacy guidance.
* [Title 10, U.S. Code, Section 1142. “Preseparation Counseling; Transmittal of Medical Records to Department of Veterans Affairs.”](https://www.govinfo.gov/content/pkg/USCODE-2006-title10/pdf/USCODE-2006-title10-subtitleA-partII-chap58-sec1142.pdf)
Title 10, U.S. Code, Section 1142, “Preseparation Counseling; transmittal of medical records to Department of Veterans Affairs,” outlines provisions to provide counseling services for service members with anticipated discharge or release dates.

### REPORTS

* [Report to the Committee on Armed Services of the House of Representatives, “Assessing Implementation of the Blended Retirement System” (May 2024)](/assets/downloads/FINRED-AssessingImplementationBRS.pdf)
This report gives the Committee on Armed Services of the House of Representatives an overview of the Blended Retirement System (BRS) five years after its implementation. It analyzes the impact BRS has had on retirement investing and financial literacy among military service members, and suggests service members are more financially savvy than their civilian counterparts.
* [Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces: Results from the 2022 Status of Forces Survey (December 2023)](/assets/downloads/FINRED-2023-FinancialLiteracy-R.pdf.pdf)
This report shares the results of the 2022 Status of Forces Survey, including an overview of Department of Defense efforts to support financial readiness among service members.
* [Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces: Results from the 2020 Status of Forces Survey (April 2022)](/assets/downloads/FINRED-2022-FinancialLiteracy-R.pdf)
This report shares the results of the 2020 Status of Forces Survey, including an overview of Department of Defense efforts to support financial readiness among service members.
* [Report on the Military Lending Act and the Effects of High Interest Rates on Readiness](/assets/downloads/FINRED-MLA_ReportEffectsHighInterestRatesOnReadiness-May2021.pdf)
The Military Lending Act protects active-duty service members and their families from unfair and predatory lending practices that can negatively impact financial readiness.
* [Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces: Results from the 2019 Status of Forces Survey (December 2020)](/assets/downloads/FINRED-2020_FinancialLiteracy-R.pdf)
This report shares the results of the 2019 Status of Forces Survey, including an overview of Department of Defense efforts to support financial readiness among service members. Read on for more.
* [Report to Congress: Blended Retirement System Implementation Study](https://militarypay.defense.gov/Portals/3/REPORT%20TO%20CONGRESS%20-%20BRS%20Implementation%20Study%20(Public%20Release).pdf?ver=Nr1SZWrcUaG8rnwZVw3yNw%3D%3D)
As a service member, you’ll face many changes in your military career. With proper planning, your finances can remain steady through every transition.
* [Report on Financial Literacy and Training Support Available to Military Spouses](/assets/downloads/FINRED-SupportAvailableMilitarySpousesReport.pdf)
“Report on Financial Literacy Training and Support Available to Military Spouses” (September 2020), evaluates the availability and effectiveness of financial literacy programs for military spouses.
* [The Department of Defense Report on Best Practices for Providing Financial Literacy Education for Separating Members of the Armed Forces](/assets/downloads/FINRED-BestPracticesReport-R.pdf)
The Department of Defense “Report on Best Practices for Providing Financial Literacy Education for Separating Members of the Armed Forces” (July 2020) details how to prepare and deliver financial literacy education to separating service members.
* [Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces: Results from the 2018 Status of Forces Survey (January 2020)](/assets/downloads/FINRED-2019-FinancialLiteracy-R.pdf)
The Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces includes results from the 2018 Status of Forces Survey. Find out more.
* [Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces: Results from the 2017 Status of Forces Survey ](/assets/downloads/FINRED-2018-FinancialLiteracy-R.pdf)
The Department of Defense’s Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces summarizes 2017 survey results. Discover the DoD’s and military services’ efforts and plans. 
* [Department of Defense Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces: Results from the 2016 Status of Forces Survey ](/assets/downloads/FINRED-2017-FinancialLiteracy-R.pdf)
The Department of Defense’s Annual Report on the Financial Literacy and Preparedness of Members of the Armed Forces summarizes 2016 survey results. Learn about the DoD’s and military services’ response. 

`}
          renderers={{ link: MarkLink }}
        />
      </Page>
    );
}
