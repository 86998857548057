/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function PostDeploymentRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Post-Deployment|${root}` : `Post-Deployment|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-101_-_af_post-deployment_20_-_course_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlanConsiderations', 'Spending Plan Considerations', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-102_-_af_post-deployment_20_-_spending_plan_considerations_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PotentialIncomeChanges', 'Potential Income Changes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-103_-_af_post-deployment_20_-_special_pay,_entitlements_and_other_pot_inc_changes_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingsandEmergencyFunds', 'Savings and Emergency Funds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-104_-_af_post-deployment_20_-_savings_and_emergency_funds_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'WarningSignsofFinanciallyAt', 'Warning Signs of Financially At-Risk Service Members', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-105_-_af_post-deployment_20_-_warning_signs_of_financially_at-risk_service_members_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoIntermediatePersonalFinanceTopics', 'Transition to Intermediate Personal Finance Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-106_-_af_post_deployment_20_-_transition_to_intermediate_personal_finance_topics_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FundamentalsofDebtandCredit', 'Fundamentals of Debt and Credit', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-107_-_af_post-deployment_20_-_debt_and_credit_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IncomeTaxFundamentals', 'Income Tax Fundamentals', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-108_-_af_post-deployment_20_-_income_tax_fundamentals_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PotentialPost-DeploymentTaxChanges', 'Potential Post-Deployment Tax Changes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-109_-_af_post-deployment_20_-_potential_post-deployment_tax_changes_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UnderstandingtheThriftSavingsPlan', 'Understanding the Thrift Savings Plan', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-110_-_af_post-deployment_20_-_understanding_the_thrift_savings_plan_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingYourTSPAccount', 'Managing Your TSP Account', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-111_-_af_post-deployment_20_-_managing_your_tsp_account_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoInsurance', 'Transition to Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-112_-_af_post_deployment_20_-_transition_to_insurance_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'InsuranceOverview', 'Insurance Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-113_-_af_post-deployment_20_-_insurance_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-114_-_af_post-deployment_20_-_life_insurance_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TRICAREOverview', 'TRICARE Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-115_-_af_post-deployment_20_-_tricare_overview_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoMilitaryConsumerProtections', 'Transition to Military Consumer Protections', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-116_-_af_post_deployment_20_-_transition_to_military_consumer_protection_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SCRA,MLAandCreditProtections', 'SCRA, MLA and Credit Protections', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-117_-_af_post-deployment_20_-_scra,_mla,_and_credit_protection_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MisleadingConsumerPracticesandIdentityTheft', 'Misleading Consumer Practices and Identity Theft', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-118_-_af_post-deployment_20_-_misleading_consumer_practices_and_identity_theft_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoSurvivorBenefitsandEstatePlanning', 'Transition to Survivor Benefits and Estate Planning', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-119_-_af_post_deployment_20_-_transition_to_survivor_benefits_and_estate_planning_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SurvivorandDependentBenefits', 'Survivor and Dependent Benefits', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-120_-_af_post-deployment_20_-_survivor_and_dependent_benefits_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PurposeandImplicationsofEstatePlanning', 'Purpose and Implications of Estate Planning', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-121_-_af_post-deployment_20_-_purpose_and_implications_of_estate_planning_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/post-deployment/44-122_-_af_post_deployment_20_-_course_wrap-up_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPostDeployment-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
