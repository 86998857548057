/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material

* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import Img from './img';
import Link from './link';
import Markdown from './markdown';
import Page from './trendingPage';

export default function BlogRenderer(props) {
    const [markdown, setMarkdown] = useState('');
    const root = props.match.path;

    useEffect(() => {
        if (props.file) {
            // eslint-disable-next-line no-undef
            fetch(props.file)
                .then(res => res.text())
                .then(text => setMarkdown(text));
        }
    }, []);

    const onCopy = () => {
        copy(window.location.href);
    };

    return (
      <Page
        crumbs={`${props.crumbs},${props.title.replace(',', '')}|${root}`}
        toc={props.toc}
      >
        <div className="blogpost blog-article">
          <h2>{props.title}</h2>
          <Img large src={props.img} alt={props.alt || ' '} title={props.alt || ' '} />
          <div className="blog-article-caption" style={props.originalDate ? { marginBottom: '2px' } : {}}>
            <div className="date-read-time">{props.originalDate ? 'Updated ' : ''}{props.date} — {props.readingTime} read</div>
            <div className="blog-article-functions">
              <FacebookShareButton
                // url={`https://finred.usalearning.gov${root}`}
                url={window.location.href}
                quote={`FINRED - ${props.title}`}
              >
                <i className="fa fa-facebook-square function-icon" title="Share to Facebook" />
              </FacebookShareButton>
              <TwitterShareButton
                url={window.location.href}
                // url={`https://finred.usalearning.gov${root}`}
                title={`FINRED - ${props.title}`}
              >
                <i className="fa fa-twitter function-icon" title="Share to Twitter" />
              </TwitterShareButton>
              {/* <i className="fab fa-instagram function-icon" /> */}
              <i className="fa fa-link function-icon" onClick={onCopy} title="Copy link to clipboard" />
            </div>
          </div>
          {props.originalDate && <div className="date-read-time">Originally published {props.originalDate}</div>}
          <Markdown source={markdown} />
          {props.children}
          <div className="blog-tags">
            {
              props.tags.map(
                  t => (
                    <div className="blog-tag tag-button blog-link-button">
                      <Link to={`${props.root}/tag/${t}`}>{t}</Link>
                    </div>
                  ),
              )
            }
          </div>
        </div>
      </Page>
    );
}
