/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ImageUploader2 from 'react-images-upload';
import Dialog from './editorDialog';
import api from '../api';
class newPost extends React.Component {
    constructor(props) {
        super(props);

        this.state = { pictures: [] };
        this.onDrop = this.onDrop.bind(this);
        this.post = this.post.bind(this);
    }

    async post() {}

    onDrop(picture) {
        console.log(picture);
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }

    componentDidMount() {}

    async componentWillMount() {

        /* let url = "/" + this.props._id + "/assets";
    let body = await api.get(url, {
      title: window.$("#title").val(),
      body: window.$("#body").val(),
      abstract: window.$("#abstract").val()
    }); */
    }

    close() {
        this.props.onClose();
    }

    async uploadOne(img) {
        const formData = new FormData();

        formData.append('assetFile', img);
        await api.upload('/posts/' + this.props._id + '/assets', formData);
        this.props.onClose(1);
    }

    async upload() {
        for (const i in this.state.pictures) {
            await this.uploadOne(this.state.pictures[i]);
        }
    }

    render() {
        return (
          <Dialog
            title="Upload Images"
            onClose={() => this.close()}
            buttons={
        {
            Upload: () => { this.upload(); },
            Cancel: () => { this.close(); },
        }
      }
          >
            <form action=".">
              <ImageUploader2
                withIcon
                buttonText="Choose images"
                onChange={this.onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={3145728}
                label="Max file size: 3mb, accepted: .jpg, .gif, .png"
                withPreview
              />
            </form>
          </Dialog>
        );
    }
}

export default newPost;
