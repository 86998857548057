/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/nonTOCPage';
import Link from '../components/link';


export default function Oops404Page (props) {
    return (
      <Page crumbs="404 Page Not Found">
        <div className="oops-404">
          <img src="/assets/img/FINRED-404-oops.png" alt="Speech bubble with word “Oops!”" />
          <h3>Looks like we can’t find that page.</h3>
          <h3>Not to worry!</h3>
          <h3>We have many other financial tools and resources to help you:</h3>
          <div>
            <ul>
              <li><Link to="/">Visit the DoD Office of Financial Readiness Homepage</Link></li>
              <li><Link to="/pfcMap">Find a Personal Financial Counselor</Link></li>
              <li><Link to="/FWBA">Take the Financial Well-Being Assessment</Link></li>
              <li><Link to="/ToolsAndAddRes/Calculators">Use a Financial Calculator</Link></li>
              <li><Link to="/ToolsAndAddRes/Sen$e">Download the DoD’s Financial Literacy App, Sen$e</Link></li>
            </ul>
          </div>
        </div>
      </Page>
    );
}
