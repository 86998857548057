/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { renderers } from 'react-markdown';
import { connect } from 'react-redux';
import Link from '../link';
import Markdown from '../markdown';
class MarkSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, JSON.parse(JSON.stringify(props)), { editing: false, editingResource: -1 });
    }

    addResource() {
        this.setState(() => ({ resources: this.state.resources.concat([{
            title: 'A new resource',
            href: 'Some link',
            subtext: 'the paragraph',
        }]) }));
    }

    editResource(e, j) {
        this.setState(() => ({ editingResource: j }));
        e.preventDefault();
        return false;
    }

    stopEditingResource() {
        this.setState(() => ({ editingResource: -1 }));
    }

    set(i, name, value) {
        this.state.resources[i] = Object.assign(this.state.resources[i], { [name]: value });
        this.setState(() => ({ resources: this.state.resources.concat([]) }));
    }

    save() {
        this.props.onSectionEdited('ResourceList', this.props.i, this.state.resources);
    }

    cancel() {
        const resources = JSON.parse(JSON.stringify(this.props.resources));
        this.setState(() => ({ resources }));
        this.props.onEndEditSection(this.props.i);
    }

    removeResource(e, j) {
        this.setState(() => ({ resources: this.state.resources.filter((i, k) => k != j) }));
        e.preventDefault();
        return false;
    }

    renderResources() {
        const Resources = [];
        for (const j in this.state.resources) {
            const i = this.state.resources[j];
            if (this.state.editingResource === j) {
                Resources.push(<li>
                  <span onClick={(e) => this.stopEditingResource(j)} className="fa fa-save float-right" />
                  <input type="text" onChange={(e) => this.set(j, 'href', e.target.value)} value={i.href} />
                  <input type="text" onChange={(e) => this.set(j, 'title', e.target.value)} value={i.title} />

                  <textarea onChange={(e) => this.set(j, 'subtext', e.target.value)} value={i.subtext} />
                </li>);
            } else {
                Resources.push(

                  <li><Link to={i.href}>{i.title}
                    {this.props.sectionEditing && <span onClick={(e) => this.editResource(e, j)} className="fa fa-edit float-right" />}
                    {this.props.sectionEditing && <span onClick={(e) => this.removeResource(e, j)} className="fa fa-close float-right" />}
                  </Link>
                    <Markdown source={i.subtext} />
                  </li>,

                );
            }
        }
        return Resources;
    }



    renderEditButtons() {
        const buttons = [];
        if (this.props.editing) {
            if (!this.props.sectionEditing) {
                buttons.push(<span className="fa fa-edit" onClick={() => this.props.onStartEditSection(this.props.i)} />);
                buttons.push(<span className="fa fa-close" onClick={() => this.props.onRemoveSection(this.props.i)} />);
            }
        }
        if (this.props.sectionEditing) {
            buttons.push(<span onClick={(e) => this.save()} className="fa fa-save" />);
            buttons.push(<span onClick={(e) => this.cancel()} className="fa fa-undo" />);
        }

        if (this.props.editing || this.props.sectionEditing) {
            return (<>
              <div className="sectionEdit">{buttons}

              </div>
              <div className="clearfix" />
            </>
            );
        }
    }

    render() {
        return (<>
          {
              (this.renderEditButtons())
          }

          <ul className="resourceList">
            {this.renderResources()}
          </ul>
          { this.props.sectionEditing
             && <span onClick={(e) => this.addResource()} className="fa fa-plus float-right" />
          }

          </>
        );
    }
}


const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = dispatch => ({
    onStartEditSection: (i) => dispatch({ type: 'startSectionEdit', i }),
    onEndEditSection: (i) => dispatch({ type: 'endSectionEdit', i }),
    onRemoveSection: (i) => dispatch({ type: 'sectionRemoved', i }),
    onSectionEdited: (sectionType, index, payload) => dispatch({ type: 'sectionEdited', sectionType, index, payload }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarkSection);
