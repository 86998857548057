/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Benefits ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Knowing Your Benefits and Entitlements`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Knowing Your Benefits and Entitlements
Make sure you’re taking advantage of all the military benefits and entitlements that are available to you and your family. You may qualify, for example, for education assistance or other special programs. Find out by making an appointment and receiving no-cost, personal support from an accredited [personal financial manager](https://installations.militaryonesource.mil/) or counselor. Plus, in addition to the useful information on this page, don’t forget to visit [FINRED's collection of benefits and entitlement resources](/Benefits).

* [5 Things to Do After Filing Your FAFSA® Form](https://www.ed.gov/content/5-things-do-after-filing-your-fafsa%C2%AE-form-0)
  The U.S. Department of Education recommends taking five steps after you submit your Free Application for Federal Student Aid (FAFSA) form. Review them here.
* [Blended Retirement System Comparison Calculator ](https://militarypay.defense.gov/Calculators/BRS/)
  Take the Blended Retirement System (BRS) Opt-In Course, if applicable, then use this Comparison Calculator to compare the legacy retirement system and BRS.
* [Budgeting for COLA Payment Fluctuations](https://www.defensetravel.dod.mil/site/colaBudgeting.cfm)
  Service members’ overseas Cost of Living Allowance, or COLA, can change from pay period to pay period. Plan ahead using the tips, calculator on this webpage.
* [Financial Aid Information](https://www.dodea.edu/StudentServices/financial-aid.cfm)
  Use this Department of Defense Education Activity webpage to research scholarships, aid and grants that can help you pay for post-secondary school.
* [GI Bill® Comparison Tool](https://www.vets.gov/gi-bill-comparison-tool)
  Use this GI Bill® Comparison Tool to learn about education programs and compare benefits by school so that you make the best decision for you.
* [How to Complete the FAFSA® Form When You Have Multiple Children](https://studentaid.gov/articles/fafsa-multiple-children/)
  Have two or more children going to college at the same time? Here's how to save time filling out your Free Application for Federal Student Aid, or FAFSA, forms.
* [MySECO ](https://myseco.militaryonesource.mil/portal/)
  Turn to the Spouse Education and Career Opportunities program for education and career guidance and a suite of tools and resources for every career stage.
* [Post-9/11 GI Bill](https://explore.va.gov/education-training/gi-bill)
  Explore the Post-9/11 GI Bill®, which offers higher education and training benefits to veterans and service members who have served after Sept. 10, 2001.
* [Uniformed Services Blended Retirement System ](https://militarypay.defense.gov/blendedretirement/)
  Find everything you need to know about the Uniformed Services Blended Retirement System, including questions and answers, videos and a calculator.
* [VA Home Loans](https://benefits.va.gov/homeloans/)
  Explore the benefits, eligibility and application details of home loans that feature a guarantee from the Department of Veterans Affairs, or VA.
* [VA Home Loans and Housing-Related Assistance](https://explore.va.gov/home-loans-and-housing)
  Buying, refinancing or improving a home? Check out Department of Veterans Affairs (VA) home loan programs and apply online or by lender or mail.
* [Veterans’ Spouses, Dependents, and Survivors Eligibility Overview](https://explore.va.gov/health-care/spouses-dependents-survivors)
  Spouses, dependents and survivors of veterans may qualify for Department of Veterans Affairs health care benefits through certain programs. Learn more.
* [What Are the Differences Between 529 Plans? ](https://www.consumerfinance.gov/ask-cfpb/what-are-the-differences-between-529-plans-en-2078/)
  Learn about the two types of tax-advantaged 529 college savings plans and decide which one — prepaid tuition or savings — is best for you and your family.
* [What You Need to Know About Your Federal Student Loan Benefits](https://studentaid.gov/understand-aid/types/military)
  Wondering about the variety of federal student loan benefits and repayment options for service members? Here's where you can find that information.
          `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


