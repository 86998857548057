/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Money ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Managing Your Money`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Managing Your Money
Don’t just assume that last year’s spending plan is still the best approach. Managing your money is a constant practice that requires consistent review. Use these resources to help you discover new and better ways to spend and save smart. Don’t hesitate to check in with an accredited [personal financial manager](https://installations.militaryonesource.mil/) or counselor to make sure you’re still making the best choices for your financial future. Plus, in addition to the supplemental sources on this page, don’t forget to visit [FINRED's collection of money management resources](/Money).

* [11 Calculators to Help You Scope Out Your Personal Finances](https://www.militaryonesource.mil/-/11-calculators-to-help-you-scope-out-your-personal-finances?inheritRedirect=true&redirect=%2Ffinancial-legal)
  Make numbers add up in your favor! Use these 11 calculators to calculate vehicle payments, credit card payoffs, college savings and more and take action.
* [Defense Finance and Accounting Service](https://www.dfas.mil/)
  Access the Defense Finance and Accounting Service for information about payment services for military members, retirees, civilian employees and more.
* [Financial Resource Guide](https://www.yellowribbon.mil/cms/financial-resource-guide/)
  Turn to the Yellow Ribbon Reintegration Program, which offers vetted resources to support the financial well-being of those who serve and those who support.
* [Find Support for Every MilLife Milestone](/assets/downloads/FINRED-M3Resources-F.pdf)
  Military spouses face MilLife Milestones alongside their partners. This flyer shares resources to help you navigate each stop along the way.
* [Internal Revenue Service ](https://www.irs.gov/)
  Visit the Internal Revenue Service website to check your refund status, get your tax record, view your account, make a payment, find forms and more. 
* [IRS Withholding Calculator ](https://www.irs.gov/individuals/irs-withholding-calculator)
  Follow Internal Revenue Service advice: Use this IRS Withholding Calculator to make sure you have the right amount of tax withheld from your paycheck.
* [Loan Calculator ](https://tools.finra.org/loan/)
  Use the Financial Industry Regulatory Authority (FINRA) Loan Calculator to find the monthly payment for a fixed rate loan for a vehicle or mortgage.
* [Military Compensation Website](https://militarypay.defense.gov/)
  Bookmark this Military Compensation webpage. It's your resource for information about pay, benefits, retirement, survivor benefits, taxation and more.
* [MyMoney.gov](https://www.mymoney.gov/Pages/default.aspx)
  Watch your wealth stack up! Try using five building blocks — Earn, Save and Invest, Protect, Spend and Borrow — and find tips galore on MyMoney.gov.
* [myPay](https://mypay.dfas.mil/)
  Create a myPay account to process certain discretionary pay data items online with no need for paper forms, and access your pay and tax statements.
* [Tips for Eating Healthy on a Budget](https://www.myplate.gov/eat-healthy/healthy-eating-budget)
  Check out these useful tips and convenient tools that can help you lower your grocery bill while maintaining a healthy diet.
* [U.S. Department of the Treasury ](https://home.treasury.gov/)
  Learn more about the U.S. Department of the Treasury and catch up on the latest news, interest rate statistics, financial sanctions and more.
         `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


