/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';

import { getFullName } from './helpers';
import Link from '../../components/link';


const Results = function({ i, setPreviewPoint, map, setZoom }) {
    function zoom(i) {
        map.panTo({ lat: i.lat, lng: i.long });
        const ZOOMINCRIMENT = 1;
        const ENDZOOM = 14;
        function zoomIn() {
            if (map.getZoom() < ENDZOOM) {
                map.setZoom(map.getZoom() + ZOOMINCRIMENT);
                setTimeout(zoomIn, 2 * 100);
                setZoom(map.getZoom());
            }
        }
        zoomIn();
    }
    return (
      <div className="" onMouseEnter={() => setPreviewPoint(i)} onMouseLeave={() => setPreviewPoint()}>
        {
              i.properties.results && i.properties.results.map(i => (
                <div key={i.phone} onClick={() => zoom(i)} className="markerGroup">
                  <p>{i.city}</p>
                  <p>{i.email}</p>
                  <p>{i.phone}</p>
                  <p>{i.type}</p>
                </div>
              ))
          }
        {
              !i.properties.results
                && (
                <div key={i.phone} onClick={() => zoom(i)} className="markerGroup">
                  <p>{i.city}</p>
                  <p>{i.email}</p>
                  <p>{i.phone}</p>
                  <p>{i.type}</p>
                </div>
                )
          }
      </div>
    );
};
function zip(m) {
    const s = {};
    for (const i of m) {
        const k = i.lat + ',' + i.long;
        if (!s[k]) {
            s[k] = {
                lat: i.lat,
                long: i.long,
                results: [i],
            };
        } else s[k].results.push(i);
    }
    return Object.values(s);
}


function clusterPrep(s) {
    for (const i of s) {
        i.properties = {
            ...i,
        };
        i.geometry = {
            type: 'Point',
            coordinates: [i.long, i.lat],
        };

        i.type = 'Feature';
    }
    return s;
}
// markers = zip(markers);


const index = new Supercluster({
    radius: 120,
    maxZoom: 31,
    map: (props) => ({ results: [props] }),
    reduce: (a, c) => {
        a.results = a.results.concat(c.results);
    },
});



function PFCMap ({ location, noLocationFound }) {

    const clusteredMarkers = clusterPrep(location);
    const [previewPoint, setPreviewPoint] = useState();
    const [points, setPoints] = useState([]);
    const [zoom, setZoom] = useState([]);

    const mapRef = useRef();

    index.load(clusteredMarkers);

    const defaultProps = {
        center: {
            lat: clusteredMarkers[0].lat,
            lng: clusteredMarkers[0].long,
        },
        zoom: 5,
    };
    const Marker = function({ i, preview, setZoom, screenSpace = { x: 0, y: 150 } }) {
        const map = mapRef.current;
        function zoom(i) {
            map.panTo({ lat: i.lat, lng: i.long });
            const ZOOMINCRIMENT = 1;
            const ENDZOOM = 15;
            function zoomIn() {
                if (map.getZoom() < ENDZOOM) {
                    map.setZoom(map.getZoom() + ZOOMINCRIMENT);
                    setTimeout(zoomIn, 2 * 100);
                    setZoom(map.getZoom());
                }
            }
            zoomIn();
        }
        const [selected, setSelected] = useState();

        return (
          <div
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
          >
            <div className={'marker-container ' + (selected ? ' clear' : '')}>
              <div className={'marker ' + (preview ? ' preview' : ' ')}>1</div>
            </div>




            { selected && (
            <div className={'hidden-border ' + (screenSpace.x > 540 ? ' left' : '') + (screenSpace.y > 300 ? ' up' : '') + (screenSpace.y < 100 ? ' down' : '')} style={{ zIndex: selected ? 10000 : 1 }}>
              <div
                className="marker-expanded-single"
                onClick={() => zoom(i)}

              >
                <div className="marker-group-container" key={i.email}>
                  <p className="marker-header">{i.city}</p>
                  <ul className="marker-list">
                    <li>{i.state}</li>
                    <li><a onClick={(e)=>{e.stopPropagation()}} href={`mailTo:${i.email}`}>{i.email}</a></li>
                    <li>{i.phone}</li>
                    <li>{getFullName(i.BOS)}</li>
                  </ul>
                </div>
              </div>
            </div>
            ) }
          </div>
        );
    };
    const MarkerGroup = function({ i, preview, setZoom, screenSpace = { x: 0, y: 150 } }) { 
        const map = mapRef.current;
        function zoom(i) {
            map.panTo({ lat: i.lat, lng: i.long });
            const ZOOMINCRIMENT = 1;
            const ENDZOOM = 8;
            function zoomIn() {
                if (map.getZoom() < ENDZOOM) {
                    map.setZoom(map.getZoom() + ZOOMINCRIMENT);
                    setTimeout(zoomIn, 2 * 100);
                    setZoom(map.getZoom());
                }
            }
            zoomIn();
        }
        const [selected, setSelected] = useState();
        const markersCount = i.properties.results.length - 1;

        return (
          <div
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
          >

            <div className={'marker-container ' + (selected ? ' clear' : '')}>
              <div className={'marker ' + (preview ? ' preview' : ' ')}>
                {i.properties.point_count}
              </div>
            </div>

            { selected && (
            <div className={'hidden-border group' + (screenSpace.x > 540 ? ' left' : '') + (screenSpace.y > 250 ? ' up' : '') + (screenSpace.y < 100 ? ' down' : '')} style={{ zIndex: selected ? 10000 : 1 }}>
              <div
                className="marker-expanded"
                onClick={() => zoom(i)}
              >
                { i.properties.results.map((i, index) => (
                  <div className="marker-group-container" key={i.email}>
                    <p className="marker-header">{i.city}</p>
                    <ul className="marker-list">
                      <li>{i.state}</li>
                      <li><a onClick={(e)=>{e.stopPropagation()}} href={`mailTo:${i.email}`}>{i.email}</a></li>
                      <li>{i.phone}</li>
                      <li>{getFullName(i.BOS)}</li>
                    </ul>
                    { index < markersCount && <hr className="result-divider" /> }
                  </div>
                ))}
              </div>
            </div>
            ) }
          </div>
        );
    };

    return (
      <>
        <div className="mapsHolder">
          <GoogleMapReact
            // key={location} // force refresh
            bootstrapURLKeys={{ key: 'AIzaSyAhBdbWK1CsV0rs8JCys7xzb28N74oBcE4' }}
            center={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            maxZoom={7}
            yesIWantToUseGoogleMapApiInternals
            onChange={({ zoom, bounds }) => {
                const points = index.getClusters([
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat,
                ], zoom);
                points.forEach(p => {
                    p.lat = p.geometry.coordinates[1];
                    p.long = p.geometry.coordinates[0];

                    if (mapRef.current) {
                        const google = window.google;
                        const map = mapRef.current;
                        const numTiles = 1 << map.getZoom();
                        const projection = map.getProjection();

                        const point = new google.maps.LatLng(
                            p.lat,
                            p.long,
                        );


                        const worldCoordinate = projection.fromLatLngToPoint(point);
                        const pixelCoordinate = new google.maps.Point(
                            worldCoordinate.x * numTiles,
                            worldCoordinate.y * numTiles,
                        );

                        const topLeft = new google.maps.LatLng(
                            map.getBounds().getNorthEast().lat(),
                            map.getBounds().getSouthWest().lng(),
                        );

                        const topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
                        const topLeftPixelCoordinate = new google.maps.Point(
                            topLeftWorldCoordinate.x * numTiles,
                            topLeftWorldCoordinate.y * numTiles,
                        );

                        p.screenSpace = new google.maps.Point(
                            pixelCoordinate.x - topLeftPixelCoordinate.x,
                            pixelCoordinate.y - topLeftPixelCoordinate.y,
                        );
                    }
                });
                setPoints(points);
            }}
            onGoogleApiLoaded={({ map }) => {
                mapRef.current = map;
            }}
          >
            { !noLocationFound
                ? points.map(m => (
                    m.properties.results ? (
                      <MarkerGroup
                        key={m.lat}
                        lat={m.lat}
                        lng={m.long}
                        i={m}
                        preview={previewPoint && m.lat === previewPoint.lat && m.long === previewPoint.long}
                        map={mapRef.current}
                        setZoom={setZoom}
                        screenSpace={m.screenSpace}
                      />
                    ) : (
                      <Marker
                        key={m.lat}
                        lat={m.lat}
                        lng={m.long}
                        i={m}
                        preview={previewPoint && m.lat === previewPoint.lat && m.long === previewPoint.long}
                        map={mapRef.current}
                        setZoom={setZoom}
                        screenSpace={m.screenSpace}
                      />
                    )
                ))
                : (
                  <div className="no-locations-found">
                    <p>If your state or country is not listed, visit Military OneSource at <Link to="https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/">https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/</Link> to determine what no-cost financial counseling resources are available to you.</p>
                  </div>
                )
            }

          </GoogleMapReact>
        </div>
      </>
    );
}

export default PFCMap;
