/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Auto (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Auto Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Auto Calculators
![Red car](/assets/img/FINRED-WebsiteImage-Auto-C.jpg "Red car")

Before you head to the dealership, take the time to figure out the numbers you need to know. For instance, what’s the total amount — interest and fees included — that you want to spend? How much can you afford for a payment each month? Doing these calculations ahead of time will give you the confidence and clarity you need to negotiate the right deal for you.

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
       `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Auto Refinance Interest Savings Calculator"
              calculator="/assets/calc/ShouldIRefiAuto.html"
              title="1"
              resource={calculator}
              resourceLabel="Auto-Refinance-Interest-Savings-Calculator"
            >
            Refinancing your auto loan could mean saving money on interest. Use this calculator to determine just how much you could save.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Car Loan"
              calculator="/assets/calc/AutoLoan.html"
              title="2"
              resource={calculator}
              resourceLabel="Car-Loan"
            >
            Use this calculator to see how buying a car will affect your spending plan by determining your estimated monthly payment based on purchase price.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Lease vs. Buy"
              calculator="/assets/calc/BuyvsLease.html"
              title="3"
              resource={calculator}
              resourceLabel="Lease-vs-Buy"
            >
            Should you lease or should you buy? This calculator can help give you more information to answer that question.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Auto Loan Early Payoff"
              calculator="/assets/calc/AutoPayoff.html"
              title="4"
              resource={calculator}
              resourceLabel="Auto-Loan-Early-Payoff"
            >
            Increasing your monthly payment can yield big savings in interest over time — use this calculator to see just how much.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Auto Rebate vs Low Financing"
              calculator="/assets/calc/AutoRebate.html"
              title="5"
              resource={calculator}
              resourceLabel="Auto-Rebate-vs-Low-Financing"
            >
            With this calculator, you can compare the numbers for taking advantage of low interest financing versus a manufacturer’s rebate.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


