/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import BNA from '../pages/bna';
import BNAServiceResources from '../pages/bna-service-resources';
import HookUpArticle from '../components/hookUpArticle';
import BenefitsTOC from '../components/tocs/benefits';
import Oops404Page from '../pages/oops-404';

export default function BnaRoutes(props) {
    const root = props.match.path;
    const crumbs = `${props.crumbs},Basic Needs Allowance|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <BNA {...routerProps} crumbs={crumbs} toc={props.toc} />}
        />

        <Route
          exact
          path={`${root}/ServiceResources`}
          render={(routerProps) => <BNAServiceResources {...routerProps} crumbs={crumbs} toc={props.toc} />}
        />

        {/* {[
            HookUpArticle(root, crumbs, BenefitsTOC, 'ServiceResources.md', 'Benefits', 'FAQ and Service Resources'),
        ]} */}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

