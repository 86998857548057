/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import path from 'path';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from 'constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from '../components/page';
import MarkLink from '../components/markLink';
import Link from '../components/link';
import BlogpostHeader from '../components/blogpost';
import ReactMarkdown from '../components/markdown';
import api from '../api';

const mds = require.context('./blog', false, /\.md$/);
function strip(str) {
    const regexp = /<!--|-->|##/g;
    return str.replace(regexp, '');
}
function stripImg(str) {
    const regexp = /\!\[\]\(|\)/g;
    return str.replace(regexp, '');
}
class ArticleManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
        };
    }

    componentWillMount() {
        this.load();
    }

    async newPost() {
        const newPost = {
            title: 'An article title',
            pullquote: 'This is the abstract that will be seen on the home page',
            href: '',
            img: '',
            category: '',
        };
        await api.post('/posts', newPost);
        this.load();
    }

    load() {
        const keys = mds.keys();
        const paths = [];
        const hrefs = [];
        for (const i in keys) {
            const fetchpath = mds(keys[i]);
            const filename = path.basename(keys[i], path.extname(keys[i]));
            hrefs.push(filename);
            paths.push(fetchpath);
        }
        api.get('/posts').then((response) => {
            const DBPosts = response.data;

            const posts = DBPosts.map(i => ({
                title: i.title,
                pullquote: i.abstract,
                href: i._id,
                img: i.thumbnail,
                category: i.category,
            }));

            this.props.onLoadBlogs(posts);
        });
    }

    renderAddPost() {
        if (this.props.session.loggedIn) {
            return (

              <div onClick={() => this.newPost()} className="btn btn-primary btn-raised">New Article</div>

            );
        }
    }

    renderPosts() {
        const posts = [];
        for (const i in this.props.posts) {
            posts.push(
              <BlogpostHeader i={i} {...i} parent={this} />,
            );
        }
        return posts;
    }

    render() {
        if (!this.props.session.loggedIn) { return ''; }
        return (
          <Page crumbs="Home|/,Manage Articles">
            <div className="usa-grid no-left-pad no-right-pad">

              <div className="usa-width-full">
                {this.renderAddPost()}
                <h2>Manage Articles</h2>
                {this.renderPosts()}
              </div>
            </div>
          </Page>
        );
    }
}


const mapStateToProps = state => ({
    posts: state.blogs.docs,
    session: state.session,
});

const mapDispatchToProps = dispatch => ({
    onLoadBlogs: (blogs) => dispatch({ type: 'blogsLoaded', blogs }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ArticleManager));
