/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import contacts from '../pages/map-resources/pfc.json';

import usePreferedColorScheme from '../pages/map-resources/hooks/use-prefered-color-scheme';

const columns = [
    { key: 'location', name: 'Location', width: 300 },
    { key: 'address', name: 'Address', width: 900 },
    { key: 'zipCode', name: 'Zip Code', width: 140 },
    { key: 'state', name: 'State or Country', width: 180 },
    { key: 'mos', name: 'MOS', width: 100 },
    { key: 'email', name: 'Email', width: 300 },
    { key: 'phone', name: 'Phone', width: 200 },
    { key: 'branch', name: 'branch', width: 380 },
    { key: 'mapType', name: 'Map Type', width: 100 },
];

const compareState = (aObj, bObj) => {
    const a = aObj.state.toUpperCase();
    const b = bObj.state.toUpperCase();

    if (a < b) return -1;
    if (a < b) return 1;
    return 0;
};

const getRowData = () => contacts.filter(validContact => validContact.city !== 'Vermont')
    .map(contact => (
        {
            location: contact.city,
            address: contact.address,
            zipCode: contact.zipcode,
            state: contact.state,
            mos: contact.BOS,
            email: contact.email,
            phone: contact.phone,
            branch: contact.type,
            mapType: contact.mapType,
        }
    )).sort(compareState);

export default function MapSpreadsheetRoutes(props) {
    const mode = usePreferedColorScheme();

    const rowData = getRowData();
    // ag-theme-alpine-dark
    return (
      <>
        {mode
        && (
          <div className={mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'} style={{ height: '100vh' }}>
            <AgGridReact
              rowData={rowData}
              defaultColDef={{
                  width: 500,
              }}
            >
              {
                columns.map(column => (
                  <AgGridColumn
                    field={column.key}
                    key={column.key}
                    width={column.width}
                  />
                ))
              }
            </AgGridReact>
          </div>
        )
      }
      </>
    );
}
