/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function PersonalFinance (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Personal Finance Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Personal Finance Calculators
![Paperwork with credit cards](/assets/img/FINRED-WebsiteImage-PersonalFinance-C.jpg "Paperwork with credit cards")

Keeping track of your personal finances helps ensure you’re spending your money in a way that aligns with your priorities. With these calculators, you can assess your personal finances and make a plan to adjust your spending as needed.

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
       `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Comprehensive Life Insurance Analysis Calculator"
              calculator="/assets/calc/EnhancedLifeIns.html"
              title="1"
              resource={calculator}
              resourceLabel="Comprehensive-Life-Insurance-Analysis-Calculator"
            >
            Life insurance is one of the best ways to ensure your family will be taken care of upon your death. Use this tool to calculate how much you need.
            </CalculatorLink>
          </li>
        </ul>
        <ReactMarkdown
          source={`
* [Debt Destroyer® Workshop](${root}/DebtDestroyer)
Start destroying your debt with this workshop. Learn techniques for making the most of your money, tackling consumer debt and securing your financial future.
          `}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Debt to Income Ratio"
              calculator="/assets/calc/PersonalDebtRatio.html"
              title="2"
              resource={calculator}
              resourceLabel="Debt-to-Income-Ratio"
            >
            Your ratio of debt to income is an important metric for your financial health and your ability to qualify for a loan. Use this calculator to figure it out.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Home Budget Analysis"
              calculator="/assets/calc/HomeBudget.html"
              title="3"
              resource={calculator}
              resourceLabel="Home-Budget-Analysis"
            >
            Your household’s spending plan is the best indicator of how you spend your money. See where it’s going and how you can cut costs with this calculator.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Student Budget"
              calculator="/assets/calc/StudentBudget.html"
              title="4"
              resource={calculator}
              resourceLabel="Student-Budget"
            >
            Specifically designed for students, this calculator can help you stay on top of your finances, so you can focus on what really matters: your studies.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Should My Spouse Work?"
              calculator="/assets/calc/IncomeOneorTwo.html"
              title="5"
              resource={calculator}
              resourceLabel="Should-My-Spouse-Work"
            >
            Having two incomes can make a big difference in your finances. This calculator can help you compare the numbers.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Credit Assessment"
              calculator="/assets/calc/CreditAssessment.html"
              title="6"
              resource={calculator}
              resourceLabel="Credit-Assessment"
            >
            Trying to build your credit? This calculator can help you assess your current credit and see areas for improvement.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


