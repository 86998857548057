/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SplTOC from '../../components/tocs/splTOC';
import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import DebtDestroyer from '../../pages/navy-resources/debt-destroyer';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function DebtDestroyerRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Debt Destroyer® Workshop|${root}` : `Debt Destroyer® Workshop|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(rps) => <DebtDestroyer {...rps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Step1-Stop-The-Bleeding', 'Step 1: Stop the Bleeding', crumbs,
                      '/assets/video/navy-resource/debt/ndd_step1_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Step2-Build-a-Safety-Net', 'Step 2: Build a Safety Net', crumbs,
                      '/assets/video/navy-resource/debt/ndd_step2_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Step3-Confront-Your-Debt', 'Step 3: Confront Your Debt', crumbs,
                      '/assets/video/navy-resource/debt/ndd_step3_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Step-4-Know-Your-Cash-Flow', 'Step 4: Know Your Cash Flow', crumbs,
                      '/assets/video/navy-resource/debt/ndd_step4_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Step-5-Adjust-Your-Cash-Flow', 'Step 5: Adjust Your Cash Flow', crumbs,
                      '/assets/video/navy-resource/debt/ndd_step5_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Step-6-Pay-It-Down', 'Step 6: Pay It Down!', crumbs,
                      '/assets/video/navy-resource/debt/ndd_step6_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Analyze-and-Minimize', 'Analyze and Minimize', crumbs,
                      '/assets/video/navy-resource/debt/ndd_wtdn-a_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Debt-Payoff-Plan', 'Debt Payoff Plan', crumbs,
                      '/assets/video/navy-resource/debt/ndd_wtdn-b_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Introduction', 'Introduction', crumbs,
                      '/assets/video/navy-resource/debt/ndd_intro_final (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Marketing-B', 'Marketing B', crumbs,
                      '/assets/video/navy-resource/debt/ndd_marketing_video_-_b (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Marketing-C', 'Marketing C', crumbs,
                      '/assets/video/navy-resource/debt/ndd_marketing_video_-_c (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-DebtDestroyer-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
