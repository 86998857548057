/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Link from './link';
import Img from './img';
export default class SiteTitle extends React.Component {
    render() {
        return (
          <div className="usa-logo " id="extended-logo">
            <em className="usa-logo-text">
              <Link to="/" title="Home" aria-label="Home">
                <Img alt="DoD Seal" style={{ objectFit: 'contain', width: '230px', verticalAlign: 'middle' }} src="/assets/img/mainlogo.png" />
              </Link>
            </em>
          </div>
        );
    }
}

