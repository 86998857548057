/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react';

import Page from '../components/page';
import LinkModal from '../components/linkModal';
import Link from '../components/link';

export default function FWBSelfAssessment (props) {
    const root = props.match.path;
    const [showModal, setShowModal] = useState(false);
    const [modalSite, setModalSite] = useState();

    useEffect(() => {
        const handler = event => {
            let data;
            try {
                data = JSON.parse(event.data);
            } catch (error) {
                return;
            }

            if (data.message) {
                if (data.message.startsWith('/')) {
                    window.open(data.message, '_blank');
                } else {
                    setModalSite(data.message);
                    setShowModal(true);
                }
            }
        };

        window.addEventListener('message', handler);

        // clean up
        return () => window.removeEventListener('message', handler);
    }, []);

    return (
      <>
        <Page
          crumbs={`${props.crumbs},Financial Well-Being Assessment (Digital)`}
        >
          <Link to={props.prev}>Return to Main Page</Link>
          <IframeResizer
            log
            heightCalculationMethod="bodyScroll"
            id="my-iframe"
            title="1"
            src="/usaaef-finred-self-assessment/index.html"
            style={{ width: '1px', border: 'none', minWidth: '100%' }}
            sandbox="allow-scripts allow-same-origin allow-modals"
          />
        </Page>
        {
            showModal && (
              <LinkModal
                dismiss={() => setShowModal(false)}
                href={modalSite}
              />
            )
        }
      </>
    );
}
