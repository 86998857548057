/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';

export default function LightBox({ open = false, onOpen = () => { }, onClose = () => { }, ...props }) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => { if (open) onOpen(); });

    function _open(e) {
        e.preventDefault();
        onOpen();
        setIsOpen(true);
    }

    function _close() {
        onClose();
        setIsOpen(false);
    }

    function renderLightbox() {
        return (
          <div className="lightbox-back">
            <div className="lightbox-close">
              <i className="fa fa-close" onClick={() => _close()} />
            </div>
            <div className={'lightbox-fore ' + (props.dark ? ' dark' : '')}>
              {/* <div className="lightbox-close">
                <i className="fa fa-close" onClick={() => this.close()} />
              </div> */}
              {props.children}
            </div>
          </div>
        );
    }

    function renderLink() {
        return props.text && <a href="." {...props} onClick={(e) => _open(e)}>{props.text}</a>;
    }

    return (
      <>
        {!props.doNotDisplayLink && renderLink() }
        {(open || isOpen) && renderLightbox()}
      </>
    );
}

