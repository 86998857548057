/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import Page from '../components/splPage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function LeaderCard (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Starting the Conversation

Financial readiness is an important part of mission readiness — and talking to service members about their financial health is a key step to achieving both. Yet, the stigma around money can make introducing the subject of personal finances especially challenging. With these topic-based cards, you gain helpful tips and ideas for starting the conversation, as well as resources you can share to help your service members build their financial health. Once printed, these cards are designed to fold and fit in a uniform pocket, so you can easily pull them out for a quick reference.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-LC.jpg"
            alt="Helping Service Members Assess Basic Needs Allowance"
            title="Talking to Service Members About Basic Needs Allowance"
            href="/assets/downloads/FINRED-BNA-LC.pdf"
            type="Leader Card"
            link="Download"
          >
            <p>
            Help yourself and service members under your command better understand Basic Needs Allowance and where to find support and resources. Use this card to start the conversation.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-SPC.jpg"
            alt="Helping Service Members Assess Basic Needs Allowance Eligibility"
            title="Helping Service Members Assess Basic Needs Allowance Eligibility"
            href="/assets/downloads/FINRED-BNA-SPC.pdf"
            type="Leader Card"
            link="Learn More"
          >
            <p>
            As a service provider, help the service members you support understand whether they might be eligible for the Basic Needs Allowance. This conversation card can help.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-LeadershipDiscussions-A.jpg"
            alt="Two soldiers looking at a laptop"
            title="Leadership Discussions: Lasting Impacts Before You Go"
            href={`${root}/LeadershipDiscussions`}
            link="Learn More"
          >
            <p>
            Talking to your service members about retirement savings is one way to leave a lasting impact as a leader. Use these tips to start the conversation.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Starting the Conversation: How To Talk to Service Members About Personal Finances](${root}/StartingTheConversation)
Talking about money isn’t easy, but it’s important for leaders at all levels to start the conversation with their service members.
* [Talking to Service Members About Consumer Protections](/assets/downloads/FINRED-ConsumerProtection-LC.pdf)
Service members may not always know about the consumer protections available to them, but you can help spread awareness with these conversation starters.
* [Talking to Service Members About Creating a Spending Plan](/assets/downloads/FINRED-SpendingPlan-LC.pdf)
Having a spending plan sets service members on the path to financial readiness. Use these conversation starters and resources to discuss how to set one up.            
* [Talking to Service Members About Credit](/assets/downloads/FINRED-CreditImportance-LC.pdf)
Building, managing and monitoring credit are all important factors of financial and mission readiness. Here’s how to talk about it with your service members.            
* [Talking to Service Members About Filing Taxes ](/assets/downloads/FINRED-TaxFiling-LC.pdf)
Filing taxes while in the military can get complicated. Use this leader card to discuss the benefits and resources available to your service members to help them navigate the process.
* [Talking to Service Members About Financial Calculators](/assets/downloads/FINRED-FinancialCalculators-LC.pdf)
Financial calculators can help your service members get an accurate picture of their financial health. Use these resources to start the conversation.
* [Talking to Service Members About Identity Protection](/assets/downloads/FINRED-IdentityTheft-LC.pdf)
Identity protection can help service members reduce their risk of fraud. Use these resources to start the conversation about protecting personal information.
* [Talking to Service Members About Large Purchases](/assets/downloads/FINRED-LargePurchase-LC.pdf)
Military families can make smart money moves together. Guide service members and their spouses to access financial resources through MilSpouse Money Mission.
* [Talking to Service Members About Retirement](/assets/downloads/FINRED-RetirementSavings-LC.pdf)
Actively preparing for retirement is one of the best ways to safeguard future finances. Talk to your service members about their plans and strategies.
* [Talking to Service Members About Starting Their Financial Readiness Journey](/assets/downloads/FINRED-WhereToStart-LC.pdf)
Getting started is often the hardest part of any good plan. Talk to your service members about where to find the best resources for financial advice.
* [Talking to Service Members About the Financial Well-Being Assessment](/assets/downloads/FINRED-FWBA-LC.pdf)
Encourage your service members to assess their financial well-being with the new self-assessment from the Department of Defense. Here’s how to start the conversation.
* [Talking to Service Members About Their Financial Toolkit](/assets/downloads/FINRED-FinancialToolkit-LC.pdf)
Help your service members improve their financial well-being on the go. Encourage them to plan ahead with Sen$e, our no-cost mobile app.
* [Talking to Service Members About Their Financial Upbringing](/assets/downloads/FINRED-InfluenceUpbringing-LC.pdf)
A service member’s background can influence how they make financial decisions. These questions and resources can help you start the conversation.
          `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


