/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';

export default function StartHere (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Overview`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Overview
Use these foundational resources as a starting point for your work as a personal financial manager or command financial specialist. With important background on the Touchpoint Curriculum, policy reminders and additional support materials, we invite you to return to this page often as you assist Coast Guard members through career milestones and financial turning points.

### How To Use Touchpoint Materials
Please use this PowerPoint, Instructor Guide and Reference Manual to orient your leadership team with the Touchpoint Curriculum. This resource can assist with basic PFM and CFS tasks. This curriculum provides useful strategies and tips to help service members address financial considerations.

* [PowerPoint Slides](/assets/downloads/TC%201%20Introduction%20Touchpoint%20Curriculum%20Presentation%20CG%208-5-21.pptx)
* [Instructor Guide](/assets/downloads/TC%201%20Introduction%20Touchpoint%20Curriculum%20Instructor%20Guide%20CG%208-5-21.pdf)
* [CFS Reference Manual](/assets/downloads/TC%201%20Introduction%20Touchpoint%20Curriculum%20CFS%20Reference%20Manual%20CG%208-5-21.pdf)

### Personal Financial Management Program Policy

* [Commandant Instruction
    1740.8A](https://media.defense.gov/2020/Dec/21/2002555275/-1/-1/0/CI_1740_8A.PDF):
    Coast Guard Personal Financial Management Program
* [Commandant Instruction
    1740.3A](https://media.defense.gov/2017/Mar/06/2001707728/-1/-1/0/CI_1740_3A.PDF):
    Use of Non-Governmental Educational Material and Presenters — All
    non-governmental organizations, or NGOs, must submit a written
    request to Commandant (CG-111) and obtain designation as a qualified
    non-government topical education presenter.
* **Qualified Non-Government Organizations**: This
    **[list](https://www.dcms.uscg.mil/Portals/10/CG-1/cg111/docs/Per%20Fin/Qualified_Non-Goverment_Organizations.docx?ver=2020-05-07-073532-707)**
    includes Commandant (CG-111)-approved non-government agencies that
    can provide personal financial management classes and materials to
    Coast Guard personnel.

### Command Financial Specialist Program Overview 

* [Program Overview](${root}/CFSProgramOverview)

### Where Are Personal Financial Managers Located

* Please **[contact your
    PFM](https://www.dcms.uscg.mil/Portals/10/CG-1/cg111/docs/Finance/Copy%20of%20August%20PFM%20list%20update.xlsx?ver=Uv_-50uBvCqVfNmkQCTxLw%3d%3d)**
    on your Regional Work-Life Staff at 202-475-5100. If additional
    assistance is needed, please contact Office of Work-Life HQ at
    **<HQS-SMB-FamilySupportServices@uscg.mil>**.
* [PFM Map](/assets/downloads/PFM%20Map.pdf)

### CG SUPRT Offerings

CG SUPRT offers all eligible Coast Guard personnel enhanced personal
financial management services. The program provides one-on-one Money
Coaching, a personalized website with helpful tools and calculators,
twice-a-month webinars and more!

**You may contact CG SUPRT** by visiting
[www.CGSUPRT.com](http://www.cgsuprt.com/) or **855-CG SUPRT**
(247-8778)

* [Tax Filing
    Flyer](https://www.dcms.uscg.mil/Portals/10/CG-1/cg111/docs/pdf/CG%20SUPRT%20Tax%20Filing%202018.pdf?ver=2018-11-19-124431-833)
* [CG SUPRT Personal Financial Wellness Education
    Catalog](https://assets.mysecureadvantage.com/cgsuprt/cgsuprt-education-catalog-2020.pdf)

### CFS Materials and Resources

All Coast Guard command financial specialist, or CFS, resources can be
found in the [Coast Guard CFS Resource
website](https://www.milsuite.mil/book/groups/uscg-cfs-resources). The
website includes CFS curriculum, resources and training materials.
Please contact your local Coast Guard PFM if you are unable to access
the CFS milSuite site (CAC protected).

`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


