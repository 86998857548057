/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpArticle from '../components/hookUpArticle';
import TrendingTOC from '../components/tocs/trendingTOC';
import SMChallenge from '../pages/sm-challenges';
import Oops404Page from '../pages/oops-404';


export default function SMChallengesRoutes(props) {
    const root = props.match.path;
    const toc = () => (props.toc || <TrendingTOC />);
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `Take a Challenge|${root}`;

    return (
      <div>
        <Switch>
          <Route
            exact
            path={root}
            render={(routeProps) => <SMChallenge {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          {[
              HookUpArticle(root, crumbs, toc(), '30DayFinancialChallenge-Week1.md', 'Trending SMChallenges', 'Week 1 — Family and Self-Assessments'),
              HookUpArticle(root, crumbs, toc(), '30DayFinancialChallenge-Week2.md', 'Trending SMChallenges', 'Week 2 — Basic Finances: Spending Plan'),
              HookUpArticle(root, crumbs, toc(), '30DayFinancialChallenge-Week3.md', 'Trending SMChallenges', 'Week 3 — Saving for Emergencies, Investing for the Future'),
              HookUpArticle(root, crumbs, toc(), '30DayFinancialChallenge-Week4.md', 'Trending SMChallenges', 'Week 4 — Debt and Credit Management'),
              HookUpArticle(root, crumbs, toc(), '30DayFinancialChallenge-Week5.md', 'Trending SMChallenges', 'Week 5 — Reinforcing Gains and Behaviors and Looking Ahead'),
              HookUpArticle(root, crumbs, toc(), 'InvestingChallenge-Week1.md', 'Trending SMChallenges', 'Week 1 — Investing Essentials'),
              HookUpArticle(root, crumbs, toc(), 'InvestingChallenge-Week2.md', 'Trending SMChallenges', 'Week 2 — Balancing Risks Versus Returns'),
              HookUpArticle(root, crumbs, toc(), 'InvestingChallenge-Week3.md', 'Trending SMChallenges', 'Week 3 — Alternative Investments'),
              HookUpArticle(root, crumbs, toc(), 'InvestingChallenge-Week4.md', 'Trending SMChallenges', 'Week 4 — Investing in Your Retirement'),
              HookUpArticle(root, crumbs, toc(), 'CreditChallenge-Week1.md', 'Trending SMChallenges', 'Week 1 — Credit Fundamentals and Accessing Your Credit Information'),
              HookUpArticle(root, crumbs, toc(), 'CreditChallenge-Week2.md', 'Trending SMChallenges', 'Week 2 — Types of Credit and Their Protections'),
              HookUpArticle(root, crumbs, toc(), 'CreditChallenge-Week3.md', 'Trending SMChallenges', 'Week 3 — Applying for and Using Credit Cards'),
          ]}

          <Route
            exact
            path={`${root}/video/:video`}
            render={(routerProps) => <SMChallenge {...routerProps} crumbs={crumbs} toc={toc()} isVideo />}
          />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
