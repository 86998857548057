/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';


export default function NetWorthResources (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Helpful Net Worth Resources|${root}`}
        toc={props.toc}
        articleClass="launch-page"
      >

        <ReactMarkdown
          source={`
  ## Helpful Net Worth Resources
  Use these resources to find additional information about understanding your net worth and creating a personal net worth statement.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TrackingNetWorth-B.jpg"
            alt="Hands holding dollar symbol and person icon holding paper money graphic"
            title="Tracking Your Net Worth Through Life's Changes"
            href="/Trending/Blog/TrackingYourNetWorth"
            link="Read Blog"
          >
            <p>
            In this blog, a young service member shares how using financial tools like the Spending Plan Worksheet and the Personal Net Worth Tracker helped him plan for his future.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Calculators.jpg"
            alt="Calculator website page screenshot"
            title="Calculators"
            href="/ToolsAndAddRes/Calculators"
            link="Visit Page"
          >
            <p>
            Don’t just guess. Use these helpful tools to get an accurate picture of how various decisions affect your overall financial health.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPointFinanceCheckup-V.jpg"
            alt="Illustration of man with clipboard, money bag and calculator"
            title="It’s Time to Schedule a Checkup — For Your Finances"
            href="/money/video/Schedule-a-Checkup-For-Your-Finances"
            link="Watch Now"
          >
            <p>
            It’s time to check on your vitals — your financial vitals! Tune in to this video to find out how checkups can help you achieve and sustain your goals.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PFCLocatorMap-V.jpg"
            alt="PFC Locator Map on smartphone"
            title="Find Your Nearest PFC in Less Than Three Clicks"
            href="/ToolsAndAddRes/HowToVideos/video/Find-Your-Nearest-PFC-in-Less-Than-Three-Clicks"
            link="Watch Now"
          >
            <p>
            With the PFC Locator Map, you can find contact information for your nearest personal financial counselor in minutes. Learn how in this video!
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}


