/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import ReactMarkdown from '../../components/markdown';

export default function DCFSAStrengthingOurSupport(props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Strengthening Our Support: Resources`}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Strengthening Our Support: Resources
`}
        />
        <ReactMarkdown
          source={`
The [Dependent Care Flexible Spending Account](/Benefits/DCFSA) benefit may provide financial relief for service members and their families. The following resources provide key details and information. Use them to help those you support in your role as a leader or service provider.

* Defense.gov Article, [New Benefit Gives Military Families More Flexibility in Paying for Dependent Care](https://www.defense.gov/News/Releases/Release/Article/3481813/new-benefit-gives-military-families-more-flexibility-in-paying-for-dependent-ca/)
* Press Release, [Senior DOD Leaders Host On-the-Record Media Roundtable on the Availability of Dependent Care Flexible Spending Accounts for Service Members](https://www.defense.gov/News/Transcripts/Transcript/Article/3482472/senior-dod-leaders-host-on-the-record-media-roundtable-on-the-availability-of-d/)
* Secretary of Defense Memorandum, “[Strengthening Our Support to Service Members and Their Families](https://media.defense.gov/2023/Mar/22/2003184739/-1/-1/1/STRENGTHENING-OUR-SUPPORT-TO-SERVICE-MEMBERS-AND-THEIR-FAMILIES.PDF)” (March 22, 2023) 
* USD (P&R) DCFSA Announcement Memorandum, [Availability of Dependent Care Flexible Spending Accounts for Service Members](https://media.defense.gov/2023/Aug/03/2003273930/-1/-1/0/AVAILABILITY-OF-DEPENDENT-CARE-FLEXIBLE-SPENDING-ACCOUNTS-FOR-SERVICE-MEMBERS-SIGNED-AUGUST-3-2023.PDF)
* White House Executive Order, “[Executive Order on Advancing Economic Security for Military and Veteran Spouses, Military Caregivers, and Survivors](https://www.whitehouse.gov/briefing-room/presidential-actions/2023/06/09/executive-order-on-advancing-economic-security-for-military-and-veteran-spouses-military-caregivers-and-survivors/)”
`}
        />
      </Page>
    );
}
