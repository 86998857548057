/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import ReactMarkdown from '../../components/markdown';

export default function DCFSAWebinars (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Upcoming DCFSA Information Sessions`}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Upcoming DCFSA Information Sessions
`}
        />
        <ReactMarkdown
          source={`
A [Dependent Care Flexible Spending Account](/Benefits/DCFSA) can help service members save on eligible dependent care expenses such as child care, preschool, summer day camps and more.

**If you could not attend the webinar sessions that took place in October and November, you can access the [recorded webinar here](https://www.youtube.com/watch?v=zcT7jVBB9Qs).**  
`}
        />
      </Page>
    );
}
