/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
export default class ReadMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.expand = this.expand.bind(this);
    }

    expand() {
        // console.log("got here");
        this.setState((e) => ({
            expanded: !e.expanded,
        }));
    }

    render() {
        const id = require('uuid').v4();
        const expanded = this.state.expanded;
        const style = {
            display: 'inline',
        };
        if (!expanded) { style.display = 'none'; }
        return (<>



          <a onClick={this.expand} className="usa-accordion-button readmore" aria-expanded={expanded} aria-controls={id + '-content'}>
                        Read More ...
          </a>
          <div id={id + '-content'} className="usa-accordion-content readmore" aria-hidden={!expanded} style={style}>

            {this.props.children}

          </div>

       </>);
    }
}

