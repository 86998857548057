/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';


export default function NetWorth (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >

        <ReactMarkdown
          source={`
## Track Your Personal Net Worth
Tracking your net worth will help you better understand where you are financially today and enable you to track your progress over time to strengthen your financial well-being. The Personal Net Worth Tracker simplifies calculating and monitoring your assets (what you own) and your liabilities (what you owe). To create your personal net worth statement, start by downloading this tracker, filling in your details and periodically checking your progress toward eliminating debt, building wealth and meeting your financial goals.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PersonalNetWorth-T.jpg"
            alt="Hands holding dollar symbol, laptop with bar chart"
            title="Personal Net Worth Tracker"
            href="/assets/downloads/FINRED-PersonalNetWorthTracker.xlsx"
            link="Download Now"
          >
            <p>
            Tracking your net worth is a key part of becoming financially stable and building wealth. Use our Personal Net Worth Tracker to track your assets and liabilities and calculate your net worth over time.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PNWTHowTo-V.jpg"
            alt="Hands holding dollar symbol, laptop with play button"
            title="How To Use the Personal Net Worth Tracker"
            href={`${root}`}
            cornerBadge="Coming Soon"
            link="Coming Soon"
          >
            <p>
            Stay tuned — a new video is in progress. Check back soon to see how to better use the Personal Net Worth Tracker!
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PersonalNetWorth-R.jpg"
            alt="Hands holding dollar symbol and resource documents"
            title="Helpful Net Worth Resources"
            href={`${root}/NetWorthResources`}
            link="Learn More"
          >
            <p>
            From understanding the definition of net worth  to creating your personal net worth statement, use these tools to help you build a stable financial future.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}


