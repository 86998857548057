/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import ReactMarkdown from '../../components/markdown';

export default function DCFSAEligibility (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Service Member DCFSA Eligibility Guide`}
        toc={props.toc}
        articleClass="dcfsa"
      >
        <ReactMarkdown
          source={`
## Service Member DCFSA Eligibility Guide
`}
        />
        <ReactMarkdown
          source={`
[Service Member DCFSA Eligibility Guide (Printable)](/assets/downloads/FINRED-DCFSA-Eligibility-G-Printable.pdf)
Use the Service Member DCFSA Eligibility Guide to help you decide whether the [DCFSA](/Benefits/DCFSA) is right for your family. Digital and printable versions are available for your convenience.

* [Service Member DCFSA Eligibility Guide (Digital)](/assets/downloads/FINRED-DCFSA-Eligibility-G.pdf)
* [Service Member DCFSA Eligibility Guide (Printable)](/assets/downloads/FINRED-DCFSA-Eligibility-G-Printable.pdf)
`}
        />
      </Page>
    );
}
