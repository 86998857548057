/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../components/page';
import Session from '../session';
import settings from '../settings';
import Dialog from '../components/editorDialog';
import api from '../api';
import AssetEditor from '../components/assetEditor';
class newPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: null,
        };
        this.post = this.post.bind(this);
        this.hideMeta = this.hideMeta.bind(this);
        this.showMeta = this.showMeta.bind(this);
        this.metaShown = this.metaShown.bind(this);

        this.hideAssets = this.hideAssets.bind(this);
        this.showAssets = this.showAssets.bind(this);
        this.assetsShown = this.assetsShown.bind(this);
    }

    componentWillMount() {
        if (this.props._id) {
            api.get('/posts/' + this.props._id).then(res => {
                const draftText = res.data.draft || res.data.body;
                window.$('#title').val(res.data.title);
                window.$('#abstract').val(res.data.abstract);
                window.$('#body').val(draftText);
                // window.editor.setMarkdown(draftText);
                window.$('#img').val(res.data.thumbnail);
                window.$('#category').val(res.data.category);
            });
        }
    }

    componentDidMount() {
        this.hookup();
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
        window.removeEventListener('beforeunload', this.unloadHandler);
    }

    async post(publish) {
        let url = '/posts';
        if (this.props._id) url += '/' + this.props._id;
        const payload = {
            title: window.$('#title').val(),
            abstract: window.$('#abstract').val(),
            thumbnail: window.$('#img').val(),
            category: window.$('#category').val(),
        };
        if (publish) {
            payload.body = window.$('#body').val();
            payload.draft = '';
        } else {
            payload.draft = window.$('#body').val();
        }
        const body = await api.post(url, payload);

        if (body.success) {
            this.props.history.push('/articles/' + body.data._id);
        } else {
            console.log('Invalid login');
            this.setState(() => ({
                feedback: 'Invalid Login',
            }));
        }
    }

    cancel() {
        this.props.history.push('/articles/' + this.props._id);
    }

    publish() {
        this.post(true);
    }

    showMeta() {
        this.setState(() => ({ metaShown: true }));
    }

    hideMeta() {
        this.setState(() => ({ metaShown: false }));
    }

    showCancel() {
        this.setState(() => ({ cancelShown: true }));
    }

    hideCancel() {
        this.setState(() => ({ cancelShown: false }));
    }

    showAssets() {
        this.setState(() => ({ assetsShown: true }));
    }

    hideAssets() {
        this.setState(() => ({ assetsShown: false }));
    }

    metaShown() {
        if (this.state.metaShown) return 1;
        return 0;
    }

    assetsShown() {
        if (this.state.assetsShown) return 1;
        return 0;
    }


    hookup() {
        this.unloadHandler = function() {
            return "Are you sure you want to leave? Your changes may be lost. Please use the 'Save' or 'Cancel' buttons";
        };
        window.onbeforeunload = this.unloadHandler;


        const editor = window.editormd('bodyholder', {
            width: '100%',
            height: '100%',
            path: '/assets/js/md/lib/',
            pageBreak: false, // Enable parse page break [========]
            atLink: false, // for @link
            emailLink: false,
            toc: false,
            imageUpload: false,
            mode: 'markdown',


            toolbarIconsClass: {
                assets: 'fa-image',
                metadata: 'fa-edit',
                save: 'fa-save',
                cancel: 'fa-close',
                publish: 'fa-rss-square',
            },
            toolbarIconTexts: {
                assets: 'Manage Assets',
                metadata: 'Set Article Metadata',
                save: 'Save Draft',
                cancel: 'Discard Changed',
                publish: 'Publish Article',
            },
            lang: {
                toolbar: {
                    assets: 'Manage Assets',
                    metadata: 'Set Article Metadata',
                    save: 'Save Draft',
                    cancel: 'Discard Changed',
                    publish: 'Publish Article',
                },
            },

            toolbarIcons: function() {
                // Or return editormd.toolbarModes[name]; // full, simple, mini
                // Using "||" set icons align right.
                return [
                    'undo',
                    'redo',
                    '|',
                    'bold',
                    'del',
                    'italic',
                    'quote',
                    'ucwords',
                    'uppercase',
                    'lowercase',
                    '|',
                    'h1',
                    'h2',
                    'h3',
                    'h4',
                    'h5',
                    'h6',
                    '|',
                    'list-ul',
                    'list-ol',
                    'hr',
                    '|',
                    'link',
                    'reference-link',
                    'image',
                    'code',
                    'preformatted-text',
                    'code-block',
                    'table',
                    'datetime',
                    'html-entities',
                    '|',
                    'goto-line',
                    'watch',
                    'preview',
                    'search',
                    '||',
                    'assets',
                    'metadata',
                    'cancel',
                    'save',
                    '|',
                    'publish',
                ];
            },
            toolbarHandlers: {
                assets: () => {
                    this.showAssets();
                },
                metadata: () => {
                    this.showMeta();
                },
                save: () => {
                    this.post();
                },
                cancel: () => {
                    this.showCancel();
                },
                publish: () => {
                    this.publish();
                },
            },
            onBeforePreview() {
                console.log('onBeforePReview');
            },
            onload: () => {
                const aff = 'https://cdn.rawgit.com/kofifus/Typo.js/312bf158a814dda6eac3bd991e3a133c84472fc8/typo/dictionaries/en_US/en_US.aff';
                const dic = 'https://cdn.rawgit.com/kofifus/Typo.js/312bf158a814dda6eac3bd991e3a133c84472fc8/typo/dictionaries/en_US/en_US.dic';
                const typoLoaded = window.loadTypo(aff, dic);
                typoLoaded.then(typo => {
                    window.startSpellCheck(editor.cm, typo);
                });
            },
        });
        window.editor = editor;



        //  CodeMirror.fromTextArea(document.getElementById("textarea"), {
        //    mode: "spell-checker",
        //    backdrop: "gfm" // Your desired mode
        //  });
    }


    renderAssets() {
        if (this.assetsShown()) {
            return (
              <AssetEditor showInsert _id={this.props._id} onClose={() => this.hideAssets()} />
            );
        }
    }

    renderCancel() {
        if (this.state.cancelShown) {
            return (
              <Dialog
                title="Discard Changes?"
                onClose={() => this.hideCancel()}
                buttons={{
                    Discard: () => this.cancel(),
                    Cancel: () => this.hideCancel(),
                }}
              >
      Are you sure you want to exit without saving?
              </Dialog>
            );
        }
    }

    renderFeedback() {
        if (this.state.feedback) {
            return (
              <div className="alert alert-primary" role="alert">
                {this.state.feedback}
              </div>
            );
        }
    }

    renderMeta() {
        return (
          <div
            className="editormd-dialog editormd-dialog"
            style={{
                position: 'fixed',
                display: 'block',
                zIndex: 10000 + this.metaShown(),
            }}
          >
            <div className="editormd-dialog-header">
              <strong className="editormd-dialog-title">Article Metadata</strong>
            </div>
            <a
              onClick={this.hideMeta}
              className="fa fa-close editormd-dialog-close"
              style={{
                  cursor: 'pointer',
              }}
            />
            <div className="editormd-dialog-container">
              <div className="editormd-form">
                <label>Abstract</label>
                <textArea type="text" id="abstract" data-url="" style={{ minWidth: 300 }} />
                <br />
                <div>
                  <label>Title</label>
                </div>
                <input type="text" id="title" data-title="" />
                <div>
                  <label>Category</label>
                </div>
                <select id="category" data-title="">
                  <option value="Benefits">Benefits And Entitlements</option>
                  <option value="Money">Managing Your Money</option>
                  <option value="Planning">Planning For the Future</option>
                  <option value="Saving">Saving and Investing</option>
                  <option value="Consumer-Protections">Consumer Protections</option>
                  <option value="Housing">Housing</option>
                </select>

                <input className="sr-only" type="text" id="img" data-title="" />
                <br />
              </div>
              <div className="editormd-dialog-footer">
                <button
                  onClick={this.hideMeta}
                  style={{
                      cursor: 'pointer',
                  }}
                  className="editormd-btn editormd-enter-btn"
                >
              OK
                </button>
              </div>
            </div>
            <div className="editormd-dialog-mask editormd-dialog-mask-bg" />
            <div className="editormd-dialog-mask editormd-dialog-mask-con" />
          </div>
        );
    }

    render() {
        return (
          <Page crumbs="login">
            <div className="usa-grid no-left-pad no-right-pad">
              <div className="">
                <form
                  onSubmit={e => {
                      e.preventDefault();
                      return false;
                  }}
                  action=""
                  className="editorForm"
                >
                  {this.renderMeta()}
                  {this.renderAssets()}
                  {this.renderCancel()}

                  <label htmlFor="body">Body</label>
                  <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 10000,
                    }}
                  >
                    <div id="bodyholder">
                      <textArea
                        style={{ display: 'none' }}
                        id="body"
                        type="password"
                      />
                    </div>
                  </div>
                </form>
                <p>{this.renderFeedback()}</p>
              </div>
            </div>
          </Page>
        );
    }
}


export default withRouter(newPost);
