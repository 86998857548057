/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Page from '../../components/articlePage';
import DCFSAQuestionCard from '../../components/dcfsa-question-card';
import ReactMarkdown from '../../components/markdown';

import questions from './faqs.json';

export default function FAQ (props) {
    const root = props.match.path;
    const [selectedContent, setSelectedContent] = useState([]);
    const [searchText, setSearchText] = useState('');

    const contentClassMap = {
        'About FSAFEDS': 'fsafeds',
        'About DCFSA': 'about',
        'Enrollment & Elections': 'enrollment',
        'Eligibility & Eligible Expenses': 'eligibility',
        'Planning & Budgeting': 'planning',
        'Spouse Employment Considerations': 'spouse',
        'Tax Implications': 'tax',
    };

    const handleSelectContent = value => () => {
        let sContent = [...selectedContent];
        if (sContent.includes(value)) {
            sContent = sContent.filter(sc => sc !== value);
        } else {
            sContent.push(value);
        }
        setSelectedContent(sContent);
    };

    const getContentClass = (contentText) => (
        selectedContent.includes(contentText)
            ? `selected dcfsa-${contentClassMap[contentText]} primary`
            : `dcfsa-${contentClassMap[contentText]} secondary`
    );

    const filterBySelectedContent = () => question => (
        selectedContent.length > 0 ? selectedContent.includes(question.category) : true
    );

    const filterBySearch = () => question => {
        try {
            const sText = searchText.trim().replace(/[[\]]/g, '').replace(/([^\w\d\s])/ig, '\\$1');
            if (sText === '') return true;
            const searchRegex = new RegExp(`(?<!\\()${sText}(?![\\w\\s\\\\.\\/\\?\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\{\\}\\;\\:\\<\\>\\|]*[\\)])`, 'igm');
            return searchRegex.test(question.question) || searchRegex.test(question.answer);
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const highlightSearchTerms = (text) => {
        try {
            const sText = searchText.trim().replace(/[[\]]/g, '').replace(/(\W)/ig, '\\$1');
            if (sText === '') return text;
            const searchRegex = new RegExp(`(?<!\\()(${sText})(?![\\w\\s\\\\.\\/\\?\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\{\\}\\;\\:\\<\\>\\|]*[\\)])`, 'igm');

            if (searchRegex.test(text)) {
                text = text.replace(searchRegex, '<mark><strong>$1</strong></mark>');
                const linkRegex = new RegExp(`\\[([^\\]]*?)((<mark><strong>${sText}</strong></mark>.*?)+?)\\]\\((.*?)\\)`, 'mgi');
                const markRegex = new RegExp(`<mark><strong>(${sText})</strong></mark>`, 'mgi');
                let m = linkRegex.exec(text);
                while (m !== null) {
                    const markTransform = m[2].replace(markRegex, '|mark||strong|$1|/strong||/mark|');
                    text = text.replace(m[0], `[${m[1]}${markTransform}](${m[4]})`);
                    m = linkRegex.exec(text);
                }
            }

            return text;
        } catch (err) {
            console.log(err);
            return text;
        }
    };


    return (
      <Page
        crumbs={`${props.crumbs},Frequently Asked Questions about the DCFSA Benefit`}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Frequently Asked Questions about the DCFSA Benefit
`}
        />
        <ReactMarkdown
          source={`
Below are FAQ about the [DCFSA](/Benefits/DCFSA) benefit for Department of Defense service members and civilian employees. Questions are categorized by subject for easy searchability. While the FAQ may provide answers to your DCFSA questions, it is recommended to consult a [personal financial manager](https://installations.militaryonesource.mil/?looking-for-a=program/program-service=30/focus=program) or [counselor](/pfcMap) or [tax consultant](https://www.militaryonesource.mil/financial-legal/taxes/miltax-military-tax-services/) for a comprehensive understanding of how the DCFSA benefit can affect your family’s financial and tax situation. These services are available free of cost for service members and their families through Military OneSource and the Office of Financial Readiness (FINRED).
`}
        />
        <div className="dcfsa-content-container">
          {
            Object.keys(contentClassMap).map(key => (
              <button className={`dcfsa-toc-button ${getContentClass(key)}`} onClick={handleSelectContent(key)}>{key}</button>
            ))
          }
          <div className="dcfsa-search-box">
            <div className="dcfsa-search-container">
              <input placeholder="Start typing to search" type="text" className="dcfsa usa-input" onChange={e => setSearchText(e.target.value)} />
              <div className="dcfsa-search-label">Search All FAQs</div>
            </div>
          </div>
        </div>
        <div className="dcfsa-questions-container">
          {
                questions.filter(filterBySelectedContent()).filter(filterBySearch()).map(q => (
                  <DCFSAQuestionCard
                    key={q.question}
                    classTag={contentClassMap[q.category]}
                    question={highlightSearchTerms(q.question)}
                    answer={highlightSearchTerms(q.answer)}
                  />
                ))
          }
        </div>
      </Page>
    );
}
