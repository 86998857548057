/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MilitaryJourneyTouchpoints from '../pages/military-journey-touchpoints';
import SplTOC from '../components/tocs/splTOC';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function MilitaryJourneyTPRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Military Journey Touchpoints|${root}` : `Military Journey Touchpoints|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routeProps) => <MilitaryJourneyTouchpoints {...routeProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Military-Touchpoint-Series-Introduction', 'Military Touchpoint Series Introduction', crumbs,
                      '/assets/video/micro-learning-series/TP1/720p/TP1 Intro Compressed.mp4',
                      '/assets/video/micro-learning-series/TP1/720p 508-Compliant/TP1 Intro 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-01-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Decode-Your-Leave-and-Earnings-Statement', 'Decode Your Leave and Earnings Statement', crumbs,
                      '/assets/video/micro-learning-series/TP1/720p/TP1 Topic Compressed.mp4',
                      '/assets/video/micro-learning-series/TP1/720p 508-Compliant/TP1 Topic 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-01-Topic.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'First-Duty-Station', 'First Duty Station', crumbs,
                      '/assets/video/micro-learning-series/TP2/720p/TP2 Intro Compressed.mp4',
                      '/assets/video/micro-learning-series/TP2/720p 508-Compliant/TP2 Intro 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-02-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Creating-a-Spending-Plan', 'Creating a Spending Plan', crumbs,
                      '/assets/video/micro-learning-series/TP2/720p/TP2 Topic Compressed.mp4',
                      '/assets/video/micro-learning-series/TP2/720p 508-Compliant/TP2 Topic 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-02-Topic.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Each-PCS', 'Each PCS', crumbs,
                      '/assets/video/micro-learning-series/TP3/720p/TP3Intro Compressed.mp4',
                      '/assets/video/micro-learning-series/TP3/720p 508-Compliant/TP3Intro 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-03-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Updating-a-Spending-Plan', 'Updating a Spending Plan', crumbs,
                      '/assets/video/micro-learning-series/TP3/720p/TP3Topic Compressed.mp4',
                      '/assets/video/micro-learning-series/TP3/720p 508-Compliant/TP3Topic 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-03-Topic.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Promotion', 'Promotion', crumbs,
                      '/assets/video/micro-learning-series/TP4/TP4 Intro Compressed.mp4',
                      '/assets/video/micro-learning-series/TP4/TP4 Intro 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-04-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Financial-Savings', 'Financial Savings', crumbs,
                      '/assets/video/micro-learning-series/TP4/TP4 Topic Compressed.mp4',
                      '/assets/video/micro-learning-series/TP4/TP4 Topic 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-05-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Continuation-Pay', 'Continuation Pay', crumbs,
                      '/assets/video/micro-learning-series/TP6/TP6 Intro Compressed.mp4',
                      '/assets/video/micro-learning-series/TP6/TP6 Intro 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-06-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Major-Life-Events', 'Major Life Events', crumbs,
                      '/assets/video/micro-learning-series/TP7/TP7 Intro Compressed.mp4',
                      '/assets/video/micro-learning-series/TP7/TP7 Intro 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-07-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Financial-Planning-for-Life-Events', 'Financial Planning for Life Events', crumbs,
                      '/assets/video/micro-learning-series/TP7/TP7 Topic Compressed.mp4',
                      '/assets/video/micro-learning-series/TP7/TP7 Topic 508C Compressed.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-07-Topic.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Leadership-Training', 'Leadership Training', crumbs,
                      '/assets/video/micro-learning-series/TP8/TP8 Intro 720p.mp4',
                      '/assets/video/micro-learning-series/TP8/TP8 Intro 508C 720p.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-08-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Recognize-Financial-Distress', 'Recognize Financial Distress', crumbs,
                      '/assets/video/micro-learning-series/TP8/TP8 Topic 720p.mp4',
                      '/assets/video/micro-learning-series/TP8/TP8 Topic 508C 720p.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-08-Topic.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Pre-and-Post-Deployment', 'Pre- and Post-Deployment', crumbs,
                      '/assets/video/micro-learning-series/TP9/TP9 Intro 720p.mp4',
                      '/assets/video/micro-learning-series/TP9/TP9 Intro 508C 720p.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-09-Intro.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Deployment-and-Your-Finances', 'Deployment and Your Finances', crumbs,
                      '/assets/video/micro-learning-series/TP9/TP9 Topic 720p.mp4',
                      '/assets/video/micro-learning-series/TP9/TP9 Topic 508C 720p.mp4',
                      '/assets/img/FINRED-WebsiteImage-TouchPoint-09-Topic.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
