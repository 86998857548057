/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import Link from './link';
import Img from './img';
import MediaBlock from './mediaBlock';
import GraphicList from './graphicList';
import Section from './section';


import api from '../api';
class Event extends React.Component {
    constructor(props) {
        super(props);
    }

    set(key) {
        return function(e) {
            this.props.onValueSet('event' + (parseInt(this.props.i) + 1) + '.' + key, e.target.value);
        }.bind(this);
    }

    render() {
        if (!this.props.data) return '';
        if (!this.props.editing) {
            return (
              <MediaBlock src={this.props.data.icon} alt="Alt text">
                <h3>
                  {this.props.data.title}
                </h3>

                <p>
                  {this.props.data.body}

                  <Link to={this.props.data.link} className="">
                    {this.props.data.linkText}
                  </Link>
                </p>
              </MediaBlock>
            );
        }
        return (
          <MediaBlock src={this.props.data.icon} alt="Alt text">
            <h3>
              <textarea className="inlineEdit" onChange={this.set('title')} type="text" value={this.props.data.title} />
            </h3>

            <p>
              <textarea className="inlineEdit" onChange={this.set('body')} value={this.props.data.body} />
              <textarea className="inlineEdit" type="text" onChange={this.set('link')} value={this.props.data.link} />
              <textarea className="inlineEdit" type="text" onChange={this.set('linkText')} value={this.props.data.linkText} />

            </p>
          </MediaBlock>
        );
    }
}


function getHomepage(state) {
    if (state && state.homepage.editing) { return state.homepage.draft; }
    if (state && !state.homepage.editing) { return state.homepage.published; }
    return {};
}


const mapStateToProps = (state, ownprops) => ({

    editing: state.homepage.editing,
    data: getHomepage(state)['event' + (parseInt(ownprops.i) + 1)],
}
);

const mapDispatchToProps = dispatch => ({
    onValueSet: (key, value) => dispatch({ type: 'homepageValueSet', key, value }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Event);

