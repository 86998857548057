/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookupArticle from '../components/hookUpArticle';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import TrendingTOC from '../components/tocs/trendingTOC';
import Mirl from '../pages/mirl';
import articles from '../pages/blog/mirl/articles.json';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function MirlRoutes(props) {
    const root = props.match.path;
    const toc = () => (props.toc || <TrendingTOC />);
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `Money in Real Life Resources|${root}`;

    return (
      <div>
        <Switch>
          <Route
            exact
            path={root}
            render={(routeProps) => <Mirl {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          {
              articles.map(a => HookupArticle(root, crumbs, toc(), `${a.href}.md`, 'Trending', a.title))
          }


          <Route
            path={`${root}/video`}
            render={(rps) => (
              <Switch>
                {[
                    HookUpSiteVideo(
                        rps.match.path, 'Time-to-PCS', 'Time to PCS? Here’s How to Cut Down on the Financial Stress', crumbs,
                        '/assets/video/mirl/FINRED-MIRL-PCSTips-V.mp4',
                        null, '/assets/img/FINRED-WebsiteImage-MIRL-V.jpg',
                    ),
                    HookUpOopsPage(),
                ]}
              </Switch>
            )}
          />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
