/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function PermanentChangeOfStationRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Permanent Change of Station|${root}` : `Permanent Change of Station|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/pcs/40-101_-_af_pcs_20_-_course_overview_navy_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PCS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlan', 'Spending Plan', crumbs,
                      '/assets/video/navy-resource/pcs/42-112_-_navy_pcs_20_-_spending_plan_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PCS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingDebtandCredit', 'Managing Debt and Credit', crumbs,
                      '/assets/video/navy-resource/pcs/42-111_-_navy_pcs_20_-_managing_debt_and_credit_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PCS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitaryConsumerAwareness', 'Military Consumer Awareness', crumbs,
                      '/assets/video/navy-resource/pcs/41-103_-_first_duty_station_-_military_consumer_protections_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PCS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/pcs/40-117_-_af_pcs_20_-_course_wrap-up_v3_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PCS-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
