/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import Session from './session';
import settings from './settings';
class API {
    constructor() {
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
    }

    async login(creds) {
        return this.post('/login', creds);
    }

    logout() {
        return this.get('/logout');
    }

    async isLoggedIn() {
        const body = await this.get('/loggedIn');

        return body.success;
    }

    get(path) {
        return new Promise((resolve, reject) => {
            window.$.ajax({
                url: settings.api + path,
                method: 'get',
                contentType: 'application/json',
                dataType: 'json',

                xhrFields: {
                    withCredentials: true,
                },
                success: (body, status, xhr) => {
                    resolve(body);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    }

    post(path, body) {
        return new Promise((resolve, reject) => {
            window.$.ajax({
                url: settings.api + path,
                method: 'post',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(body),
                xhrFields: {
                    withCredentials: true,
                },
                success: (body, status, xhr) => {
                    resolve(body);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    }

    src(path) {
        return settings.api + path;
    }

    upload(path, data) {
        return new Promise((resolve, reject) => {
            window.$.ajax({
                url: settings.api + path,
                data: data,
                processData: false,
                contentType: false,
                type: 'POST',
                xhrFields: {
                    withCredentials: true,
                },
                success: (body, status, xhr) => {
                    resolve(body);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    }

    delete(path) {
        return new Promise((resolve, reject) => {
            window.$.ajax({
                url: settings.api + path,
                method: 'delete',
                contentType: 'application/json',
                dataType: 'json',

                xhrFields: {
                    withCredentials: true,
                },
                success: (body, status, xhr) => {
                    resolve(body);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    }
}
export default new API();
