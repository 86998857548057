/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import BuildVersion from './buildVersion';

import Link from './link';
import Img from './img';
import settings from '../settings';
import api from '../api.js';
import NewsletterFormFooter from './newsletterFormFooter';

class Footer extends React.Component {
    async logout(e) {
        await api.logout();
        this.props.onLogout();
        return false;
    }

    renderLogin() {
        if (settings.useAPI) {
            const loggedIn = this.props.session.loggedIn;
            if (!loggedIn) {
                return (
                  <Link to="/login" className="usa-footer-primary-link">Admin Login</Link>
                );
            }
            return (
              <div onClick={() => this.logout()} className="usa-footer-primary-link">Logout</div>
            );
        }
        return null;
    }



    render() {
        return (
          <>
            <footer className="usa-footer usa-footer-medium" role="contentinfo">

              <div className="usa-grid bg0 usa-footer-primary-section">
                <div className="no-left-margin">
                  <nav className="usa-footer-nav">
                    <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>

                      <Link to="http://www.defense.gov/about/" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>About DoD</Link>
                      <Link to="http://dodcio.defense.gov/DoDSection508/Std_Stmt.aspx" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>Accessibility</Link>
                      <a href="mailto:osd.mc-alex.ousd-p-r.mbx.financial-readiness@mail.mil" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>Feedback</a>
                      <Link to="http://open.defense.gov/Transparency/FOIA.aspx" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>FOIA</Link>
                      <Link to="http://www.defense.gov/Resources/Privacy" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>Privacy and Security</Link>
                      <Link to="/SiteMap" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>Site Map </Link>
                      <Link to="http://dodcio.defense.gov/dodwebpolicy" className="usa-footer-primary-link" style={{ cursor: 'pointer' }}>Web Policy </Link>
                      {this.renderLogin()}

                    </div>
                  </nav>
                </div>
              </div>

              <div className="usa-footer">
                <div className="usa-grid bg0 no-left-margin">
                  <div className="usa-footer-logo usa-width-one-half" style={{ paddingTop: 0 }}>
                    <h3 className="usa-footer-contact-heading" style={{ marginTop: '.5rem' }}>
                      <Link to="/About">About Us</Link>
                    </h3>
                    
                    <Link to="/" title="Home" aria-label="Home">
                      <Img alt="DoD Seal" style={{ height: '5em', verticalAlign: 'middle', margin: '20px 0px'  }} src="/assets/img/mainlogo.png" />
                    </Link>

                    <Link to="https://dodcareerready.usalearning.gov/" className="usa-footer-primary-link dod" style={{ cursor: 'pointer' }}>
                      <Img alt="DoD Seal" style={{ width: '55px', verticalAlign: 'middle' }} src="/assets/img/DoD-Seal.jpg" /> DoD Career Ready 
                    </Link>
                  </div>
                  <div className="usa-footer-contact-links usa-width-one-half">
                    {/* $0 */}
                    <h3 className="usa-footer-contact-heading">Questions or Comments?</h3>
                    <address>
                      <div className="usa-footer-primary-content usa-footer-contact_info">
                        <p>
                          <a target="_blank" href="tel:1-833-200-0035">833-200-0035 (Monday-Friday, 8:30 AM-6:00 PM EST)</a>
                        </p>

                      </div>
                      <div className="usa-footer-primary-content usa-footer-contact_info">
                        <p><a target="_blank" href="mailto:support@usalearning.net">support@usalearning.net</a></p>
                      </div>
                    </address>
                    <NewsletterFormFooter />
                    <div className="footer-service-branches">
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="Army Seal" src="/assets/img/FINRED-SealArmy-60x60.png" />
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="Marines Seal" src="/assets/img/FINRED-SealMarineCorps-60x60.png" />
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="Navy Seal" src="/assets/img/FINRED-SealNavy-60x60.png" />
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="Air Force Seal" src="/assets/img/FINRED-SealAirForce-60x60.png" />
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="Space Force Seal" src="/assets/img/FINRED-USSF-60x60.png" />
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="National Guard Seal" src="/assets/img/FINRED-SealNatGuard-60x60.png" />
                      <Img style={{ width: '55px', verticalAlign: 'middle' }} alt="Coast Guard Seal" src="/assets/img/FINRED-SealCoastGuard-60x60.png" />
                    </div>
                  </div>
                </div>
              </div>

            </footer>
          </>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch({ type: 'logout' }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Footer);




