/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Dialog from './editorDialog';
import api from '../api';
import ImageUpload from './imageUpload';
import Img from './img';
class newPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpload: null,
            assets: [],
        };
    }

    async componentDidMount() {
        await this.load();
    }

    showUpload() {
        this.setState(() => ({ showUpload: true }));
    }

    hideUpload(i) {
        this.setState(() => ({ showUpload: false }));
        if (i) {
            this.load();
        }
    }

    async post() {}

    async load() {
        const url = '/posts/' + this.props._id + '/assets';
        const body = await api.get(url);
        if (body.success) {
            const lead = window.$('#img').val();
            this.setState(() => ({ lead, assets: body.data.map(i => { i.key = i.uuid; return i; }) }));
        }
    }

    async delete(asset) {
        const url = '/posts/' + this.props._id + '/assets/' + asset.uuid;
        const body = await api.delete(url);
        this.load();
    }

    async makeLead(asset) {
        const url = '/posts/' + this.props._id + '/assets/' + asset.uuid;
        const link = await api.src(url);
        window.$('#img').val(link);
        this.setState(() => ({ lead: link }));
        if (this.props.onSetLead) { this.props.onSetLead('/posts/' + this.props._id + '/assets/' + asset.uuid); }
    // this.load();
    }

    async insert(asset) {
        const editor = window.editor.codeEditor;
        const doc = editor.getDoc();
        const cursor = doc.getCursor();
        doc.replaceRange(`![${asset.path}](${api.src('/posts/' + this.props._id + '/assets/' + asset.uuid)})`, cursor);
        this.close();
    }

    async copy(asset) {
        const el = document.createElement('textarea');
        el.value = `![${asset.path}](${api.src('/posts/' + this.props._id + '/assets/' + asset.uuid)})`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    close() {
        this.props.onClose();
    }

    notIsLead(url) {
        return this.state.lead !== url;
    }



    closePreview() {
        this.setState(() => ({ preview: null }));
    }

    preview(asset) {
        this.setState(() => ({ preview: asset }));
    }

    renderAssets() {
        const assets = [];
        for (const i in this.state.assets) {
            const marklink = `![${this.state.assets[i].path}](${url})`;
            const url = api.src('/posts/' + this.props._id + '/assets/' + this.state.assets[i].uuid);
            assets.push(<div className={'assetRow ' + (!this.notIsLead(url) ? ' leadRow' : '')}>
              <span onClick={() => this.preview(this.state.assets[i])} href={url} target="_blank"><Img src={url} /></span>
              <div className="vertOptions">
                <span>{this.state.assets[i].path}</span>
                <span className="small" id={'a' + this.state.assets[i].uuid.replace(/-/g, '')}>
                  {marklink}
                </span>
              </div>
              <div className="vertOptions">

                { this.props.showInsert && <div className="btn btn-primary btn-raised" onClick={() => { this.copy(this.state.assets[i]); }}>Copy Tag</div> }
                { this.props.showInsert && <div className="btn btn-primary btn-raised" onClick={() => { this.insert(this.state.assets[i]); }}>Insert</div> }
                <div className="btn btn-primary btn-raised" onClick={() => { this.delete(this.state.assets[i]); }}>Delete</div>
                {this.notIsLead(url) && <div className="btn btn-primary btn-raised" onClick={() => { this.makeLead(this.state.assets[i]); }}>Make Lead</div>}
              </div>

            </div>);
        }
        return assets;
    }

    renderPreview() {
        if (this.state.preview) {
            const url = api.src('/posts/' + this.props._id + '/assets/' + this.state.preview.uuid);
            return (
              <Dialog title="Manage Post Assets" onClose={() => this.closePreview()}>
                <Img src={url} />
              </Dialog>
            );
        }
    }

    render() {
        return (
          <>
            {this.state.showUpload && <ImageUpload _id={this.props._id} onUpload={() => this.load()} onClose={(i) => this.hideUpload(i)} />}
            {!this.state.showUpload && (
            <Dialog title="Manage Post Assets" onClose={this.props.onClose}>
          <div className="btn btn-raised btn-primary" onClick={() => this.showUpload()}> Upload Images </div>
          {this.renderAssets()}
        </Dialog>
            )}
            {this.renderPreview()}
          </>
        );
    }
}

export default newPost;
