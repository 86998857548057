/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import polyfills from './polyfills.js';
import './App.css';
import NavigationScroller from './components/navigationScroller';
import ScrollToTop from './components/scrollToTop';
import settings from './settings';
import api from './api';
import AppRoutes from './routes/app-routes';

let root = settings.root;
if (root === '/') root = '';


const mds = require.context('./pages/blog', false, /\.md$/);
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (settings.useAPI) {
            api.isLoggedIn().then((res) => {
                if (res) { this.props.onLogin(); }
            });
        }
    }

    render() {
        return (
          <Router>
            <div>
              <NavigationScroller />
              <Route path={root} component={AppRoutes} />
              <ScrollToTop />
            </div>
          </Router>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
    onLogin: () => dispatch({ type: 'login' }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);



