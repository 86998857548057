/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import SiteTitle from './siteTitle';
import Link from './link';
import settings from '../settings';

import Img from './img';
class Hero extends React.Component {
    set(key) {
        return function(e) {
            this.props.onValueSet(key, e.target.value);
        }.bind(this);
    }

    render() {
        let root = settings.root;

        if (root === '/') { root = ''; }

        return (
          <section className="usa-hero">
            <div className={'usa-grid bg0 no-left-pad no-right-pad heroFlex' + (this.props.thin ? 'thin' : '')}>
              <div className="usa-hero-callout usa-section-dark heroText">
                {!this.props.editing && <h2>{this.props.config.header}</h2>}
                {this.props.editing && <h2><textarea onChange={this.set('hero.header')} className="inlineEdit" value={this.props.config.header} /></h2>}

                {!this.props.editing && <p>{this.props.config.body}</p>}
                {this.props.editing && <p><textarea className="inlineEdit" onChange={this.set('hero.body')} value={this.props.config.body} /></p>}

                {!this.props.editing && <Link className="usa-button" to={this.props.config.href}>{this.props.config.linkText}</Link>}
                {this.props.editing && <div className="usa-button"><textarea className="inlineEdit" onChange={this.set('hero.linkText')} value={this.props.config.linkText} /></div> }
                {this.props.editing && <div className="usa-button"><textarea className="inlineEdit" onChange={this.set('hero.href')} value={this.props.config.href} /></div> }


              </div>
              <div className="heroImage">
                <Img src={this.props.config.img} />
              </div>
            </div>
          </section>
        );
    }
}


function getHomepage(state) {
    if (state && state.homepage.editing) { return state.homepage.draft; }
    if (state && !state.homepage.editing) { return state.homepage.published; }
}

const mapStateToProps = state => ({
    config: getHomepage(state).hero,
    editing: state.homepage && state.homepage.editing,
});

const mapDispatchToProps = dispatch => ({
    onValueSet: (key, value) => dispatch({ type: 'homepageValueSet', key, value }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Hero);
