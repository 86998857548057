/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Tax (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Tax Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Tax Calculators
![US Individual Tax Return Form 1040 and pen](/assets/img/FINRED-WebsiteImage-Tax-C.jpg "US Individual Tax Return Form 1040 and pen")

Taxes and calculators go together like peanut butter and jelly — one is just infinitely better with the other. Thanks to the calculators on this page, you can get a more accurate prediction of your tax bill and a clearer picture on your net take-home pay. 

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
       `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="1040 Tax"
              calculator="/assets/calc/Tax1040.html"
              title="1"
              resource={calculator}
              resourceLabel="1040-Tax"
            >
            Estimate your tax bill using the most recent tax year’s rates and rules, so you won’t have to worry about any surprises come Tax Day.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Payroll Deductions"
              calculator="/assets/calc/PayrollCurrent.html"
              title="2"
              resource={calculator}
              resourceLabel="Payroll-Deductions"
            >
            When you know your take-home pay, you can budget better. This calculator helps you determine your net paycheck using the most up-to-date tax policy.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


