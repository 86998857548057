/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/import React from 'react';

export default function NewsletterFormControl (props) {
    return (
      <form className="newsletter-form-control" method="post" action="https://public.govdelivery.com/accounts/USDODFINRED/subscribers/qualify" target="_blank">
        <input type="hidden" name="utf8" value="&#x2713;" />
        <input type="hidden" name="subscription_type" value="email" />
        <div className="input-container">
          <input type="email" name="email" id="email" placeholder="Enter email" required />
          <input type="submit" value="SIGN UP" />
        </div>
      </form>
    );
}
