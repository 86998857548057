/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from '../components/page';

import settings from '../settings';
import api from '../api';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: null,
        };
        this.login = this.login.bind(this);
    }

    async login() {
        const body = await api.login({
            username: window.$('#username').val(),
            password: window.$('#password').val(),
        });

        if (body.success) {
            this.props.history.push('/');
            this.setState(() => ({
                feedback: 'Success',
            }));
            this.props.onLogin();
        } else {
            console.log('Invalid login');
            this.setState(() => ({
                feedback: 'Invalid Login',
            }));
        }
    }

    renderFeedback() {
        if (this.state.feedback) {
            return (
              <div className="alert alert-primary" role="alert">
                {this.state.feedback}
              </div>
            );
        }
    }

    render() {
        return (
          <Page crumbs="Home|/,login">
            <div
              className="usa-grid no-left-pad no-right-pad"
              style={{ minHeight: 1000 }}
            >
              <div className="usa-width-one-fourth" />
              <div className="usa-width-three-fourths">
                <form
                  onSubmit={e => {
                      e.preventDefault();
                      return false;
                  }}
                  action=""
                >
                  <label htmlFor="username">Username</label>
                  <input id="username" type="text" />
                  <label htmlFor="password">Password</label>
                  <input id="password" type="password" />
                  <button
                    className="btn btn-raised btn-primary"
                    onClick={this.login}
                  >
                Login
                  </button>
                </form>
                <p>{this.renderFeedback()}</p>
              </div>
            </div>
          </Page>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
    onLogin: () => dispatch({ type: 'login' }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Login));


