/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../components/toolsAndResourcesPage';

import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';


export default function AdditionlResources (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >

        <ReactMarkdown
          source={`
## How-To Videos
Looking for your nearest personal financial counselor? Ready to crunch some numbers but not sure where to start? Interested in learning about our mobile app, Sen$e? Well, you’ve come to the right place! The videos on this page walk you through how to access and use some of our most popular tools, step by step. 
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBAHowTo-V.jpg"
            title="Inside the Financial Well-Being Assessment"
            alt="Video: Financial Well-Being Assessment"
            href={`${root}/video/Inside-the-Financial-Well-Being-Assessment`}
            link="Watch Now"
          >
            <p>
            The Financial Well-Being Assessment takes a big-picture look at your financial well-being — now and in the future — with your military experience in mind. This video shows how.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PFCLocatorMap-V.jpg"
            title="Find Your Nearest PFC in Less Than Three Clicks"
            alt="PFC Locator Map on smartphone"
            href={`${root}/video/Find-Your-Nearest-PFC-in-Less-Than-Three-Clicks`}
            link="Watch Now"
          >
            <p>
            With the PFC Locator Map, you can find contact information for your nearest personal financial counselor in minutes. Learn how in this video!
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CalculatorHowTo-V.jpg"
            title="Knowing Your Numbers With FINRED Calculators"
            alt="FINRED calculators on smartphone"
            href={`${root}/video/Knowing-Your-Numbers-With-FINRED-Calculators`}
            link="Watch Now"
          >
            <p>
            When it comes to your finances, you need clear, accurate insights. Learn how to access the more than 30 calculators on the FINRED website in this how-to video.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SenseHowTo-V.jpg"
            title="Take Financial Sen$e On the Go"
            alt="Sen$e on smartphone"
            href={`${root}/video/Take-Financial-Sense-On-the-Go`}
            link="Watch Now"
          >
            <p>
            Improving and maintaining your financial health doesn’t get much simpler or more accessible than our mobile app, Sen$e. Watch this video to see how to make the most of it.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}


