/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

export default function OfficialBanner (props) {
    const [expanded, setExpanded] = useState(false);

    return (
      <>
        <section className="usa-banner">
          <div className="usa-accordian">
            <header className="usa-banner-header" style={{ backgroundColor: '#FFF' }}>
              <div className="official-banner usa-grid usa-banner-inner">
                <img src="/assets/img/us_flag_small.png" alt="U.S. flag" />
                <p>An official website of the United States government</p>
                <button className="usa-accordion-button usa-banner-button" aria-expanded="false" aria-controls="gov-banner" onClick={() => setExpanded(!expanded)}>
                  <span className="usa-banner-button-text">Here's how you know</span>
                </button>
              </div>
            </header>
            <div className="usa-banner-content usa-grid usa-accordian-content usa-grid" style={{ display: expanded ? 'block' : 'none' }} id="gov-banner" aria-hidden="false">
              <div className="usa-banner-guidance-gov usa-width-one-half">
                <img className="usa-banner-icon usa-media_block-img" src="/assets/img/icon-dot-gov.svg" alt="Dot gov" />
                <div className="usa-media_block-body">
                  <p>
                    <strong>The .gov means it’s official.</strong><br />Federal government websites often end in .gov or .mil. Before sharing sensitive information, make sure you’re on a federal government site.
                  </p>
                </div>
              </div>
              <div className="usa-banner-guidance-ssl usa-width-one-half">
                <img className="usa-banner-icon usa-media_block-img" src="/assets/img/icon-https.svg" alt="Https" />
                <div className="usa-media_block-body">
                  <p>
                    <strong>The site is secure.</strong><br />The <strong>https://</strong> ensures that you are connecting to the official website and that any information you provide is encrypted and transmitted securely.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

