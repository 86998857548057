/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function PcsRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Permanent Change of Station|${root}` : `Permanent Change of Station|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-101_-_af_pcs_20_-_course_overview_airf_v6 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PCSImpactonYourSpendingPlan', 'PCS Impact on Your Spending Plan', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-102_-_af_pcs_20_-_pcs_impact_on_your_spending_plan_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PotentialExpensesDuringaPCS', 'Potential Expenses During a PCS', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-103_-_af_pcs_20_-_potential_expenses_during_a_pcs_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpecialPay,EntitlementsandOtherPotentialIncomeChanges', 'Special Pay, Entitlements and Other Potential Income Changes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-104_-_af_pcs_20_-_special_pay,_entitlements_and_other_potential_income_changes_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'OCONUSPCSConsiderations', 'OCONUS PCS Considerations', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-105_-_af_pcs_20_-_oconus_pcs_considerations_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PCSMoneyTipsI', 'PCS Money Tips I', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-106_-_af_pcs_20_-_pcs_money_tips_i_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PCSMoneyTipsII', 'PCS Money Tips II', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-107_-_af_pcs_20_-_pcs_money_tips_ii_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoDebtandCredit', 'Transition to Debt and Credit', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-108_-_af_pcs_20_-_transition_to_debt_and_credit_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CreditandDebt', 'Credit and Debt', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-109_-_af_pcs_20_-_fundamentals_and_management_of_credit_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'DebtManagement', 'Debt Management', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-110_-_af_pcs_20_-_fundamentals_and_management_of_debt_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoMilitaryConsumerProtections', 'Transition to Military Consumer Protections', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-111_-_af_pcs_20_-_transition_to_military_consumer_protections_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitaryConsumerProtection', 'Military Consumer Protection', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-112_-_af_pcs_20_-_military_consumer_protection_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoPCSHousingTopics', 'Transition to PCS Housing Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-113_-_af_pcs_20_-_transition_to_pcs_housing_topics_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PCSHousingConsiderations', 'PCS Housing Considerations', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-114_-_af_pcs_20_-_pcs_housing_considerations_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'RentingvsBuying', 'Renting vs Buying', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-115_-_af_pcs_20_-_renting_vs_buying_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, '5RulesforBuyingaHouse', '5 Rules for Buying a House', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-116_-_af_pcs_20_-_5_rules_for_buying_a_house_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pcs/40-117_-_af_pcs_20_-_course_wrap-up_v2_airf (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPCS-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
