/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import VideoPlayer from './video-player';


export default function VideoPlayerWith508({ urlNormal, url508, lightImg, isStandalone, isInSite, onPlay }) {
    const [is508C, setIs508C] = useState(false);

    function toggleMode() {
        const mode = !is508C;
        setIs508C(mode);
    }

    return (
      <>
        <VideoPlayer
          key={is508C}
          url={is508C ? url508 : urlNormal}
          webVtt={`${is508C ? url508 : urlNormal}.vtt`}
          lightImg={lightImg}
          isStandalone={isStandalone}
          isInSite={isInSite}
          onPlay={onPlay}
        />
        {
          url508 && (
            <button
              className="usa-button video-player-button"
              onClick={toggleMode}
            >
              {`${is508C ? 'Audio Descriptions Off' : 'Audio Descriptions On'}`}
            </button>
          )
        }
      </>
    );
}
