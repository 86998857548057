/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function ContinuationPay (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Continuation Pay`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Continuation Pay
Continuation pay is a one-time, midcareer bonus for eligible service
members covered under the Blended Retirement System — and receiving it
leads to some important financial decisions. Use these resources to
provide guidance on how Coast Guard members can make the most of this
milestone.

### Checklists

* [Continuation Pay Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20Continuation%20Pay%20CP.pdf)
* [Continuation Pay Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20Continuation%20Pay%20CP.pdf)

### Handouts

* [Basic Investing](/assets/downloads/USCG%20TC%20Handout-Basic%20Investing.pdf)
* [Entitlement to Continuation Pay](/assets/downloads/USCG%20TC%20Handout-Entitlement%20to%20Continuation%20Pay.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Military Retirement](/assets/downloads/USCG%20TC%20Handout-Military%20Retirement.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)


### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%206%20Continuation%20Pay%20Presentation%20CG%206-10-21.pptx)
* [Instructor Guide](/assets/downloads/TC%206%20Continuation%20Pay%20Instructor%20Guide%20CG%206-10-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%206%20Continuation%20Pay%20CFS%20Reference%20Manual%20CG%206-11-21.pdf)

### Video Discussion Guide

* [Continuation Pay](/assets/downloads/Continuation%20Pay%20Video%20Discussion%20Guide-5.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


