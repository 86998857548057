/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SplTOC from '../../components/tocs/splTOC';
import AdditionalResources from '../../pages/uscg-resources/additional-resources/additional-resources';
import FinancialPlanningWS from '../../pages/uscg-resources/additional-resources/financial-planning-ws';
import OnlineFinancialCalculators from '../../pages/uscg-resources/additional-resources/online-financial-calc';
import CommandFinancialSpecialists from '../../pages/uscg-resources/additional-resources/command-financial-specialists';
import CoastGuardMember from '../../pages/uscg-resources/additional-resources/coast-guard-member';
import CoastGuardSpouse from '../../pages/uscg-resources/additional-resources/coast-guard-spouse';
import CoastGuardCivilian from '../../pages/uscg-resources/additional-resources/coast-guard-civilian';
import CoastGuardRetiree from '../../pages/uscg-resources/additional-resources/coast-guard-retiree';
import Oops404Page from '../../pages/oops-404';


export default function AdditionalResourceRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Additional Resources|${root}` : `Additional Resources|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routeProps) => <AdditionalResources {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/FinancialPlanningWS`}
          render={(routeProps) => <FinancialPlanningWS {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/OnlineFinancialCalculators`}
          render={(routeProps) => <OnlineFinancialCalculators {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/CommandFinancialSpecialists`}
          render={(routeProps) => <CommandFinancialSpecialists {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/CoastGuardMember`}
          render={(routeProps) => <CoastGuardMember {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/CoastGuardSpouse`}
          render={(routeProps) => <CoastGuardSpouse {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/CoastGuardCivilian`}
          render={(routeProps) => <CoastGuardCivilian {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          exact
          path={`${root}/CoastGuardRetiree`}
          render={(routeProps) => <CoastGuardRetiree {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
