/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../../../components/splPage';

import MarkLink from '../../../components/markLink';
import FeaturedAsset from '../../../components/featuredArticle';
import ReactMarkdown from '../../../components/markdown';

export default function NavyResource (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
##  Additional Resources 
Click through this page to find additional resources for each audience,
as well as links to helpful financial planning tools to supplement and
aid your work as a PFM or CFS.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FinancialPlanningWorksheet-R.jpg"
            alt="Young couple checking their home finances at home"
            title="Financial Planning Worksheet"
            href={`${root}/FinancialPlanningWS`}
            link="Visit Now"
          >
            <p>
            Use this worksheet to help Coast Guard members better
            understand their spending, debt and overall financial health.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGOnlineFinancialCalculators-R.jpg"
            alt="Man using laptop at desk in home office"
            title="Online Financial Calculators"
            href={`${root}/OnlineFinancialCalculators`}
            link="Visit Now"
          >
            <p>
            Take the guesswork out of number crunching with this
            collection of online financial calculators from Thrift Savings Plan,
            Office of Financial Readiness and more.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGCommandFinancialSpecialists-R.jpg"
            alt="Personal financial management specialist smiling"
            title="Command Financial Specialists"
            href={`${root}/CommandFinancialSpecialists`}
            link="Visit Now"
          >
            <p>
            Review this collection of resources specifically curated
            to assist you in your work as a CFS. Find helpful links, directions
            to important materials and more.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGMember-R.jpg"
            alt="Coast Guard members looking at a map"
            title="Coast Guard Member"
            href={`${root}/CoastGuardMember`}
            link="Visit Now"
          >
            <p>
            These links and resources can offer extra support as you
            assist Coast Guard members on their journey to financial readiness.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGSpouse-R.jpg"
            alt="Coast Guard spouses deliver goodie bags to crewmembers"
            title="Coast Guard Spouse"
            href={`${root}/CoastGuardSpouse`}
            link="Visit Now"
          >
            <p>
            The links on this page can help provide Coast Guard
            spouses with information catered to their unique financial readiness
            journey.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGCivilian-R.jpg"
            alt="Coast Guard civilian meeting with team"
            title="Coast Guard Civilian"
            href={`${root}/CoastGuardCivilian`}
            link="Visit Now"
          >
            <p>
            Review this collection of links and resources designed to
            assist Coast Guard civilians on their journey to financial
            readiness.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGRetiree-R.jpg"
            alt="Two Coast Guard members holding framed certificate during retirement ceremony"
            title="Coast Guard Retiree"
            href={`${root}/CoastGuardRetiree`}
            link="Visit Now"
          >
            <p>
            Use the links and resources on this page to support Coast
            Guard retirees as they transition to a new phase of financial
            readiness.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGSUPRT-R.jpg"
            alt="Crewmembers enjoy the sunset from the fantail while underway in the Pacific Ocean"
            title="CG SUPRT"
            href="https://www.cgsuprt.com/portal/landing"
            link="Visit Now"
          >
            <p>
            Encourage Coast Guard members to get the support and services
            they need to navigate life's challenges — from personal financial
            wellness, to legal services and more
            </p>
          </FeaturedAsset>
        </div>

      </Page>
    );
}
