/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function CoastGuardCivilian (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Coast Guard Civilian`}
        toc={props.toc}
        articleClass="subpage uscg"
      >
        <ReactMarkdown
          source={`
## Coast Guard Civilian
This collection of resources and guidance can provide additional
support in your work as a PFM or CFS. Use the following bullets to
instruct, inform and engage Coast Guard civilians.

* [Benefits for Federal Employees: BENEFEDS | Federal Benefits Enrollment (FEDVIP, FSAFEDS, FLTCIP)](https://www.benefeds.com/)
* [CGMA | The official relief society of the U.S. Coast Guard](https://cgmahq.org/)
* [CG SUPRT](https://www.cgsuprt.com/portal/landing)
* [Consumer Financial Protection
    Bureau](https://www.consumerfinance.gov/)
* [Federal Human Resources Navigator: FedHR: Login](https://app.fedhrnavigator.com/frbweb/logon.do?operation=index&client=USCG)
* [FINRA BrokerCheck](https://brokercheck.finra.org/)
    BrokerCheck is a free tool to research the background and
    experience of financial brokers, advisers and firms.
* [Flexible Spending Account for Federal Employees: FSAFEDS](https://www.fsafeds.com/)
* HR Coast Guard Retiree Services  
    Contact information about Coast Guard Retiree Services can be
    found below or submit a ticket via **[FedHR](https://app.fedhrnavigator.com/frbweb/logon.do?operation=index&client=USCG)**
    * Northeast Region: 410-636-7605
    * Southeast Region: 202-795-6341
    * Headquarters: 202-795-6337
    * West (California, Kansas) and Northeast (Academy): 202-795-6349
    * West (Hawaii, Alaska, Washington, Oregon) and Southeast (West
        Virginia, Ohio): 202-475-5081
* [Human Resources Operations Division, CG-123](https://www.dcms.uscg.mil/Our-Organization/Assistant-Commandant-for-Human-Resources-CG-1/Civilian-Human-Resources-Diversity-and-Leadership-Directorate-CG-12/Civilian-HR/CG-1211-HR-Operations/)
* [IdentityTheft.gov](https://identitytheft.gov/)
* [IRS.gov](https://www.irs.gov/)
* [My Employee Personal Page: My EPP (usda.gov)](https://www.nfc.usda.gov/EPPS/index.aspx?ReturnUrl=%2fepps%2f)
* [OPM](https://www.opm.gov/)
* [PowerPay](https://extension.usu.edu/powerpay/index)
* [Thrift Savings Plan](https://www.tsp.gov/)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


