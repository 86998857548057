/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/splPage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function ServiceResources (props) {
    const root = props.match.path;
    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >
        <ReactMarkdown
          source={`
## Service Resources
Strong financial skills help service members stay mission-focused and prepared for whatever lies ahead. Visit our [Learning Resource Library](https://community.apan.org/wg/finred-lrl) to stay up to date on the latest service-specific developments and training. These trusted resources will help your service members make informed decisions and stay financially ready.
`}
          renderers={{ link: MarkLink }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CoastGuard-R.jpg"
            title="Coast Guard Resources"
            href={`${root}/CoastGuardResource`}
            type="Training Resources"
            link="Visit Now"
          >
            <p>
            Use the products and resources on this page to help guide your Coast Guardsmen to better financial readiness.
            </p>
          </FeaturedAsset>

        </div>

      </Page>
    );
}
