/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';
import Link from '../../components/link';
import ReactMarkdown from '../../components/markdown';


export default function AdditionlResources (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >

        <ReactMarkdown
          source={`
  ## Additional Resources
  There’s no such thing as too many resources — and this page brings together some of the most useful education across the web from our most trusted collaborators to give you more insight on your financial readiness journey. From helpful information for each phase of your military career, to resources specific to your service, each page has information to help you learn more and continue building your financial literacy.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Sense-Main-R.jpg"
            alt="Sen$e logo, money texture and screenshot on mobile phone"
            title="Meet Sen$e"
            href="/ToolsAndAddRes/Sen$e"
            link="Show Me How"
          >
            <p>
            Discover how Sen$e, the DoD’s financial literacy app, can help you grow your financial know-how throughout your military career and beyond.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-KnowingBenefits-R.jpg"
            title="Knowing Your Benefits and Entitlements "
            href={`${root}/Benefits`}
            link="Learn More"
          >
            <p>
            Ensure you’re making the most of the benefits and entitlements that come with your military career. Check out these links to learn more.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ManagingMoney-R.jpg"
            title="Managing Your Money"
            href={`${root}/Money`}
            link="Learn More"
          >
            <p>
            Get back to the basics of money management with these resources that will help you secure your spending plan and feel confident in your finances.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SavingInvesting-R.jpg"
            title="Saving and Investing"
            href={`${root}/Saving`}
            link="Learn More"
          >
            <p>
            Figuring out smart ways to save and invest is one of the best ways to create financial stability. These resources can help you develop your saving strategy.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PlanningFuture-R.jpg"
            title="Planning for the Future"
            href={`${root}/Planning`}
            link="Learn More"
          >
            <p>
            By taking steps now to plan for your financial future, you can meet your long-term goals and be better prepared to handle the unexpected.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerProtections-R.jpg"
            title="Understanding Consumer Protections"
            href={`${root}/Protections`}
            link="Learn More"
          >
            <p>
            As a consumer, there are laws in place to protect you against scams and unfair practices. Learn more about your rights and how to stay safe with these resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ServicesSpecific-R.jpg"
            title="Service Specific Resources"
            href={`${root}/Services`}
            link="Learn More"
          >
            <p>
            View resources specific to your service for the information you need as it applies to your branch.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FeaturedCollaborators-R.jpg"
            title="Featured Collaborators"
            href={`${root}/Collaborators`}
            link="Learn More"
            type=""
          >
            <p>
            Learn about our trusted collaborators, who provide some of the most helpful and informative financial resources you can find online.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}


