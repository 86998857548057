/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Future from '../pages/future';
import SurvivorBenefitPlanRoutes from './survivor-benefit-plan-routes';
import HookUpArticle from '../components/hookUpArticle';
import FutureTOC from '../components/tocs/future';
import Oops404Page from '../pages/oops-404';

export default function SavingRoutes(props) {
    const root = props.match.path;
    const crumbs = `Planning for the Future|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <Future {...routerProps} crumbs={crumbs} toc={FutureTOC} />}
        />

        {[
            HookUpArticle(root, crumbs, FutureTOC, 'EaseTransitions.md', 'Planning', 'Ease Military Transitions Through Financial Planning'),
            HookUpArticle(root, crumbs, FutureTOC, 'FuturePlanning.md', 'Planning', 'Don’t Just Plan for the Future — Plan for Your Future'),
            HookUpArticle(root, crumbs, FutureTOC, 'FinancialPlanningforTransition.md', 'Planning', 'Financial Planning for Transition - TRICARE'),
            HookUpArticle(root, crumbs, FutureTOC, 'CC-DOL-May13-26.md', 'Planning', 'Prepare for Retirement'),
            HookUpArticle(root, crumbs, FutureTOC, 'FinancialPlanningTricareReserve.md', 'Planning', 'Financial Planning for Transition: TRICARE for Reserve Component Members'),
            HookUpArticle(root, crumbs, FutureTOC, 'InsuranceContracts.md', 'Planning', 'How to Read — and Understand! — Your Insurance Contracts'),
            HookUpArticle(root, crumbs, FutureTOC, 'CertificationProcessEases.md', 'Planning', 'Certification Process Eases'),
            HookUpArticle(root, crumbs, FutureTOC, 'CareerReadyPortal.md', 'Planning', 'Career Ready Portal: Providing Our Military the Tools to Thrive'),
            HookUpArticle(root, crumbs, FutureTOC, 'ChildrenFinances.md', 'Planning', 'Financial Planning for Children: Beyond Diapers and Wipes'),
            HookUpArticle(root, crumbs, FutureTOC, 'POAMilitary.md', 'Planning', 'How Powers of Attorney Serve the Military'),
            HookUpArticle(root, crumbs, FutureTOC, 'CompBenefitsEntitlements.md', 'Planning', 'Financial Planning for Transition: Compensation, Benefit and Entitlement Considerations for Military Spouses'),
            HookUpArticle(root, crumbs, FutureTOC, 'GetRetirementReadyChecklist.md', 'Planning', 'Get Retirement Ready With This Checklist'),
            HookUpArticle(root, crumbs, FutureTOC, 'PostRetirementJobs.md', 'Planning', 'Prepare Your Finances for the Post-Retirement Job Hunt'),
            HookUpArticle(root, crumbs, FutureTOC, 'ImportanceofHavingaWill.md', 'Planning', 'The Importance of Having a Will'),
            HookUpArticle(root, crumbs, FutureTOC, 'EstatePlanningOverview.md', 'Planning', 'Estate Planning Overview'),
            HookUpArticle(root, crumbs, FutureTOC, 'RetirementExpectationsVsReality.md', 'Planning', 'Retiring From the Military: Expectations vs. Reality'),
            HookUpArticle(root, crumbs, FutureTOC, 'ConsiderTrusts.md', 'Planning', 'Consider a Trust in Your Estate Plan'),
            HookUpArticle(root, crumbs, FutureTOC, 'FinancialHealth.md', 'Planning', 'Money Matters: The Connection Between Financial Health and Mental Health'),
            HookUpArticle(root, crumbs, FutureTOC, 'MilitaryMedicalRetirement.md', 'Planning', 'Financial Considerations for Military Medical Retirement'),
            HookUpArticle(root, crumbs, FutureTOC, 'PostServiceTaxImplications.md', 'Planning', 'Understanding Post-Service Tax Implications'),
        ]}

        <Route
          path={`${root}/SurvivorBenefitPlan`}
          render={(routerProps) => <SurvivorBenefitPlanRoutes {...routerProps} crumbs={crumbs} toc={FutureTOC} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

