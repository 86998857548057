/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import HookUpHostedVideo from '../components/hookUpHostedVideo';
import Oops404Page from '../pages/oops-404';

export default function HostedRoutes(props) {
    const root = props.match.path;

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(routeProps) => (
            <Switch>
              {[
                  HookUpHostedVideo(routeProps.match.path, 'MoneyPersonalities', '/assets/video/hosted/FR03-03_-_Money_Personalities_v1_COMPRESSED.mp4'),
                  HookUpHostedVideo(routeProps.match.path, 'PFCSpendingPlan', '/assets/video/hosted/FR03-05_-_PFC_Spending_Plan_v3.mp4'),
              ]}

              <Route render={() => <Oops404Page />} />
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
