/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';


export default function InvestingMicrolearning (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Investing Microlearning
Big topics, tackled through accessible, short videos — that’s what our investing microlearning series is all about. These one-minute videos will introduce you to important investing concepts, like when to invest and when to save, how to grow your money through compounding, and how to balance risk and return. Check back frequently as the series grows to cover new and exciting topics.
            `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentBasics01-V.png"
            alt="Video: Investing Vs. Saving"
            title="Investing vs. Saving"
            href={`${root}/video/Investing-vs-Saving`}
            link="Watch Now"
          >
            <p>
            You don’t need to choose between saving and investing — both can help you reach your goals! This video offers reasons and tips to make the most of each approach.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-02.png"
            alt="Video: How Does Money Grow Through Compounding?"
            title="How Does Your Money Grow?"
            href={`${root}/video/How-Does-Your-Money-Grow`}
            link="Watch Now"
          >
            <p>
            Make the most of your savings by growing your money through compounding. The earlier you start, the more your savings will be worth later.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-03.png"
            alt="Video: Balancing Risk and Returns"
            title="Balancing Risk and Returns"
            href={`${root}/video/Balancing-Risk-and-Returns`}
            link="Watch Now"
          >
            <p>
            Think of investment risks and returns as a balancing act. Learn more about key factors — when you’ll need your money and how much risk you want to take.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-04.png"
            alt="Video: Buying Stocks and Bonds"
            title="Buying and Selling Stocks and Bonds"
            href={`${root}/video/Buying-and-Selling-Stocks-and-Bonds`}
            link="Watch Now"
          >
            <p>
            Stocks and bonds have one important thing in common: They can help you invest in your future. Use this video to find out more about each option.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-05.png"
            alt="Video: What is a Mutual Fund?"
            title="What Is a Mutual Fund?"
            href={`${root}/video/What-Is-a-Mutual-Fund`}
            link="Watch Now"
          >
            <p>
            Overseen by professionals, mutual funds can help diversify your investment portfolio. See how they work and why they often come with lower fees and risks.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-06.png"
            alt="Video: Stock Market Ups and Downs"
            title="Stock Market Ups and Downs"
            href={`${root}/video/Stock-Market-Ups-and-Downs`}
            link="Watch Now"
          >
            <p>
            Stock market swings happen. Fortunately, you don’t have to sweat them if you’re investing for the long haul. Learn about bear and bull markets now.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-07.png"
            alt="Video: What is a Diversified Portfolio?"
            title="A Diversified Portfolio"
            href={`${root}/video/A-Diversified-Portfolio`}
            link="Watch Now"
          >
            <p>
            Spreading your financial holdings among different asset classes can help reduce risks. Learn why a diversified portfolio can be a smart investment move.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-08.png"
            alt="Video: Balancing Your Portfolio"
            title="Balancing Your Portfolio"
            href={`${root}/video/Balancing-Your-Portfolio`}
            link="Watch Now"
          >
            <p>
            Your goals and risk tolerance may change over time. This video explores why periodically adjusting your portfolio can make for more balanced investments.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-09.png"
            alt="Video: How Do Investments Make Money?"
            title="How Investments Make Money"
            href={`${root}/video/How-Investments-Make-Money`}
            link="Watch Now"
          >
            <p>
            Depending on the type, investments increase in value in different ways. Learn more about interest, dividends and capital gains in this video.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-10.png"
            alt="Video: Managing Gains & Losses"
            title="Managing Gains and Losses"
            href={`${root}/video/Managing-Gains-and-Losses`}
            link="Watch Now"
          >
            <p>
            There’s a difference between realized and unrealized gains or losses. This video explains the impact of buying and selling — and how it affects your taxes.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-11.png"
            alt="Video: Measuring the Market"
            title="Measuring the Market"
            href={`${root}/video/Measuring-the-Market`}
            link="Watch Now"
          >
            <p>
            Learn about an investment’s rate of return and how to measure the stock market’s overall return rate by analyzing three major indexes.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-InvestmentSeries-12.png"
            alt="Video: Market Volatility"
            title="Market Volatility"
            href={`${root}/video/Market-Volatility`}
            link="Watch Now"
          >
            <p>
            Periods of market volatility can be scary. In this video, you’ll learn the importance of having a long-term investment strategy and diversified portfolio.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
