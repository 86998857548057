/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect } from 'react';
// import { withRouter } from 'react-router';


export default function SecondaryNav () {
    useEffect(() => {
        const cx = '009790642128191989921:2tb17kjemu8';
        const gcse = document.createElement('script');
        gcse.type = 'text/javascript';
        gcse.async = true;
        gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(gcse, s);
    }, []);

    return (
      <ul className="usa-unstyled-list usa-nav-secondary-links">
        <li className="js-search-button-container">
          <div className="usa-search usa-search-small  js-search-form">
            <div role="search">
              <label className="usa-sr-only" htmlFor="header-search-form">Search small</label>
              <div
                className="gcse-search"
                data-resultsUrl="http://www.example.com"
                data-newWindow="true"
                data-queryParameterName="search"
              />
            </div>
          </div>
          {/* <button onClick={this.expand} style={style2} className="usa-header-search-button js-search-button">Search</button> */}
        </li>
      </ul>
    );
}
