/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function FirstDutyStationRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},First Duty Station|${root}` : `First Duty Station|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-101 - Video 1 - Facilitator - First Duty Station_v4 GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CompensationTaxesandBanking', 'Compensation, Taxes and Banking', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-102 - Video 2 - Compensation, Taxes and Banking _v7.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlanandEmergencyFunds', 'Spending Plan and Emergency Funds', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-103 - Video 3 - Spending Plan and Emergency Funds_v7 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingDebtandUnderstandingCredit', 'Managing Debt and Understanding Credit', crumbs,
                      '/assets/video/navy-resource/first-duty-station/41-102_-_first_duty_station_-_managing_debt_and_credit_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitaryConsumerAwareness', 'Military Consumer Awareness', crumbs,
                      '/assets/video/navy-resource/first-duty-station/41-103_-_first_duty_station_-_military_consumer_protections_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancialPlanningTopicsTransition', 'Financial Planning Topics Transition', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-106 - Video 6 - Facilitator - Transition to other financial planning topics_v5.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancingaMajorPurchase', 'Financing a Major Purchase', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-107 - Video 7 - Financing a Major Purchase_v7 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EducationandCareerInvestmentPrograms', 'Education and Career Investment Programs', crumbs,
                      '/assets/video/navy-resource/first-duty-station/41-101_-_video_8_-_education_and_career_investment_programs_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeandHealthInsurance', 'Life and Health Insurance', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-109_-_video_9_-_life_and_health_insurance_v9_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HowTimeBuildsWealth', 'How Time Builds Wealth', crumbs,
                      '/assets/video/navy-resource/first-duty-station/15_01_NAVY-How Time Builds Wealth_v8 NoDisc TP Look NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'BasicInvesting', 'Basic Investing', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-111 - Video 11 - Basic Investing_v8.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UnderstandingtheTSP', 'Understanding the TSP', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-112 - Video 12 - Understanding the TSP_v7.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingYourTSP', 'Managing Your TSP', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-113 - Video 13 - Managing your TSP_v8 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/first-duty-station/18-114 - Video 14 - Facilitator - First Duty Station Conclusion_v4 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstDuty-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
