/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function Promotion (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Promotion`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Promotion
Receiving a promotion is an exciting time for any service member.
However, as their careers take off, it's important for Coast Guard members
to make decisions and adjustments that set them up for future success.
Use these materials to guide them through the financial considerations
beyond the celebration.

### Checklists

* [Promotion Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20Promotion.pdf)
* [Promotion Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20Promotion.pdf)

### Handouts

* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Thrift Savings Plan](/assets/downloads/USCG%20TC%20Handout-Thrift%20Savings%20Plan.pdf)
* [Understanding Credit](/assets/downloads/USCG%20TC%20Handout-Understanding%20Credit.pdf)

### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%204%20Promotion%20Presentation%20CG%206-4-21.pptx)
* [Instructor Guide](/assets/downloads/TC%204%20Promotion%20Instructor%20Guide%20CG%206-4-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%204%20Promotion%20Reference%20Manual%206-14-21.pdf)

### Video Discussion Guide

* [Promotion](/assets/downloads/Promotion%20Video%20Discussion%20Guide-8.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


