/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function FirstChild (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},First Child`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## First Child
The changes brought about by welcoming a new child are seemingly
endless. Encourage Coast Guard members to assess, prepare and adjust
their finances to plan ahead as much as possible — from how they
approach their spending plan and emergency fund, to saving for college
and planning for the future.

### Checklists

* [First Child Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20Welcoming%20First%20Child.pdf)
* [First Child Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20Welcoming%20First%20Child.pdf)

### Handouts

* [5 Rules of Buying a House](/assets/downloads/USCG%20TC%20Handout-5%20Rules%20of%20Buying%20a%20House.pdf)
* [Education Benefits and Savings](/assets/downloads/USCG%20TC%20Handout-Education%20Benefits%20&%20Savings.pdf)
* [Estate Planning](/assets/downloads/USCG%20TC%20Handout-Estate%20Planning.pdf)
* [Major Purchases](/assets/downloads/USCG%20TC%20Handout-Major%20Purchases.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Military Retirement](/assets/downloads/USCG%20TC%20Handout-Military%20Retirement.pdf)
* [Paying Off Student Loans](/assets/downloads/USCG%20TC%20Handout-Paying%20off%20Student%20Loans.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Survivor Benefits Overview](/assets/downloads/USCG%20TC%20Handout-Survivor%20Benefits%20Overview.pdf)
* [TRICARE Overview](/assets/downloads/USCG%20TC%20Handout-TRICARE%20Overview.pdf)


### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%208%20First%20Child%20Presentation%20CG%206-15-21.pptx)
* [Instructor Guide](/assets/downloads/TC%208%20First%20Child%20Instructor%20Guide%20CG%206-15-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%208%20First%20Child%20CFS%20Reference%20Manual%20CG%206-15-21.pdf)

### Video Discussion Guide

* [First Child](/assets/downloads/First%20Child%20Video%20Discussion%20Guide-6.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


