/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import NewsletterFormControl from './newsletterFormControl';
import Link from './link';

export default function NewsletterFormLarge (props) {
    return (
      <div>
        <div className="newsletter-subscribe inline">
          <div>
            <div className="title">
            Sign up for an eNewsletter
            </div>
            <NewsletterFormControl />
          </div>
        </div>
        <div className="epub-link">
          <Link to="/ePublications">eNewsletter Editions & Archives</Link>
        </div>
      </div>
    );
}
