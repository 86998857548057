/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function PromotionRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Promotion|${root}` : `Promotion|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_101_-_af_promotion_20_-_course_overview_v3.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlanConsiderations', 'Spending Plan Considerations', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_102_-_af_promotion_20_-_spending_plan_considerations_v3.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingsandEmergencyFunds', 'Savings and Emergency Funds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_103_-_af_promotion_20_-_savings_and_emergency_funds_v3.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoFuture-FocusedTopics', 'Transition to Future-Focused Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_104_-_af_promotion_20_-_transition_to_future-focused_topics_v2.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ThriftSavingsPlanBasics', 'Thrift Savings Plan Basics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_105_-_af_promotion_20_-_thrift_savings_plan_basics_v3.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingYourTSP', 'Managing Your TSP', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_106_-_af_promotion_20_-_managing_your_tsp_v3.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CareerInvestmentPrograms', 'Career Investment Programs', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_107_-_af_promotion_20_-_career_investment_programs_v4.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/promotion/45_-_108_-_af_promotion_20_-_course_wrap-up_v2.mp4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPromotion-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
