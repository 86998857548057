/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Saving ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Saving and Investing`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Saving and Investing
These resources can help you develop a strong saving and investment plan to help achieve your future financial goals. You can also schedule an appointment with a [personal financial manager](https://installations.militaryonesource.mil/) or [counselor](/pfcMap) to discuss your options and make sure you’re on track to meet important milestones — like retirement. In addition to the supplemental sources on this page, don’t forget to visit [FINRED's collection of saving and investing resources](/Saving).

* [Compound Interest Calculator](https://www.investor.gov/additional-resources/free-financial-planning-tools/compound-interest-calculator)
  Put compound interest to work for you! Use this calculator to determine how much your money can grow, and tap other no-cost financial planning tools.
* [Estimation Calculators](https://www.treasurydirect.gov/indiv/tools/tools_estimationcalc.htm)
  Holding savings bonds? Use these estimation calculators to get their approximate future value and see how much and how long it will take to reach your goals.
* [Federal Trade Commission's Consumer Information ](https://www.consumer.ftc.gov/)
  Protect yourself from scams, file a complaint, stop unwanted calls and more using this consumer information from the Federal Trade Commission.
* [Financial Readiness Saving and Investing for Military Personnel](https://www.investor.gov/sites/investorgov/files/2019-02/SEC%20Financial%20Readiness%20Brochure_0.pdf)
  Let this Securities and Exchange Commission booklet help you work on your financial plan and save for the future as a member of the military community.
* [FINRA](http://www.finra.org/)
  The nonprofit Financial Industry Regulatory Authority (FINRA) educates and informs investors and safeguards against fraud and bad practices. Find out how.
* [Investor Bulletin: Savings and Investing Basics for Military Personnel](http://www.bbbmarketplacetrust.org/story/28213095/investor-bulletin-savings-and-investing-basics-for-military-personnel)
  Stop scammers in their tracks! Use these savings and investing tips for military personnel from the U.S. Securities and Exchange Commission.
* [Investor.gov Military Resources](https://www.investor.gov/additional-resources/specialized-resources/military)
  Learn about U.S. Securities and Exchange Commission programs and initiatives that assist members of the military with investing wisely and avoiding fraud.
* [Military Spouses: Ensuring Financial Readiness on the Homefront](https://www.investor.gov/additional-resources/specialized-resources/directors-take/military-spouses-ensuring-financial-0)
  Military spouses, make sure you’re financially ready —especially during deployments — with these tips from the U.S. Securities and Exchange Commission.
* [MyMoney.gov](https://www.mymoney.gov/Pages/default.aspx)
  Watch your wealth stack up! Try using five building blocks — Earn, Save and Invest, Protect, Spend and Borrow — and find tips galore on MyMoney.gov.
* [Social Security Administration Calculators](https://www.ssa.gov/planners/benefitcalculators.html)
  Plan ahead by creating a “my Social Security” account so you can verify your earnings, get statements and more. And try the various calculators.
* [U.S. Securities and Exchange Commission](https://www.investor.gov/)
  Research before you invest by checking your investment professional's background, registration status and more and staying up to date on scam alerts.
* [What are the Differences Between 529 Plans? ](https://www.consumerfinance.gov/ask-cfpb/what-are-the-differences-between-529-plans-en-2078/)
  Learn about the two types of tax-advantaged 529 college savings plans and decide which one — prepaid tuition or savings — is best for you and your family.  
         `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


