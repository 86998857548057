/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';
import Link from './link';
export default function MarkLink (props) {
    const text = props.children[0].props.value;
    const href = props.href;

    if (!href || href.trim() === '') {
        return (
          <a id={text} href={`#${text}`} />
        );
    }

    const markedLinkText = /(.*?)(\|mark\|\|strong\|(.+?)\|\/strong\|\|\/mark\|)(.*)/i;
    let textMatch = markedLinkText.exec(text);
    if (textMatch !== null) {
        let end;
        let html = (<></>);
        while (textMatch !== null) {
            html = (<>{html}{textMatch[1]}<mark><strong>{textMatch[3]}</strong></mark></>);
            end = textMatch[4];
            textMatch = markedLinkText.exec(end);
        }

        return <Link to={href}><>{html}{end}</></Link>;
    }

    return <Link to={href}>{text}</Link>;
}
