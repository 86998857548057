/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Protections ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Understanding Consumer Protections`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Understanding Consumer Protections
Use these resources to learn more about consumer credit protections and your rights as a service or family member. If you have questions or need assistance, a [personal financial manager](https://installations.militaryonesource.mil/) or counselor can work with you to become a savvy consumer. Plus, in addition to the supplemental sources on this page, don’t forget to visit [FINRED's collection of consumer protection resources](/Consumer-Protections).

* [Buying a House: Tools and Resources for Homebuyers](https://www.consumerfinance.gov/owning-a-home/)
  House hunting? These tools and resources can provide answers to your questions and help you feel confident about choosing the right loan for your situation.
* [Consumer Financial Protection Bureau](https://www.consumerfinance.gov/consumer-tools/educator-tools/servicemembers/)
  The Consumer Financial Protection Bureau works to help military families overcome unique financial challenges. Find out how.
* [Consumer Protection: Mortgages ](https://www.occ.treas.gov/topics/consumers-and-communities/consumer-protection/mortgages/index-mortgages.html)
  A house is a big, exciting purchase. Be familiar with consumer protections before you apply for a mortgage so you can compare offers and get the best deal.
* [Department of the Treasury's Consumer Protection Resources](https://www.occ.treas.gov/topics/consumers-and-communities/consumer-protection/index-consumer-protection.html)
  Bank on this: The Office of the Comptroller of the Currency can help if you're a national bank customer and need assistance, answers or to file a complaint.
* [Empowering Military Consumers – All Year Long](https://www.consumerfinance.gov/about-us/blog/empowering-military-consumers-all-year-long)
  Military Consumer Month may be over, but there are lots of tools and resources ready to empower you all year long. This article is a great place to start.
* [Financial and Housing Rights](https://www.justice.gov/servicemembers/financial-and-housing-rights)
  Members of the military community receive a wide range of benefits and protections under the Servicemembers Civil Relief Act. Take advantage!
* [FINRA](http://www.finra.org/)
  The nonprofit Financial Industry Regulatory Authority, or FINRA, educates and informs investors and safeguards against fraud and bad practices. Find out more.
* [Homes and Mortgages](https://consumer.ftc.gov/articles/mortgage-relief-scams)
  Protect your money! Learn about home loan deals and duds and common home improvement, rental and timeshare scams before you buy, rent or make changes.
* [Talk to a Housing Counselor](https://www.hud.gov/i_want_to/talk_to_a_housing_counselor)
  Seeking advice on homebuying, renting, default, foreclosure avoidance, credit and other issues? The Department of Housing and Urban Development can help.
* [The Uniformed Services Employment and Reemployment Rights Act](https://esgr.mil/USERRA/What-is-USERRA)
  Understand the Uniformed Services Employment and Reemployment Rights Act of 1994 and what it means for uniformed service members and their civilian employers.
* [USA.gov Consumer Issues](https://www.usa.gov/consumer)
  Despise robocalls? Learn how to be a safe consumer, end unwanted calls and mail, act when a purchase or service goes wrong, search product recalls and more.
* [Welcome to the Official Servicemembers Civil Relief Act (SCRA) Website](https://scra.dmdc.osd.mil/scra/#/home)
  The Servicemembers Civil Relief Act provides protections in lending. Here’s how you can submit a request for records and put it to work for you.
* [What are my rights under the Military Lending Act?](https://www.consumerfinance.gov/ask-cfpb/what-are-my-rights-under-the-military-lending-act-en-1783/)
  You have rights under the Military Lending Act. Understand those rights and know what steps to take if you are having an issue with a consumer loan.
         `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


