/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../components/splPage';

import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function MilitaryJourneyTouchpoints (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >

        <ReactMarkdown
          source="
## Military Journey Touchpoints
Each phase of your military journey comes with exciting new milestones and important financial considerations. Make sure you’re prepared to navigate each step with these short, informative videos designed to help you succeed at each touchpoint. From your first duty station to each PCS and major life event after, taking care of your finances helps to keep you mission-ready and prepared for the future.
"
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          {/* TP1 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-01-Intro.jpg"
            title="Military Touchpoint Series Introduction"
            href={`${root}/video/Military-Touchpoint-Series-Introduction`}
            link="Watch Now"
          >
            <p>
            Watch this video to learn about financial information and training service members receive at regular milestones, or touchpoints, throughout their journeys.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-01-Topic.jpg"
            title="Decode Your Leave and Earnings Statement"
            href={`${root}/video/Decode-Your-Leave-and-Earnings-Statement`}
            link="Watch Now"
          >
            <p>
            Learn to decipher Leave and Earnings Statements with this detailed video that covers entitlements, deductions, allotments, taxes, leave and other categories.
            </p>
          </FeaturedAsset>

          {/* TP2 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-02-Intro.jpg"
            title="First Duty Station"
            href={`${root}/video/First-Duty-Station`}
            link="Watch Now"
          >
            <p>
            Financial training is essential. Make sure to review your benefits, complete training and learn more about finance at your first duty station.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-02-Topic.jpg"
            title="Creating a Spending Plan"
            href={`${root}/video/Creating-a-Spending-Plan`}
            link="Watch Now"
          >
            <p>
            Enjoy financial health! Create a spending plan today that will keep you on track towards financial freedom.
            </p>
          </FeaturedAsset>

          {/* TP3 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-03-Intro.jpg"
            title="Each PCS"
            href={`${root}/video/Each-PCS`}
            link="Watch Now"
          >
            <p>
            You already know to get the lay of the land with each permanent change of station. Find out why it's also key to see what's on the horizon for your finances.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-03-Topic.jpg"
            title="Updating a Spending Plan"
            href={`${root}/video/Updating-a-Spending-Plan`}
            link="Watch Now"
          >
            <p>
            Permanent change of station brings changes in scenery, housing, routine — and expenses! Find out how to review and update your spending plan with each move.
            </p>
          </FeaturedAsset>

          {/* TP4 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-04-Intro.jpg"
            title="Promotion"
            href={`${root}/video/Promotion`}
            link="Watch Now"
          >
            <p>
            You know that promotions put more money in your paycheck. Find out how to maximize it to help achieve your financial goals for the future.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-04-Topic.jpg"
            title="Financial Savings"
            href={`${root}/video/Financial-Savings`}
            link="Watch Now"
          >
            <p>
            Get extra mileage from your next promotion and the extra money it puts in your paycheck by focusing on the savings portion of your spending plan.
            </p>
          </FeaturedAsset>

          {/* TP6 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-06-Intro.jpg"
            title="Continuation Pay"
            href={`${root}/video/Continuation-Pay`}
            link="Watch Now"
          >
            <p>
            Continuation pay could help you pay down debt or meet other goals! Watch this video about this one-time, midcareer payment available through the Blended Retirement System.
            </p>
          </FeaturedAsset>

          {/* TP7 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-07-Intro.jpg"
            title="Major Life Events"
            href={`${root}/video/Major-Life-Events`}
            link="Watch Now"
          >
            <p>
            Getting married, growing your family, experiencing illness, injury or loss: These are major life events, and they are among the most common causes of financial stress. Find out what can help.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-07-Topic.jpg"
            title="Financial Planning for Life Events"
            href={`${root}/video/Financial-Planning-for-Life-Events`}
            link="Watch Now"
          >
            <p>
            You can plan for some major life events, such as getting married. Others, such as illness, may come as a surprise. Explore the steps you can take to minimize their financial impact.
            </p>
          </FeaturedAsset>

          {/* TP8 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-08-Intro.jpg"
            title="Leadership Training"
            href={`${root}/video/Leadership-Training`}
            link="Watch Now"
          >
            <p>
            Becoming a leader means helping your unit successfully prepare for and complete its missions. That’s why knowing when members are experiencing financial and other issues is key.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-08-Topic.jpg"
            title="Recognize Financial Distress"
            href={`${root}/video/Recognize-Financial-Distress`}
            link="Watch Now"
          >
            <p>
            Each individual’s strength and focus impacts their unit as a whole. Find out what steps you can take, as a leader, to support your members’ personal financial readiness.
            </p>
          </FeaturedAsset>

          {/* TP9 */}
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-09-Intro.jpg"
            title="Pre- and Post-Deployment"
            href={`${root}/video/Pre-and-Post-Deployment`}
            link="Watch Now"
          >
            <p>
            Keep your financial plan on track during your next deployment — or, even better, speed up your timeline for reaching your goals. Learn about key considerations such as performing checkups.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPoint-09-Topic.jpg"
            title="Deployment and Your Finances"
            href={`${root}/video/Deployment-and-Your-Finances`}
            link="Watch Now"
          >
            <p>
            Making sure your financial house is in order is very important, especially if you have a deployment coming up. Here are the steps to take before you leave — and again when you return.
            </p>
          </FeaturedAsset>
        </div>

      </Page>
    );
}


