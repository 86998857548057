/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch } from 'react-router-dom';

import HookUpSiteVideo from '../components/hookUpSiteVideo';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function CarBuyingBasicsVideoRoutes(props) {
    const root = props.match.path;

    return (
      <Switch>
        {[
            HookUpSiteVideo(root, 'NewVsUsed', 'New vs. Pre-Owned', props.crumbs, '/assets/video/money/cb/Car Buying Basics _ Film 01 _ New vs Pre-owned.mp4', null, '/assets/img/FINRED-WebsiteImage-CarBuyingBasics01-NewVsUsed-V.jpeg'),
            HookUpSiteVideo(root, 'DealerAddOns', 'Dealer Add-Ons', props.crumbs, '/assets/video/money/cb/Car Buying Basics _ Film 02 _ Dealer Add-ons.mp4', null, '/assets/img/FINRED-WebsiteImage-CarBuyingBasics02-DealerAddOns-V.jpeg'),
            HookUpSiteVideo(root, '5StepsFinancing', 'Five Steps to Vehicle Financing', props.crumbs, '/assets/video/money/cb/Car Buying Basics _ Film 03 _ 5 Steps to Vehicle Financing.mp4', null, '/assets/img/FINRED-WebsiteImage-CarBuyingBasics03-5StepsFinancing-V.jpeg'),
            HookUpSiteVideo(root, 'NegotiationGame', 'Negotiating the Deal', props.crumbs, '/assets/video/money/cb/Car Buying Basics _ Film 04 _ Negotiating the Deal.mp4', null, '/assets/img/FINRED-WebsiteImage-CarBuyingBasics04-NegotiationGame-V.jpeg'),
            HookUpSiteVideo(root, 'NegativeEquity', 'Trade-Ins and Negative Equity', props.crumbs, '/assets/video/money/cb/Car Buying Basics _ Film 05 _ Trade-Ins and Negative Equity.mp4', null, '/assets/img/FINRED-WebsiteImage-CarBuyingBasics05-NegativeEquity-V.jpeg'),
            HookUpSiteVideo(root, 'NegotiatePayment', 'What Do You Want Your Payments To Be?', props.crumbs, '/assets/video/money/cb/Car Buying Basics _ Film 06 _ What Do You Want Your Payment to Be_.mp4', null, '/assets/img/FINRED-WebsiteImage-CarBuyingBasics06-NegotiatePayment-V.jpeg'),
            HookUpOopsPage(),
        ]}
      </Switch>
    );
}
