/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MaxMilMoney from '../pages/max-mil-money';
import JoiningTheMilitary from '../pages/joining-the-military';
import HookUpArticle from '../components/hookUpArticle';
import BenefitsTOC from '../components/tocs/benefits';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function JoiningTheMilitaryRoutes(props) {
    const root = props.match.path;
    const crumbs = `${props.crumbs},Joining the Military|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <JoiningTheMilitary {...routerProps} crumbs={crumbs} toc={props.toc} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'WelcometoMilitaryService', 'Welcome to Military Service', crumbs,
                      '/assets/video/benefits/jtm/FR01-01/normal/FR01-01 - Video 1. Welcome to Military Service_v5.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-PreAccession01-WelcomeToMilitaryService-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PrepareYourBanking', 'Prepare Your Banking', crumbs,
                      '/assets/video/benefits/jtm/FR01-02/normal/FR01-02 - Video 2. Prepare Your Banking_v4.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession02-PrepareYourBanking-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PlanForYourPaychecks', 'Plan For Your Paychecks', crumbs,
                      '/assets/video/benefits/jtm/FR01-03/normal/FR01-03 - Video 3. Plan For Your Paychecks_v3.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession03-PlanForYourPaychecks-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UnderstandingYourW-4', 'Understanding Your W-4', crumbs,
                      '/assets/video/benefits/jtm/FR01-04/normal/FR01-04 - Video 4. Understanding Your W-4_v4.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession04-UnderstandingYourW4-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ServicemembersGroupLifeInsurance', 'Servicemembers’ Group Life Insurance', crumbs,
                      '/assets/video/benefits/jtm/FR01-05/normal/FR01-05 - Video 5. Servicemembers Group Life Insurance_v3.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession05-SGLI-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'NamingBeneficiaries', 'Naming Beneficiaries', crumbs,
                      '/assets/video/benefits/jtm/FR01-06/normal/FR01-06 - Video 6. Naming Beneficiaries _v3.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession06-NamingBeneficiaries-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UnderstandingGIBillBenefits', 'Understanding GI Bill Benefits', crumbs,
                      '/assets/video/benefits/jtm/FR01-07/normal/FR01-07 - Video 7. Understanding GI Bill Benefits_v4.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession07-UnderstandingGI-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'RetirementBenefits', 'Retirement Benefits', crumbs,
                      '/assets/video/benefits/jtm/FR01-08/normal/FR01-08 - Video 8. Retirement Benefits_v3.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession08-RetirementBenefits-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitarySpouses', 'Military Spouses', crumbs,
                      '/assets/video/benefits/jtm/FR01-09/normal/FR01-09 - Video 9. Military Spouses_v4.mp4',
                      null,
                      '/assets/img/FINRED-WebsiteImage-PreAccession09-MilitarySpouses-V.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route
          exact
          path={`${root}/MaxMilMoney`}
          render={(routerProps) => <MaxMilMoney {...routerProps} crumbs={crumbs} toc={props.toc} prev={root} />}
        />

        {[
            HookUpArticle(root, crumbs, BenefitsTOC, 'NewtoMilitaryStartHere.md', 'Benefits', 'New to the Military? Start Here'),
        ]}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

