/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import Link from '../components/link';
import BlogCard from '../components/blogCard';

import articles from './blog/mirl/articles.json';

export default function Mirl(props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="mirl"
      >
        <div className="main-mirl-page">
          <ReactMarkdown
            source={`
![Smiling sailor](/assets/img/FINRED-WebsiteImage-MIRLMain.jpg "Smiling sailor")

Money: It’s something we all have in common. On any given week, we’re all faced with dozens of common, routine financial considerations and concerns. From choosing how much to spend on that post-deployment celebration, to calculating the cost of a PCS, to budgeting for the holidays and everything in between — these are the real-life choices and goals being made by service members and their families, day after day.

We’re shining a spotlight on those everyday money matters. Broken down into key focus areas, the tips and tools on this page are designed to fit your real life as you work to achieve your real goals. Follow along as we go in-depth on financial topics big and small, significant and ordinary — and check back frequently for updates.
            `}
            renderers={{
                link: MarkLink,
            }}
          />
        </div>

        <h3>Basic Finance</h3>
        <div>
        Sometimes, you just need to get back to the basics — and strong financial habits can ensure you’re always prepared to handle those little things that add up. By considering real-life decisions, like adopting a pet and making a major purchase, these topics aim to address the extras and unknowns in your spending plan.
        </div>
        <br />
        <div>
        To learn more about basic finance, visit our main <Link to="/Money">Managing Your Money page</Link>.
        </div>
        <div className="main-mirl-page blogs">
          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-MeetingBasicNeeds-F.jpg"
            alt="Meeting Basic Needs and Covering the Essentials Flyer"
            title="Meeting Basic Needs and Covering the Essentials"
            type="Flyer"
            href="/assets/downloads/FINRED-MIRL-MeetingBasicNeeds-F.pdf"
            linkLabel="Learn More"
          >
            The rising cost of living has many military families feeling the crunch. This flyer shares resources and strategies to help you cover the essentials.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-HomecomingCelebrations-I.jpg"
            alt="Money In Real Life Homecoming Celebrations"
            title="Celebrate a Homecoming — Without Breaking the Bank"
            type="Infographic"
            href="/assets/downloads/FINRED-MIRL-HomecomingCelebrations-I.pdf"
            linkLabel="Learn More"
          >
            It’s easy to get caught up in the excitement of a homecoming — and even easier to overspend! Check out the money-saving tips in this graphic to plan ahead.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-ProfessionalCertificates-V.jpg"
            alt="Money in Real Life Professional Certificates"
            title="Get Ahead With a Professional Certificate"
            type="Infographic"
            href="/assets/downloads/FINRED-MIRL-ProfessionalCertificates-I.pdf"
            linkLabel="Learn More"
          >
            Looking to boost your earning power? Learn how earning a professional certificate could give you a competitive edge.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-Subscriptions-I.jpg"
            alt="Money In Real Life Subscription Plans"
            title="Take Command of Your Subscriptions"
            type="Graphic"
            href="/assets/downloads/FINRED-MIRL-Subscriptions-I.pdf"
            linkLabel="Learn More"
          >
            You can sign up for subscriptions with the click of a button, but canceling usually takes more effort. Review and reevaluate your recurring monthly costs.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRLPetOwnership-I.jpg"
            alt="Money In Real Life - Pet Ownership"
            title="Weighing Love at First Sight With Regular Care Costs"
            type="Infographic"
            href="/assets/downloads/FINRED-MIRL-PetOwnership.pdf"
            linkLabel="Learn More"
          >
            Adopting a pet? Budget ahead of time to maintain their physical and mental well-being, as well as save for costly moves or vet visits.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-VacaPlanning-V.jpg"
            alt="Money In Real Life - Planning for Vacations"
            title="The Key to Wallet-Friendly, Enjoyable Vacations"
            type="Infographic"
            href="/assets/downloads/FINRED-MIRL-VacaPlanning-I.pdf"
            linkLabel="Learn More"
          >
            Saving up for R & R? Connect with on-base travel experts to stretch your dollars further and finally afford that luxurious getaway or fun-filled staycation.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-Extracurricular-F.jpg"
            alt="Money In Real Life - Extracurricular Activities"
            title="Affording the Extras"
            type="Flyer"
            href="/assets/downloads/FINRED-MIRL-Extracurricular-F.pdf"
            linkLabel="Learn More"
          >
            From dance classes to summer camp, kids’ activities can be pricey. Here are a few tips to plan ahead — keeping your kid’s interest and your wallet in mind.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-Meme-GC.jpeg"
            alt="Man and woman sitting on a yoga mat."
            title="Don't Weigh Down Your PCS"
            type="Memes"
            href="/assets/downloads/FINRED-MIRL-OutfittingSpace1-M.pdf|/assets/downloads/FINRED-MIRL-OutfittingSpace2-M.pdf"
            linkLabel="Meme 1|Meme 2"
          >
            Having too much stuff can (quite literally) weigh down your PCS with extra costs. When it comes to purchasing decisions, make sure you’re planning ahead.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-MajorPurchase-I.jpg"
            alt="Don't let big ticket items document."
            title="Don’t Let Big-Ticket Items Be Impulse Buys"
            type="Graphic"
            href="/assets/downloads/FINRED-MIRL-MajorPurchaseToy-I.pdf"
            linkLabel="Learn More"
          >
            A new boat or RV would be fun, right? But these items can come with hefty price tags. Here’s how to plan ahead for big-ticket purchases.
          </BlogCard>
        </div>

        <h3>Planning for the Future</h3>
        <div>
          Ready or not, the future is coming. While you can’t prepare for everything, you can plan for some things. To kick off the year of real-life money moments, we’re covering topics like welcoming a new child, moving and PCSing, and geo-baching.
        </div>
        <br />
        <div>
          To learn more about the power of planning ahead, visit our main <Link to="/Planning">Planning for the Future page</Link>.
        </div>
        <div className="main-mirl-page blogs">
          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRLPromotions-I.jpg"
            alt="So, you got a promotion document."
            title="So, You Got a Promotion"
            type="Graphic"
            href="/assets/downloads/FINRED-MIRL-Promotions-I.pdf"
            linkLabel="Learn More"
          >
            A promotion is cause for celebration, but it’s also a chance to make financial choices that will set you up for even more success. Here’s how to start.
          </BlogCard>

          <BlogCard
            cardImageClass="top-left-image"
            img="/assets/img/FINRED-WebsiteImage-MIRL-V.jpg"
            alt="Drawing of a moving van"
            title="Time to PCS? Here’s How to Cut Down on the Financial Stress"
            href={`${root}/video/Time-to-PCS`}
            linkLabel="Watch Now"
          >
            Don’t money-stress your PCS! Use these five tips to get your finances lined up — even if you’re still waiting on those official orders.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-MIRL-NewChildChecklist-TK.jpg"
            alt="Baby sleeping"
            title=" Welcoming a New Child to Your Military Family: A Financial Planning Checklist"
            type="Checklist"
            href="/assets/downloads/FINRED-MIRL-NewChildChecklist-TK.pdf"
            linkLabel="Learn More"
          >
            Congratulations on your new family member! Amid the excitement, don’t forget to account for changes in your finances by using this handy checklist.
          </BlogCard>

          <BlogCard
            blog={articles.find(a => a.href === 'SupportingFamilyMembers')}
            href={`${root}/SupportingFamilyMembers`}
          />

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-GeoBaching-I.jpg"
            alt="Woman looking at her cellphone"
            title="Geo-Baching? Let’s Talk Budgeting"
            type="Graphic"
            href="/assets/downloads/FINRED-MIRL-GeoBaching-I.pdf"
            linkLabel="Learn More"
          >
            Geo-baching can be a practical option for your military family — just make sure you’re also planning ahead for certain financial considerations.
          </BlogCard>
        </div>

        <div className="mirl-social-media">
          <div className="mirl-social-media-abstract">
            Follow @DoDFINRED to ensure you never miss a moment!
          </div>
          <div className="mirl-social-media-images">
            <div className="mirl-social-media-image">
              <Link to="https://www.facebook.com/DoDFINRED/">
                <img src="/assets/img/FINRED-SMIcon-FB.png" alt="Facebook icon" title="Facebook icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.instagram.com/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-IG.png" alt="Instagram icon" title="Instagram icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.linkedin.com/company/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-LinkedIn.png" alt="LinkedIn icon" title="LinkedIn icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://twitter.com/DoDFINRED">
                <img src="/assets/img/FINRED-SMIcon-X.png" alt="X icon" title="X icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.youtube.com/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-YT.png" alt="YouTube icon" title="YouTube icon" />
              </Link>
            </div>
          </div>
        </div>
      </Page>
    );
}
