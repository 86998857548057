/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function LeadershipTraining (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Leadership Training`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Leadership Training
Coast Guard leaders have a responsibility to promote mission readiness
through encouraging financial readiness among their service members.
These resources are designed to give leaders the tools and knowledge
they need to handle that aspect of their role.

### Checklists

* [Leadership Training Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20Leadership.pdf)
* [Leadership Training Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20Leadership.pdf)

### Handouts

* [Financial Warning Signs and Military Risk Factors](/assets/downloads/USCG%20TC%20Handout-Financial%20Warning%20Signs.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Military Retirement](/assets/downloads/USCG%20TC%20Handout-Military%20Retirement.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [The Need for Personal Financial Management](/assets/downloads/USCG%20TC%20Handout-The%20Need%20for%20PFM.pdf)
* [Thrift Savings Plan](/assets/downloads/USCG%20TC%20Handout-Thrift%20Savings%20Plan.pdf)

### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC_11_Leadership_CG_12-9-21.pptx)
* [Instructor Guide](/assets/downloads/TC%2011%20Leadership%20Training%20CG%20Instructor%20Guide%206-9-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%2011%20Leadership%20Training%20CFS%20Reference%20Manual%20CG%206-9-21.pdf)

### Video Discussion Guide

* [Leadership](/assets/downloads/Leadership%20Video%20Discussion%20Guide-4.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


