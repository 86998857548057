/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import Link from '../components/link';
import ReactMarkdown from '../components/markdown';

export default function BNAServiceResources (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},BNA FAQ and Service Resources|${root}`}
        toc={props.toc}
        articleClass="bna subpage"
      >
        <ReactMarkdown
          source={`
## BNA FAQ and Service Resources`}
          renderers={{
              link: MarkLink,
          }}
        />
        <div className="warning-alert-box">
          For frequently asked questions on the Basic Needs Allowance:<br />
          <Link to="https://www.dfas.mil/MilitaryMembers/payentitlements/bna/">Defense Finance and Accounting Service: Basic Needs Allowance — What is it?</Link>
        </div>
        <ReactMarkdown
          source={`
### Service-Specific Resources

Refer to these military service-specific pages and FAQs for general
information about the Basic Needs Allowance, or BNA, policy
clarifications and guidance. ***Please  check back here periodically as the services are updating these links due to the increase of the income eligibility threshold from 130% to 150% effective July 2023.***

**Army**  
<https://www.financialfrontline.org/service-providers>

**Marine Corps**  
[USMC Manpower Military Policy - Compensation](https://www.manpower.usmc.mil/wordpress/)

**Navy**  
[Navy Pay and Allowances](https://www.mynavyhr.navy.mil/References/Pay-Benefits/N130C/)

**Air Force**  
[myFSS BNA information](https://myfss.us.af.mil/USAFCommunity/s/knowledge-detail?pid=kA08300000001NrCAI)

Airmen and guardians may contact the Total Force Service Center at 210-565-0102 or via myFSS and select “Ask A Question” from the top menu bar: <https://myfss.us.af.mil/USAFCommunity/s/>. For BNA questions, select “Compensation” from the “What program does your question pertain to” and use “BNA” in the subject of the question. 

**Coast Guard**  
[BNA Guidance for Coast Guard Members](https://www.mycg.uscg.mil/News/Article/3292250/bna-is-now-payable-to-eligible-coast-guard-members/)  
[BNA Eligibility Expanded](https://www.mycg.uscg.mil/News/Article/3448003/basic-needs-allowance-bna-eligibility-expanded/)  
Contact the Division of Military Compensation at <compensation@uscg.mil>.

### Additional Resources

For background information on the Basic Needs Allowance check out these government resources:

-   **[Department of Defense Instruction 1341.15, "Basic Needs Allowance," (Nov. 15, 2022/Updated: July 1, 2023).](https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/134115p.PDF?ver=tqF1mBA_PSHwdqogpkQFaw%3d%3d)**
    Department of Defense Instruction 1341.15, "Basic Needs Allowance," establishes policy, assigns responsibility and describes procedures for determining service member eligibility for the Basic Needs Allowance. It also gives the requirements for the distribution and administration of the BNA.

-   **[Department of Health and Human Services: Federal Poverty Guidelines](https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines)**
    The U.S. Department of Health and Human Services uses the Consumer Price Index to determine the least amount of income a person or family needs to meet their basic needs. These results are published annually in the Federal Register. Access general information and links to the official current and prior year Federal Poverty Guideline charts to determine financial eligibility and estimate payments for the Basic Needs Allowance.
            `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
