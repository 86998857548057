/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

export default function VideoPlayer ({ key, url, webVtt, lightImg, isStandalone, isInSite, onPlay }) {
    return (
      <div>
        <ReactPlayer
          controls
          playing
          key={key}
          id="video"
          light={lightImg}
          width="100%"
          height={isStandalone ? (isInSite ? '63.5vh' : '100vh') : '50vh'}
          style={{ background: 'black' }}
          url={url}
          onPlay={onPlay}
          config={{ file: {
              tracks: [
                  {
                      kind: 'subtitles',
                      src: webVtt,
                      srcLang: 'en',
                      default: false,
                  },
              ],
          } }}
        />
      </div>
    );
}
