/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Link from './link';
import Img from './img';

export default function BlogCard(props) {
    const blog = props.blog ? { ...props.blog } : { ...props };
    const splitLinkLabel = blog.linkLabel.split('|');
    blog.href = props.href || blog.href;
    const splitHref = blog.href.split('|');
    const isMultipleResources = blog.linkLabel.length > 1;

    return (
      <div className="blog-card">
        <div className={`blog-card-image ${props.cardImageClass}`}>
          <div className="small-image">
            <Img small src={blog.img} alt={blog.alt || ' '} title={blog.alt || ' '} />
          </div>
          <div className="large-image">
            <Img large src={blog.img} alt={blog.alt || ' '} title={blog.alt || ' '} />
          </div>
          { blog.new && <div className="new-corner-badge"><img src="/assets/img/new-corner-badge_v2.png" alt="New corner badge" /></div> }
          { blog.updated && <div className="new-corner-badge"><img src="/assets/img/updated-corner-badge.png" alt="Update corner badge" /></div> }
        </div>
        <div className="blog-card-body">
          <div className="blog-card-title">{blog.title}</div>
          { blog.date && blog.readingTime && <div className="blog-card-date">{blog.originalDate ? 'Updated ' : ''}{blog.date} — {blog.readingTime} read</div>}
          { blog.originalDate && <div className="blog-card-date">Originally published {blog.originalDate}</div>}
          <div className="blog-card-abstract">
            {props.children || blog.abstract}
            { (blog.linkLabel && blog.href) && (
                splitLinkLabel.map((linkLabel, index) => (
                  <Link className="blog-card-labellink" to={splitHref[index]}>{linkLabel}</Link>
                )))
            }
          </div>
        </div>
      </div>
    );
}
