/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function MarriageRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Marriage|${root}` : `Marriage|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-101_-_01_af_marriage_20_-_course_introduction_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MarriageFinancialCommunication', 'Marriage Financial Communication', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-102_-_02_af_marriage_20_-_communication_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoPaperworkandAdministrativeTasks', 'Transition to Paperwork and Administrative Tasks', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-103_-_03_af_marriage_20_-_transition_to_paperwork_and_admin_tasks_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PaperworkandAdministrativeTasks', 'Paperwork and Administrative Tasks', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-104_-_04_af_marriage_20_-_paperwork_and_administrative_tasks_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoFoundationalPlanningTopics', 'Transition to Foundational Planning Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-105_-_05_af_marriage_20_-_transition_to_foundational_planning_topics_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Creating,ManagingandUpdatingaSpendingPlan', 'Creating, Managing and Updating a Spending Plan', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-106_-_06_af_marriage_20_-_creating,_managing_and_updating_a_spending_plan_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingsandEmergencyFunds', 'Savings and Emergency Funds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-107_-_07_af_marriage_20_-_savings_and_emergency_funds_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'BankingforNewlyweds', 'Banking for Newlyweds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-108_-_08_af_marriage_20_-_banking_for_newlyweds_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CreditforNewlyweds', 'Credit for Newlyweds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-109_-_09_af_marriage_20_-_credit_for_newlyweds_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoInsurance', 'Transition to Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-110_-_10_af_marriage_20_-_transition_to_insurance_v1 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IntroductiontoInsurance', 'Introduction to Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-111_-_11_af_marriage_20_-_reasons,_needs_and_types_of_insurance_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TRICAREInsurance', 'TRICARE Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-112_-_12_af_marriage_20_-_tricare_options_and_costs_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PropertyandCasualtyInsurance', 'Property and Casualty Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-113_-_13_af_marriage_20_-_property_and_casualty_insurance_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-114_-_14_af_marriage_20_-_life_insurance_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoIncomeTaxes', 'Transition to Income Taxes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-115_-_15_af_marriage_20_-_transition_to_income_taxes_v1 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IncomeTaxFundamentals', 'Income Tax Fundamentals', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-116_-_16_af_marriage_20_-_income_tax_fundamentals_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PotentialIncomeTaxChanges', 'Potential Income Tax Changes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-117_-_17_af_marriage_20_-_potential_income_tax_changes_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoLonger-TermPlanningTopics', 'Transition to Longer-Term Planning Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-118_-_18_af_marriage_20_-_transition_to_longer-term_planning_topics_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EducationFinancing', 'Education Financing', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-119_-_19_af_marriage_20_-_education_financing_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TheImportanceofPreparingforRetirement', 'The Importance of Preparing for Retirement', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-120_-_20_af_marriage_20_-_importance_of_preparing_for_retirement_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ComponentsoftheMilitaryRetirementSystem', 'Components of the Military Retirement System', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-121_-_21_af_marriage_20_-_components_of_the_military_retirement_system_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoSurvivorBenefitsandEstatePlanning', 'Transition to Survivor Benefits and Estate Planning', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-122_-_22_af_marriage_20_-_transition_to_survivor_benefits_and_estate_planning_v1 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SurvivorandDependentBenefits', 'Survivor and Dependent Benefits', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-123_-_23_af_marriage_20_-_survivor_and_dependent_benefits_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningOverview', 'Estate Planning Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-124_-_24_af_marriage_20_-_estate_planning_overview_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningTasks', 'Estate Planning Tasks', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-125_-_25_af_marriage_20_-_estate_planning_tasks_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/marriage/35-126_-_26_af_marriage_20_-_wrap-up_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFMarriage-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
