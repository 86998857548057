/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NetWorth from '../pages/net-worth/net-worth';
import NetWorthResources from '../pages/net-worth/net-worth-resources';
import RouteWithVideos from '../components/routeWithVideos';
import Oops404Page from '../pages/oops-404';

export default function NetWorthRoutes(props) {
    const root = props.match.path;
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `Track Your Personal Net Worth|${root}`;

    return (
      <div>
        <Switch>
          <RouteWithVideos component={<NetWorthResources />} path={`${root}/NetWorthResources`} crumbs={crumbs} toc={props.toc} />
          <RouteWithVideos component={<NetWorth />} path={root} crumbs={crumbs} toc={props.toc} />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
