/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import FWBATOC from '../components/tocs/fwbaTOC';
import FeaturedAsset from '../components/featuredArticle';

export default function FWBA (props) {
    const root = props.match.path;
    const toc = () => (props.toc || <FWBATOC root={root} />);
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={props.crumbs}
        toc={toc()}
        articleClass="launch-page"
      >
        <ReactMarkdown
          source={`
## Financial Well-Being Assessment

![A service member filling out paper form on clipboard in front of laptop.](/assets/img/FINRED-WebsiteImage-FWBA-TK.jpg "A service member filling out paper form on clipboard in front of laptop.")

You go to the doctor to check on your physical health — but when is the last time you checked on your financial health?

The Financial Well-Being Assessment can help you conduct a "checkup" on your financial health. No matter where you are in your military career, taking time to assess your financial well-being can provide insights into whether you’re on the right path or have some areas to improve. Answer just 12 simple questions to get your score, then review additional resources organized by topic. All of your answers are anonymous and the assessment does not track any data.

Either once per year or as your financial situation changes, retake the self-assessment to see how your score changes. While fluctuations are completely normal and to be expected, knowledge is power when taking control of your financial well-being. 
            `}

          renderers={{
              link: MarkLink,
          }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA-Digital.jpg"
            alt="A service member on a laptop."
            title="Financial Well-Being Assessment (Digital)"
            href={`${root}/DigitalTool`}
            link="Get Started"
          >
            <p>
            Use the interactive digital version of the assessment to have your score automatically calculated for you in just a few clicks.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA-Downloadable.jpg"
            alt="A service member sitting at desk filling out form with children in the background."
            title="Financial Well-Being Assessment (Downloadable)"
            href="/assets/downloads/FINRED-FWBA-TK.pdf"
            link="Download"
          >
            <p>
            Take the assessment with you on the go with this downloadable version that includes URLs to key resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA-R.jpg"
            alt="A service member sitting at desk looking at form and talking on mobile phone."
            title="Financial Well-Being Assessment Resources"
            href={`${root}/Resources`}
            link="Get Resources"
          >
            <p>
            Find more information about each of the four areas of financial well-being with this comprehensive list of additional resources that can help you improve your score.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ITookFWBA-B.jpg"
            alt="Woman using smart phone while man working at kitchen counter"
            title="I Took the Financial Well-Being Assessment — Here’s What Happened Next"
            href="/Trending/Blog/ITookFWBA"
            link="Read Blog"
          >
            <p>
            In this blog, one military spouse shares her experience assessing her family’s financial well-being — and how the surprise score motivated her to take action.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA-F.jpg"
            alt="Financial Well-Being Assessment flyer product image."
            title="Get Your Financial Well-Being Score!"
            href="/assets/downloads/FINRED-FWBA-F.pdf"
            link="Download"
          >
            <p>
            With the Department of Defense Financial Well-Being Assessment, you can identify your financial strengths and areas for improvement. Get the details in this flyer.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA-LC.jpg"
            alt="Service member looking at paperwork"
            title="Talking to Service Members About the Financial Well-Being Assessment"
            href="/assets/downloads/FINRED-FWBA-LC.pdf"
            link="Download"
          >
            <p>
            Encourage your service members to assess their financial well-being with the new self-assessment from the Department of Defense. Here’s how to start the conversation.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
