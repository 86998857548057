/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect, useState } from 'react';

import LightBox from './lightbox';

export default function LightboxLink(props) {
    let handleClose = () => { };
    let handleOpen = () => { };

    function setGAEvent(to) {
        /* global gtag */
        gtag('event', `${props.resourceType}_click`, {
            event_label: to,
            event_category: 'resources',
            value: 1,
        });
    }

    if (props.resourceLabel) {
        if (props.resourceLabel === props.resource) {
            handleOpen = () => setGAEvent(props.to);
            handleClose = () => props.history.push(props.to.replace(`/${props.resourceType}/${props.resourceLabel}`, ''));
        } else {
            handleOpen = () => setGAEvent(`${props.to}/${props.resourceType}/${props.resourceLabel}`);
        }
    }

    return (
      <LightBox {...props} open={props.resourceLabel === props.resource} onClose={handleClose} onOpen={handleOpen}>
        {props.children}
      </LightBox>
    );
}


