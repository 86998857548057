/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function CarBuyingBasics (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Car Buying Basics
Buying a car is one big decision … made up of a lot of smaller decisions. Gain confidence at each stage of the process by doing your research, understanding what you want and getting your finances in order. However, while it may sound like a lot of work, that doesn’t mean you can’t still have some fun along the way. Settle in to watch this entertaining video series and laugh while you learn.
            `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasics01-NewVsUsed-V.jpeg"
            alt="Video Episode 1 New Versus Pre-Owned"
            title="New vs. Pre-Owned"
            href={`${root}/video/NewVsUsed`}
            link="Watch Now"
          >
            <p>
            Get the lowdown on the pros and cons of buying a new versus a pre-owned vehicle in this first video of the Car Buying Basics series.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasics02-DealerAddOns-V.jpeg"
            alt="Video Episode 2 Dealer Add-Ons"
            title="Dealer Add-Ons"
            href={`${root}/video/DealerAddOns`}
            link="Watch Now"
          >
            <p>
            Learn about common dealer add-ons and prepare in advance for the hard sell in this second video of the Car Buying Basics series.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasics03-5StepsFinancing-V.jpeg"
            alt="Episode 3 Five Steps to Vehicle Financing"
            title="Five Steps to Vehicle Financing"
            href={`${root}/video/5StepsFinancing`}
            link="Watch Now"
          >
            <p>
            Take the time to explore your financing options before walking into the car dealership. This third video of the Car Buying Basics series has some tips.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasics04-NegotiationGame-V.jpeg"
            alt="Video Episode 4 Negotiating the Deal"
            title="Negotiating the Deal"
            href={`${root}/video/NegotiationGame`}
            link="Watch Now"
          >
            <p>
            Prepare to navigate the negotiating game with confidence using the strategies in this video — the fourth in the Car Buying Basics series.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasics05-NegativeEquity-V.jpeg"
            alt="Video Episode 5 Trade-ins and Negative Equity"
            title="Trade-Ins and Negative Equity"
            href={`${root}/video/NegativeEquity`}
            link="Watch Now"
          >
            <p>
            Are you underwater on your car and considering a trade-in? This fifth video in the Car Buying Basics series can help you understand the financial risks.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-CarBuyingBasics06-NegotiatePayment-V.jpeg"
            alt="Video Episode 6 What Do You Want Your Payments To Be?"
            title="What Do You Want Your Payments To Be?"
            href={`${root}/video/NegotiatePayment`}
            link="Watch Now"
          >
            <p>
            Think beyond the monthly cost of your car payment and consider the big picture. Watch the sixth video in the Car Buying Basics series for more tips.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
