/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function LeadershipTrainingRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Leadership Training|${root}` : `Leadership Training|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/leadership/41-110_-_leadership_-_course_overview_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20LeadershipTraining-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'NeedforPFMandFinancialWarningSigns', 'Need for PFM and Financial Warning Signs', crumbs,
                      '/assets/video/navy-resource/leadership/39-102_-_navy_leadership_-_need_for_pfm_and_financial_warning_signs_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20LeadershipTraining-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancialPlanning', 'Financial Planning', crumbs,
                      '/assets/video/navy-resource/leadership/18-117_-_video_3_-_financial_planning_v4_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20LeadershipTraining-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'RetirementSystemOverview', 'Retirement System Overview', crumbs,
                      '/assets/video/navy-resource/leadership/18-118_-_video_4_-_retirement_system_overview_v8_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20LeadershipTraining-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitaryConsumerProtections', 'Military Consumer Protections', crumbs,
                      '/assets/video/navy-resource/leadership/39-101_-_navy_leadership_-_military_consumer_protections_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20LeadershipTraining-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/leadership/41-111_-_leadership_-_final_thoughts_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20LeadershipTraining-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
