/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect } from 'react';

import Page from '../../components/toolsAndResourcesPage';
import ReactMarkdown from '../../components/markdown';
import Link from '../../components/link';
import VideoPlayerWith508 from '../../components/video-player-with508';
import Img from '../../components/img';

export default function Sense(props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="sense launch-page"
      >
        <div>
          <h2>Sen$e, Your Financial Tool</h2>

        </div>

        <VideoPlayerWith508
          isStandalone
          isInSite
          urlNormal="/assets/video/sense/FINRED-SenseNewProfile-V.mp4"
          lightImg="/assets/img/FINRED-WebsiteImage-Sense-Main-LI.jpg"
          onPlay={event => {
              /* global gtag */
              gtag('event', 'video_click', {
                  event_label: '/assets/video/sense/FINRED-SenseNewProfile-V.mp4',
                  event_category: 'resources',
                  value: 1,
              });
          }}
        />

        <ReactMarkdown
          source={`
Watch your financial know-how grow with Sen$e — the DoD’s free financial literacy app. This engaging tool lets you customize your experience, earn badges, bookmark your favorite content, take notes and more! You'll also find interactive tools and calculators.
              `}
        />

        <div className="sense-media-container">
          <div className="app-download">
            <div className="title">
              Download Sen$e:
            </div>
            <div className="mirl-social-media-image">
              <Img
                small
                src="/assets/img/FINRED-WebsiteImage-Homepage-Sense.png"
                alt="Sen$e dollar sign icon"
                title="Sen$e dollar sign icon"
              />
            </div>
            <Link
              to="https://play.google.com/store/apps/details?id=com.powertrain.sense&hl=en_US&gl=US"
            >
              <img
                className="button-image"
                src="/assets/img/Google-play-badge-adjustedsize.png"
                alt="Google Play button"
                title="Download on Google Play"
              />
            </Link>
            <Link
              to="https://apps.apple.com/us/app/sen%24e/id1443911840"
            >
              <img
                className="button-image"
                src="/assets/img/Download_on_the_App_Store_Badge_US-UK_blk_092917.png"
                alt="App Store button"
                title="Download on the App Store"
              />
            </Link>
          </div>
        </div>
        <ReactMarkdown
          source={`
Available on mobile devices only.
          `}
        />
      </Page>
    );
}
