/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import FWBAResources from '../pages/fwba-resourses';
import FWBAResourcesServices from '../pages/fwba-resourses-services';
import FWBSelfAssessment from '../pages/fwb-self-assessment';
import Oops404Page from '../pages/oops-404';

import FWBA from '../pages/fwba';

export default function FWBARoutes(props) {
    const root = props.match.path;
    let crumbs = props.crumbs ? `${props.crumbs},` : '';
    crumbs = `${crumbs}Financial Well-Being Assessment|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <FWBA {...routerProps} crumbs={crumbs} toc={props.toc} />}
        />

        <Route
          path={`${root}/Resources`}
          render={(routerProps) => (
            <Switch>
              <Route
                exact
                path={routerProps.match.path}
                render={(rps) => <FWBAResources {...rps} crumbs={`${crumbs},Financial Well-Being Assessment Resources|${rps.match.path}`} />}
              />


              <Route
                exact
                path={`${routerProps.match.path}/Services`}
                render={(rps) => <FWBAResourcesServices {...rps} crumbs={`${crumbs},Financial Well-Being Assessment Resources|${routerProps.match.path},Financial Well-Being Assessment Service Resources|${rps.match.path}`} />}
              />

              <Route render={() => <Oops404Page />} />
            </Switch>
          )}
        />

        <Route
          exact
          path={`${root}/DigitalTool`}
          render={(routerProps) => <FWBSelfAssessment {...routerProps} crumbs={crumbs} prev={root} />}
        />

        <Route
          exact
          path={`${root}/DigitalTool/QRCode`}
          render={(routerProps) => <FWBSelfAssessment {...routerProps} crumbs={crumbs} prev={root} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

