/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import api from '../api';
class Dialog extends React.Component {
    constructor(props) {
        super(props);
    }

    close() {
        this.props.onClose();
    }

    renderButtons() {
        if (!this.props.buttons) {
            return (
              <button
                onClick={() => this.close()}
                style={{
                    cursor: 'pointer',
                }}
                className="editormd-btn editormd-enter-btn"
              >
              OK
              </button>
            );
        }
        const buttons = [];
        for (const i in this.props.buttons) {
            buttons.push(<button
              onClick={() => this.props.buttons[i]()}
              style={{
                  cursor: 'pointer',
              }}
              className="editormd-btn editormd-enter-btn"
            >
              {i}
            </button>);
        }
        return buttons;
    }

    render() {
        return (
          <div
            className="editorForm"
            style={{

                zIndex: 10006,
            }}
          >
            <div
              className="editormd-dialog custom-dialog "
              style={{

                  display: 'block',

              }}
            >
              <div className="editormd-dialog-header">
                <strong className="editormd-dialog-title">{this.props.title}</strong>
              </div>
              <a
                onClick={() => this.close()}
                className="fa fa-close editormd-dialog-close"
                style={{
                    cursor: 'pointer',
                }}
              />
              <div className="editormd-dialog-container">
                {this.props.children}
                <div className="editormd-dialog-footer">
                  {this.renderButtons()}
                </div>
              </div>
              <div className="editormd-dialog-mask editormd-dialog-mask-bg" />
              <div className="editormd-dialog-mask editormd-dialog-mask-con" />
            </div>
          </div>
        );
    }
}

export default Dialog;
