/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import Page from '../components/splPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';

export default function PFC (props) {
    return (
      <Page
        crumbs={`${props.crumbs},Request a PFC`}
        toc={props.toc}
        articleClass="launch-page"
      >
        <ReactMarkdown
          source={`
## Request a Personal Financial Counselor
Personal financial counselors, or PFCs, provide no-cost, trusted support including face-to-face appointments with individuals and families, group presentations, workshops, referrals to military and community resources, family support during deployments, help with budgeting, credit management and navigating benefits. Service members and their families can request PFC support through their installation Military and Family Support Center. Leaders can request short-term PFC support for emergency or temporary assignments, family activities and other events at [https://supportrequest.militaryonesource.mil/](https://supportrequest.militaryonesource.mil/).

* [It Pays to Visit a Personal Financial Counselor](/assets/downloads/FINRED-PFC-F.pdf)
Find out how free, trusted personal support from personal financial counselors can help service members and their families make the most of their money.
* [Personal Financial Counselor Program Guide](/assets/downloads/FINRED-PFC-G.pdf)
The PFC Program provides financial readiness tools, education and counseling. Find out how these no-cost services support total force readiness.
`}
          renderers={{ link: MarkLink }}
        />
      </Page>
    );
}


