/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function ConsumerCredit (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Consumer Credit`}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Consumer Credit

![Credit Cards](/assets/img/FINRED-WebsiteImage-ConsumerCredit-VS.jpg "Credit Cards")

Your credit reputation impacts many areas of your life, including your ability to get a loan, the amount you’ll pay in interest and even your security clearance. Understanding credit may sound complicated, but it doesn’t have to be. This eight-part video series breaks down the basics of consumer credit, covering everything from credit reports and scores to choosing the best lender for your needs. Follow along and explore strategies for achieving the financial health you need to meet your long-term goals.
            `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit01-WhatIsCredit-V.jpg"
            alt="Consumer Credit Video 1: What Is Credit and Why Is It Important?"
            title="What Is Credit and Why Is It Important?"
            href={`${root}/video/WhatIsCredit`}
            link="Watch Now"
          >
            <p>
            Learn why your credit reputation matters for everything from interest rates to job opportunities. The more you know, the better prepared you’ll be. 
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit02-BuildingCreditReputation-V.jpg"
            alt="Consumer Credit Video 2: Building a Credit Reputation"
            title="Building a Credit Reputation"
            href={`${root}/video/BuildingaCreditReputation`}
            link="Watch Now"
          >
            <p>
            Get your credit journey started with three easy strategies for building a strong credit reputation. This video will help you make the most of each approach. 
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit03-ShoppingForCredit-V.jpg"
            alt="Consumer Credit Video 3: Shopping for Credit"
            title="Shopping for Credit"
            href={`${root}/video/ShoppingforCredit`}
            link="Watch Now"
          >
            <p>
            Five simple tips can help you make an informed decision when applying for credit. Learn what to look out for when choosing a lender. 
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit04-CreditReports-V.jpg"
            alt="Consumer Credit Video 4: Credit Reports"
            title="Credit Reports"
            href={`${root}/video/CreditReports`}
            link="Watch Now"
          >
            <p>
            Reviewing your credit report can identify errors that may cost you in the long run. Here’s what to do if something doesn’t look quite right. 
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit05-CreditScores-V.jpg"
            alt="Consumer Credit Video 5: Credit Scores"
            title="Credit Scores"
            href={`${root}/video/CreditScores`}
            link="Watch Now"
          >
            <p>
            Looking to improve your credit score but don’t know where to begin? This video will help you understand the factors that influence your score.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit06-HealthyCreditHabits-V.jpg"
            alt="Consumer Credit Video 6: Healthy Credit Habits"
            title="Healthy Credit Habits"
            href={`${root}/video/HealthyCreditHabits`}
            link="Watch Now"
          >
            <p>
            Building credit doesn’t have to be difficult. Explore actions you can take to boost your financial health, saving you both money and stress.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit07-ProtectingCredit-V.jpg"
            alt="Consumer Credit Video 7: Protecting Your Credit"
            title="Protecting Your Credit"
            href={`${root}/video/ProtectingYourCredit`}
            link="Watch Now"
          >
            <p>
            Credit monitoring helps protect your identity from criminals that commit fraud. Stay up to date on best practices for keeping your information safe.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsumerCredit08-CreditRepairScams-V.jpg"
            alt="Consumer Credit Video 8: Credit Repair Scams"
            title="Credit Repair Scams"
            href={`${root}/video/CreditRepairScams`}
            link="Watch Now"
          >
            <p>
            Repairing credit takes time and any company offering a “quick fix” should stand out as a red flag. Learn more about what to watch out for in this video.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
