/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import HeroBanner from './heroBanner';

export default function HeroCarouselSmall({ setSite }) {
    return (
      <div className="hero-container">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          totalSlides={4}
          interval={5000}
          isPlaying
        >
          <Slider>
            <Slide index={0}>
              <HeroBanner
                // setSite={setSite}
                title="MilTax Free Tax Software and Services for the Military"
                img="/assets/img/MOS-MilTax2024-FINRED-Service-Slider-01_1.png"
                imgAlt="Couple looking at laptop"
                buttonLink="https://www.militaryonesource.mil/financial-legal/taxes/miltax-military-tax-services/?utm_source=finred.usalearning.gov&utm_medium=referral&utm_campaign=miltax-2024&utm_content=service-providers-slider-1"
                buttonText="Get to Know MilTax"
              >
                Let the people you serve know about MilTax free tax services made for MilLife.
              </HeroBanner>
            </Slide>

            <Slide index={1}>
              <HeroBanner
                // setSite={setSite}
                title="Stay in the Know"
                img="/assets/img/FINRED-WebsiteImage-Jan24-H-eNews-2.jpg"
                imgAlt="Woman looking at mobile phone"
                buttonLink="/ePublications"
                buttonText="Subscribe Now"
              >
                Learn the latest financial news affecting service members when you read our monthly eNewsletters.
              </HeroBanner>
            </Slide>

            <Slide index={2}>
              <HeroBanner
                // setSite={setSite}
                title="Discussing Personal Finance"
                img="/assets/img/FINRED-WebsiteImage-Jan24-H-Tips.jpg"
                imgAlt="Service member talking to another service member outside"
                buttonLink="/SPL/Training/LeaderCard/StartingTheConversation"
                buttonText="Share Tips"
              >
                <p>
                Help military leaders talk about personal finance with their troops with talking tips.
                </p>
              </HeroBanner>
            </Slide>

            <Slide index={3}>
              <HeroBanner
                // setSite={setSite}
                title="Try a New Tool in 2024"
                img="/assets/img/FINRED-WebsiteImage-January2024-H-Calculator-SP.jpg"
                imgAlt="Person sitting at desk with calculator and papers"
                buttonLink="/assets/downloads/FINRED-FinancialCalculators-LC.pdf"
                buttonText="Check Them Out"
              >
                Encourage service members to use the DoD’s many no-cost resources like these financial calculators.
              </HeroBanner>
            </Slide>

          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
