/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/splPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';

export default function NavyResource (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >
        <ReactMarkdown
          source={`
##  Coast Guard Resources 
Thank you for visiting the Coast Guard Resources page! Here, you’ll find an extensive collection of tools to use in your work as a personal financial manager or command financial specialist. With these materials, our goal is to support you as you support Coast Guard members and their families on their financial readiness journeys.

Please begin with the “Overview” page, which contains important policy, reference and introductory materials. From there, resources are organized by touchpoint, with each page including checklists, handouts, videos and more. Be sure to also check out the “Additional Resources” page, which includes further helpful links organized by audience.

*The United States Coast Guard created, owns and maintains all resources and products found here. Please direct any questions to the Office of Work-Life HQ at [HQS-SMB-FamilySupportServices@uscg.mil](mailto:HQS-SMB-FamilySupportServices@uscg.mil) or visit the [Office of Work-Life Programs: Personal Financial Management Program website](https://www.dcms.uscg.mil/Our-Organization/Assistant-Commandant-for-Human-Resources-CG-1/Health-Safety-and-Work-Life-CG-11/Office-of-Work-Life-CG-111/Personal-Financial-Management-Program-PFMP/).*
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGOverview-R.jpg"
            alt="Coast Guard members attending ceremony"
            title="Overview"
            href={`${root}/Overview`}
            link="Visit Now"
          >
            <p>
            Start here to review detailed curriculum instruction, background information and important policy resources to use in your work with Coast Guard members.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGFirstDutyStation-R.jpg"
            alt="Recruits receive orders to their first duty stations"
            title="First Duty Station"
            href={`${root}/FirstDutyStation`}
            link="Visit Now"
          >
            <p>
            The first big military milestone also comes with important financial considerations. Use these resources to walk your Coast Guard members through the details.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGEachPCS-R.jpg"
            alt="Couple lifting moving boxes"
            title="Each PCS"
            href={`${root}/EachPCS`}
            link="Visit Now"
          >
            <p>
            Each permanent change of station marks a significant shift in routines and finances. These materials can help Coast Guard members prepare for their next move.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGPromotion-R.jpg"
            alt="Coast Guard member receiving promotion during pinning ceremony"
            title="Promotion"
            href={`${root}/Promotion`}
            link="Visit Now"
          >
            <p>
            A promotion is a time for celebration — but it’s also an opportunity to review finances. Help Coast Guard members make the most of their pay increases with these resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGVestingTSP-R.jpg"
            alt="Man working in home office"
            title="Vesting in TSP"
            href={`${root}/VestingInTSP`}
            link="Visit Now"
          >
            <p>
            Help your Coast Guard members develop a proactive retirement strategy that takes advantage of the benefits offered through the Thrift Savings Plan.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGContinuationPay-R.jpg"
            alt="Money fanned out"
            title="Continuation Pay"
            href={`${root}/ContinuationPay`}
            link="Visit Now"
          >
            <p>
            Receiving continuation pay is an important career milestone for Coast Guard members. Use these resources to help them prepare for this midcareer bonus.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGMarriage-R.jpg"
            alt="Groom placing wedding ring on bride’s hand"
            title="Marriage"
            href={`${root}/Marriage`}
            link="Visit Now"
          >
            <p>
            Help Coast Guard members navigate how getting married can prompt changes to their finances — using these helpful tools and resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGFirstChild-R.jpg"
            alt="Infant swaddled in a hospital"
            title="First Child"
            href={`${root}/FirstChild`}
            link="Visit Now"
          >
            <p>
            Welcoming a new child changes everything, and finances are no exception. These resources can help Coast Guard members adjust their finances for their new family member.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGDivorce-R.jpg"
            alt="Gavel on table"
            title="Financial Implications of Divorce"
            href={`${root}/Divorce`}
            link="Visit Now"
          >
            <p>
            Going through a divorce can be complicated — including financially. Use these resources to help Coast Guard members simplify the financial implications of divorce.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGDisablingCondition-R.jpg"
            alt="Man in a wheelchair"
            title="Disabling Condition"
            href={`${root}/DisablingCondition`}
            link="Visit Now"
          >
            <p>
            When a Coast Guard member or someone in their family becomes disabled, priorities, plans and finances change. Determine a solutions-focused way forward with these resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGLeadershipTraining-R.jpg"
            alt="Coast Guard members attend training"
            title="Leadership Training"
            href={`${root}/LeadershipTraining`}
            link="Visit Now"
          >
            <p>
            Use these resources to help leaders learn to promote financial readiness and address the unique financial challenges that come with military life.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGPreDeployment-R.jpg"
            alt="Coast Guard ship in port preparing to depart"
            title="Pre-Deployment"
            href={`${root}/PreDeployment`}
            link="Visit Now"
          >
            <p>
            Deployments offer an opportunity for Coast Guard members to improve their financial situations — if they prepare in advance. Use these resources to help them get ready.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGPostDeployment-R.jpg"
            alt="Coast Guard member hugging son"
            title="Post-Deployment"
            href={`${root}/PostDeployment`}
            link="Visit Now"
          >
            <p>
            Beyond the excitement and adjustment of post-deployment, there are also several financial tasks Coast Guard members should complete. Guide them with these resources.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGTransition-R.jpg"
            alt="Closeup businesspeople shaking hands in an office"
            title="Transition"
            href="https://www.dcms.uscg.mil/Our-Organization/Assistant-Commandant-for-Human-Resources-CG-1/Health-Safety-and-Work-Life-CG-11/Office-of-Work-Life-CG-111/Transition-Assistance-Program/"
            link="Visit Now"
          >
            <p>
            As you assist members of the Coast Guard with their transition to civilian life, direct them to the resources available through the Transition Assistance Program.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-USCGAdditionalResources-R.jpg"
            alt="Large brass bell"
            title="Additional Resources"
            href={`${root}/AdditionalResources`}
            link="Visit Now"
          >
            <p>
            Use these links and additional resources to supplement your work as a personal financial manager or command financial specialist.
            </p>
          </FeaturedAsset>
        </div>

      </Page>
    );
}


