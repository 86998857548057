/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function JoiningTheMilitary (props) {
    const root = props.match.path;

    return (
      <Page crumbs={props.crumbs} toc={props.toc}>
        <ReactMarkdown
          source={`
## Joining the Military

![Maximizing Your Military Money: What to Know](/assets/img/FINRED-WebsiteImage-NewToMilitary-Landing.jpg "Maximizing Your Military Money: What to Know")

Congratulations on your decision to join the military! While you’ve already taken the biggest step, you’ll be making several other important decisions before you leave for basic training or boot camp. The information and tips you’ll find on this page can help you make financial and benefit choices that work best for you — while maximizing your military money.
          `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-NewtoMilitaryStartHere-A.jpg"
            alt="An incoming class of service members"
            title="New to the Military? Start Here"
            href={`${root}/NewtoMilitaryStartHere`}
            link="Read Now"
          >
            <p>
            Find the resources you need for each step in your financial journey. This article shows how to navigate the FINRED website as a new recruit.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-NewToMilitary-Main.jpg"
            alt="Maximizing Your Military Money: What to Know"
            title="Maximizing Your Military Money: What To Know Before You Go"
            href={`${root}/MaxMilMoney`}
            type="Guide"
            link="Get Started"
          >
            <p>
            From banking to retirement, this guide walks you through all the financial and military benefits information you need to know before starting your career. 
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-NewToMilitary-Checklist.jpg"
            alt="Maximizing Your Military Money Summary Checklist"
            title="Maximizing Your Military Money: Summary Checklist"
            href="/assets/downloads/FINRED-Pre-AccessionsChecklist-CL.pdf"
            type="Checklist"
            link="Download"
          >
            <p>
            Don’t let anything important slip through the cracks. With this summary checklist, you can stay on top of your “new to the military” task list.
            </p>
          </FeaturedAsset>


          {/* Pre-accession video series */}

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession01-WelcomeToMilitaryService-V.jpg"
            alt="Pre-Accession Video: Welcome to Military Service"
            title="Welcome to Military Service"
            href={`${root}/video/WelcometoMilitaryService`}
            link="Watch Now"
          >
            <p>
            Prepare your finances for entering the military and set yourself up for future success with helpful tips for making the most of your money.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession02-PrepareYourBanking-V.jpg"
            alt="Pre-Accession Video: Prepare Your Banking"
            title="Prepare Your Banking"
            href={`${root}/video/PrepareYourBanking`}
            link="Watch Now"
          >
            <p>
            Three easy strategies can help you square away your banking situation before heading to initial entry training. Follow along with this video to learn how.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession03-PlanForYourPaychecks-V.jpg"
            alt="Pre-Accession Video: Plan For Your Paychecks"
            title="Plan For Your Paychecks"
            href={`${root}/video/PlanForYourPaychecks`}
            link="Watch Now"
          >
            <p>
            Understanding your financial situation can help avoid costly surprises. Prepare for initial entry training and post-graduation life with this four-step plan.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession04-UnderstandingYourW4-V.jpg"
            alt="Pre-Accession Video: Understanding Your W-4"
            title="Understanding Your W-4"
            href={`${root}/video/UnderstandingYourW-4`}
            link="Watch Now"
          >
            <p>
            Unsure how much money should be withheld from your paycheck for taxes? This video gives you the lowdown on how to fill out your Form W-4.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession05-SGLI-V.jpg"
            alt="Pre-Accession Video: Servicemembers' Group Life Insurance"
            title="Servicemembers’ Group Life Insurance"
            href={`${root}/video/ServicemembersGroupLifeInsurance`}
            link="Watch Now"
          >
            <p>
            Low-cost term life insurance is one of the many benefits of serving. Get familiar with your policy, including cost, family coverage and beneficiaries.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession06-NamingBeneficiaries-V.jpg"
            alt="Pre-Accession Video: Naming Beneficiaries"
            title="Naming Beneficiaries"
            href={`${root}/video/NamingBeneficiaries`}
            link="Watch Now"
          >
            <p>
            Make sure your money goes to the right people should the unthinkable happen. Review the three military benefits on which you should list a beneficiary.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession07-UnderstandingGI-V.jpg"
            alt="Pre-Accession Video: Understanding GI Bill Benefits"
            title="Understanding GI Bill Benefits"
            href={`${root}/video/UnderstandingGIBillBenefits`}
            link="Watch Now"
          >
            <p>
            Explore the education and training benefits available to you through the GI Bill. This video breaks down each program from multiple perspectives.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession08-RetirementBenefits-V.jpg"
            alt="Pre-Accession Video: Retirement Benefits"
            title="Retirement Benefits"
            href={`${root}/video/RetirementBenefits`}
            link="Watch Now"
          >
            <p>
            It’s never too early to start saving for retirement. Learn about two key components of the military’s Blended Retirement System in this video.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PreAccession09-MilitarySpouses-V.jpg"
            alt="Pre-Accession Video: Military Spouses"
            title="Military Spouses"
            href={`${root}/video/MilitarySpouses`}
            link="Watch Now"
          >
            <p>
            Get on the same page as your spouse before heading off to initial entry training with helpful tips and resources for organizing your family’s finances.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}


