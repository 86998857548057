/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import Section from './section';
import SocialColCard from './socialColCard';
class Social extends React.Component {
    set(key) {
        return function(e) {
            this.props.onValueSet('collaboratorCorner.' + key, e.target.value);
        }.bind(this);
    }

    render() {
        const style = { width: 'calc(100% - 20px)' };

        return (
          <Section heading="" dark={false} noTop={false} noBottom={false}>
            <div className="usa-width-one-third border-right">
              <h3>Facebook</h3>
              <iframe style={style} src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDoDFINRED%2F&amp;tabs=timeline&amp;width=300&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="250" height="300" scrolling="no" frameBorder="0" allowTransparency="true" allow="encrypted-media" />
            </div>
            <div className="usa-width-one-third border-right" style={{ maxHeight: 350, overflowY: 'auto' }}>
              <SocialColCard
                title="FINRED Blog"
                img="/assets/img/FINRED-WebsiteImage-BlogAssets-Widget-B.jpg"
                alt="Money in Real Life Blog"
                href="/Trending/Blog"
                linkLabel="Read Blogs"
              >
                Share personal experiences and offer expert tips — with the goal of helping you make better sense of your finances, one blog post at a time.
              </SocialColCard>
            </div>

            <div className="usa-width-one-third" style={{ maxHeight: 350, overflowY: 'auto' }}>
              <SocialColCard
                title="Featured Resource"
                img="/assets/img/FINRED-WebsiteImage-August2023-HomepageHero-5.jpg"
                alt="Hands holding dollar symbol and person icon holding paper money graphic"
                href="/Trending/Blog/TrackingYourNetWorth"
                linkLabel="Read Blog"
              >
                Discover how a young sailor uses the Personal Net Worth Tracker to achieve long-term financial goals — and how you can use this resource, too.
              </SocialColCard>
            </div>
          </Section>
        );
    }
}

function getHomepage(state) {
    if (state && state.homepage.editing) { return state.homepage.draft; }
    if (state && !state.homepage.editing) { return state.homepage.published; }
}


const mapStateToProps = (state, ownprops) => ({

    config: getHomepage(state).collaboratorCorner,
    editing: state.homepage.editing,
    session: state.session,
}
);

const mapDispatchToProps = dispatch => ({
    onValueSet: (key, value) => dispatch({ type: 'homepageValueSet', key, value }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Social);

