/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch } from 'react-router-dom';

import HookUpSiteVideo from '../components/hookUpSiteVideo';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function HomeBuyingBasicsVideoRoutes(props) {
    const root = props.match.path;

    return (
      <Switch>
        {[
            HookUpSiteVideo(root, 'RentingVsBuyingPart1Renting', 'Renting Vs. Buying Part 1: Renting', props.crumbs, '/assets/video/money/hb/hbb___ep01___final___part_1 (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-Rent-V1.jpg'),
            HookUpSiteVideo(root, 'RentingVsBuyingPart2Buying', 'Renting Vs. Buying Part 2: Buying', props.crumbs, '/assets/video/money/hb/hbb___ep01___final___part_2 (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-Buy-V2.jpg'),
            HookUpSiteVideo(root, 'SmartRentingMoves', 'Smart Renting Moves', props.crumbs, '/assets/video/money/hb/hbb___ep02___smart_renting_moves___final (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-VS-SmartRent-V3.jpg'),
            HookUpSiteVideo(root, 'SmartBuyingMoves', 'Smart Buying Moves', props.crumbs, '/assets/video/money/hb/hbb___ep03___smart_buying_moves___final (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-SmartBuy-V4.jpg'),
            HookUpSiteVideo(root, 'HomeBuyingBasics', 'Home Buying Basics', props.crumbs, '/assets/video/money/hb/hbb___ep04___home_buying_process___final (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-Basics-V5.jpg'),
            HookUpSiteVideo(root, 'MortgageLoanTypes', 'Mortgage Loan Types', props.crumbs, '/assets/video/money/hb/hbb___ep05___mortgage_loan_types___final (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-MortageLoans.jpg'),
            HookUpSiteVideo(root, 'HowMortgagePaymentsWork', 'How Mortgage Payments Work', props.crumbs, '/assets/video/money/hb/hbb___ep06___how_mortgage_payments_work___final (720p).mp4', null, '/assets/img/FINRED-WebsiteImage-HBB-MortgagePayments-V7.jpg'),
            HookUpOopsPage(),
        ]}
      </Switch>
    );
}
