/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Page from '../components/splPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import Link from '../components/link';
import HeroCarouselSmall from '../components/heroCarouselSmall';
import LinkModal from '../components/linkModal';
import FeaturedAsset from '../components/featuredArticle';

export default function Spl (props) {
    const root = props.match.path;
    const [showModal, setShowModal] = useState(false);
    const [modalSite, setModalSite] = useState('');

    const setShowModalSite = (site) => {
        setShowModal(true);
        setModalSite(site);
    };

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >
        <h2>Service Providers and Leaders</h2>
        <HeroCarouselSmall setSite={(site) => setShowModalSite(site)} />
        <div className="main-mirl-page">
          <ReactMarkdown
            source={`
Welcome, service providers and leaders! Find resources below for developing your social media presence, requesting a PFC, enhancing your coaching skills through training and much more.
`}
            renderers={{ link: MarkLink }}
          />
        </div>
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SPL-RequestPFC.jpg"
            alt="Service member and partner talking with financial service provider"
            title="Request a PFC"
            href={`${root}/PFC`}
            link="Read More"
          >
            <p>
            PFCs provide a wide range of financial support to the military community. Learn how to request these no-cost services to support your unit.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ServiceResources-R.jpg"
            alt="Paper, lightbulb, clip board, chat bubbles, and laptop icons"
            title="Service Resources"
            href={`${root}/ServiceResources`}
            link="Read More"
          >
            <p>
            Guide your service members to financial well-being. Find service-specific training resources and materials for personal financial managers and counselors.
            </p>
          </FeaturedAsset>
        </div>

        <div className="mirl-social-media spl">
          <div className="mirl-social-media-abstract">
            Follow @DoDFINRED to ensure you never miss a moment!
          </div>
          <div className="mirl-social-media-images">
            <div className="mirl-social-media-image">
              <Link to="https://www.facebook.com/DoDFINRED/">
                <img src="/assets/img/FINRED-SMIcon-FB.png" alt="Facebook icon" title="Facebook icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.instagram.com/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-IG.png" alt="Instagram icon" title="Instagram icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.linkedin.com/company/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-LinkedIn.png" alt="LinkedIn icon" title="LinkedIn icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://twitter.com/DoDFINRED">
                <img src="/assets/img/FINRED-SMIcon-X.png" alt="X icon" title="X icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.youtube.com/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-YT.png" alt="YouTube icon" title="YouTube icon" />
              </Link>
            </div>
          </div>
          <div className="mirl-social-media-abstract">
            Stay Up to Date on Financial News<br />
            Get the latest news and updates directly to your inbox. <Link to="/ePublications">Subscribe today</Link>
          </div>
        </div>

        {
          showModal && (
            <LinkModal
              dismiss={() => setShowModal(false)}
              href={modalSite}
            />
          )
        }
      </Page>
    );
}
