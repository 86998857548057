/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import InvestingMicrolearning from '../pages/investing-microlearning';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import SavingTOC from '../components/tocs/saving';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';


export default function InvestingMicrolearningRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Investing Microlearning|${root}` : `Investing Microlearning|${root}`;
    const toc = () => (
        props.toc
        || (<SavingTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(rps) => <InvestingMicrolearning {...rps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Investing-vs-Saving', 'Investing vs. Saving', crumbs,
                      '/assets/video/saving/investing-microlearning/FINRED-InvestmentFundamentals-V-28September2020-SA.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-InvestmentBasics01-V.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'How-Does-Your-Money-Grow', 'How Does Your Money Grow?', crumbs,
                      '/assets/video/saving/investing-microlearning/money-grow/normal/FINRED-InvestmentFundamentals2-V-21Dec2020.mp4',
                      '/assets/video/saving/investing-microlearning/money-grow/508c/FINRED-InvestmentFundamentals2-V-21Dec2020-508CVersion.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-02.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Balancing-Risk-and-Returns', 'Balancing Risk and Returns', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-3/normal/FINRED-InvestmentFundamentals3-V-06Jan2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-3/508c/FINRED-InvestmentFundamentals3-V-29Jan2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-03.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Buying-and-Selling-Stocks-and-Bonds', 'Buying and Selling Stocks and Bonds', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-4/normal/FINRED-InvestmentFundamentals4-V-06Jan2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-4/508c/FINRED-InvestmentFundamentals4-V-29Jan2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-04.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'What-Is-a-Mutual-Fund', 'What Is a Mutual Fund?', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-5/normal/FINRED-InvestmentFundamentals5-V-14Apr2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-5/508c/FINRED-InvestmentFundamentals5-V-508C-12Apr2021.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-05.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Stock-Market-Ups-and-Downs', 'Stock Market Ups and Downs', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-6/normal/FINRED-InvestmentFundamentals6-V-10Feb2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-6/508c/FINRED-InvestmentFundamentals6-V-26Feb2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-06.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'A-Diversified-Portfolio', 'A Diversified Portfolio', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-7/normal/FINRED-InvestmentFundamentals7-V-07Apr2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-7/508c/FINRED-InvestmentFundamentals7-V-07Apr2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-07.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Balancing-Your-Portfolio', 'Balancing Your Portfolio', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-8/normal/FINRED-InvestmentFundamentals8-V-07Apr2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-8/508c/FINRED-InvestmentFundamentals8-V-07Apr2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-08.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'How-Investments-Make-Money', 'How Investments Make Money', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-9/normal/FINRED-InvestmentFundamentals9-V-03May2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-9/508c/FINRED-InvestmentFundamentals9-V-03May2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-09.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Managing-Gains-and-Losses', 'Managing Gains and Losses', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-10/normal/FINRED-InvestmentFundamentals10-V-03May2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-10/508c/FINRED-InvestmentFundamentals10-V-03May2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-10.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Measuring-the-Market', 'Measuring the Market', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-11/normal/FINRED-InvestmentFundamentals11-V-14Jun2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-11/508c/FINRED-InvestmentFundamentals11-V-14Jun2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-11.png',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Market-Volatility', 'Market Volatility', crumbs,
                      '/assets/video/saving/investing-microlearning/invest-fund-12/normal/FINRED-InvestmentFundamentals12-V-14Jun2021.mp4',
                      '/assets/video/saving/investing-microlearning/invest-fund-12/508c/FINRED-InvestmentFundamentals12-V-14Jun2021-508C.mp4',
                      '/assets/img/FINRED-WebsiteImage-InvestmentSeries-12.png',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
