/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

import Link from './link';

import selected from './utils/selectNav';

export default function FutureMenu({ setSite }) {
    return (
      <li id="Planning" className={`${selected('/Planning')} header-dropdown`} style={{ flexBasis: '12%', color: '#112e51' }}>
        <>
          <Link
            to={'/Planning'}
            className="usa-nav__link"
          >
            Planning for the Future
          </Link>
          <ul id="extended-nav-section-two" className="usa-nav-submenu planning-menu">
            <li>
              <HashLink
                smooth
                to="/Planning#Estate-Planning"
              >
                Estate Planning
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Planning#Readiness"
              >
                Readiness
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Planning#Insurance"
              >
                Insurance
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Planning#Additional-Information"
              >
                Additional Information
              </HashLink>
            </li>
          </ul>
        </>
      </li>
    );
}


