/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ConsumerCredit from '../pages/consumer-credit';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import ProtectionTOC from '../components/tocs/understanding';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';


export default function ConsumerCreditRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Consumer Credit|${root}` : `Consumer Credit|${root}`;
    const toc = () => (
        props.toc
        || (<ProtectionTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(rps) => <ConsumerCredit {...rps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(rps.match.path, 'WhatIsCredit', 'What Is Credit and Why Is It Important?', crumbs, '/assets/video/protection/cc/FR02-01/normal/FR02-01 - Video 1 What is Credit and Why is it Important_v3.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit01-WhatIsCredit-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'BuildingaCreditReputation', 'Building a Credit Reputation', crumbs, '/assets/video/protection/cc/FR02-02/normal/FR02-02 - Video 2 Establishing Credit_v4.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit02-BuildingCreditReputation-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'ShoppingforCredit', 'Shopping for Credit', crumbs, '/assets/video/protection/cc/FR02-03/normal/FR02-03 - Video 3 Shopping for Credit_v3.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit03-ShoppingForCredit-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'CreditReports', 'Credit Reports', crumbs, '/assets/video/protection/cc/FR02-04/normal/FR02-04 - Video 4 Credit Reports_v5.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit04-CreditReports-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'CreditScores', 'Credit Scores', crumbs, '/assets/video/protection/cc/FR02-05/normal/FR02-05 - Video 5 Credit Scores_v4.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit05-CreditScores-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'HealthyCreditHabits', 'Healthy Credit Habits', crumbs, '/assets/video/protection/cc/FR02-06/normal/FR02-06 - Video 6 Healthy Credit Habits_v3.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit06-HealthyCreditHabits-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'ProtectingYourCredit', 'Protecting Your Credit', crumbs, '/assets/video/protection/cc/FR02-07/normal/FR02-07 - Video 7 Protecting Your Credit_v3.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit07-ProtectingCredit-V.jpg'),
                  HookUpSiteVideo(rps.match.path, 'CreditRepairScams', 'Credit Repair Scams', crumbs, '/assets/video/protection/cc/FR02-08/normal/FR02-08 - Video 8 Credit Repair Scams_v3.mp4', null, '/assets/img/FINRED-WebsiteImage-ConsumerCredit08-CreditRepairScams-V.jpg'),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
