/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/page';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function DigitalDesignDemo (props) {
    const root = props.match.path;

    return (
      <Page
        articleClass="launch-page"
        {...props}
      >
        <ReactMarkdown
          source={`
## Digital Design Demo

* [Template 1](${root}/template1-example)
* [Template 2](${root}/template2-example)
* [Template 3](${root}/template3-example)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
