/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';

export default function Handouts (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Handouts`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Handouts
Handouts offer a quick and clear delivery of financial learning objectives. Coast Guard members can easily review important topical information in an accessible format after their training, as needed. In many cases, using an accompanying handout is necessary to meet financial literacy requirements when teaching with checklists or videos. Please provide handouts in classes, one-on-one counseling or as supplemental information for specific topics.

* [5 Rules of Buying a House](/assets/downloads/USCG%20TC%20Handout-5%20Rules%20of%20Buying%20a%20House.pdf)
* [Basic Investing](/assets/downloads/USCG%20TC%20Handout-Basic%20Investing.pdf)
* [Combat Zone Tax Exclusion](/assets/downloads/USCG%20TC%20Handout-Combat%20Zone%20Tax%20Exclusion.pdf)
* [Education Benefits and Savings](/assets/downloads/USCG%20TC%20Handout-Education%20Benefits%20&%20Savings.pdf)
* [Entitlement to Continuation Pay](/assets/downloads/USCG%20TC%20Handout-Entitlement%20to%20Continuation%20Pay.pdf)
* [Estate Planning](/assets/downloads/USCG%20TC%20Handout-Estate%20Planning.pdf) 
* [Estimated Travel Cost for a PCS Move](/assets/downloads/USCG%20TC%20Handout-Estimated%20Travel%20Cost.pdf)
* [Financial Planning for a PCS Move](/assets/downloads/USCG%20TC%20Handout-Financial%20Planning%20Worksheet%20for%20a%20PCS%20Move.pdf)
* [Financial Values](/assets/downloads/USCG%20TC%20Handout-Financial%20Values.pdf)
* [Financial Warning Signs and Military Risk Factors](/assets/downloads/USCG%20TC%20Handout-Financial%20Warning%20Signs.pdf)
* [Major Purchases](/assets/downloads/USCG%20TC%20Handout-Major%20Purchases.pdf)
* [Military Banking](/assets/downloads/USCG%20TC%20Handout-Military%20Banking.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Military Retirement](/assets/downloads/USCG%20TC%20Handout-Military%20Retirement.pdf)
* [My Rating as a Money Manager](/assets/downloads/USCG%20TC%20Handout-My%20Rating%20as%20a%20Money%20Manager.pdf)
* [Paying Off Student Loans](/assets/downloads/USCG%20TC%20Handout-Paying%20off%20Student%20Loans.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Survivor Benefits Overview](/assets/downloads/USCG%20TC%20Handout-Survivor%20Benefits%20Overview.pdf)
* [The Need for Personal Financial Management](/assets/downloads/USCG%20TC%20Handout-The%20Need%20for%20PFM.pdf)
* [Thrift Savings Plan](/assets/downloads/USCG%20TC%20Handout-Thrift%20Savings%20Plan.pdf)
* [TRICARE Overview](/assets/downloads/USCG%20TC%20Handout-TRICARE%20Overview.pdf)
* [Understanding Credit](/assets/downloads/USCG%20TC%20Handout-Understanding%20Credit.pdf)

`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


