/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Investing (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Investing Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Investing Calculators
![Dollars being planted in dirt](/assets/img/FINRED-WebsiteImage-Investing-C.jpg "Dollars being planted in dirt")

Having an investment goal is one thing — achieving it is another. Luckily, these calculators can help you stay on top of your financial goals by calculating if you’re on the right track. In fact, you can even view suggestions for how your investment plan may need to change if you’re falling short. 

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
       `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Investment Goal"
              calculator="/assets/calc/InvestmentVariables.html"
              title="2"
              resource={calculator}
              resourceLabel="Investment-Goal"
            >
            Use this calculator to see the status of your investment goals. The results can tell you whether it’s time to adjust your plan or stay the course.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="What Is Your Investment Style?"
              calculator="/assets/calc/InvestmentProfile.html"
              title="3"
              resource={calculator}
              resourceLabel="Investment-Questionnaire-Broad-Portfolio"
            >
            Diversity is key to successful investing. Answer this questionnaire designed to identify various asset classes matching your risk tolerance and experience.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Asset Allocation Calculator"
              calculator="/assets/calc/AssetAllocator.html"
              title="1"
              resource={calculator}
              resourceLabel="Asset-Allocation-Calculator"
            >
            Create a balanced portfolio of investments — stocks, bonds and cash — based on your age, annual savings, tax rate and current assets using this calculator.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


