/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import settings from '../settings';
import LinkModal from './linkModal';


export default class PopOutLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
        };
        this.select = this.select.bind(this);
        this.dismiss = this.dismiss.bind(this);
    }

    onWhitelistedExternalSiteClick() {
        /* global gtag */
        gtag('event', 'outgoing_click', {
            event_label: this.props.to,
            event_category: 'resources',
            value: 1,
        });
    }

    select() {
        this.setState(() => ({
            selected: true,
        }));
    }

    dismiss() {
        this.setState(() => ({
            selected: false,
        }));
    }

    download() {
        /* global gtag */
        gtag('event', 'download', {
            event_label: this.props.to,
            event_category: 'resources',
            value: 1,
        });
    }

    renderModal() {
        if (this.state.selected) { return <LinkModal dismiss={this.dismiss} href={this.props.to}>{this.props.children} id={this.props.to}></LinkModal>; }
    }

    render() {
        let root = settings.root;
        // const clientDomain = settings.clientDomain;
        const p = Object.assign(new Map(), this.props);
        if (!p.className) { p.className = ''; }
        p.className += ' ';
        if (root === '/') { root = ''; }
        if (!this.props.to) { return this.props.children; }
        // if (this.props.to.indexOf(clientDomain === 0)) {
        //     return <a className={p.className} style={p.style} href={root + this.props.to} target="_blank" rel="noopener noreferrer">{this.props.children}</a>;
        // }
        let title = this.props.to.split('/');
        title = title[title.length - 1];
        if (this.props.to.indexOf('http') === 0 || this.props.to.indexOf('mailto') === 0) {
            const milGovRegex = /(\.mil|\.gov|www\.milspousemoneymission\.org|\.govdelivery\.com)(\/.*)*$/i;
            // return <a className={p.className} style={p.style} target="_blank" rel="noopener noreferrer" href={this.props.to}>{this.props.children}</a>
            const modalHash = '#' + this.props.to;
            const link = !milGovRegex.test(this.props.to) ? (
              <span>
                <a
                  onClick={this.select}
                  className={p.className}
                  style={p.style}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-toggle="modal"
                  href={modalHash}
                  title={this.props.to}
                >{this.props.children}</a>
                { this.renderModal() }
              </span>
            ) : (
              <span>
                <a
                  onClick={() => this.onWhitelistedExternalSiteClick()}
                  href={this.props.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={p.className}
                  style={p.style}
                  title={this.props.to}
                >
                  {this.props.children}
                </a>
              </span>
            );
            return link;
        }
        if (this.props.to.indexOf('/assets/downloads') === 0) { return <a onClick={() => this.download()} className={p.className} style={p.style} target="_blank" rel="noopener noreferrer" href={root + this.props.to} title={title}>{this.props.children}</a>; }
        if (this.props.to.indexOf('/video/') !== -1) { return <a onClick={this.select} className={p.className} style={p.style} target="_blank" rel="noopener noreferrer" href={root + this.props.to} title={title}>{this.props.children}</a>; }
        return (
          <Link2
            className={this.props.className}
            style={this.props.style}
            to={root + this.props.to}
            title={title}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.children}
          </Link2>
        );
    }
}

