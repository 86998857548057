/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import ReactMarkdown from './markdown';
import MarkLink from './markLink';
import PopOutMarkLink from './popOutMarkLink';
const ReactMarkdown2 = require('react-markdown/with-html');

export default function DCFSAQuestionCard ({ question, answer, classTag }) {
    const [expanded, setExpanded] = useState(false);

    return (
      <div className="dcfsa-question-card">
        <div className="dcfsa-question-container" onClick={() => setExpanded(!expanded)}>
          <div className={`dcfsa-question-tab dcfsa-${classTag} tab`} />
          <div className={`dcfsa-question-content dcfsa-${classTag} secondary`}>
            <ReactMarkdown
              source={question}
            />
          </div>
        </div>
        { expanded
          && (
          <div className="dcfsa-answer-container">
            <div className="dcfsa-question-tab" />
            <div className={`dcfsa-question-content dcfsa-${classTag} secondary`}>
              <ReactMarkdown2
                source={answer}
                escapeHtml={false}
                renderers={{
                    link: PopOutMarkLink,
                }}
              />
            </div>
          </div>
          )
        }
      </div>
    );
}
