/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Savings (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Savings Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Savings Calculators
![White piggy banks on green surface](/assets/img/FINRED-WebsiteImage-Savings-C.jpg "White piggy banks on green surface")

It can be challenging to see the long-term benefits of saving when you’re living in the short-term. With these calculators, you can better visualize how your money can grow over time and help you meet some of your biggest savings goals.

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
       `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="College Savings"
              calculator="/assets/calc/CollegeSavings.html"
              title="1"
              resource={calculator}
              resourceLabel="College-Savings"
            >
            If you’re overwhelmed by the idea of saving for education costs, this calculator can help you develop a plan to meet your goals.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Compare Savings Rates Calculator"
              calculator="/assets/calc/CompareSavings.html"
              title="2"
              resource={calculator}
              resourceLabel="Compare-Savings-Rates-Calculator"
            >
            Every little bit counts toward a higher balance and meeting your spending plan goals. Compare savings rates with this calculator to see how you could earn.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Compound and Your Return"
              calculator="/assets/calc/CompoundSavings.html"
              title="3"
              resource={calculator}
              resourceLabel="Compound-and-Your-Return"
            >
            Get the most out of your savings accounts by taking advantage of compounding. See how it works here.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Don't Delay Your Savings! Calculator"
              calculator="/assets/calc/WaitCost.html"
              title="4"
              resource={calculator}
              resourceLabel="Dont-Delay-Your-Savings-Calculator"
            >
            Postponing your savings can delay reaching financial goals. Use this calculator to better understand the importance of starting good saving practices today.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Savings Goal Calculator"
              calculator="/assets/calc/Savings.html"
              title="5"
              resource={calculator}
              resourceLabel="Savings-Goal-Calculator"
            >
            Find out how long it will take to reach your biggest financial goals. This calculator can help you build your savings plan timeline.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Savings, Taxes and Inflation Calculator"
              calculator="/assets/calc/SavingsVariables.html"
              title="6"
              resource={calculator}
              resourceLabel="Savings-Taxes-and-Inflation-Calculator"
            >
            Taxes and inflation can influence the true value of your savings. Learn more about the impact of these outside factors with this calculator.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


