/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import Link from '../components/link';
import BlogCard from '../components/blogCard';

export default function SMChallenges(props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="sm-challenges"
      >
        <div className="main-mirl-page">
          <ReactMarkdown
            source={`
## Take a Challenge
![Table with various office and financial tools and character Max holding coffee cup](/assets/img/FINRED-WebsiteImage-TakeAChallenge-Main.jpg "Table with various office and financial tools and character Max holding coffee cup")

Give your financial health a checkup and put your skills to the test with FINRED’s challenges. Whether you’re a financial pro or just getting started on your journey, the activities, tools and resources on this page will help you assess your current situation and create new habits. Explore challenges by topic and complete them at your own pace — and be sure to check back for updates as we tackle new subjects!
            `}
            renderers={{
                link: MarkLink,
            }}
          />
        </div>

        <h3>Credit Challenge</h3>
        <div>
        Having good credit can make a big difference for your savings. FINRED’s Credit Challenge will help you make strong financial decisions to support your future, no matter your current level of credit knowledge. Join us as we look into credit cards, credit lenders and more.
        </div>
        <div className="main-mirl-page blogs sm-challenges">
          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-CreditChallenge2022-Week1.jpg"
            alt="FINRED credit challenge week 1 with credit score icon"
            title="Week 1 — Credit Fundamentals and Accessing Your Credit Information"
            href={`${root}/CreditChallenge-Week1`}
            linkLabel="Learn More"
          >
            Start the first week of the Credit Challenge by reviewing credit fundamentals. Read your credit report and examine any credit freezes.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-CreditChallenge2022-Week2.jpg"
            alt="FINRED credit challenge week 2 with sales person and fast credit icon"
            title="Week 2 — Types of Credit and Their Protections"
            href={`${root}/CreditChallenge-Week2`}
            linkLabel="Learn More"
          >
             During week two, we’ll cover different types of credit. Learn about credit cards, lenders and terms — such as rent to own and buy now, pay later.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-CreditChallenge2022-Week3.jpg"
            alt="FINRED credit challenge week 32 with hand and credit card icon"
            title="Week 3 — Applying for and Using Credit Cards "
            href={`${root}/CreditChallenge-Week3`}
            linkLabel="Learn More"
          >
             For the final week of the challenge, we’ll look at comparing the benefits and protections of credit cards before you apply.
          </BlogCard>
        </div>

        <h3>Investing Challenge</h3>
        <div>
        Ready to grow your investing knowledge and gain the tools to make smarter money decisions? Whether you’re a seasoned investor or just starting out, our Investing Challenge has something for everyone. Follow along week by week or at your own pace!
        </div>
        <div className="main-mirl-page blogs sm-challenges">
          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-InvestingChallenge2021-Week1.jpg"
            alt="FINRED investing challenge week 1 with calendar icon"
            title="Week 1 — Investing Essentials"
            href={`${root}/InvestingChallenge-Week1`}
            linkLabel="Learn More"
          >
            Kick off the first week of the Investing Challenge by reviewing the essentials. Take control of your accounts, learn about key concepts and more!
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-InvestingChallenge2021-Week2.jpg"
            alt="FINRED investing challenge week 2 with scales icon"
            title="Week 2 — Balancing Risks Versus Returns"
            href={`${root}/InvestingChallenge-Week2`}
            linkLabel="Learn More"
          >
             The second week of the Investing Challenge is all about balance! Learn how to navigate risks versus returns while building a diversified portfolio.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-InvestingChallenge2021-Week3.jpg"
            alt="FINRED investing challenge week 3 with computor monitor icon"
            title="Week 3 — Alternative Investments"
            href={`${root}/InvestingChallenge-Week3`}
            linkLabel="Learn More"
          >
             Get involved with Week 3 of the Investing Challenge by focusing on alternative investments. Come learn about digital assets and cryptocurrency with us!
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-InvestingChallenge2021-Week4.jpg"
            alt="FINRED investing challenge week 4 with ribbon and dollar icon"
            title="Week 4 — Investing in Your Retirement"
            href={`${root}/InvestingChallenge-Week4`}
            linkLabel="Learn More"
          >
             Join us for our final week of our Investing Challenge! The best time to start saving for retirement is now — so let’s look into investment options.
          </BlogCard>
        </div>

        <h3>30-Day Financial Challenge</h3>
        <div>
        In June 2021, FINRED’s social media followers challenged themselves to improve their financial well-being, one small task at a time. If you missed out (or if you’re ready for a refresher!), you can now follow along week by week and complete the inaugural 30-Day Financial Challenge at your own pace. Pick and choose from the topics below to get started!
        </div>
        <div className="main-mirl-page blogs sm-challenges">
          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-30DayChallenge2021-Week1.jpg"
            alt="30-day challenge week 1 with paper and graph icon."
            title="Week 1 — Family and Self-Assessments"
            href={`${root}/30DayFinancialChallenge-Week1`}
            linkLabel="Learn More"
          >
            To kick off the 30-Day Financial Challenge, use these Week 1 tasks to assess your past habits and future goals. You might be surprised by what you learn!
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-30DayChallenge2021-Week2.jpg"
            alt="30-day challenge week 2 with calculator icon."
            title="Week 2 — Basic Finances: Spending Plan"
            href={`${root}/30DayFinancialChallenge-Week2`}
            linkLabel="Learn More"
          >
            Get back to the basics with this week’s tasks from the 30-Day Financial Challenge. Choose one, two or even a few and see how it affects your spending plan!
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-30DayChallenge2021-Week3.jpg"
            alt="30-day challenge week 3 with paper money icon."
            title="Week 3 — Saving for Emergencies, Investing for the Future"
            href={`${root}/30DayFinancialChallenge-Week3`}
            linkLabel="Learn More"
          >
            Tackle Week 3 of the 30-Day Financial Challenge by getting proactive with your savings and investing strategies. Your future self will thank you!
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-30DayChallenge2021-Week4.jpg"
            alt="30-day challenge week 4 with credit cards icon."
            title="Week 4 — Debt and Credit Management"
            href={`${root}/30DayFinancialChallenge-Week4`}
            linkLabel="Learn More"
          >
            Take control of your debt and credit with Week 4 of the 30-Day Financial Challenge. These tasks offer the perfect opportunity to make positive changes.
          </BlogCard>

          <BlogCard
            img="/assets/img/FINRED-WebsiteImage-30DayChallenge2021-Week5.jpg"
            alt="30-day challenge week 5 with laptop icon."
            title="Week 5 — Reinforcing Gains and Behaviors and Looking Ahead"
            href={`${root}/30DayFinancialChallenge-Week5`}
            linkLabel="Learn More"
          >
            Finish strong with the final week in the 30-Day Financial Challenge! Use these tasks to help your new habits last beyond the month.
          </BlogCard>
        </div>

        <div className="mirl-social-media">
          <div className="mirl-social-media-abstract">
            Follow @DoDFINRED to ensure you never miss a moment!
          </div>
          <div className="mirl-social-media-images">
            <div className="mirl-social-media-image">
              <Link to="https://www.facebook.com/DoDFINRED/">
                <img src="/assets/img/FINRED-SMIcon-FB.png" alt="Facebook icon" title="Facebook icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.instagram.com/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-IG.png" alt="Instagram icon" title="Instagram icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.linkedin.com/company/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-LinkedIn.png" alt="LinkedIn icon" title="LinkedIn icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://twitter.com/DoDFINRED">
                <img src="/assets/img/FINRED-SMIcon-X.png" alt="X icon" title="X icon" />
              </Link>
            </div>
            <div className="mirl-social-media-image">
              <Link to="https://www.youtube.com/dodfinred">
                <img src="/assets/img/FINRED-SMIcon-YT.png" alt="YouTube icon" title="YouTube icon" />
              </Link>
            </div>
          </div>
        </div>
      </Page>
    );
}
