/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function ContinuationPayRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Continuation Pay|${root}` : `Continuation Pay|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/continuation-pay/Navy Continuation Pay Video 01 - Introduction.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'BRSOverview', 'BRS Overview', crumbs,
                      '/assets/video/navy-resource/continuation-pay/42-101_-_navy_continuation_pay_20_-_brs_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CalculatingHowMuchCPYouMayReceive', 'Calculating How Much CP You May Receive', crumbs,
                      '/assets/video/navy-resource/continuation-pay/42-102_-_navy_continuation_pay_20_-_calculating_how_much_cp_you_may_receive_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'OtherCPConsiderations', 'Other CP Considerations', crumbs,
                      '/assets/video/navy-resource/continuation-pay/Navy Continuation Pay Video 04 - Other CP Considerations.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SettingFinancialGoalsandCreatingaSpendingPlan', 'Setting Financial Goals and Creating a Spending Plan', crumbs,
                      '/assets/video/navy-resource/continuation-pay/42-104_-_navy_continuation_pay_20_-_setting_financial_goals_and_creating_a_spending_plan_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HowBesttoUseCP', 'How Best to Use CP', crumbs,
                      '/assets/video/navy-resource/continuation-pay/42-103_-_navy_continuation_pay_20_-_how_to_best_use_cp_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/continuation-pay/Navy Continuation Pay Video 07 - Closing.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-ContinuationPay-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
