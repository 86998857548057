/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';

import Autosuggest from 'react-autosuggest';

const SearchTab = ({ tabHeaderText, tabBodyText, onSearch, onExpand, opened = false, autosuggest, suggestAll = false, classNames = [] }) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [hideSearch, setHideSearch] = useState(false);

    const classes = ['search-tab-container', ...classNames].join(' ');

    const onInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const sendInputValue = () => {
        if (inputValue) {
            onSearch(inputValue);
        }
    };

    const toggle = () => {
        setOpen(previous => {
            const toggle = !previous;
            onExpand(toggle);
            return toggle;
        });
    };
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (inputValue) {
                onSearch(inputValue);
            }
        }
    };

    // AUTO SUGGESTION FUNCTIONS
    const onChange = (event, { newValue }) => {
        setInputValue(newValue);
    };
    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (!isNaN(value)) {
            return [];
        }

        const found = inputLength === 0 ? []
            : autosuggest.filter(term => term.name.toLowerCase().slice(0, inputLength) === inputValue);

        if (found.length > 0) {
            return found;
        }
        if (suggestAll) {
            return autosuggest;
        }
        return [];
    };

    const getSuggestionValue = suggestion => suggestion.name;

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
        setHideSearch(true);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
        setHideSearch(false);
    };
    const renderSuggestion = suggestions => (<div>{suggestions.name.replaceAll('_', ' ')}</div>);

    useEffect(() => {
        setOpen(opened);
    }, [opened]);

    return (
      <div className={classes}>
        <div className="search-tab-header" onClick={toggle}>
          <p>{tabHeaderText}</p>
        </div>
        { open
        && (
          <div className="search-tab-body">
            <div className="search-input-container">
              <p>{tabBodyText}</p>
              {
                autosuggest
                    ? (
                      <Autosuggest
                        suggestions={suggestions}
                        getSuggestionValue={getSuggestionValue}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        renderSuggestion={renderSuggestion}
                        inputProps={
                        {
                            value: inputValue.replaceAll('_', ' '),
                            onChange: onChange,
                            onKeyDown: onKeyDown,
                        }
                      }
                      />
                    )
                    : <input type="text" className="search-input" aria-label={`${tabBodyText}`} onChange={onInputChange} />
              }

            </div>
            {
              suggestions.length > 0 ? null : <button className="usa-button search-tab-button" aria-label="Search Button" onClick={sendInputValue}>Search</button>
            }
          </div>
        )
      }
      </div>
    );
};

export default SearchTab;
