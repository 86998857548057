/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Page from '../../components/articlePage';
import ReactMarkdown from '../../components/markdown';
import BRSSubjectCard from '../../components/brs-subject-card';

import brsSubjects from './brs.json';

export default function BRS (props) {
    const root = props.match.path;
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchText, setSearchText] = useState('');

    const tags = [
        'BRS Defined Benefit (Pension)',
        'BRS Defined Contribution (Thrift Savings Plan)',
        'BRS Continuation Pay',
        'BRS Lump Sum Distribution',
        'Blended Retirement System',
        'Advantages',
        'Disadvantages',
        'Taxes',
        'Vesting',
        'Matching Contributions',
        'Eligibility',
        'Withdrawal Considerations',
    ];

    const handleSelectTag = (value) => () => {
        let sTags = [...selectedTags];
        if (sTags.includes(value)) {
            sTags = sTags.filter(s => s !== value);
        } else {
            sTags.push(value);
        }
        setSelectedTags(sTags);
    };

    const getTagClass = (tagText) => (selectedTags.includes(tagText) ? 'selected' : ' ');

    const filterBySelectedTags = () => (blog) => (
        selectedTags.length > 0 ? selectedTags.filter(t => blog.tags.includes(t)).length > 0 : blog
    );

    const filterBySearch = () => article => {
        try {
            const sText = searchText.trim().replace(/[[\]]/g, '').replace(/([^\w\d\s])/ig, '\\$1');
            if (sText === '') return true;
            const searchRegex = new RegExp(`(?<!\\()${sText}(?![\\w\\s\\\\.\\/\\?\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\{\\}\\;\\:\\<\\>\\|]*[\\)])`, 'igm');
            return searchRegex.test(article.title) || searchRegex.test(article.abstract);
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const highlightSearchTerms = (text) => {
        try {
            const sText = searchText.trim().replace(/[[\]]/g, '').replace(/(\W)/ig, '\\$1');
            if (sText === '') return text;
            const searchRegex = new RegExp(`(?<!\\()(${sText})(?![\\w\\s\\\\.\\/\\?\\!\\@\\#\\$\\%\\^\\&\\*\\_\\-\\+\\=\\{\\}\\;\\:\\<\\>\\|]*[\\)])`, 'igm');

            if (searchRegex.test(text)) {
                text = text.replace(searchRegex, '<mark><strong>$1</strong></mark>');
                const linkRegex = new RegExp(`\\[([^\\]]*?)((<mark><strong>${sText}</strong></mark>.*?)+?)\\]\\((.*?)\\)`, 'mgi');
                const markRegex = new RegExp(`<mark><strong>(${sText})</strong></mark>`, 'mgi');
                let m = linkRegex.exec(text);
                while (m !== null) {
                    const markTransform = m[2].replace(markRegex, '|mark||strong|$1|/strong||/mark|');
                    text = text.replace(m[0], `[${m[1]}${markTransform}](${m[4]})`);
                    m = linkRegex.exec(text);
                }
            }

            return text;
        } catch (err) {
            console.log(err);
            return text;
        }
    };

    return (
      <Page
        crumbs={`${props.crumbs},Blended Retirement System`}
        toc={props.toc}
        articleClass="retirement brs launch-page"
      >
        <ReactMarkdown
          source={`
## Blended Retirement System
Explore the Uniformed Services Blended Retirement System (BRS), from eligibility to benefits to resources. Understand how your Thrift Savings Plan (TSP) works within the BRS and how to manage and increase your contributions to grow your nest egg for retirement.
`}
        />
        <div className="blog-tags main-blog-page">
          {
              tags.map(t => <div className={`blog-tag ${getTagClass(t)} tag-button`} onClick={handleSelectTag(t)}>{t}</div>)
            }
        </div>

        <div className="dcfsa-search-box">
          <div className="dcfsa-search-container">
            <input placeholder="Start typing to search" type="text" className="dcfsa usa-input" onChange={e => setSearchText(e.target.value)} />
            <div className="dcfsa-search-label">Search BRS</div>
          </div>
        </div>

        <div className="dcfsa-questions-container">
          {
                brsSubjects.filter(filterBySelectedTags()).filter(filterBySearch()).map((subject, key) => (
                  <BRSSubjectCard
                    key={subject.title}
                    title={highlightSearchTerms(subject.title)}
                    abstract={highlightSearchTerms(subject.abstract)}
                    linkLabel={subject.linkLabel}
                    href={subject.href}
                  />
                ))
            }
        </div>
      </Page>
    );
}
