/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function CoastGuardSpouse (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Coast Guard Spouse`}
        toc={props.toc}
        articleClass="subpage uscg"
      >
        <ReactMarkdown
          source={`
## Coast Guard Spouse
This collection of resources and guidance can provide additional
support in your work as a PFM or CFS. Use the following bullets to
instruct, inform and engage Coast Guard spouses.

* [CG SUPRT](https://www.cgsuprt.com/portal/landing)
* [Consumer Financial Protection
    Bureau](https://www.consumerfinance.gov)
* [FINRA BrokerCheck](https://brokercheck.finra.org/)
    BrokerCheck is a free tool to research the background and
    experience of financial brokers, advisers and firms.
* [FINRA Foundation Military Spouse AFC® Fellowship
    Program](https://www.afcpe.org/career-and-resource-center/finra-foundation-military-spouse-fellowship/)
* [IdentityTheft.gov](https://identitytheft.gov/)
* [IRS.gov](https://www.irs.gov/)
* [Military Saves](https://militarysaves.org/)
* [MilSpouse Money Mission](https://www.milspousemoneymission.org/)
* [Office of Financial Readiness](/)
* [PowerPay](https://extension.usu.edu/powerpay/index)
* [Scholarship
    Opportunities](https://www.dcms.uscg.mil/Our-Organization/Assistant-Commandant-for-Human-Resources-CG-1/Health-Safety-and-Work-Life-CG-11/Office-of-Work-Life-CG-111/scholarship-program/)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


