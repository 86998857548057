/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SurvivorBenefitPlan from '../pages/survivor-benefit-plan';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import FutureTOC from '../components/tocs/future';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';


export default function SurvivorBenefitPlanRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Investing Microlearning|${root}` : `Investing Microlearning|${root}`;
    const toc = () => (
        props.toc
        || (<FutureTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(rps) => <SurvivorBenefitPlan {...rps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'What-is-the-Survivor-Benefit-Plan', 'What is the Survivor Benefit Plan?', crumbs,
                      '/assets/video/plan/sb/S03-101 - What is the Survivor Benefit Plan (SBP)_v4.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-SBP101-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Overview-of-Changes-Coming-to-the-Survivor-Benefit-Plan', 'Overview of Changes Coming to the Survivor Benefit Plan', crumbs,
                      '/assets/video/plan/sb/S03-102 - Overview of Changes Coming to the Survivor Benefit Plan (SBP)_v4.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-SBP102-V.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Elimination-of-the-SBP-DIC-Offset', 'Elimination of the SBP-DIC Offset', crumbs,
                      '/assets/video/plan/sb/S03-103 - Elimination of the SBP-DIC Offset_v5.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-SBP103-V.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
