/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import NonTOCPage from '../components/nonTOCPage';
import Img from '../components/img';
import RftrIconTag from '../components/rftr-icon-tag';
import Link from '../components/link';

export default function Rtfr(props) {
    const root = props.match.path;
    const [zIndexOrder, setzIndexOrder] = useState([]);

    const onIconClick = (tag, isClickingAbstract) => {
        const tempzIndexOrder = [...zIndexOrder];

        const index = tempzIndexOrder.indexOf(tag);

        if (index > -1) {
            tempzIndexOrder.splice(index, 1);
            if (isClickingAbstract) tempzIndexOrder.push(tag);
        } else {
            tempzIndexOrder.push(tag);
        }

        setzIndexOrder(tempzIndexOrder);
    };

    return (
      <NonTOCPage
        crumbs={`${props.crumbs},Road to Financial Readiness|${root}`}
        articleClass="rtfr"
      >
        <div className="rtfr-background">
          <div style={{ position: 'relative' }}>
            <Img src="/assets/img/FINRED-CarAnimation.gif" />
            <div className="rftr-title-sign-container"><Img src="/assets/img/FINRED-RTFR-TitleSign.png" className="rftr-title-sign" /></div>
          </div>
          <div style={{ position: 'relative' }}>
            <Img src="/assets/img/FINRED-RTFR-BackgroundTexture.png" style={{ opacity: '.5' }} />
            <div className="rftr-abstract"><h3>Think of your financial journey as a road trip. You’ll need a map, regular fill-ups and maintenance to get where you want to go.</h3></div>
            <Img src="/assets/img/FINRED-RTFR-WebsiteRoad.png" className="rftr-road" />
            <div className="rftr-tag rftr-start">
              <h4>The Department of Defense offers financial training at each of these touchpoints:</h4>
            </div>
            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-1A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-1B.png"
              tagText="As you enter service"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="11.4%"
              left="8%"
              abstractText={`
Know before you go. From account numbers to beneficiary details, have some simple details at the ready.
- [Breaking Down Types of Military Pay (infographic)](/assets/downloads/FINRED-TypesOfPay-I.pdf)
- [Joining the Military (webpage)](/Benefits/JoiningtheMilitary)
- [Know the Importance of Financial Well-Being (article)](/Money/FinancialLiteracy)
- [Understanding Basic Allowance for Housing (article)](/Benefits/BAH)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-3A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-3B.png"
              tagText="Arrival at first duty station"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="12.8%"
              left="53%"
              abstractText={`
Register for all benefits, complete general financial training, update your spending plan, focus on emergency savings and save by using your commissary, fitness center and recreation program offerings.
- [Emergency Funds: How Much is Enough (video)](/Money/video/Emergency-Funds-How-Much-Is-Enough)
- [Financial Well-Being Assessment](/FWBA)
- [Knowing Your Benefits and Entitlements (webpage)](/Benefits)
- [Military Touchpoint Series: First Duty Station (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/First-Duty-Station)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-2A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-2B.png"
              tagText="Initial entry training"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="19.7%"
              left="21%"
              abstractText={`
Learn about financial readiness, create a spending plan, understand your Leave and Earnings Statement, review your retirement system and make a plan for a successful future.
- [Military Touchpoint Series: Decode Your Leave and Earnings Statement (video) ](/SPL/Training/MilitaryJourneyTouchpoints/video/Decode-Your-Leave-and-Earnings-Statement)
- [Spending Plan Worksheet ](/assets/downloads/FINRED-Spendingplan-TK.pdf)
- [Investing Microlearning (video series)](/Saving/InvestingMicrolearning)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-4A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-4B.png"
              tagText="Vesting in Thrift Savings Plan*"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="20%"
              left="57%"
              abstractText={`
Identify long-term financial goals and create steps to reach them, maximize contributions now for a greater payoff in retirement and attend workshops on investing offered by your Military and Family Support Center.
- [Thrift Savings Plan Withdrawal Considerations (article)](/Saving/EarlyTSPWithdrawals)
- [The Blended Retirement System: Continuation Pay (fact sheet) ](/assets/downloads/FINRED-ContinuationPay-FS.pdf)
- [The Blended Retirement System: Defined Contribution (fact sheet)](/assets/downloads/FINRED-DefinedContribution-FS.pdf)
- [The Blended Retirement System: Defined Benefit (fact sheet)](/assets/downloads/FINRED-DefinedBenefit-FS.pdf)
- [The Blended Retirement System: Lump Sum (fact sheet)](/assets/downloads/FINRED-LumpSum-FS.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-6A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-6B.png"
              tagText="Each promotion"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="29.2%"
              left="12%"
              abstractText={`
Review your spending plan, maximize savings, minimize debt, understand the impact of your credit score on your long-term plans and reassess Thrift Savings Plan contributions.
- [A Tale of Two Choices (infographic)](https://finred.usalearning.gov/assets/downloads/FINRED-TaleofTwoChoices-I.pdf)
- [Financial Well-Being Assessment](/FWBA)
- [Military Touchpoint Series: Promotion (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Promotion)
- [Personal Net Worth Tracker](/ToolsAndAddRes/NetWorth)
- [Planning Ahead for Your Next Raise (infographic)](https://finred.usalearning.gov/assets/downloads/FINRED-PlanningAhead-I.pdf)
- [So, You Got a Promotion (infographic)](/assets/downloads/FINRED-MIRL-Promotions-I.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-5A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-5B.png"
              tagText="At each subsequent duty station"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="29.2%"
              left="50%"
              abstractText={`
Plan ahead for PCS expenses, review changes to your pay, benefits and taxes, consider cost-of-living expenses, and update your spending plan.
- [Military Touchpoint Series: Creating a Spending Plan (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Creating-a-Spending-Plan)
- [Military Touchpoint Series: Each PCS (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Each-PCS)
- [Personal Net Worth Tracker](/ToolsAndAddRes/NetWorth)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-7A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-7B.png"
              tagText="Pre- and post-deployment training"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="36.8%"
              left="34%"
              abstractText={`
Update paperwork and insurance policies, consider freezing your credit report and setting up credit alerts, review and adjust your spending plan, increase savings, understand any changes in pay and allowances, meet with a personal financial manager/counselor and review the Savings Deposit Program.
- [Freeze Your Credit Information (infographic)](/assets/downloads/FINRED-CreditFreeze-I.pdf)
- [Military Touchpoint Series: Pre- and Post-Deployment (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Pre-and-Post-Deployment)
- [Planning for the Future: Insurance (webpage)](/Planning#Insurance)
- [Spending Plan Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-10A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-10B.png"
              tagText="Divorce"
              tagColor="#F99F1E"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="47.3%"
              left="27%"
              abstractText={`
Update your DEERS information, update paperwork and insurance policies, notify creditors, and review and adjust your spending plan.
- [Financial Well-Being Assessment](/FWBA)
- [MilSpouse Money Mission: Preparing for a Military Divorce (webpage)](https://www.milspousemoneymission.org/divorce-touchpoint/#started)
- [Personal Net Worth Tracker](/ToolsAndAddRes/NetWorth)
- [Spending Plan Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-8A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-8B.png"
              tagText="Marriage"
              tagColor="#F99F1E"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="46%"
              left="57%"
              abstractText={`
Register your spouse in the Defense Enrollment Eligibility Reporting System (DEERS), update health, dental and life insurance policies, identify joint financial goals and adjust your spending plan accordingly.
- [Financial Well-Being Assessment](/FWBA)
- [Military Touchpoint Series: Decode Your Leave and Earnings Statement (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Decode-Your-Leave-and-Earnings-Statement)
- [Military Touchpoint Series: Major Life Events (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Major-Life-Events)
- [Personal Net Worth Tracker](/ToolsAndAddRes/NetWorth)
- [Survivor Benefit Plan (webpage)](/Planning/SurvivorBenefitPlan)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-11A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-11B.png"
              tagText="Disabling condition/death"
              tagColor="#F99F1E"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="55.3%"
              left="12%"
              abstractText={`
Update your spending plan and goals, review insurance policies and resources and revisit your plan for long-term savings.
- [Estate Planning (webpage)](/Planning#Estate-Planning)
- [Planning for the Future: Insurance (webpage)](/Planning#Insurance)
- [Spending Plan Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-9A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-9B.png"
              tagText="Birth and adoption"
              tagColor="#F99F1E"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="52%"
              left="45.5%"
              abstractText={`
Register your child in DEERS, update health, dental and life insurance policies, review and adjust your spending plan, and consider opening a college savings plan.
- [529 Education Savings Plans: The Basics for Service Members (fact sheet)](https://finred.usalearning.gov/assets/downloads/FINRED-529Plans-FS.pdf)
- [Financial Planning for Children (article)](https://finred.usalearning.gov/Planning/ChildrenFinances)
- [Financial Well-Being Assessment](https://finred.usalearning.gov/FWBA)
- [Military Touchpoint Series: Financial Planning for Life Events (video)](https://finred.usalearning.gov/SPL/Training/MilitaryJourneyTouchpoints/video/Financial-Planning-for-Life-Events)
- [Spending Plan Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-12A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-12B.png"
              tagText="Leadership training"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="65.5%"
              left="14%"
              abstractText={`
Understand the value of financial readiness and its impact on mission readiness, identify personal financial challenges your service members may experience, understand the impact they can have on job performance and get to know resources.
- [Military Touchpoint Series: Leadership Training (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Leadership-Training)
- [Starting the Conversation (leader card series)](/SPL/Training/LeaderCard)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-13A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-13B.png"
              tagText="Entitlement for continuation pay*"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="65.9%"
              left="57%"
              abstractText={`
Maximize your entitlement by investing in your future, increasing your savings, minimizing debt and planning for potential tax impacts.
- [Military Touchpoint Series: Continuation Pay (video)](/SPL/Training/MilitaryJourneyTouchpoints/video/Continuation-Pay)
- [The Blended Retirement System: Continuation Pay (fact sheet)](/assets/downloads/FINRED-ContinuationPay-FS.pdf)
              `}
            />

            <RftrIconTag
              iconImg="/assets/img/FINRED-RTFR-Icon-14A.png"
              activeIconImg="/assets/img/FINRED-RTFR-Icon-14B.png"
              tagText="Career transition"
              tagColor="#5C823B"
              onIconClick={onIconClick}
              zIndexOrder={zIndexOrder}
              top="78%"
              left="55%"
              abstractText={`
Examine your goals, spending plan and savings, notify civilian employers (for reserve component members coming off of active duty), meet with your personal financial manager/counselor, attend transition workshops and review options for retired pay.
- [Get Retirement Ready With This Checklist](/Planning/GetRetirementReadyChecklist)
- [Planning for the Future: Estate Planning (webpage)](/Planning#Estate-Planning)
- [Spending Plan Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
              `}
            />

            <Img className="rftr-guage" src="/assets/img/FINRED-MoneyGaugeAnimation.gif" alt="Money guage" title="Money guage" />
            <div className="rftr-footer">
              <Img src="/assets/img/FINRED-Map.png" />
              <div className="rftr-abstract">
                <h5>
                To learn more or for help navigating your finances, make a free appointment with a <Link to="https://installations.militaryonesource.mil/?looking-for-a=program/program-service=30/focus=program">personal financial manager</Link> or <Link to="/pfcMap">counselor</Link> at your nearest Military and Family Support Center. Take the <Link to="/FWBA">Financial Well-Being Assessment</Link> either once per year or as your financial situation changes. And view, print and share the <Link to="/assets/downloads/FINRED-RTFR-F.pdf">Road to Financial Readiness flyer</Link>.
                </h5>
              </div>
            </div>
            <div className="rftr-footnote">
              <div>*Only applies to those enrolled in the Blended Retirement System (BRS).</div>
              <div>To learn more about the BRS, go to <Link to="/Benefits/Retirement/BlendedRetirementSystem">https://finred.usalearning.gov/Benefits/Retirement/BlendedRetirementSystem</Link>.</div>
            </div>
          </div>
        </div>
      </NonTOCPage>
    );
}
