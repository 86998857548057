/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function DivorceRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Divorce|${root}` : `Divorce|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/divorce/38-108_-_divorce_20_-_course_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ImportantDocumentsandTasks', 'Important Documents and Tasks', crumbs,
                      '/assets/video/navy-resource/divorce/38-104_-_divorce_20_-_documents_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlans', 'Spending Plans', crumbs,
                      '/assets/video/navy-resource/divorce/Navy Divorce Video 04 - Budget.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EmergencyFundsBankingandPayingBills', 'Emergency Funds Banking and Paying Bills', crumbs,
                      '/assets/video/navy-resource/divorce/Navy Divorce Video 05 - Bank Accounts, Bill Paying, and Emergency Funds.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitaryRetirement', 'Military Retirement', crumbs,
                      '/assets/video/navy-resource/divorce/38-107_-_divorce_20_-_military_retirement_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      '/assets/video/navy-resource/divorce/38-106_-_divorce_20_-_life_insurance_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HealthPropertyandDisabilityInsurance', 'Health Property and Disability Insurance', crumbs,
                      '/assets/video/navy-resource/divorce/Navy Divorce Video 10 - Property, Health and Disability Insurance.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IncomeTaxes', 'Income Taxes', crumbs,
                      '/assets/video/navy-resource/divorce/38-105_-_divorce_20_-_income_taxes_v4_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/divorce/38-109_-_divorce_20_-_final_wrap-up_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-%20Divorce-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
