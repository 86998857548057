/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TrendingTOC from '../components/tocs/trendingTOC';
import DollarsAndSenseBlog from '../pages/dollars-and-sense-blog';
import HookUpBlog from '../components/hookUpBlog';
import Oops404Page from '../pages/oops-404';

import dnsBlogs from '../pages/blog/dollars-and-sense/blogs.json';

export default function DollarsAndSenseBlogRoutes(props) {
    const root = props.match.path;
    const toc = () => (props.toc || <TrendingTOC />);
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `Money in Real Life Blog|${root}`;

    return (
      <div>
        <Switch>
          <Route
            exact
            path={root}
            render={(routeProps) => <DollarsAndSenseBlog {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            exact
            path={`${root}/tag/:tag`}
            render={(routerProps) => <Redirect to={{ pathname: `${root}`, state: routerProps.match.params }} />}
          />

          {
            dnsBlogs.map(blog => HookUpBlog(root, crumbs, toc(), `${blog.href}.md`, blog))
          }

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
