/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LeaderCard from '../pages/leader-card';
import HookUpArticle from '../components/hookUpArticle';
import SplTOC from '../components/tocs/splTOC';
import Oops404Page from '../pages/oops-404';


export default function LeaderCardeRoutes(props) {
    const root = props.match.path;
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `Starting the Conversation|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <LeaderCard {...routerProps} crumbs={crumbs} toc={toc()} />}
        />

        {[
            HookUpArticle(root, crumbs, toc(), 'StartingtheConversation.md', 'SPL', 'Starting the Conversation: How To Talk to Service Members About Personal Finances'),
            HookUpArticle(root, crumbs, toc(), 'LeadershipDiscussions.md', 'SPL', 'Leadership Discussions: Lasting Impacts Before You Go'),
        ]}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
