/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function VestingInTSPRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Vesting In TSP|${root}` : `Vesting In TSP|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/Navy Vesting in TSP Video 01 - Course Overview.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'WhySaveforRetirement', 'Why Save for Retirement?', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/Navy Vesting in TSP Video 02 - Why Save for Retirement_%23C4C6.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MilitaryRetirementOverview', 'Military Retirement Overview', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/41-104_-_vesting_in_tsp_-_military_retirement_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'VestingintheTSP', 'Vesting in the TSP', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/41-106_-_vesting_in_tsp_-_vesting_in_tsp_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UnderstandingtheTSP', 'Understanding the TSP', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/17-105_-_vesting_in_the_tsp_video_5_-_understanding_the_tsp_v7 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingyourTSP', 'Managing your TSP', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/Navy Vesting in TSP Video 06 - How to Manage Your TSP.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TSPMaxTakeittotheLimit', 'TSP Max: Take it to the Limit', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/41-105_-_vesting_in_tsp_-_tsp_max_-_take_it_to_the_limit_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/vesting-in-tsp/Navy Vesting in TSP Video 08 - Final Thoughts.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-VestingTPS-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
