/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import { connect } from 'react-redux';
import Page from '../page';
import Link from '../link';
import MarkSection from './markSection';
import FeaturedAssetsSection from './featuredAssetsSection';
import ResourcesSection from './resourcesSection';
class PageEditor extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = Object.assign({}, props);
    }

    componentWillMount() {

    }

    delete() {

    }

    renderTOC() {
        return (
          <div className="">
            <h5 className="toc-title"> %title% </h5>
            <div className="toc-root">
              {this.props.sections.map((i) => <div className="toc-entry"><Link to="/Benefits-and-Entitlements#Blended-Retirement-System">%section%</Link></div>)}

            </div>
          </div>
        );
    }

    renderSections() {
        const sections = [];
        for (const i in this.props.sections) {
            const section = this.props.sections[i];
            const j = i;
            if (section.type == 'Markdown') {
                sections.push(
                  <MarkSection {...section} i={j} editing={this.props.editing} />,
                );
            }
            if (section.type == 'FeaturedAssets') {
                sections.push(
                  <FeaturedAssetsSection {...section} i={j} editing={this.props.editing} />,
                );
            }
            if (section.type == 'ResourceList') {
                sections.push(
                  <ResourcesSection {...section} i={j} editing={this.props.editing} />,
                );
            }
        }
        return sections.map(i => <div className="clearfix">{i}</div>);
    }

    renderAddSection() {
        return (
          <div>
            <div onClick={() => this.props.onAddSection('Markdown')} className="btn btn-raised btn-primary">Add Markdown Section</div>
            <div onClick={() => this.props.onAddSection('FeaturedAssets')} className="btn btn-raised btn-primary">Add Featured Assets Section</div>
            <div onClick={() => this.props.onAddSection('ResourceList')} className="btn btn-raised btn-primary">Add Resources Section</div>
          </div>
        );
    }

    render() {
        return (
          <Page
            crumbs="Home|/,News and Events|, Blog|/Home/News/Blog/,"
            toc={['Housing', 'Housing', 'Resources']}
          >
            <div className="usa-grid no-left-pad no-right-pad">
              <div className="usa-width-one-fourth">
                {this.renderTOC()}
              </div>
              <div className="usa-width-three-fourths article">
                {this.props.session.loggedIn && (
                <p style={{ float: 'left' }}>
                  <span className="fa fa-edit" onClick={() => this.props.onStartEdit(this.props._id)} />
                  {this.props.editing && <span className="fa fa-save" onClick={() => this.props.onSaveEdit(this.props._id)} />}
                  {this.props.editing && <span className="fa fa-close" onClick={() => this.props.onCancelEdit(this.props._id)} />}
                </p>
                )}
                {this.renderSections()}
                {this.props.editing && this.renderAddSection()}
              </div>
            </div>
          </Page>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    const articles = state.articles.docs;
    for (const i in articles) {
        if (ownProps._id == articles[i]._id) { return Object.assign({}, articles[i], ownProps, { session: state.session }); }
    }
};

const mapDispatchToProps = dispatch => ({
    onStartEdit: (_id) => dispatch({ type: 'startEdit', _id }),
    onCancelEdit: (_id) => dispatch({ type: 'cancelEdit', _id }),
    onSaveEdit: (_id) => dispatch({ type: 'saveEdit', _id }),
    onAddSection: (sectionType) => dispatch({ type: 'addSection', sectionType }),
    onRemoveSection: (i) => dispatch({ type: 'removeSection', i }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageEditor);

