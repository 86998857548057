/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { renderers } from 'react-markdown';
import { connect } from 'react-redux';
import Markdown from '../markdown';

export default class InlineMarkdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({ uuid: require('uuid').v4().replace(/-/, '') }, props);
    }

    componentDidMount() {
        this.unloadHandler = function() {
            return "Are you sure you want to leave? Your changes may be lost. Please use the 'Save' or 'Cancel' buttons";
        };
        window.onbeforeunload = this.unloadHandler;

        const editor = window.editormd('markEditor' + this.state.uuid, {
            width: '100%',
            height: '100%',
            path: '/assets/js/md/lib/',
            pageBreak: false, // Enable parse page break [========]
            atLink: false, // for @link
            emailLink: false,
            toc: false,
            imageUpload: false,
            watch: false,
            mode: 'markdown',
            toolbarIconsClass: {
                assets: 'fa-image',
                metadata: 'fa-edit',
                save: 'fa-save',
                cancel: 'fa-close',
                publish: 'fa-rss-square',
            },
            toolbarIconTexts: {
                assets: 'Manage Assets',
                metadata: 'Set Post Metadata',
                save: 'Save Draft',
                cancel: 'Discard Changed',
                publish: 'Publish Post',
            },
            lang: {
                toolbar: {
                    assets: 'Manage Assets',
                    metadata: 'Set Post Metadata',
                    save: 'Save Draft',
                    cancel: 'Discard Changed',
                    publish: 'Publish Post',
                },
            },

            toolbarIcons: function() {
                // Or return editormd.toolbarModes[name]; // full, simple, mini
                // Using "||" set icons align right.
                return [
                    'undo',
                    'redo',
                    '|',
                    'bold',
                    'del',
                    'italic',
                    'quote',
                    '|',
                    'h1',
                    'h2',
                    'h3',
                    'h4',
                    'h5',
                    'h6',
                    '|',
                    'list-ul',
                    'list-ol',
                    'hr',
                    '|',
                    'link',
                    'reference-link',
                    'image',
                    'code',
                    'preformatted-text',
                    'code-block',
                    'table',
                    'datetime',
                    'html-entities',
                    '|',
                    'goto-line',
                    'search',

                ];
            },
            toolbarHandlers: {

                save: () => {
                    this.save();
                },
                cancel: () => {
                    this.cancel();
                },

            },
            onBeforePreview() {
                console.log('onBeforePReview');
            },
            onload: () => {
                const aff = 'https://cdn.rawgit.com/kofifus/Typo.js/312bf158a814dda6eac3bd991e3a133c84472fc8/typo/dictionaries/en_US/en_US.aff';
                const dic = 'https://cdn.rawgit.com/kofifus/Typo.js/312bf158a814dda6eac3bd991e3a133c84472fc8/typo/dictionaries/en_US/en_US.dic';
                const typoLoaded = window.loadTypo(aff, dic);
                typoLoaded.then(typo => {
                    window.startSpellCheck(editor.cm, typo);
                });
            },
        });
        window.editor = editor;
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
        window.removeEventListener('beforeunload', this.unloadHandler);
    }

    save() {
        const newText = window.$('#markEditor' + this.state.uuid + ' textarea').val();
        this.props.onSave(newText);
    }

    cancel() {
        this.props.onCancel();
    }

    renderEditButtons() {
        const buttons = [];
        buttons.push(<span className="fa fa-save" onClick={() => this.save()} />);
        buttons.push(<span className="fa fa-undo" onClick={() => this.cancel()} />);

        return (<>
          <div className="sectionEdit">{buttons}

          </div>
          <div className="clearfix" />
            </>
        );
    }

    render() {
        return (
          <>
            {this.renderEditButtons()}
            <form>
              <div id={'markEditor' + this.state.uuid}>

                <textarea onChange={() => {}} value={this.props.value} />
              </div>
            </form>
          </>
        );
    }
}


