/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Housing (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Housing Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Housing Calculators
![Hand holding keys](/assets/img/FINRED-WebsiteImage-Housing-C.jpg "Hand holding keys")

For most people, buying a house is the most expensive purchase they’ll ever make. Knowing how the numbers will affect your finances is essential and accurate calculators can help.

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
          `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="15 vs. 30-Year Mortgage Calculator"
              calculator="/assets/calc/MortgageCompare.html"
              title="1"
              resource={calculator}
              resourceLabel="15-vs-30-Year-Mortgage-Calculator"
            >
            A shorter mortgage means paying less interest in the end, but a longer mortgage means smaller monthly payments. Use this calculator to compare and choose.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Home Closing Cost Calculator"
              calculator="/assets/calc/BuyerCostEstimate.html"
              title="2"
              resource={calculator}
              resourceLabel="Home-Closing-Cost-Calculator"
            >
            Using your closing date, mortgage price, insurance and taxes, title charges, and other costs, estimate how much you will need in total closing costs.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Home Rent vs. Buy Calculator"
              calculator="/assets/calc/MortgageRentvsBuy.html"
              title="3"
              resource={calculator}
              resourceLabel="Home-Rent-vs-Buy-Calculator"
            >
            Want to purchase a home? This calculator uses fees, taxes and monthly payments to tally your costs and compare if renting or buying is better for you.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Mortgage Calculator"
              calculator="/assets/calc/MortgageLoan2.html"
              title="4"
              resource={calculator}
              resourceLabel="Mortgage-Calculator"
            >
            Better understand your estimated monthly payments — including the amount of interest — and an amortization schedule using this mortgage calculator.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Mortgage Points Calculator"
              calculator="/assets/calc/MortgagePoints.html"
              title="5"
              resource={calculator}
              resourceLabel="Mortgage-Points-Calculator"
            >
            Purchasing mortgage points can help reduce your interest rate and monthly payments. See if this is the best route for you with this calculator.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}
