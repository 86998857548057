/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react';

import HeaderTitle from './headerTitle';
import HeaderAbstract from './headerAbstract';
import ContentBlockHeader from './contentBlockHeader';


export default function T2Header ({ header }) {
    return (
      <div className="dd-t2-header">
        <div>
          <HeaderTitle text={header.title.text} />
          <ContentBlockHeader header={header.subHeader} />
          <HeaderAbstract text={header.abstract.text} />
        </div>
        <div className="dd-image">
          <img src={header.image.src} alt={header.image.alt} title={header.image.alt} />
        </div>
      </div>
    );
}
