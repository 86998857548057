/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function VestingInTSP (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Vesting in TSP`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Vesting in TSP
With two years of service completed, eligible members of the Coast Guard
become vested in the Thrift Savings Plan. This milestone marks an
important step in planning for retirement. Use these resources to ensure
your Coast Guard member understands the importance of saving for
retirement, as well as how to manage their TSP account.

### Checklists

* [Vesting in TSP Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20Vesting%20in%20the%20TSP.pdf)
* [Vesting in TSP Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20Vesting%20in%20the%20TSP.pdf)

### Handouts

* [Basic Investing](/assets/downloads/USCG%20TC%20Handout-Basic%20Investing.pdf)
* [Military Retirement](/assets/downloads/USCG%20TC%20Handout-Military%20Retirement.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Thrift Savings Plan](/assets/downloads/USCG%20TC%20Handout-Thrift%20Savings%20Plan.pdf)

### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%205%20Vesting%20In%20The%20TSP%20CG%205-17-21.pptx)
* [Instructor Guide](/assets/downloads/TC%205%20Vesting%20Instructor%20Guide%20CG%206-15-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%205%20Vesting%20CFS%20Reference%20Manual%20CG%206-7-21.pdf)

### Video Discussion Guide

* [Vesting in TSP](/assets/downloads/TSP%20Vesting%20Video%20Discussion%20Guide-7.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


