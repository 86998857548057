/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Retirement (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Retirement Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Retirement Calculators
![Two service people looking at framed service awards](/assets/img/FINRED-WebsiteImage-Retirement-C.jpg "Two service people looking at framed service awards")

Don’t leave your retirement savings up to guesswork. Instead, set a clear plan for your savings goals — and take the time to calculate the numbers that will help you do it.

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
         `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Retirement Nest Egg Calculator"
              calculator="/assets/calc/RetirementNestegg.html"
              title="1"
              resource={calculator}
              resourceLabel="Retirement-Nest-Egg-Calculator"
            >
            Unsure how much you need to save for your retirement? This calculator can help you determine just how big your nest egg should be.
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Retirement Planner for Two Working Spouses Calculator"
              calculator="/assets/calc/RetirementPlanTwoIncomes.html"
              title="2"
              resource={calculator}
              resourceLabel="Retirement-Planner-for-Two-Working-Spouses-Calculator"
            >
            Together, you and your spouse can input your ages, earned incomes and current retirement savings to understand your path to a secure retirement.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


