/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Img from '../../img';
import ContentBody from './contentBody';

const ImageLeft = ({ image, content }) => (
  <div className={`dd-t2-content dd-left ${image.size}`}>
    <div className="dd-image">
      <Img src={image.src} alt={image.alt} title={image.alt} />
    </div>
    <div className="dd-content">
      <h4>{content.header.text}</h4>
      <ContentBody body={content.body} />
    </div>
  </div>
);

const ImageRight = ({ image, content }) => (
  <div className={`dd-t2-content dd-right ${image.size}`}>
    <div className="dd-content">
      <h4>{content.header.text}</h4>
      <ContentBody body={content.body} />
    </div>
    <div className="dd-image">
      <Img src={image.src} alt={image.alt} title={image.alt} />
    </div>
  </div>
);

export default function T2Content ({ content }) {
    return content.imageOrientation === 'right'
        ? <ImageRight image={content.image} content={content.content} />
        : <ImageLeft image={content.image} content={content.content} />;
}
