/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import ReactMarkdown from 'react-markdown';
import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';
export default class DebtDestroyer extends React.Component {
    render() {
        const root = this.props.match.path;

        return (
          <Page
            crumbs={this.props.crumbs}
            toc={this.props.toc}
            articleClass="subpage debt-destroyer rtm-reduction with-roman-ol with-alpha-ol"
          >

            <ReactMarkdown
              source={`
## Debt Destroyer® Workshop
Welcome to the United States Navy Debt Destroyer®
Workshop! This course is designed to empower you and your family to get
out — and stay out — of debt. You will learn proven techniques to
overcome your high interest rate consumer debt, make the most of the
money you have coming in and get on track to a more secure financial
future.

### Overview

This Debt Destroyer® Workshop consists of six steps. Each step includes
a video explanation and activities you will need to complete. The six
steps are:

1.  Stop the Bleeding
2.  Build a Safety Net
3.  Confront Your Debt
4.  Know Your Cash Flow
5.  Adjust Your Cash Flow
6.  Pay It Down!

### Preparation

Whether you are completing this workshop on your own (self-paced) or in
person, you will need the following items:
`}
              renderers={{
                  link: MarkLink,
              }}
            />
            <ol>
              <li>List of all creditors (include all loans, lines of credit and credit cards) with the following information:</li>
              <ol>
                <li>Balance due</li>
                <li>Due date</li>
                <li>Minimum monthly payment</li>
                <li>Annual percentage rate (APR)</li>
              </ol>
              <li>Accurate details of your income and expenses. Reference your Leave
                  and Earnings Statement (LES), other sources of income,
                  mortgage/rent, utilities, groceries, entertainment, etc.</li>
              <li>Debt Destroyer® Workshop materials:</li>
              <ol>
                <li><Link to="/assets/downloads/Debt Destroyer Participant Workbook - Printable.pdf">Participant Guide</Link>:
                    Download and print this file or order a printed copy from the <Link to="https://www.personalfinanceformilitarylife.org/debtdestroyerresourcecenter">
                    Debt Destroyer® Workshop Resource Center</Link>.</li>
                <li><Link to="/assets/downloads/Debt Destroyer Tool.xlsx">Debt Destroyer® Tool</Link>: Download to your personal computer.</li>
                <li><a href="/debt-destroyer-calculator/debt-destroyer-calculator.html" target="_blank" rel="noopener noreferrer">Debt Destroyer® Online Calculator</a>: Mobile device friendly tool</li>
              </ol>
              <li>Internet connection to stream videos</li>
              <ol>
                <li><b>Watch this <div className="video" style={{ display: 'inline' }}>
                  <Link to={`${root}/video/Introduction`}>introductory video.</Link>
                </div></b></li>
              </ol>
            </ol>

            <ReactMarkdown
              source={`
This course is available to you right here, right now and right at your
own pace. We provide the videos and tools you need to get started, but
you'll need to gather some documents and be prepared. This is a
WORKshop. You know what that means.

As you work through this six-step program, know you are not alone in
this journey! The Personal Financial Managers (PFMs) at your local Fleet
and Family Support Center (FFSC) or the Command Financial Specialists
(CFS) at your command are available to assist you. [Locate the closest
Fleet and Family Support Center to
you](https://www.cnic.navy.mil/ffr/family_readiness/fleet_and_family_support_program/FFSC.html).

Call your FFSC to register for an in-person Debt Destroyer® Workshop
near you!

### Understand Your Motivation

Two primary motivators tend to drive most people wanting to take control
of their debt: the avoidance of pain or the achievement of something
important. Viewed another way, negative emotions or positive emotions.
Which better describes you? Is it both? Whatever your motivation, you'll
have better odds of succeeding if you take a few minutes before diving
into the work and really think about, then write down, your top drivers
for wanting to make this change.

What are your top three motivations for wanting to take control of your
debt? Write them down.

Keep this list handy as you move through the six steps of the Debt
Destroyer® Workshop, and review it frequently as you work your plan.
Once the real work starts, it will be important to regularly remind
yourself *why* it's important for you to gain control of your debt.

### Imagine Your Future

Getting control of your debt and becoming more financially secure
usually takes a while, and during the journey, it can be hard to stay
the course if you don't have a clear image in your mind of where you're
headed.

Take a few minutes right now to think about your future. Imagine how
your life will look after taking action and putting in the work. What
has changed? How are things different? Write down your vision of the
future with no debt.

Now that you know your **motivations** and have a **vision** for your
future, make a **commitment** to yourself and your family to put in the
work and complete the steps of this workshop. Print your workbook and
let's get started.

### Step 1: Stop the Bleeding
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <div className="video">
              <Link to={`${root}/video/Step1-Stop-The-Bleeding`}>Watch this video: Stop the Bleeding</Link>
            </div>

            <ReactMarkdown
              source={`
**Key Points —**
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <ul>
              <li><b><u>S</u></b>urvive this step by Evading, Resisting and Escaping new debt.</li>
              <li><b><u>E</u></b>vade new debt by paying with cash only.</li>
              <li><b><u>R</u></b>esist the temptation to take on new debt by identifying and changing problematic behaviors.</li>
              <li><b><u>E</u></b>scape late fees and penalties by making payments on time.</li>
            </ul>

            <ReactMarkdown
              source={`
**Activity 1: Financial SERE Training for Your Debt**

Now it's time to put your financial **SERE** training into action.
Reflect on the decisions and behaviors that led to your current debt
situation. Then, brainstorm strategies to "Stop the Bleeding," and
commit to implementing at least one strategy per category. How will you
evade, resist and escape? Write it down.

**Resources:** Check out the [Resources section of your workbook](/assets/downloads/Debt%20Destroyer%20Participant%20Workbook%20-%20Printable.pdf) for tips
and strategies to assist you with each step of your Debt Destroyer®
journey.
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <blockquote>When is the last time you saw your credit report? Page 13 of your workbook shows how to request a free copy of your credit report and where to sign up for free credit monitoring. Do this. It’s really important. If you have questions, call your CFS or PFM.</blockquote>

            <ReactMarkdown
              source={`
### Step 2: Build a Safety Net
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <div className="video">
              <Link to={`${root}/video/Step2-Build-a-Safety-Net`}>Watch this video: Build a Safety Net</Link>
            </div>

            <ReactMarkdown
              source={`
**Key Points —**

- Build a safety net of $1,000 to pay for emergencies.
- Understand a true emergency fund is typically three to six months of
living expenses, but when getting out of debt, $1,000 will cover many
financial setbacks.
- Do not borrow to build this cushion.

**Activity 2: $1K Savings Sprint Strategies**

If you don't already have one, it's time to get your emergency fund
started! Come up with a plan to get at least $1,000 in a savings
account as quickly as possible. Think about what you have available or
what you can do to bring in extra money right now. List three things you
will do to accumulate a $1,000 safety net as soon as possible.

**Resources:** Check out the [Resources section of your workbook](/assets/downloads/Debt%20Destroyer%20Participant%20Workbook%20-%20Printable.pdf) for tips
and strategies to assist you with each step of your Debt Destroyer®
journey.
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <blockquote>Need a little help getting started? Check out "Tips for Building an Emergency Fund" on page 14 of your workbook.</blockquote>

            <ReactMarkdown
              source={`
### Step 3: Confront Your Debt
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <div className="video">
              <Link to={`${root}/video/Step3-Confront-Your-Debt`}>Watch this video: Confront Your Debt</Link>
            </div>

            <ReactMarkdown
              source={`
**Key Points —**

- Analyze and record the balance, minimum payment and interest rate of
each of your debts.
- Strategize ways to minimize the cost of your debt. Speak with whom you
owe. Seek an interest rate reduction, explore a balance transfer and
research the Servicemembers Civil Relief Act.

**Activity 3: Debt Details Scavenger Hunt**

Now it's time to get this mission organized. In this next activity, you
will go on a scavenger hunt through the statements you gathered in
preparation for this course to uncover the details of each of your debts
and record them in your workbook. We will be using this information
again in Step 6.

![Activity 3](/assets/img/FINRED-WebsiteImage-activity3.png "Activity 3")

**Resources:** Gather all your loan, line of credit and credit card
statements. This includes car loans or any other leisure vehicles that
you do not own outright. Be sure you write down all five details of all
your debt (creditor, type of debt, current balance, APR and minimum
payment).
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <blockquote>Learn more about the Servicemembers Civil Relief Act, "Tips for
    Minimizing the Cost of Debt" and strategies for "Communicating with
    Creditors" in the Resources section, beginning on page 15 of your
    workbook.</blockquote>

            <ReactMarkdown
              source={`
### Step 4: Know Your Cash Flow
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <div className="video">
              <Link to={`${root}/video/Step-4-Know-Your-Cash-Flow`}>Watch this video: Know Your Cash Flow</Link>
            </div>

            <ReactMarkdown
              source={`
**Key Points —**

- Update or create your spending plan.
- Document all your inflows and outflows for the last 30 days.
- Reduce expenses and, if possible, earn more.

**Activity 4: Start Your Spending Plan Worksheet**

![Activity 4](/assets/img/FINRED-WebsiteImage-activity4.png "Activity 4")

For this activity, you'll need the Spending Plan Worksheet on pages
11-12 in your [workbook](/assets/downloads/Debt%20Destroyer%20Participant%20Workbook%20-%20Printable.pdf) and/or
the Spending Plan Worksheet tab on the [Debt Destroyer® Tool](/assets/downloads/Debt%20Destroyer%20Tool.xlsx).

Complete the LEFT column. Reference your most recent Leave and Earnings
Statement (LES), pay stub (civilian employers), bank and credit card
activity or statements to complete this exercise.

Once you complete the left column, check your results. If your Spending
Plan Worksheet shows a monthly surplus, but your bank account does not
confirm this result, go back and review all your inputs.

**Resources:** You may need to track expenses to get a better idea of
what is coming in and going out.
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <blockquote>Use "Track Your Expenses" on page 23 of your <Link to="/assets/downloads/Debt Destroyer Participant Workbook - Printable.pdf">workbook</Link> to get a
    better handle of what money is coming in, what money is going out
    and where that money is going.</blockquote>

            <ReactMarkdown
              source={`
### Step 5: Adjust Your Cash Flow
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <div className="video">
              <Link to={`${root}/video/Step-5-Adjust-Your-Cash-Flow`}>Watch this video: Adjust Your Cash Flow</Link>
            </div>

            <ReactMarkdown
              source={`
**Key Points —**

- Determine how much extra cash you'll need to pay down your debt.
- Decide where that extra money is going to come from (make more or
spend less).
- If cutting expenses is your plan, target variable expenses first.

**Activity 5: Complete Your Spending Plan Worksheet**

![Activity 5](/assets/img/FINRED-WebsiteImage-activity5.png "Activity 5")

For this activity, please refer to the Spending Plan Worksheet and
complete the RIGHT column, looking for places where you think you can
reduce spending and free up money to put toward paying down your debt.
We'll call this "new" money Debt Destroyer® Dollars and refer to it in
Step 6.

Once you complete the right column, review the spending categories with
the largest values. Do these categories truly reflect your priority to
pay down debt? If certain categories, such as vehicle payments or
housing, are too large to allow for debt reduction, then changes to
these expenditures may need to be considered.

**Resources:** PFMs at your local FFSC are available to help with a wide
array of personal financial matters. For additional assistance on cash
management and budgeting, attend the Developing Your Spending Plan
workshop offered through the FFSC. [Locate the closest Fleet and Family
Support Center to
you](https://www.cnic.navy.mil/ffr/family_readiness/fleet_and_family_support_program/FFSC.html).

### Step 6: Pay It Down!
`}
              renderers={{
                  link: MarkLink,
              }}
            />

            <div className="video">
              <Link to={`${root}/video/Step-6-Pay-It-Down`}>Watch this video: Pay It Down!</Link>
            </div>

            <ReactMarkdown
              source={`
**Key Points —** This is it! This is where you evaluate and decide
which payoff method you will use.

- **Avalanche Method** prioritizes applying extra dollars to the highest
interest rate debt first, while paying minimums on all others
- **Snowball Method** prioritizes applying extra dollars to the lowest
balance debt first, while paying minimums on all others

**Activity 6: Use the Debt Destroyer® Tool**

Complete the [Debt Destroyer® Tool](/assets/downloads/Debt%20Destroyer%20Tool.xlsx) using the data you collected in Step 3
and Debt Destroyer® Dollars you uncovered from Step 5.

After reviewing each strategy, select yours and complete the "My Plan of
Action" section below.

![Activity 6](/assets/img/FINRED-WebsiteImage-activity6.jpg "Activity 6")
*Note: Debt Destroyer® Dollars can be a one-time payment (tax refund) or
the money you "find" in Step 5 by cutting expenses or increasing
income.*

Now that you have completed Steps 1-5, it's time to put your plan into
action! Determine your plan of action by reviewing both avalanche and
snowball methods of debt repayment. Each plan will show you the exact
date that you will be DEBT FREE!

Write down your Plan of Action in your [workbook](/assets/downloads/Debt%20Destroyer%20Participant%20Workbook%20-%20Printable.pdf) on Page 8.

![Activity 6.2](/assets/img/FINRED-WebsiteImage-activity6-2.png "Activity 6.2")

### Final Thoughts
Congratulations! You now have a plan for gaining control of your debt
and even a date when that will happen, if all goes according to plan.

Now, the real work begins!

Your success as a Debt Destroyer® will depend on staying committed and
sticking with your plan through the inevitable challenges that are bound
to come your way. Refer to the Resources section of your workbook for
more information. Here are a few tips to help you succeed on your
journey:

- Revisit your motivations.

- Begin implementing your SERE plans to Evade, Resist and Escape new
debt.

- Build your $1,000 safety net.
- Contact your creditors.
- Implement your new spending plan.
- Find an accountability partner.
- Set Debt Destroyer® calendar reminders.

***For PFMs ONLY:***

The [Debt Destroyer® Workshop Support Center](https://www.personalfinanceformilitarylife.org/debtdestroyer) 
features wallet cards, participant workbooks and DVDs for PFMs to order.

For a live workshop, please download and use the [Debt Destroyer®
Workshop presentation](/assets/downloads/Debt%20Destroyer%20Workshop%20Presentation.pptx).

**DISCLAIMER:** *The strategies presented in this Debt Destroyer®
Participant Guide are not intended to be or to provide financial, tax,
investment or legal advice. They are only a general overview of the
subject presented and are provided solely for your information.
Applicable laws are complex, the penalties for non-compliance may be
severe and the applicable law of your sate may differ. Consult your tax
and legal advisers regarding your specific situation.*
`}
              renderers={{
                  link: MarkLink,
              }}
            />
          </Page>
        );
    }
}


