/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import BlogCard from '../components/blogCard';

import dnsBlogs from './blog/dollars-and-sense/blogs.json';

export default function DollarsAndSenseBlog(props) {
    const root = props.match.path;
    const propsTag = props.location.state && props.location.state.tag;
    const [selectedTags, setSelectedTags] = useState(propsTag ? [propsTag] : []);

    const handleSelectTag = (value) => () => {
        let sTags = [...selectedTags];
        if (sTags.includes(value)) {
            // sTags.pop(value);
            sTags = sTags.filter(s => s !== value);
        } else {
            sTags.push(value);
        }
        setSelectedTags(sTags);
    };

    const getTagClass = (tagText) => (selectedTags.includes(tagText) ? 'selected' : ' ');

    const filterBySelectedTags = () => (blog) => (
        selectedTags.length > 0 ? selectedTags.filter(t => blog.tags.includes(t)).length > 0 : blog
    );

    const tags = [
        'Banking', 'Basic Finance', 'Compensation, Benefits and Entitlements', 'Consumer Protection',
        'Credit Score', 'Debt and Credit', 'Emergency Fund', 'Identity Theft', 'Insurance', 'Major Purchases',
        'Money Management', 'Planning for the Future', 'Retirement', 'Saving and Investing', 'Spending Plan',
        'Taxes',
    ];

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <div className="main-blog-page">
          <ReactMarkdown
            source={`
![Money in Real Life Blog](/assets/img/FINRED-WebsiteImage-MIRLBlog-Header.jpg "Money in Real Life Blog")

Life happens! The Money in Real Life blog is here to help you navigate those all-too-real-life money situations. Check back often for new blogs with practical information to help you reach your financial goals through each major milestone — including routine or unexpected life changes. 
            `}
            renderers={{
                link: MarkLink,
            }}
          />
        </div>
        <div className="blog-tags main-blog-page">
          {
            tags.map(t => <div className={`blog-tag ${getTagClass(t)} tag-button`} onClick={handleSelectTag(t)}>{t}</div>)
          }
        </div>

        <div>
          {
            dnsBlogs.filter(blog => blog.new).filter(filterBySelectedTags()).length > 0 && (
              <div className="new-blogs blogs">
                {
                  dnsBlogs.filter(blog => blog.new).filter(filterBySelectedTags()).map((blog, key) => (
                    <BlogCard
                      new={blog.new}
                      updated={blog.updated}
                      img={blog.img}
                      alt={blog.alt}
                      title={blog.title}
                      href={`${root}/${blog.href}`}
                      date={blog.date}
                      originalDate={blog.originalDate}
                      readingTime={blog.readingTime}
                      linkLabel={blog.linkLabel}
                    >
                      {blog.abstract}
                    </BlogCard>
                  ))
                }
              </div>
            )
          }
          <div className="blogs">
            {
              dnsBlogs.filter(blog => !blog.new).filter(filterBySelectedTags()).map((blog, key) => (
                <BlogCard
                  new={blog.new}
                  updated={blog.updated}
                  img={blog.img}
                  alt={blog.alt}
                  title={blog.title}
                  href={`${root}/${blog.href}`}
                  date={blog.date}
                  originalDate={blog.originalDate}
                  readingTime={blog.readingTime}
                  linkLabel={blog.linkLabel}
                >
                  {blog.abstract}
                </BlogCard>
              ))
            }
          </div>
        </div>
      </Page>
    );
}
