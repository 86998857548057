/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Saving from '../pages/saving';
import InvestingMicrolearningRoutes from './investing-microlearning-routes';
import HookUpArticle from '../components/hookUpArticle';
import SavingTOC from '../components/tocs/saving';
import Oops404Page from '../pages/oops-404';

export default function SavingRoutes(props) {
    const root = props.match.path;
    const crumbs = `Saving and Investing|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <Saving {...routerProps} crumbs={crumbs} toc={SavingTOC} />}
        />

        {[
            HookUpArticle(root, crumbs, SavingTOC, 'InvestmentOps.md', 'Saving', 'Other Investment Opportunities'),
            HookUpArticle(root, crumbs, SavingTOC, '5WsRetirement.md', 'Saving', 'Know the Five Ws of Military Retirement Planning'),
            HookUpArticle(root, crumbs, SavingTOC, 'RetirementReady.md', 'Saving', 'Are You Retirement Ready?'),
            HookUpArticle(root, crumbs, SavingTOC, 'UnderstandingInterest.md', 'Saving', 'Understanding Interest and How to Calculate It'),
            HookUpArticle(root, crumbs, SavingTOC, 'StocksBondsMutualFunds.md', 'Saving', 'Investing Basics: Bonds Stocks Mutual Funds and ETFs'),
            HookUpArticle(root, crumbs, SavingTOC, 'EarlyTSPWithdrawals.md', 'Saving', 'Thrift Savings Plan Withdrawal Considerations'),
            HookUpArticle(root, crumbs, SavingTOC, 'TSPOptions.md', 'Saving', 'Know Your TSP Options at Separation/Military Retirement'),
        ]}

        <Route
          exact
          path={`${root}/video/:video`}
          render={(routerProps) => <Saving {...routerProps} crumbs={crumbs} toc={SavingTOC} isVideo />}
        />

        <Route
          path={`${root}/InvestingMicrolearning`}
          render={(routerProps) => <InvestingMicrolearningRoutes {...routerProps} crumbs={crumbs} toc={SavingTOC} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

