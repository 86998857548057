/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from './nonTOCPage';
import PrintReadyCard from './printReadyCard';
import Img from './img';

export default function PrintReadyRenderer (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},${(props.title).replace(',', '')}|${root}`}
        articleClass="mos print-ready"
      >
        <div>
          <h2>{props.title}</h2>
          <Img large src={props.img} alt={props.alt || ' '} title={props.alt || ' '} />
          <h3>{props.type}</h3>
          <p>{props.abstract}</p>
          <PrintReadyCard
            files={props.files}
            prSpecFile={props.prSpecFile}
            prSpecLabel={props.prSpecLabel}
          />
        </div>
      </Page>
    );
}
