/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function PromotionRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Promotion|${root}` : `Promotion|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/promotion/Navy Promotion Video 01 - Course Overview.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Promotion-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancialPlanning', 'Financial Planning', crumbs,
                      '/assets/video/navy-resource/promotion/Navy Promotion Video 02 - Financial Planning.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Promotion-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingforRetirement', 'Saving for Retirement', crumbs,
                      '/assets/video/navy-resource/promotion/41-107_-_promotion_-_saving_for_retirement_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Promotion-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CareerInvestmentPrograms', 'Career Investment Programs', crumbs,
                      '/assets/video/navy-resource/promotion/17-112_-_promotion_video_4_-_career_investment_programs_v7_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Promotion-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/promotion/Navy Promotion Video 05 - Final Thoughts.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Promotion-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
