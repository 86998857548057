/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SplTOC from '../../components/tocs/splTOC';
import DivorceRoutes from './divorce-routes';
import FirstChildRoutes from './first-child-routes';
import MarriageRoutes from './marriage-routes';
import PcsRoutes from './pcs-routes';
import PostDeploymentRoutes from './post-deployment-routes';
import PreDeploymentRoutes from './pre-deployment-routes';
import PromotionRoutes from './promotion-routes';
import VestingInTSPRoutes from './vesting-on-tsp-routes';
import Oops404Page from '../../pages/oops-404';


export default function AirForceResource2Routes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Air Force Resource|${root}` : `Air Force Resource|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route path={`${root}/FirstChild`} render={(rps) => (<FirstChildRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/Divorce`} render={(rps) => (<DivorceRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/Marriage`} render={(rps) => (<MarriageRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/PermanentChangeOfStation`} render={(rps) => (<PcsRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/PostDeployment`} render={(rps) => (<PostDeploymentRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/PreDeployment`} render={(rps) => (<PreDeploymentRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/Promotion`} render={(rps) => (<PromotionRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route path={`${root}/VestingInTSP`} render={(rps) => (<VestingInTSPRoutes {...rps} crumbs={crumbs} toc={toc()} />)} />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
