/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function BNA (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Basic Needs Allowance 
The DoD is implementing the Basic Needs Allowance, or BNA, to help larger military households with low income to better afford basic needs and increase their economic security. These resources for service members, military leaders and service providers give more information about the BNA and who may qualify.
            `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-F.jpg"
            alt="Understanding the Basic Needs Allowance"
            title="Understanding the Basic Needs Allowance"
            href="/assets/downloads/FINRED-BNA-F.pdf"
            link="Download"
          >
            <p>
            Service members who have dependents and household income that falls below a federal income threshold may qualify for the Basic Needs Allowance. Learn more in this flyer.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-LC.jpg"
            alt="Helping Service Members Assess Basic Needs Allowance Eligibility"
            title="Talking to Service Members About Basic Needs Allowance"
            href="/assets/downloads/FINRED-BNA-LC.pdf"
            type="Leader Card"
            link="Download"
          >
            <p>
            Help yourself and service members under your command better understand Basic Needs Allowance and where to find support and resources. Use this card to start the conversation.
            </p>
          </FeaturedAsset>


          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-SPC.jpg"
            alt="Helping Service Members Assess Basic Needs Allowance Eligibility"
            title="Helping Service Members Assess Basic Needs Allowance Eligibility"
            href="/assets/downloads/FINRED-BNA-SPC.pdf"
            link="Download"
          >
            <p>
            As a service provider, help the service members you support to understand whether they might be eligible for the Basic Needs Allowance. This conversation card can help.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MOSPersonalFinance.jpg"
            alt="Secretary of Defense Lloyd J. Austin III at podium"
            title="Taking Care of Our Service Members and Families"
            href="https://www.militaryonesource.mil/financial-legal/personal-finance/taking-care-of-people/"
            link="Visit Link"
          >
            <p>
            Learn more about the DoD’s four-part initiative to help military families afford basic needs, ease the process of PCS moves and expand job opportunities for military spouses.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-MOSBNAFactSheet.jpg"
            alt="Person on tablet viewing fact sheet"
            title="Basic Needs Allowance"
            href="https://download.militaryonesource.mil/12038/MOS/Factsheets/basic-needs-allowance-factsheet.pdf"
            link="Visit Link"
          >
            <p>
            Get answers to commonly asked questions about the Basic Needs Allowance in this fact sheet from Military OneSource. 
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-BNA-R-AllServices.jpg"
            alt="Service seals icons on patriotic background"
            title="BNA FAQ and Service Resources"
            href={`${root}/ServiceResources`}
            link="Learn More"
          >
            <p>
            Looking for military service-specific information about the Basic Needs Allowance? Check out these resources.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
