/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function FirstChildRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},First Child|${root}` : `First Child|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/first-child/Navy First Child Video 01 - Course Overview.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PaperworkandAdminTasks', 'Paperwork and Admin Tasks', crumbs,
                      '/assets/video/navy-resource/first-child/Navy First Child Video 02 - Paperwork and Admin Tasks.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancialTipsOverview', 'Financial Tips Overview', crumbs,
                      '/assets/video/navy-resource/first-child/Navy First Child Video 03 - Transition to Financial Tips.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancialTipsforNewParents', 'Financial Tips for New Parents', crumbs,
                      '/assets/video/navy-resource/first-child/41-108_-_first_child_-_financial_tips_for_new_parents_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EmergencyFund', 'Emergency Fund', crumbs,
                      '/assets/video/navy-resource/first-child/Navy First Child Video 05 - Emergency Funds.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CostofCollege', 'Cost of College', crumbs,
                      '/assets/video/navy-resource/first-child/Navy First Child Video 06 - The Cost of College.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoInsurance', 'Transition to Insurance', crumbs,
                      '/assets/video/navy-resource/first-child/16_107_-_new_child_7_-_transition_to_insurance_v7_gen (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HealthInsuranceOverview', 'Health Insurance Overview', crumbs,
                      '/assets/video/navy-resource/first-child/41-109_-_first_child_-_health_insurance_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      '/assets/video/navy-resource/first-child/16_109_-_new_child_9_-_life_insurance_v6_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/first-child/Navy First Child Video 10 - Recap.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-FirstChild-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
