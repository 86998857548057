/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SplTOC from '../../../components/tocs/splTOC';
import ContinuationPayRoutes from './continuation-pay-routes';
import DisabilityRoutes from './disability-routes';
import DivorceRoutes from './divorce-routes';
import FirstChildRoutes from './first-child-routes';
import FirstDutyStationRoutes from './first-duty-station-routes';
import LeadershipTrainingRoutes from './leadership-training-routes';
import MarriageRoutes from './marriage-routes';
import PermanentChangeOfStationRoutes from './permanent-change-of-station-routes';
import PostDeploymentRoutes from './post-deployment-routes';
import PreDeploymentRoutes from './pre-deployment-routes';
import PromotionRoutes from './promotion-routes';
import VestingInTSPRoutes from './vesting-in-tsp-routes';
import Oops404Page from '../../../pages/oops-404';


export default function NavyResourceRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Touchpoint Curriculum|${root}` : `Touchpoint Curriculum|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/FirstDutyStation`}
          render={(routeProps) => <FirstDutyStationRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/PermanentChangeOfStation`}
          render={(routeProps) => <PermanentChangeOfStationRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/Promotion`}
          render={(routeProps) => <PromotionRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/VestingInTSP`}
          render={(routeProps) => <VestingInTSPRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/ContinuationPay`}
          render={(routeProps) => <ContinuationPayRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/Marriage`}
          render={(routeProps) => <MarriageRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/Divorce`}
          render={(routeProps) => <DivorceRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/FirstChild`}
          render={(routeProps) => <FirstChildRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/Disability`}
          render={(routeProps) => <DisabilityRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/LeadershipTraining`}
          render={(routeProps) => <LeadershipTrainingRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/PreDeployment`}
          render={(routeProps) => <PreDeploymentRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />
        <Route
          path={`${root}/PostDeployment`}
          render={(routeProps) => <PostDeploymentRoutes {...routeProps} root={root} crumbs={crumbs} toc={toc()} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
