/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import BaseTOC from './baseTOC';


export default (
  <BaseTOC
    title="Managing Your Money"
    tocEntries={[
        { link: '/Money#Credit-Fundamentals', text: 'Credit Fundamentals' },
        { link: '/Money#Digital-Assets', text: 'Digital Assets' },
        { link: '/Money#Major-Purchases', text: 'Major Purchases' },
        { link: '/Money#Pay-and-Savings', text: 'Pay and Savings' },
        { link: '/Money#Tax-Information', text: 'Tax Information' },
        { link: '/Money#Additional-Information', text: 'Additional Information' },
    ]}
  />
);
