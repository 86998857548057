/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React, { useEffect } from 'react';

import Home from './home';

export default function Page(props) {
    useEffect(() => {
        const hash = props.location && props.location.hash;
        if (hash) {
            const el = document.getElementById(hash.replace('#', ''));
            el.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    }, []);

    return (
      <Home crumbs={props.crumbs}>
        <div className="usa-grid bg0" style={{ borderTop: '1px solid #DDD', paddingTop: '2em', paddingBottom: '2em' }}>
          {props.toc ? (
            <>
              <div className="usa-width-one-fourth TOC">
                {props.toc}
              </div>
              <div className={`usa-width-three-fourths article ${props.articleClass}`}>
                {props.children}
              </div>
            </>
          ) : (
            <>
              {props.children}
            </>
          )
          }

        </div>
      </Home>
    );
}
