/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

import Link from './link';

import selected from './utils/selectNav';

export default function MoneyMenu({ setSite }) {
    return (
      <li id="Money" className={`${selected('/Money')} header-dropdown`} style={{ flexBasis: '12%', color: '#112e51' }}>
        <>
          <Link
            to={'/Money'}
            className="usa-nav__link"
          >
            Managing Your Money
          </Link>
          <ul id="extended-nav-section-two" className="usa-nav-submenu money-menu">
            <li>
              <HashLink
                smooth
                to="/Money#Credit-Fundamentals"
              >
                Credit Fundamentals
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Money#Digital-Assets"
              >
                Digital Assets
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Money#Major-Purchases"
              >
                Major Purchases
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Money#Pay-and-Savings"
              >
                Pay and Savings
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Money#Tax-Information"
              >
                Tax Information
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/Money#Additional-Information"
              >
                Additional Information
              </HashLink>
            </li>
          </ul>
        </>
      </li>
    );
}


