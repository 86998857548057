/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function VestingInTSPRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Vesting in TSP|${root}` : `Vesting in TSP|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_101_-_af_vesting_in_the_tsp_20_-_course_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PayingforRetirement', 'Paying for Retirement', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_102_-_af_vesting_in_the_tsp_20_-_paying_for_retirement_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'WhySaveNowForRetirement', 'Why Save Now For Retirement', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_103_-_af_vesting_in_the_tsp_20_-_why_save_now_for_retirement_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TheImpactofContributionAmounts', 'The Impact of Contribution Amounts', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_104_-_af_vesting_in_the_tsp_20_-_the_impact_of_contribution_amounts_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'InvestingforRetirement', 'Investing for Retirement', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_105_-_af_vesting_in_the_tsp_20_-_investing_for_retirement_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TheBlendedRetirementSystem', 'The Blended Retirement System', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_106_-_af_vesting_in_the_tsp_20_-_the_military_retirement_system_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoTSPDeepDive', 'Transition to TSP Deep Dive', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_107_-_af_vesting_in_the_tsp_20_-_transition_to_tsp_deep_dive_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ThriftSavingsPlanBasics', 'Thrift Savings Plan Basics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_108_-_af_vesting_in_the_tsp_20_-_thrift_savings_plan_basics_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingYourTSP', 'Managing Your TSP', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_109_-_af_vesting_in_the_tsp_20_-_managing_your_tsp_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TSP:MaxingOutWithoutMissingOut', 'TSP: Maxing Out Without Missing Out', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_110_-_af_vesting_in_the_tsp_20_-_tsp-maxing_out_without_missing_out_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/tsp/46_-_111_-_af_vesting_in_the_tsp_20_-_course_wrap-up_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFRetirementPlanning-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
