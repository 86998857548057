/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { HashLink } from 'react-router-hash-link';

import TOCEntry from '../tocEntry';
import Link from '../link';
import NewsletterFormSmall from '../newsletterFormSmall';

const getLink = (entry, root) => {
    if (entry.component) {
        return React.cloneElement(entry.component);
    }

    if (/#/.test(entry.link)) {
        return (
          <HashLink
            smooth
            to={root ? `${root}${entry.link}` : entry.link}
          >
            {entry.text}
          </HashLink>
        );
    }

    if (/\/assets\/downloads/.test(entry.link)) {
        return (<Link to={entry.link}>{entry.text}</Link>);
    }

    return (<Link to={root ? `${root}${entry.link}` : entry.link}>{entry.text}</Link>);
};

export default function BaseTOC({ root, title, tocEntries, tocClass }) {
    return (
      <>
        <h5 className="toc-title">{title}</h5>
        <div className={`toc-root ${tocClass}`}>
          {tocEntries.map(tocEntry => (
            <TOCEntry text={tocEntry.link}>
              {getLink(tocEntry, root)}
            </TOCEntry>
          ))}
        </div>
        <NewsletterFormSmall />
      </>
    );
}

