/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function CoastGuardRetiree (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Coast Guard Retiree`}
        toc={props.toc}
        articleClass="subpage uscg"
      >
        <ReactMarkdown
          source={`
## Coast Guard Retiree
This collection of resources and guidance can provide additional
support in your work as a PFM or CFS. Use the following bullets to
instruct, inform and engage Coast Guard retirees.

* [CGMA | The official relief society of the U.S. Coast Guard](https://cgmahq.org/)
* [CG SUPRT](https://www.cgsuprt.com/portal/landing)
* [Consumer Financial Protection
    Bureau](https://www.consumerfinance.gov/)
* [BENEFEDS | Federal Benefits Enrollment (FEDVIP, FSAFEDS,
    FLTCIP)](https://www.benefeds.com/)
* [IdentityTheft.gov](https://identitytheft.gov/)
* [IRS.gov](https://www.irs.gov/)
* [Military Saves](https://militarysaves.org/)
* [PowerPay](https://extension.usu.edu/powerpay/index)
* Retirement Planning
    * [OPM Retirement Information
        Office](https://www.opm.gov/retirement-services/)
    * [Retirement
        Pay](https://militarypay.defense.gov/Pay/Retirement/)
* [TRICARE](https://www.tricare.mil/)
* [Veterans Affairs](https://www.va.gov/)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


