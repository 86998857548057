/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';

import Link from './link';
import SimulationLink from './simulationLink';

import selected from './utils/selectNav';

export default function TrendingTopicsMenu({ setSite }) {
    return (
      <li id="Trending" className={`${selected('/Trending')} header-dropdown`} style={{ flexBasis: '14%', color: '#112e51' }}>
        <a to="/Trending" className="" aria-expanded="false" aria-controls="extended-nav-section-two">
            Trending Topics
          <ul id="extended-nav-section-two" className="usa-nav-submenu trending-menu">
            <li>
              <Link to="/Trending/Blog">Money in Real Life Blog</Link>
            </li>
            <li>
              <Link to="/Trending/MIRL">Money in Real Life Resources</Link>
            </li>
            <li>
              <Link to="/Trending/Policy">Policy, Law and Reports</Link>
            </li>
            <li>
              <Link to="/Trending/SMChallenges">Take a Challenge</Link>
            </li>
          </ul>
        </a>
      </li>
    );
}


