/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import Link from './link';
import Img from './img';

class FeaturedAsset extends React.Component {
    constructor() {
        super();
        this.state = {
            editing: false,
            payload: Object.assign({}, this.props),
        };
    }

    cancel() {
        this.setState(() => ({ editing: false }));
    }

    edit() {
        this.setState(() => ({ editing: true }));
    }

    save() {
        this.props.onSave(this.props.i, this.payload, this.props.sectionIndex);
        this.setState(() => ({ editing: false }));
    }

    remove() {
        this.props.onRemove(this.props.i, this.props.sectionIndex);
    }

    render() {
        return (
          <div className="usa-width-one-third featured-asset">
            {!this.state.editing
            && [<div>
              <Img src={this.props.img} />

              <div className="featured-asset-body">
                <h4>{this.props.title}</h4>
                <p>{this.props.type}</p>
                {this.props.children}
              </div>
            </div>,
              <div className="featured-asset-bottom">
                <Link className="usa-button" to={this.props.href}>
                  {' '}

                  {this.props.link || 'Download'}{' '}
                </Link>
              </div>,
              <div className="featured-asset-bottom">
                {this.props.sectionEditing && !this.state.editing && <span onClick={() => this.edit()} className="fa fa-edit pull-right" />}
                {this.props.sectionEditing && !this.state.editing && <span onClick={() => this.remove()} className="fa fa-remove pull-right" />}

                {this.props.sectionEditing && this.state.editing && <span onClick={() => this.cancel()} className="fa fa-ban pull-right" />}
                {this.props.sectionEditing && this.state.editing && <span onClick={() => this.save()} className="fa fa-save pull-right" />}
              </div>]
            }
            {this.state.editing
               && [<input value={this.state.payload.img} type="text" />,
                 <input value={this.state.payload.title} type="text" />,
                 <input value={this.state.payload.type} type="text" />,
                 <input value={this.state.payload.text} type="text" />,
                 <input value={this.state.payload.link} type="text" />,
                 <input value={this.state.payload.href} type="text" />,
                 <div className="featured-asset-bottom">
                   {this.props.sectionEditing && !this.state.editing && <span onClick={() => this.edit()} className="fa fa-edit pull-right" />}
                   {this.props.sectionEditing && !this.state.editing && <span onClick={() => this.remove()} className="fa fa-remove pull-right" />}

                   {this.props.sectionEditing && this.state.editing && <span onClick={() => this.cancel()} className="fa fa-ban pull-right" />}
                   {this.props.sectionEditing && this.state.editing && <span onClick={() => this.save()} className="fa fa-save pull-right" />}
                 </div>]
            }
          </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = dispatch => ({
    onSave: (i, sectionIndex) => dispatch({ type: 'featuredAssetEdited', index: i, sectionIndex }),
    onRemove: (i, sectionIndex) => dispatch({ type: 'featuredAssetRemoved', index: i, sectionIndex }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeaturedAsset);
