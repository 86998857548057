/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function HomeBuyingBasics (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Home Buying Basics
Are you about to PCS and wondering if you should rent or buy? How do other military families decide? In the Home Buying Basics series, you’ll follow service members and spouses as they navigate the challenges of selecting their homes, as well as the steps they take to reach their goals. No matter the stage of your military career, you’ll learn useful tips on how to choose the right home.
            `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-Rent-V1.jpg"
            alt=" Young couple standing near For Rent sign with real estate agent"
            title="Renting Vs. Buying Part 1: Renting"
            href={`${root}/video/RentingVsBuyingPart1Renting`}
            link="Watch Now"
          >
            <p>
            Trying to decide where to live after PCSing? You’re not alone. Follow Sgt. Marcus Jones and his wife, Jill, as they choose between renting and buying in Colorado.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-Buy-V2.jpg"
            alt="Young couple with real estate agent in living room"
            title="Renting Vs. Buying Part 2: Buying"
            href={`${root}/video/RentingVsBuyingPart2Buying`}
            link="Watch Now"
          >
            <p>
            Sgt. Marcus Jones and his wife, Jill, continue house searching. After hearing all about renting, they learn about buying. Follow their journey and learn how to choose a home.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-VS-SmartRent-V3.jpg"
            alt="Husband looking at wife in front yard"
            title="Smart Renting Moves"
            href={`${root}/video/SmartRentingMoves`}
            link="Watch Now"
          >
            <p>
            Meet Nick and Ximena, a military couple PCSing to a new duty station. Discover the seven key points they consider when looking at their rental housing options.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-SmartBuy-V4.jpg"
            alt="Husband and wife walking on sidewalk near mailbox"
            title="Smart Buying Moves"
            href={`${root}/video/SmartBuyingMoves`}
            link="Watch Now"
          >
            <p>
            Join Eli and Jamie, a newly married military couple, as they search for their first home. Learn four savvy house hunting tips.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-Basics-V5.jpg"
            alt="Service member talking to real estate agent"
            title="Home Buying Basics"
            href={`${root}/video/HomeBuyingBasics`}
            link="Watch Now"
          >
            <p>
            Follow Maj. Towns, a single service member, as he navigates his home buying journey. See how he uses a mortgage calculator to determine the budget for his first home.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-MortageLoans.jpg"
            alt="Real estate agent with couple in virtual reality chamber for homes"
            title="Mortgage Loan Types"
            href={`${root}/video/MortgageLoanTypes`}
            link="Watch Now"
          >
            <p>
            During a virtual-reality home tour, a 20-year veteran and her spouse discuss how to pay for their new home. Watch and learn useful facts about different types of mortgages.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HBB-MortgagePayments-V7.jpg"
            alt="Couple talking with real estate agent in living room"
            title="How Mortgage Payments Work"
            href={`${root}/video/HowMortgagePaymentsWork`}
            link="Watch Now"
          >
            <p>
            Having trouble deciding between two homes? You’ll need to compare mortgage payments for both before deciding. Watch a military family weigh monthly costs for each house.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
