/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import Link from './link';
import Crumbs from './crumbs';
import TrendingTopicsMenu from './trending-topics-menu';
import Img from './img';
import ToolsAndAdditionResourcesMenu from './tools-additional-resources-menu';
import LinkModal from './linkModal';
import AboutMenu from './about-menu';
import BenefitsMenu from './benefits-menu';
import MoneyMenu from './money-menu';
import SavingMenu from './saving-menu';
import FutureMenu from './future-menu';
import UnderstandingMenu from './understanding-menu';

import selected from './utils/selectNav';
export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navVisible: false,
            showModal: false,
            modalSite: '',
            isSimulation: false,
        };
        this.showNav = this.showNav.bind(this);
        this.hideNav = this.hideNav.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
    }

    setShowModal(site) {
        this.setState({
            showModal: true,
            modalSite: site,
        });
    }

    showNav() {
        this.setState(s => {
            s.navVisible = true;
            return s;
        });
    }

    hideNav() {
        this.setState(s => {
            s.navVisible = false;
            return s;
        });
    }

    render() {
        return (
          <>
            <header className={'usa-grid  no-left-pad no-right-pad  bg0 usa-header usa-header-extended ' + (this.props.thin ? 'thin' : '')} role="banner">

              <div className="usa-navbar">
                <button className="usa-menu-btn" onClick={this.showNav}>Menu</button>
              </div>
              <nav role="navigation" className={'usa-nav' + (this.state.navVisible ? ' is-visible' : '')}>
                <div className="usa-nav-inner">
                  <button className="usa-nav-close">
                    <Img src="/assets/img/close.svg" alt="close" onClick={this.hideNav} />
                  </button>

                  <ul className="usa-nav-primary usa-accordion">
                    {/* <AboutMenu /> */}
                    <li id="Start Here" className={selected('/StartHere')} style={{ flexBasis: '10%' }}>
                      <Link
                        to={'/StartHere/RoadToFinancialReadiness'}
                        className="usa-nav__link"
                      >
                        <span className="text-bold">Start Here</span>
                      </Link>
                    </li>
                    <BenefitsMenu />
                    <MoneyMenu />
                    <SavingMenu />
                    <FutureMenu />
                    <UnderstandingMenu />
                    <ToolsAndAdditionResourcesMenu setSite={(site) => { this.setShowModal(site); this.setState({ isSimulation: true })}} />
                    <TrendingTopicsMenu />
                  </ul>
                </div>
              </nav>
            </header>
            {
              this.state.showModal && (
                <LinkModal
                  isSimulation={this.state.isSimulation}
                  dismiss={() => this.setState((state) => ({ showModal: false }))}
                  href={this.state.modalSite}
                />
              )
            }
            <div className={'usa-grid usa-banner-header bg0 ' + (this.props.thin ? 'thin' : '')} />
            <div className="usa-overlay" />
          </>
        );
    }
}

