/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect, useState } from 'react';
import Link from './link';

export default function Crumbs(props) {
    const [crumbs, setCrumbs] = useState([]);

    useEffect(() => {
        const html = [];
        const crumbList = props.crumbs.split(',');
        crumbList.forEach((crumb, i) => {
            const text = crumb.split('|')[0];
            const link = crumb.split('|')[1];

            if (i === crumbList.length - 1) {
                props.setTitle(`FINRED | ${text}`);
                html.push(<li key={text} className="breadcrumb-item active"><span>{text}</span></li>);
            } else {
                html.push(<li key={text} className="breadcrumb-item"><Link to={link}>{text}</Link></li>);
            }
        });

        setCrumbs(html);
    }, []);

    return (
      <ol className="usa-crumbs usa-unstyled-list ">
        {crumbs}
      </ol>
    );
}
