/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MoneyTOC from '../../components/tocs/money';
import Oops404Page from '../../pages/oops-404';
import DigitalDesignDemo from '../../pages/digital-design-demo';

import HookUpDigitalDesign from '../../components/digital-design/hookUpDigitalDesign';

export default function DigitalDesignDemoRoutes(props) {
    const root = props.match.path;
    const crumbs = `Digital Design Demo|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <DigitalDesignDemo {...routerProps} crumbs={crumbs} toc={MoneyTOC} />}
        />

        {[
            HookUpDigitalDesign(root, crumbs, MoneyTOC, 'template1-example', 'Protect Your Finances During PCS With the Servicemembers Civil Relief Act'),
            HookUpDigitalDesign(root, crumbs, MoneyTOC, 'template2-example', 'Zero Percent Student Loan Interest for Eligible Service Members'),
            HookUpDigitalDesign(root, crumbs, MoneyTOC, 'template3-example', 'Zero Percent Student Loan Interest for Eligible Service Members'),
        ]}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
