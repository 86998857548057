/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function ContinuationPay (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Financial Planning Worksheet`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Financial Planning Worksheet
The electronic financial planning worksheet, or eFPW, is a tool members can use to understand their income and deductions, track living expenses (spending plan), calculate debt and obtain a financial health assessment, which will help to determine and prioritize action items, specifically a debt-reduction strategy.

* [Electronic Financial Planning Worksheet](/assets/downloads/eFPW_02-22-2021.xlsx)
* [FPW (PDF with links)](/assets/downloads/SpendingPlanWorksheet_02-22-2021.pdf)
* [FPW (PDF without links for printing)](/assets/downloads/SpendingPlanWorksheet_Print_02-22-2021.pdf)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


