/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';

import ReactMarkdown from '../../components/markdown';

export default function Planning ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Planning for the Future`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Planning for the Future
When it comes to developing your plan for the future, it’s important to utilize all of the information at your disposal. These resources can help you make a plan, increase your savings and keep your retirement goals in mind. For more insights, connect with a [personal financial manager](https://installations.militaryonesource.mil/) or counselor to discuss how to make your future goals happen. Plus, in addition to the supplemental sources on this page, don’t forget to visit [FINRED's collection of planning for the future resources](/Planning).

* [DoD Career Ready](https://dodcareerready.usalearning.gov/service_members)
  Get "ready for life." Take full advantage of workforce development and financial readiness resources available throughout your military service and into retirement.
* [FINRA](http://www.finra.org/)
  The nonprofit Financial Industry Regulatory Authority, or FINRA, educates and informs investors and safeguards against fraud and bad practices. Find out how.
* [MyMoney.gov](https://www.mymoney.gov/Pages/default.aspx)
  Watch your wealth stack up! Try using five building blocks — Earn, Save and Invest, Protect, Spend and Borrow — and find tips galore on MyMoney.gov.
* [Personal Financial Counselor](/assets/downloads/FINRED-PFC-F.pdf)
  Find out how no-cost, personal support from personal financial counselors can help service members and their families make the most of their money.
* [Thrift Savings Plan](https://www.tsp.gov/index.html)
  Set up and manage your Thrift Savings Plan retirement account and review fund options and performance, retirement planning information and tools and more.
* [USA.gov Military Programs and Benefits](https://www.usa.gov/military-assistance)
  Check out these services for military members and families, including tuition assistance, education aid programs, life insurance and survivor benefit plans.
* [Veterans’ Spouses, Dependents, and Survivors Eligibility Overview](https://explore.va.gov/health-care/spouses-dependents-survivors)
  Spouses, dependents and survivors of veterans may qualify for Department of Veterans Affairs health care benefits through certain programs. Learn more.
          `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


