/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Protections from '../pages/protections';
import ConsumerCreditRoutes from './consumer-credit-routes';
import HookUpArticle from '../components/hookUpArticle';
import HookUpDigitalDesign from '../components/digital-design/hookUpDigitalDesign';
import ProtectionTOC from '../components/tocs/understanding';
import Oops404Page from '../pages/oops-404';

export default function ProtectionsRoutes(props) {
    const root = props.match.path;
    const crumbs = `Understanding Consumer Protections|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <Protections {...routerProps} crumbs={crumbs} toc={ProtectionTOC} />}
        />

        {[
            HookUpArticle(root, crumbs, ProtectionTOC, 'MarketingDecisions.md', 'Consumer-Protections', 'How Marketing Affects Your Purchase Decisions'),
            HookUpDigitalDesign(root, crumbs, ProtectionTOC, 'ProtectFinancesPCS', 'Protect Your Finances During PCS'),
        ]}

        <Route
          path={`${root}/ConsumerCredit`}
          render={(routerProps) => <ConsumerCreditRoutes {...routerProps} crumbs={crumbs} toc={ProtectionTOC} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
