/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function MarriageRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Marriage|${root}` : `Marriage|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 01 - Course Overview.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Communication', 'Communication', crumbs,
                      '/assets/video/navy-resource/marriage/13-105_-_video_2_-_marriage_-_communication_v5_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Documents', 'Documents', crumbs,
                      '/assets/video/navy-resource/marriage/13-107_-_video_4_-_marriage_-_documents_part_1_v6_gen (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Documentscontinued', 'Documents (continued)', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 05 - Documents, pt. 2.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlans', 'Spending Plans', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 07 - Spending Plan.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EmergencyFunds', 'Emergency Funds', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 08 - Emergency Fund.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Banking', 'Banking', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 10 - Banking.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'WheelofLife', 'Wheel of Life', crumbs,
                      '/assets/video/navy-resource/marriage/38-101_-_marriage_20_-_wheel_of_life_v4 (720p) .mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HealthandPropertyInsurance', 'Health and Property Insurance', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 12 - Health and Property Insurance.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      '/assets/video/navy-resource/marriage/13-117_-_video_11_-_marriage_-_life_insurance_v6_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SurvivorandDependentBenefits', 'Survivor and Dependent Benefits', crumbs,
                      '/assets/video/navy-resource/marriage/38-102_-_marriage_20_-_survivor_and_dependent_benefits_v2 (720p) .mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanning', 'Estate Planning', crumbs,
                      '/assets/video/navy-resource/marriage/38-103_-_marriage_20_-_estate_planning_overview_v3 (720p) .mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/marriage/Navy Marriage Video 14 - Wrap Up.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Marriage-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
