/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MoneyTOC from '../../components/tocs/money';
import SplTOC from '../../components/tocs/splTOC';
import TrendingTOC from '../../components/tocs/trendingTOC';
import HookUpArticle from '../../components/hookUpArticle';
import Oops404Page from '../../pages/oops-404';
import DigitalDesignDemoRoutes from './digital-design-demo-routes';

export default function BetaRoutes(props) {
    const root = props.match.path;
    const trendToc = () => (<TrendingTOC root={root} />);
    const splToc = () => (<SplTOC root={root} />);

    return (
      <Switch>
        <Route
          path={`${root}/DigitalDesignDemo`}
          render={(routerProps) => <DigitalDesignDemoRoutes {...routerProps} toc={MoneyTOC} />}
        />

        {[
            HookUpArticle(root, `Managing Your Money|${root}`, MoneyTOC, 'DebtTrapsPullQuoteTest.md', 'Money', 'How To Avoid — or Break — the Debt Trap Cycle'),
            HookUpArticle(root, 'Trending Topics', trendToc(), 'DebtTrapsPullQuoteTestTrend.md', 'Trending', 'How To Avoid — or Break — the Debt Trap Cycle'),
            HookUpArticle(root, `Service Providers and Leaders|${root}`, splToc(), 'DebtTrapsPullQuoteTestSPL.md', 'SPL', 'How To Avoid — or Break — the Debt Trap Cycle'),
            HookUpArticle(root, `Service Providers and Leaders|${root}`, splToc(), 'DebtTrapsPullQuoteTestBase.md', 'Base', 'How To Avoid — or Break — the Debt Trap Cycle'),
        ]}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
