/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Img from './img';
import ReactMarkdown from './markdown';
import PopOutMarkLink from './popOutMarkLink';
import Link from './link';
const ReactMarkdown2 = require('react-markdown/with-html');

export default function ArticleCard(props) {
    const article = props.article ? { ...props.article } : { ...props };
    const abstract = props.children || article.abstract;

    return (
      <div className="blog-card article">
        <div className={`blog-card-image ${props.cardImageClass}`}>
          <div className="small-image">
            <Img small src={article.img} alt={article.alt || ' '} title={article.alt || ' '} />
          </div>
          <div className="large-image">
            <Img large src={article.img} alt={article.alt || ' '} title={article.alt || ' '} />
          </div>
          { article.flag && <div className="new-corner-badge"><img src={article.flag.img} alt={`${article.flag.alt}`} /></div> }
        </div>
        <div className="blog-card-body">
          <div className="blog-card-title">
            <ReactMarkdown
              source={article.title}
            />
          </div>
          <div className="blog-card-abstract">
            <ReactMarkdown2
              source={abstract}
              escapeHtml={false}
              renderers={{
                  link: PopOutMarkLink,
              }}
            />
            { (article.href && article.linkLabel) && (<Link className="bold-link" to={article.href}>{article.linkLabel}</Link>) }
          </div>
        </div>
      </div>
    );
}
