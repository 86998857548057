/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route } from 'react-router-dom';

import DigitalDesignRenderer from './digitalDesignRenderer';

export default function HookUpDigitalDesign (root, crumbs, toc, fileName, title) {
    return (
      <Route
        exact
        path={`${root}/${fileName}`}
        render={routeProps => (
          <DigitalDesignRenderer
            root={root}
            crumbs={crumbs}
            toc={toc}
            fileName={fileName}
            title={title}
            {...routeProps}
          />
        )}
      />
    );
}
