/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Retirement from '../pages/retirement/retirement1';
import BRS from '../pages/retirement/brs1';
import Oops404Page from '../pages/oops-404';
import HookUpArticle from '../components/hookUpArticle';


export default function RetirementRoutes(props) {
    const root = props.match.path;
    const crumbs = `${props.crumbs},Retirement|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <Retirement {...routerProps} crumbs={crumbs} toc={props.toc} />}
        />

        <Route
          exact
          path={`${root}/BlendedRetirementSystem`}
          render={(routerProps) => <BRS {...routerProps} crumbs={crumbs} toc={props.toc} />}
        />

        {[
            HookUpArticle(root, crumbs, props.toc, 'RetirementIncomeFactorsDC.md', 'Benefits', 'Factors That Impact Your Retirement Income: Dependent Care'),
        ]}

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

