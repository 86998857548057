/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import settings from '../settings';
const path = require('path');

export default class Img extends React.Component {
    render() {
        let root = settings.root;

        if (root === '/') { root = ''; }
        let src = root + this.props.src;
        const small = root + this.props.src.replace('img/', 'img/build/') + '.sm.jpg';
        const med = root + this.props.src.replace('img/', 'img/build/') + '.md.jpg';
        const large = root + this.props.src.replace('img/', 'img/build/') + '.lg.jpg';
        // Default large;

        if (src.indexOf('api/') === -1) {
            if (this.props.large) src = large;
            if (this.props.med) src = med;
            if (this.props.small) src = small;
        }

        return (
          <img {...this.props} src={src} />
        );
    }
}

