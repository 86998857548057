/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import HookUpArticle from '../../components/hookUpArticle';
import SplTOC from '../../components/tocs/splTOC';
import UscgResource from '../../pages/uscg-resources/uscg-resource';
import Overview from '../../pages/uscg-resources/overview';
import FirstDutyStation from '../../pages/uscg-resources/first-duty-station';
import EachPCS from '../../pages/uscg-resources/each-pcs';
import Promotion from '../../pages/uscg-resources/promotion';
import VestingInTSP from '../../pages/uscg-resources/vesting-in-tsp';
import ContinuationPay from '../../pages/uscg-resources/continuation-pay';
import Marriage from '../../pages/uscg-resources/marriage';
import FirstChild from '../../pages/uscg-resources/first-child';
import Divorce from '../../pages/uscg-resources/divorce';
import DisablingCondition from '../../pages/uscg-resources/disabling-condition';
import LeadershipTraining from '../../pages/uscg-resources/leadership-training';
import PreDeployment from '../../pages/uscg-resources/pre-deployment';
import PostDeployment from '../../pages/uscg-resources/post-deployment';
import Handouts from '../../pages/uscg-resources/handouts';
import AdditionalResourceRoutes from './additional-resources';
import Oops404Page from '../../pages/oops-404';

import videos from '../../pages/uscg-resources/videos.json';

function MultiRoute (props) {
    return (
      <Route
        path={`${props.path}`}
        render={(routeProps) => (
          <Switch>
            <Route
              exact
              path={`${routeProps.match.path}`}
              render={(rps) => React.cloneElement(props.component, { ...rps, crumbs: props.crumbs, category: props.category, toc: props.toc })}
            />

            <Route
              path={`${routeProps.match.path}/video`}
              render={(rps) => (
                <Switch>
                  {
                      videos.filter(v => v.category === props.category).map(v => (
                          HookUpSiteVideo(
                              rps.match.path, v.resourceLabel, v.text, `${props.crumbs},${props.title}|${routeProps.match.path}`,
                              v.urlNormal, v.url508, props.image,
                          )
                      ))
                  }
                </Switch>
              )}
            />

            <Route
              exact
              path={`${routeProps.match.path}/Handouts`}
              render={(rps) => <Handouts {...rps} crumbs={`${props.crumbs},${props.title}|${routeProps.match.path}`} toc={props.toc} />}
            />
            <Route render={() => <Redirect to={`${routeProps.match.path}`} />} />
          </Switch>
        )}
      />
    );
}

export default function UscgResourceRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Coast Guard Resources|${root}` : `Coast Guard Resources|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routeProps) => <UscgResource {...routeProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route
          path={`${root}/Overview`}
          render={(routeProps) => (
            <Switch>
              <Route
                exact
                path={`${routeProps.match.path}`}
                render={(rps) => <Overview {...rps} root={root} crumbs={crumbs} toc={toc()} />}
              />

              {[
                  HookUpArticle(routeProps.match.path, `${crumbs},Overview|${routeProps.match.path}`, toc(), 'CFSProgramOverview.md', 'USCG', 'CFS Program Overview'),
              ]}

              <Route render={() => <Redirect to={`${routeProps.match.path}`} />} />
            </Switch>
          )}
        />

        <MultiRoute
          component={<FirstDutyStation />}
          path={`${root}/FirstDutyStation`}
          title="First Duty Station"
          category="FirstDutyStation"
          image="/assets/img/FINRED-WebsiteImage-USCGFirstDutyStation-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<EachPCS />}
          path={`${root}/EachPCS`}
          title="Each PCS"
          crumbs={crumbs}
          category="EachPCS"
          image="/assets/img/FINRED-WebsiteImage-USCGEachPCS-R.jpg"
          toc={toc()}
        />

        <MultiRoute
          component={<Promotion />}
          path={`${root}/Promotion`}
          title="Promotion"
          category="Promotion"
          image="/assets/img/FINRED-WebsiteImage-USCGPromotion-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<VestingInTSP />}
          path={`${root}/VestingInTSP`}
          title="Vesting in TSP"
          category="VestingInTSP"
          image="/assets/img/FINRED-WebsiteImage-USCGVestingTSP-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<ContinuationPay />}
          path={`${root}/ContinuationPay`}
          title="Continuation Pay"
          category="ContinuationPay"
          image="/assets/img/FINRED-WebsiteImage-USCGContinuationPay-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<Marriage />}
          path={`${root}/Marriage`}
          title="Marriage"
          category="Marriage"
          image="/assets/img/FINRED-WebsiteImage-USCGMarriage-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<FirstChild />}
          path={`${root}/FirstChild`}
          title="First Child"
          category="FirstChild"
          image="/assets/img/FINRED-WebsiteImage-USCGFirstChild-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<Divorce />}
          path={`${root}/Divorce`}
          title="Financial Implications of Divorce"
          category="Divorce"
          image="/assets/img/FINRED-WebsiteImage-USCGDivorce-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<DisablingCondition />}
          path={`${root}/DisablingCondition`}
          title="Disabling Condition"
          category="DisablingCondition"
          image="/assets/img/FINRED-WebsiteImage-USCGDisablingCondition-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<LeadershipTraining />}
          path={`${root}/LeadershipTraining`}
          title="Leadership Training"
          category="LeadershipTraining"
          image="/assets/img/FINRED-WebsiteImage-USCGLeadershipTraining-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<PreDeployment />}
          path={`${root}/PreDeployment`}
          title="Pre-Deployment"
          category="PreDeployment"
          image="/assets/img/FINRED-WebsiteImage-USCGPreDeployment-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <MultiRoute
          component={<PostDeployment />}
          path={`${root}/PostDeployment`}
          title="Post-Deployment"
          category="PostDeployment"
          image="/assets/img/FINRED-WebsiteImage-USCGPostDeployment-R.jpg"
          crumbs={crumbs}
          toc={toc()}
        />

        <Route
          path={`${root}/AdditionalResources`}
          render={(routeProps) => <AdditionalResourceRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
