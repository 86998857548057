/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './page';
import Markdown from './markdown';
import api from '../api';
import Link from './link';

class ArticleRenderer extends React.Component {
    constructor() {
        super();
        this.state = { markdown: '', draft: null };
    }

    componentWillMount() {
        if (this.props.file) {
            this.title = this.props.title.replace(',', '');
            // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
            fetch(this.props.file)
                .then(res => res.text())
                .then(text => this.setState({ markdown: text, ...this.props }));
        } else if (this.props._id) {
            api.get('/posts/' + this.props._id).then((res) => {
                let markdown = '### ' + res.data.title + '\n';
                markdown += '![](' + res.data.thumbnail + ')\n\n';
                markdown += res.data.body;
                this.setState({ markdown, ...res.data });
            });
        }
    }

    delete() {
        api.delete('/posts/' + this.props._id).then((res) => {
            this.props.history.push('/manage/articles/');
        });
    }

    renderEdit() {
        if (this.props.session.loggedIn && this.props._id) {
            return (
              <>
                <Link to={'/articles/' + this.props._id + '/edit'}>
                  <div className="btn btn-primary btn-raised">Edit Article</div>
                </Link>
                <div onClick={() => this.delete()} className="btn btn-primary btn-raised">Delete Article</div>
                {this.state.draft ? <small>Draft Available</small> : null}
                <Link to="/manage/articles/"><div className="btn btn-primary btn-raised">Manage Articles</div></Link>
              </>
            );
        }
    }

    render() {
        let toc = this.props.toc;
        let crumbs = (this.props.crumbs ? `${this.props.crumbs},` : '') + `${this.title}|${this.state.filename}`;

        return (
          <Page
            crumbs={`${crumbs}` || 'Articles|'}
          >
            <div className="usa-grid no-left-pad no-right-pad">
              <div className="usa-width-one-fourth TOC">
                {toc }
              </div>
              <div className="usa-width-three-fourths blogmarkdown">

                <div className={`blogpost ${this.props.category}`}>
                  <Markdown source={this.state.markdown} />
                  {this.props.children}
                </div>
                {this.renderEdit()}
              </div>
            </div>
          </Page>
        );
    }
}


const mapStateToProps = state => ({
    session: state.session,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ArticleRenderer));


