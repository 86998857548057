/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Link from './link';
import Img from './img';

const getCornerBadge = type => {
    switch (type) {
    case 'Under Construction':
        return { img: '/assets/img/under-construction.png', alt: 'Under construction' };
    case 'Coming Soon':
        return { img: '/assets/img/coming-soon.png', alt: 'Comming Soon' };
    default:
        return { img: '/assets/img/under-construction.png', alt: 'Under construction' };
    }
};

export default function FeaturedAsset(props) {
    const splitLinkLabel = props.link && props.link.split('|');
    const splitHref = props.href && props.href.split('|');

    const bodyPaddingBottom = splitLinkLabel ? 50 * splitLinkLabel.length : 50;
    const buttonClass = (splitLinkLabel && splitLinkLabel.length > 1) ? 'multiple-button' : '';

    return (
      <div className="usa-width-one-third featured-asset">
        <div className="featured-article">
          <div className="small-image">
            <Img med src={props.img} alt={props.alt || ' '} title={props.alt || ' '} />
          </div>
          <div className="large-image">
            <Img large src={props.img} alt={props.alt || ' '} title={props.alt || ' '} />
          </div>
          {props.cornerBadge && (
          <div className="new-corner-badge" style={{ position: 'absolute', left: '0', top: '0' }}>
            <img src={`${getCornerBadge(props.cornerBadge).img}`} alt={`${getCornerBadge(props.cornerBadge).alt}`} />
          </div>
          )}

          <div className="featured-asset-body" style={{ paddingBottom: `${bodyPaddingBottom}px` }}>
            <h4>{props.title}</h4>
            <br />
            {props.children}
          </div>
        </div>
        <div className="featured-asset-bottom">
          {(splitHref && splitLinkLabel) && (
              splitLinkLabel.map((linkLabel, index) => (
                <Link className={`usa-button ${buttonClass}`} to={splitHref[index]}>
                  {' '}

                  {linkLabel || 'Download'}{' '}
                </Link>
              )))
          }
          {
            props.childComponent ? props.childComponent : ''
          }
        </div>
      </div>
    );
}
