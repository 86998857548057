/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function CoastGuardMember (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Coast Guard Member`}
        toc={props.toc}
        articleClass="subpage uscg"
      >
        <ReactMarkdown
          source={`
## Coast Guard Member
This collection of resources and guidance can provide additional
support in your work as a PFM or CFS. Use the following bullets to
instruct, inform and engage Coast Guard members.

* [CGMA | The official relief society of the U.S. Coast Guard](https://cgmahq.org/)
* [CG SUPRT](https://www.cgsuprt.com/portal/landing)
* [Consumer Financial Protection
    Bureau](https://www.consumerfinance.gov)
* [FINRA BrokerCheck](https://brokercheck.finra.org/)
    BrokerCheck is a free tool to research the background and
    experience of financial brokers, advisers and firms.
* [How To Use Touchpoint Materials](./../Overview)
* [IdentityTheft.gov](https://www.identitytheft.gov/)
* [IRS.gov](https://www.irs.gov/)
* [Military Saves](https://militarysaves.org/)
* [MilSpouse Money Mission](https://www.milspousemoneymission.org/)
* [Office of Financial Readiness](/)
* [Commandant Instruction 1740.8A: Coast Guard Personal Financial
    Management
    Program](https://urldefense.proofpoint.com/v2/url?u=https-3A__media.defense.gov_2020_Dec_21_2002555275_-2D1_-2D1_0_CI-5F1740-5F8A.PDF&d=DwMFAg&c=tnPw9yRHa20_HV5YVoVFtg&r=n978TPPMJ_Rc4eMZSJEgGFnidCOR3pKPEA3SmqofwkM&m=aDSOKGGPPAClVpkL1FJvg2-P5s_W95DnjTPBRTf1LI0&s=lJkriLyUYD0RINp01V2TXGUqTPSC_lxuA0g3TlMrols&e=)
* [PowerPay](https://extension.usu.edu/powerpay/index)
* Ways To Complete Touchpoint Training  
    There are three different approaches for completing the Coast
    Guard Touchpoint Training:
    * One-on-one appointments via Touchpoint Checklists and Handouts
    * Classroom instruction
    * Distance learning — USCG e-learning site  
        At the completion of Touchpoint Training, the Coast Guard member
        will provide verification of completed training by using the
        Coast Guard LMS self-certify option. Member will be directed to
        input date of completion and counselor name into the Coast Guard
        e-learning site: **<https://elearning.uscg.mil/>**.
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


