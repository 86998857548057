/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Link from './link';
import Img from './img';
import api from '../api';
import Markdown from './markdown';
class Post extends React.Component {
    async setCollaboratorCorner() {
        let homepage = await api.get('/homepage');
        homepage = homepage.data;
        homepage.published.collaboratorCorner.img = this.props.img;
        homepage.published.collaboratorCorner.body = this.props.pullquote;
        homepage.published.collaboratorCorner.href = '/articles/' + this.props.href || '/';
        homepage.published.collaboratorCorner.link = 'Read More';
        await api.post('/homepage', homepage);
        this.props.history.push('/');
    }

    delete() {
        console.log(this.props);
        api.delete('/posts/' + this.props.href).then((res) => {
            this.props.parent.load();
        });
    }

    render() {
        return (
          <>

            <div className="blogpost postAbstract">
              <Img src={this.props.img || '/assets/img/Missing-image-232x150.png'} />
              <div className="blogpost-details">
                <div className="blogpost-title">{this.props.title || '-No Title Set-'}</div>
                {/* <div className="blogpost-date">
            Date: {this.props.date}
            </div> */}
                <div className="blogpost-author">
            Author Name:
                  {' '}
                  {this.props.author}
                </div>
                <div className="blogpost-author">
            Category:
                  {' '}
                  {this.props.category}
                </div>
                <div className="blogpost-pullquote">
                  <p>

                    <Markdown source={this.props.pullquote || '-No abstract set-'} />

                    <div style={{ position: 'absolute', bottom: '2em' }}>

                      <div onClick={() => this.setCollaboratorCorner()} className="btn btn-raised btn-primary">
                        Set Collaborator Corner
                      </div>
                      <div onClick={() => this.delete()} className="btn btn-raised btn-danger">
                        Delete
                      </div>
                      <Link to={'/articles/' + this.props.href || '/'}> View  </Link>
                      <Link to={'/articles/' + this.props.href + '/edit'}> Edit </Link>
                    </div>

                  </p>
                </div>
              </div>
            </div>

          </>
        );
    }
}


function getHomepage(state) {
    if (state && state.homepage && state.homepage.editing) { return state.homepage.draft; }
    if (state && state.homepage && !state.homepage.editing) { return state.homepage.published; }
    return {};
}

const mapStateToProps = (state, ownProps) => ({
    ...state.blogs.docs[ownProps.i],
    homepage: getHomepage(state),
});

const mapDispatchToProps = dispatch => ({
    onLoadBlogs: (blogs) => dispatch({ type: 'blogsLoaded', blogs }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Post));

