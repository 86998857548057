/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function CommandFinancialSpecialists (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Command Financial Specialists`}
        toc={props.toc}
        articleClass="subpage uscg"
      >
        <ReactMarkdown
          source={`
## Command Financial Specialists
Follow these links to find resources and policy information to help
you in your work as a CFS, as well as important financial content from
government and military collaborators.

* [Coast Guard Personal Financial Manager Program
  Portal](https://cg.portal.uscg.mil/units/hswlsc/work-life_division/SitePages/PFMP.aspx)
* [CGMA | The official relief society of the U.S. Coast Guard](https://cgmahq.org/)
* [CG SUPRT — Financial](https://www.cgsuprt.com/portal/landing)
* [Commandant Instruction 1740.8A: Coast Guard Personal Financial
  Management
  Program](https://urldefense.proofpoint.com/v2/url?u=https-3A__media.defense.gov_2020_Dec_21_2002555275_-2D1_-2D1_0_CI-5F1740-5F8A.PDF&d=DwMFAg&c=tnPw9yRHa20_HV5YVoVFtg&r=n978TPPMJ_Rc4eMZSJEgGFnidCOR3pKPEA3SmqofwkM&m=aDSOKGGPPAClVpkL1FJvg2-P5s_W95DnjTPBRTf1LI0&s=lJkriLyUYD0RINp01V2TXGUqTPSC_lxuA0g3TlMrols&e=)
* [Consumer Financial Protection
  Bureau](https://www.consumerfinance.gov/)
* [DMCS PFMP — Office of
  Work-Life](https://www.dcms.uscg.mil/Our-Organization/Assistant-Commandant-for-Human-Resources-CG-1/Health-Safety-and-Work-Life-CG-11/Office-of-Work-Life-CG-111/Personal-Financial-Management-Program-PFMP/)
* [Financial Industry Regulatory Authority, or
  FINRA](https://www.finra.org/)
* [IdentityTheft.gov](https://identitytheft.gov/)
* [IRS.gov](https://www.irs.gov/)
* [Military Saves](https://militarysaves.org/)
* [milSuite Coast Guard CFS
  Resources](https://www.milsuite.mil/book/groups/uscg-cfs-resources)
  Please contact your local Coast Guard PFM if you are unable to
  access the CFS milSuite site.
* [Office of Financial Readiness](/)
* [PowerPay](https://extension.usu.edu/powerpay/index)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


