/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/nonTOCPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';

export default function PFCSeminar (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        articleClass="launch-page mos"
      >
        <ReactMarkdown
          source={`
## PFC Program and Me Live Seminar

* [The PFC Program and Me Presentation](/assets/downloads/FINRED-ThePFCProgramandMe-April2024.pdf)
* [The PFC Program and Me Recording](${root}/video/PFCAndMe2024Webinar)
* [The PFC Program and Me Transcript](/assets/downloads/FINRED-PFCAndMe2024Webinar-7May2024-SC-ForGov.docx)
* [The PFC Program and Me Questions and Answers](/assets/downloads/FINRED-ThePFCProgramMe-QA-7May2024-SC-ForGov.pdf)
* [Service Point of Contact List](/assets/downloads/FINRED-ServicePOCList.pdf)
* [Personal Financial Counselor Program Guide](/assets/downloads/FINRED-PFC-G.pdf)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
