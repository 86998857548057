/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Img from './img';
import PopOutMarkLink from './popOutMarkLink';

const ReactMarkdown = require('react-markdown/with-html');

export default function RftrIconTag({ iconImg, activeIconImg, tagText, abstractText, tagColor, top, left, onIconClick, zIndexOrder }) {
    const [iconHovered, setIconHovered] = useState(false);
    const [tagHovered, setTagHovered] = useState(false);
    const [iconExpanded, setIconExpanded] = useState(false);

    const handleIconClick = () => {
        onIconClick(tagText);
        setIconExpanded(!iconExpanded);
    };

    const handleIconHovered = (isHovered) => {
        setIconHovered(isHovered);
        setTagHovered(isHovered);
    };

    const handleAbstractClick = () => {
        onIconClick(tagText, true);
    };

    const getzIndex = () => {
        const index = zIndexOrder.indexOf(tagText);
        return index > -1 ? `${4 + index}` : '3';
    };

    return (
      <div
        className="rftr-icon-container"
        style={{ top: top, left: left, zIndex: getzIndex() }}
      >
        <div
          className="rftr-icon-tag-container"
        >
          <div
            className="rftr-icon"
            onMouseEnter={() => handleIconHovered(true)}
            onMouseLeave={() => handleIconHovered(false)}
            onClick={handleIconClick}
          >
            { iconHovered || iconExpanded
                ? <Img src={activeIconImg} className="rftr-active-icon" />
                : <Img src={iconImg} className="rftr-inactive-icon" />
              }
          </div>
          <div
            className="rftr-tag"
            style={{ backgroundColor: tagHovered || iconExpanded ? '#6D737B' : tagColor }}
            onMouseEnter={() => handleIconHovered(true)}
            onMouseLeave={() => handleIconHovered(false)}
            onClick={handleIconClick}
          >
            <h4 style={{ color: tagColor === '#F99F1E' && !(tagHovered || iconExpanded) ? '#000' : '#fff' }}>{tagText}</h4>
          </div>
          { iconExpanded && (
            <div className="rftr-tag-abstract-container" onClick={handleAbstractClick}>
              <div className="rftr-tag-abstract">
                <ReactMarkdown source={abstractText} renderers={{ link: PopOutMarkLink }} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
}
