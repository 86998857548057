/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { renderers } from 'react-markdown';
import { connect } from 'react-redux';
import Markdown from '../markdown';
import InlineMarkdownEditor from './inlineMarkdownEditor';
class MarkSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    renderEditButtons() {
        const buttons = [];
        if (this.props.editing) {
            buttons.push(<span className="fa fa-edit" onClick={() => this.props.onStartEditSection(this.props.i)} />);
            if (!this.props.sectionEditing) {
                buttons.push(<span className="fa fa-close" onClick={() => this.props.onRemoveSection(this.props.i)} />);
            }
        }


        if (this.props.editing || this.props.sectionEditing) {
            return (<>
              <div className="sectionEdit">{buttons}

              </div>
              <div className="clearfix" />
            </>
            );
        }
    }

    render() {
        if (this.props.sectionEditing) {
            return <>
              <InlineMarkdownEditor onSave={(newText) => this.props.onSectionEdited('markdown', this.props.i, newText)} value={this.props.markdown} onCancel={() => this.props.onEndEditSection(this.props.i)} />
            </>;
        }
        if (this.props.editing
            && !this.props.sectionEditing) {
            return <>

                  { this.renderEditButtons() }
                  <Markdown source={this.props.markdown} /> </>;
        }
        return <Markdown source={this.props.markdown} />;
    }
}


const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = dispatch => ({
    onStartEditSection: (i) => dispatch({ type: 'startSectionEdit', i }),
    onEndEditSection: (i) => dispatch({ type: 'endSectionEdit', i }),
    onSectionEdited: (sectionType, index, payload) => dispatch({ type: 'sectionEdited', sectionType, index, payload }),
    onRemoveSection: (i) => dispatch({ type: 'sectionRemoved', i }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarkSection);

