/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';

import ReactMarkdown from '../../components/markdown';

export default function Service ({ crumbs, root, toc }) {
    return (
      <Page
        crumbs={`${crumbs},Service Specific Resources`}
        toc={toc}
      >

        <ReactMarkdown
          source={`
## Service Specific Resources
Each service has unique policies and resources. Find your branch below and view some of the resources available to soldiers, Marines, sailors, airmen and guardsmen. 

### Army
- [Army Emergency Relief](https://www.armyemergencyrelief.org/)
  Request an interest-free loan, grant or scholarship from Army Emergency Relief if you are a solder, retired soldier or family member in need — or donate.
- [MyArmyBenefits ](https://myarmybenefits.us.army.mil/)
  Visit the Army’s official benefits website to browse a library of more than 150 benefits, explore eligibility, calculate your retirement pay and more.

### Marine Corps
- [Marine Corps Benefits](https://www.hqmc.marines.mil/hrom/Benefits/)
  Learn about benefits that civilian Navy and Marine employees receive, including health, dental, Flexible Spending Accounts and retirement plans.
- [Marine Online](https://sso.tfs.usmc.mil/sso/Register.do)
  Create a Marine Online account and view information on unit training, pay and compensation, and resources for personal and professional development.

### Navy
- [Navy-Marine Corps Relief Society](http://www.nmcrs.org/)
  View the services that the Navy-Marine Corps Relief Society offers and learn how you can receive assistance or help others through donations.
- [Navy Personnel Center](https://www.mynavyhr.navy.mil/Navy-Personnel-Command/)
  Stop by this one-stop shop for information, resources and links that detail the fundamental components of Navy pay and benefits.

### Air Force
- [Air Force Aid Society](https://afas.org/)
  Apply for a no-interest loan or grant from Air Force Aid Society if you are an Airman or family member facing an unexpected financial crisis.
- [Air Force Personnel Center](https://www.afpc.af.mil/)
  Explore a wealth of information about military benefits and entitlements for Air Force members, retirees and civilian employees.

### Coast Guard
- [Coast Guard Benefits](https://www.gocoastguard.com/why-join/pay-benefits)
  Examine Coast Guard Reserve officer pay and benefits, including medical and dental coverage while drilling and access to education and retirement benefits.
- [Coast Guard Mutual Assistance](http://www.cgmahq.org/)
  Find out about the variety of assistance programs that Coast Guard Mutual Assistance offers, including emergency, financial, education and adoption.
          `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


