/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function SurvivorBenefitPlan (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Survivor Benefit Plan`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Survivor Benefit Plan
![The Survivor Benefit Plan logo](/assets/img/FINRED-WebsiteImage-SBP-R.jpg "The Survivor Benefit Plan logo")  

Your retirement from the military raises many financial considerations. Planning ahead is an important first step in the process. The Survivor Benefit Plan, or SBP, can help ensure your loved ones are protected should the unforeseeable happen. SBP pays a monthly benefit to designated survivors of an eligible service member or retiree ensuring their designated beneficiaries continue to receive a partially, and in some cases fully, government-subsidized annuity in place of their retired pay after their own death. SBP offers peace of mind to currently serving members, retirees and their families by ensuring ongoing financial stability. Learn more about the Survivor Benefit Plan here with these available resources. For additional information, please visit the Department of Defense's [Military Pay and Benefits page](https://militarypay.defense.gov/Benefits/Survivor-Benefit-Program/).

*Please note: Military Personnel Policy created, owns and maintains the resources on this page. Please direct any questions to the [Military Compensation Policy office](mailto:osd.pentagon.ousd-p-r.mbx.osd-military-personnel--policy@mail.mil).*
            `}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SBP101-V.jpg"
            alt="Protects military retired pay for eligible beneficiaries."
            title="What is the Survivor Benefit Plan?"
            href={`${root}/video/What-is-the-Survivor-Benefit-Plan`}
            link="Watch Now"
          >
            <p>
            Imagine if your military retired pay suddenly stopped today. This video shares how the SBP can protect your pay for eligible beneficiaries after your death.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SBP102-V.jpg"
            alt="Overview of changes - SBP-DIC Offset."
            title="Overview of Changes Coming to the Survivor Benefit Plan"
            href={`${root}/video/Overview-of-Changes-Coming-to-the-Survivor-Benefit-Plan`}
            link="Watch Now"
          >
            <p>
            If you’re a surviving spouse who qualifies for both the SBP and Dependency and Indemnity Compensation, learn about upcoming changes to the SBP-DIC offset.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SBP103-V.jpg"
            alt="Survivor Benefit Plan video 103 thumbnail"
            title="Elimination of the SBP-DIC Offset"
            href={`${root}/video/Elimination-of-the-SBP-DIC-Offset`}
            link="Watch Now"
          >
            <p>
            Learn how the phased elimination of the SBP-DIC offset, beginning in February 2021, will affect your SBP and Dependency and Indemnity Compensation.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
