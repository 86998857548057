/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../components/splPage';
import MarkLink from '../../components/markLink';
import Link from '../../components/link';

import videos from './videos.json';

export default function EachPCS (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Each PCS`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Each PCS
Whether it's the first PCS or the fifth, each new move brings changes
and financial adjustments. While you may not be able to anticipate every
challenge service members will face, you can help them prepare as much
as possible. The tools and resources on this page offer tips and
strategies to do exactly that.

### Checklists

* [Each PCS Counselor Checklist](/assets/downloads/USCG%20TC%20Counselor%20Checklist%20-%20PCS.pdf)
* [Each PCS Member Checklist](/assets/downloads/USCG%20TC%20Member%20Checklist%20-%20PCS.pdf)

### Handouts

* [5 Rules of Buying a House](/assets/downloads/USCG%20TC%20Handout-5%20Rules%20of%20Buying%20a%20House.pdf)
* [Estimated Travel Costs for a PCS Move](/assets/downloads/USCG%20TC%20Handout-Estimated%20Travel%20Cost.pdf)
* [Major Purchases](/assets/downloads/USCG%20TC%20Handout-Major%20Purchases.pdf)
* [Military Consumer Protection](/assets/downloads/USCG%20TC%20Handout-Military%20Consumer%20Protection.pdf)
* [Servicemembers Civil Relief Act](/assets/downloads/USCG%20TC%20Handout-Servicemembers%20Civil%20Relief%20Act.pdf)
* [Sources of Help for Military Consumers](/assets/downloads/USCG%20TC%20Handout-Sources%20of%20Help%20for%20Military%20Consumers.pdf)
* [Spending Plan Worksheet](/assets/downloads/USCG%20TC%20Handout-Spending%20Plan%20Worksheet.pdf)
* [Understanding Credit](/assets/downloads/USCG%20TC%20Handout-Understanding%20Credit.pdf)

### PowerPoint Slides, Instructor Guide and CFS Reference Manual

* [PowerPoint Slides](/assets/downloads/TC%203%20PCS%20Presentation%20CG%206-14-21.pptx)
* [Instructor Guide](/assets/downloads/TC%203%20Permanent%20Change%20Of%20Station%20Instructor%20Guide%20CG%206-14-21.pdf)
* [CFS Reference Manual (Student Guide)](/assets/downloads/TC%203%20Permanent%20Change%20Of%20Station%20CFS%20Reference%20Manual%20CG%206-8-21.pdf)

### Video Discussion Guide

* [PCS](/assets/downloads/PCS%20Video%20Discussion%20Guide-4.pdf)

### Videos
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          {
              videos.filter(v => v.category === props.category).sort((a, b) => a.order - b.order).map(v => (
                <li>
                  <Link to={`${root}/video/${v.resourceLabel}`}>{v.text}</Link>
                </li>
              ))
          }
        </ul>
      </Page>
    );
}


