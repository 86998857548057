/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HowToVideos from '../pages/how-to-videos';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function HowToVideosRoutes(props) {
    const root = props.match.path;
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + `How To Videos|${root}`;

    return (
      <div>
        <Switch>
          <Route
            exact
            path={root}
            render={(routerProps) => <HowToVideos {...routerProps} crumbs={crumbs} toc={props.toc} />}
          />

          <Route
            path={`${root}/video`}
            render={(rps) => (
              <Switch>
                {[
                    HookUpSiteVideo(rps.match.path, 'Find-Your-Nearest-PFC-in-Less-Than-Three-Clicks', 'Find Your Nearest PFC in Less Than Three Clicks', crumbs, 'https://finred.usalearning.gov/assets/video/how-to/PFCMap-04Feb2022.mp4', null, '/assets/img/FINRED-WebsiteImage-PFCLocatorMap-V.jpg'),
                    HookUpSiteVideo(rps.match.path, 'Knowing-Your-Numbers-With-FINRED-Calculators', 'Knowing Your Numbers With FINRED Calculators', crumbs, 'https://finred.usalearning.gov/assets/video/how-to/CalculatorHowTo-22Feb2022-SA.mp4', null, '/assets/img/FINRED-WebsiteImage-CalculatorHowTo-V.jpg'),
                    HookUpSiteVideo(rps.match.path, 'Take-Financial-Sense-On-the-Go', 'Take Financial Sen$e On the Go', crumbs, 'https://finred.usalearning.gov/assets/video/how-to/FINRED-Video-SenseHowTo-Final.mp4', null, '/assets/img/FINRED-WebsiteImage-SenseHowTo-V.jpg'),
                    HookUpSiteVideo(rps.match.path, 'Inside-the-Financial-Well-Being-Assessment', 'Inside the Financial Well-Being Assessment', crumbs, 'https://finred.usalearning.gov/assets/video/how-to/FINRED-Video-FWBAHowTo-Final.mp4', null, '/assets/img/FINRED-WebsiteImage-FWBAHowTo-V.jpg'),
                    HookUpOopsPage(),
                ]}
              </Switch>
            )}
          />

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
