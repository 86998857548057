/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route } from 'react-router-dom';

import VideoPlayerWith508 from './video-player-with508';

export default function HookUpHostedVideo (root, videoName, urlNormal, url508, lightImg) {
    return (
      <Route
        exact
        path={`${root}/${videoName}`}
        render={(routeProps) => (
          <div className="lightbox-back">
            <div className="lightbox-fore hosted dark">
              <VideoPlayerWith508
                isStandalone
                urlNormal={urlNormal}
                url508={url508}
                lightImg={lightImg}
                {...routeProps}
              />
            </div>
          </div>
        )}
      />
    );
}

