/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../components/nonTOCPage';

import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';

export default function MosBrandedProducts (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        articleClass="launch-page mos"
      >
        <ReactMarkdown
          source={`
## Military OneSource Branded Products

### Booklet
* [Consumer Credit Guide for Members of the Armed Forces](${root}/Consumer-Credit-Guide-for-Members-of-the-Armed-Forces)

### Fact Sheets
* [An Introduction to Estate Planning](${root}/An-Introduction-to-Estate-Planning)
* [Fair Credit Reporting Act: Consumer Protection for Borrowing Money](${root}/Fair-Credit-Reporting-Act)
* [Fair Debt Collection Practices Act: Consumer Protection for Borrowing Money](${root}/Fair-Debt-Collection-Practices-Act)
* [Get to Know Your Servicemembers' Group Life Insurance Benefit](${root}/Get-to-Know-Your-Servicemembers-Group-Life-Insurance-Benefit)
* [Servicemembers Civil Relief Act](${root}/Servicemembers-Civil-Relief-Act)
* [The BRS Continuation Pay](${root}/The-BRS-Continuation-Pay)
* [The BRS Defined Benefit](${root}/The-BRS-Defined-Benefit)
* [The BRS Defined Contribution](${root}/The-BRS-Defined-Contribution)
* [The BRS Lump Sum](${root}/The-BRS-Lump-Sum)
* [Truth in Lending Act: Consumer Protection for Borrowing Money](${root}/Truth-in-Lending-Act)
* [Understanding the Public Service Loan Forgiveness Program](${root}/Understanding-the-Public-Service-Loan-Forgiveness-Program)
* [Zero Percent Student Loan Interest for Eligible Service Members](${root}/Zero-Percent-Student-Loan-Interest-for-Eligible-Service-Members)

### Flyers
* [Dine in Tonight: Feast on More Savings in Retirement](${root}/Dine-in-Tonight:-Feast-on-More-Savings-in-Retirement)
* [Find Support for Every MilLife Milestone](${root}/Find-Support-for-Every-MilLife-Milestone)
* [Invest in Yourself — Not the Coffee Shop](${root}/Invest-in-Yourself-Not-the-Coffee-Shop)
* [Walk the Red Carpet to Retirement](${root}/Walk-the-Red-Carpet-to-Retirement)

#### Infographics
* [A Road Map for Your Financial Future: Directions to Plan, Save and Prepare](${root}/A-Road-Map-for-Your-Financial-Future:-Directions-to-Plan-Save-and-Prepare)
* [A Road Map for Your Financial Future: Plan for Your Road Trip](${root}/A-Road-Map-for-Your-Financial-Future:-Plan-for-Your-Road-Trip)
* [A Road Map for Your Financial Future: Prepare Your Emergency Travel Kit](${root}/A-Road-Map-for-Your-Financial-Future:-Prepare-Your-Emergency-Travel-Kit)
* [A Road Map for Your Financial Future: Save Now for the Journey](${root}/A-Road-Map-for-Your-Financial-Future:-Save-Now-for-the-Journey)
* [Dealing With Debt Collectors: Know Your Rights](${root}/Dealing-With-Debt-Collectors:-Know-Your-Rights)
* [Grab a Bagful of Savings: Bring Your Lunch Twice a Week](${root}/Grab-a-Bagful-of-Savings:-Bring-Your-Lunch-Twice-a-Week)
* [Keep Your Hard-Earned Money Safe](${root}/Keep-Your-Hard-Earned-Money-Safe)
* [Learn Tips and Tricks for Managing Your Money](${root}/Learn-Tips-and-Tricks-for-Managing-Your-Money)
* [Let's Get Financially Fit!](${root}/Lets-Get-Financially-Fit)
* [Multilevel Marketing Business vs. Pyramid Scheme: Get the Facts](${root}/Multilevel-Marketing-Business-vs.-Pyramid-Scheme:-Get-the-Facts)
* [Shop Smart: Savvy Buying Tips for Military Families](${root}/Shop-Smart:-Savvy-Buying-Tips-for-Military-Families)

`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
