/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState, useEffect } from 'react';

const usePreferedColorScheme = () => {
    const [mode, setMode] = useState();

    useEffect(() => {
    // if perfers-color-scheme not support then return light theme
        if (window.matchMedia('(prefers-color-scheme: dark)').media === 'not all') {
            return 'light';
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setMode(e.matches ? 'dark' : 'light'));

        setMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => { });
        };
    });

    return mode;
};

export default usePreferedColorScheme;