
import Page from "../../components/page";

import React from 'react';

export default class Index extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {};
        this.state.rendered = "";
        this.format = this.format.bind(this);
    }
    render() {
        return (
            <Page crumbs={"Home|/,utils|resourceRenderer"}>

            <textarea style={{maxWidth:'100%'}} onChange={this.format}>

            </textarea>
            <textarea style={{maxWidth:'100%',fontFamily:"monospace",fontSize:"12px",whitespace:"pre"}} value={this.state.rendered}>

            </textarea>
                

            </Page>
        )
    }
    format(e)
    {
        let value = e.target.value;
        let rendered = "";
        let tabs =  value.split(/[\n\t]/);
        
        for(let i =0; i < tabs.length;i)
        {   
            rendered += 
`* [${tabs[i]}](${tabs[i+2]})
  ${tabs[i+1]}
`
            i+=3;
        }
        this.setState(() => {return {rendered : rendered}});
    }
}


