/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useReducer, useEffect, useRef } from 'react';


// 3rd party
import { ReactSVG } from 'react-svg';
import * as zipcodes from 'zipcodes';

import * as usaSates from './map-resources/usa-states.json';

// data files
import pfcLocations from './map-resources/pfc.json';
import {
    oconusLocations,
    getTextId,
    oconusText,
    conusText,
    getShapeId,
    oconusAutoSuggestions,
    getFullName,
    noLocations,
    NO_LOCATION_DATA,
} from './map-resources/helpers';

// custom components
import PFCPage from '../components/pfc-page';
import PFCMap from './map-resources/pfc-maps';
import Tab from './map-resources/Tab';
import TabButton from './map-resources/tab-button';
import SearchTab from './map-resources/serach-tab';

import { reducer, initialState } from './map-resources/reducer';
import Link from '../components/link';

const ErrorInfoBox = ({ info }) => (
  <div className="error-info-box">
    <p>{info}</p>
  </div>
);

export default function Map(props) {
    // const root = props.match.path;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { searchTerm, mapType, page, conusTabExpanded, oconusTabExpanded, error } = state;

    const conusMap = React.useRef(pfcLocations.filter(location => location.mapType.toLowerCase() === 'conus'));
    const oconusMap = React.useRef(pfcLocations.filter(location => location.mapType.toLowerCase() === 'oconus'));
    const oconusAutoSuggestionsStriped = oconusAutoSuggestions.map(item => ({ name: item.name.replace('_', ' ').replace('us_', '').replace('south_', '') }));

    // these refs are used to jump to conus or oconus maps
    const conusAnchor = useRef();
    const oconusAnchor = useRef();

    // conus look up.
    const conusLookUp = (state) => {
        const location = state.toLowerCase();
        return conusMap.current.filter(loc => (loc.abbreviation.toLowerCase() === location || loc.state.toLowerCase() === location || loc.zipcode === location));
    };
    // oconus look up
    const oconusLookUp = (country) => {
        let location = country.toLowerCase().replaceAll('_', ' ');
        location = location.replace('us ', '').replace('south ', '');
        return oconusMap.current.filter(loc => {
            if (loc.state.toLowerCase() === location || loc.country.toLowerCase() === location || loc.abbreviation.toLowerCase() === location) {
                return true;
            }
            return false;
        });
    };

    const searchLocation = (search, type) => {
        // console.log('searchLocation', search, type)
        const typeUpperCase = type.toUpperCase();

        if (search === NO_LOCATION_DATA) {
            dispatch({ type: 'ERROR', payload: `${typeUpperCase}_LOCATION_NOT_FOUND` });
            return;
        }
        if (type === 'conus' && !isNaN(search)) {
            // valid US zip?
            if (!/^\d{5}(-\d{4})?$/.test(search)) {
                dispatch({ type: 'ERROR', payload: `${typeUpperCase}_LOCATION_NOT_FOUND` });
                return;
            }

            // see if search term exist within pfc.json
            let foundZip = conusLookUp(search);

            // zip found in pfc.json!!!
            if (foundZip.length > 0) {
                dispatch({ type: 'SEARCH', payload: foundZip });
            } else { // zip not found in pfc.json so find the state associated with the zipcode
                const lookUpZip = zipcodes.lookup(search);
                if (lookUpZip) {
                    foundZip = conusLookUp(lookUpZip.state);
                    dispatch({ type: 'SET_MAP_TYPE', payload: type });
                    dispatch({ type: 'SEARCH', payload: foundZip });
                } else {
                    dispatch({ type: 'ERROR', payload: `${typeUpperCase}_LOCATION_NOT_FOUND` });
                }
            }
        } else {
            const found = type === 'conus' ? conusLookUp(search) : oconusLookUp(search);
            if (found.length > 0) {
                if (found.length === 1 && found[0].nomarkers) {
                    dispatch({ type: 'ERROR', payload: `${typeUpperCase}_LOCATION_NOT_FOUND` });
                } else {
                    dispatch({ type: 'ERROR', payload: null });
                }
                dispatch({ type: 'SET_MAP_TYPE', payload: type });
                dispatch({ type: 'SEARCH', payload: found });
            } else {
                // show location not found
                dispatch({ type: 'ERROR', payload: `${typeUpperCase}_LOCATION_NOT_FOUND` });
            }
        }
    };

    // scroll page back to the top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [searchTerm]);

    // scroll to map section
    useEffect(() => {
        if (state.mapHistory && state.page === 'search') {
            if (state.mapHistory === 'conus') {
                conusAnchor.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                oconusAnchor.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [state.page]);

    const addSvgAttribute = React.useCallback((svg) => {
        svg.setAttribute('aria-hidden', 'true');
        svg.setAttribute('role', 'group');
    });

    const handleSvgMapping = React.useCallback((mapType, error, svg) => {
        if (error) {
            console.error(error);
            return;
        }
        const whichMap = mapType === 'oconus' ? oconusLocations : usaSates.default;
        // add event listeners to map shapes
        whichMap.forEach(item => {
            const itemName = mapType === 'oconus' ? item.name.toLowerCase() : item.abbreviation.toLowerCase();
            const path = document.getElementById(itemName);
            path.addEventListener('click', (e) => {
                searchLocation(e.currentTarget.id, mapType);
            });
            // since "dc" is identified as a star shape and blue in color lets not add any rollover effects to it
            const highlightText = getTextId(itemName);

            path.addEventListener('mouseover', (e) => {
                if (highlightText) {
                    const txt = document.getElementById(highlightText);
                    txt.className.baseVal = 'map-text';
                }
                if (itemName !== 'dc') {
                    path.className.baseVal = 'map-location-over';
                }
            });
            path.addEventListener('mouseout', (e) => {
                if (highlightText) {
                    const txt = document.getElementById(highlightText);
                    txt.className.baseVal = 'cls-1';
                }
                if (itemName !== 'dc') {
                    path.className.baseVal = 'map-location';
                }
            });
        });
        // add event listeners to map text
        const mapText = mapType === 'oconus' ? oconusText : conusText;
        mapText.forEach(item => {
            const shapeId = getShapeId(item).toLowerCase();
            const shape = document.getElementById(shapeId);
            const group = document.getElementById(item).children[0];

            group.setAttribute('style', 'pointer-events: auto');

            group.addEventListener('click', (e) => {
                searchLocation(shapeId.replaceAll('_', ' '), mapType);
            });
            group.addEventListener('mouseover', (e) => {
                shape.className.baseVal = 'map-location-over';
                group.className.baseVal = 'cls-1 map-text';
            });
            group.addEventListener('mouseout', (e) => {
                shape.className.baseVal = shapeId === 'dc' ? 'Graphic-Style-4' : 'map-location';
                group.className.baseVal = 'cls-1';
            });
        });
    });

    const onHandleBackButton = (e) => {
        e.preventDefault();
        dispatch({ type: 'RESET' });
    };

    return (
      <PFCPage crumbs={props.crumbs}>
        {page === 'search'
        && (
          <>
            <section className="search-section-container">
              {/* <div className="search-content"> */}
              <p className="search-section-header">Find a Personal Financial Counselor</p>
              <div className="search-tabs-container">
                <SearchTab
                  tabHeaderText="CONUS"
                  tabBodyText="Search by state:"
                  onSearch={(search) => searchLocation(search, 'conus')}
                  onExpand={(payload) => dispatch({ type: 'CONUS_TAB_EXPANDED', payload })}
                  opened={oconusTabExpanded ? false : conusTabExpanded}
                  autosuggest={usaSates.default}
                  suggestAll={false}
                  classNames={['margin-right-sm']}
                />
                <SearchTab
                  tabHeaderText="OCONUS"
                  tabBodyText="Search by country:"
                  onSearch={(search) => searchLocation(search, 'oconus')}
                  onExpand={(payload) => dispatch({ type: 'OCONUS_TAB_EXPANDED', payload })}
                  opened={conusTabExpanded ? false : oconusTabExpanded}
                  autosuggest={oconusAutoSuggestionsStriped}
                  suggestAll
                  classNames={['overlap']}
                />
                {
                  (error && error === 'OCONUS_LOCATION_NOT_FOUND' && state.oconusTabExpanded)
                  && (
                    <ErrorInfoBox
                      info={<span>If your country is not listed, visit Military OneSource at https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/ to determine what no-cost financial counseling resources are available to you.</span>}
                    />
                  )
                }
              </div>
              {(!conusTabExpanded && !oconusTabExpanded)
                && (
                  <p className="find-pfc-text">
                    All active-duty, National Guard and reserve service members, their family members and survivors are eligible to receive no-cost financial counseling services. Services include support of mandatory training for services and individual and group counseling.
                  </p>
                )
              }
              <TabButton
                cta="View all results"
                linkTo={() => window.open('./PFCMapSpreadSheet', '_blank')}
                classNames={['view-all-results-button', 'view-all-results-position']}
              />
            </section>
            <div className="maps-container">
              <p className="map-title" ref={conusAnchor}>CONUS Map</p>
              <ReactSVG
                src="/assets/img/CONUS.svg"
                afterInjection={(error, svg) => handleSvgMapping('conus', error, svg)}
                beforeInjection={addSvgAttribute}
                renumerateIRIElements={false}
              />
              <div className="oconus-map-title" ref={oconusAnchor}>
                <p className="map-title">OCONUS Map</p>
              </div>
              <ReactSVG
                style={{ background: '#ECECEC' }}
                src="/assets/img/OCONUS.svg"
                afterInjection={(error, svg) => handleSvgMapping('oconus', error, svg)}
                beforeInjection={addSvgAttribute}
                renumerateIRIElements={false}
              />
              <div className="tab-container">
                <Tab
                  title="My country is not listed."
                  text={<span>If your country is not listed, visit Military OneSource at <Link to="https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/">https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/</Link> to determine what no-cost financial counseling resources are available to you.</span>}
                />
              </div>
              <div className="spacer" />
              <p className="map-text-reference">Maps are not to exact scale. Shown for general reference only.</p>
            </div>
          </>
        )
      }
        {
        (searchTerm && page === 'result')
        && (
          <section className="search-result-container">
            <div className="back-arrow-container" onClick={onHandleBackButton}>
              <div className="arrow pfc-left" />
              <p>Back to map</p>
            </div>
            <h3>{`${!state.error ? 'Search' : 'No'} Results for “${searchTerm[0].state}”`}</h3>
            <PFCMap location={searchTerm} noLocationFound={state.error} />
            <div className="new-search-container">
              <p style={{ fontFamily: 'Merriweather', fontWeight: 'bold', fontSize: '20px' }}>New Search:</p>
              <SearchTab
                tabHeaderText="CONUS"
                tabBodyText="Search by state:"
                onSearch={(search) => searchLocation(search, 'conus')}
                onExpand={(payload) => dispatch({ type: 'CONUS_TAB_EXPANDED', payload })}
                opened={oconusTabExpanded ? false : conusTabExpanded}
                autosuggest={usaSates.default}
                suggestAll={false}
                classNames={
                  conusTabExpanded ? ['margin-right-sm', 'tab-padding', 'overlap'] : ['margin-right-sm', 'tab-padding']
                }
              />
              <SearchTab
                tabHeaderText="OCONUS"
                tabBodyText="Search by country:"
                onSearch={(search) => searchLocation(search, 'oconus')}
                onExpand={(payload) => dispatch({ type: 'OCONUS_TAB_EXPANDED', payload })}
                opened={conusTabExpanded ? false : oconusTabExpanded}
                autosuggest={oconusAutoSuggestionsStriped}
                suggestAll
                classNames={oconusTabExpanded ? ['overlap', 'tab-padding'] : ['tab-padding']}
              />
              <TabButton
                cta="View all results"
                linkTo={() => window.open('./PFCMapSpreadSheet', '_blank')}
                classNames={['view-all-results-button', 'view-all-result']}
              />
              {
                  (error && error === 'OCONUS_LOCATION_NOT_FOUND')
                  && (
                    <ErrorInfoBox
                      info={<span>If your country is not listed, visit Military OneSource at https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/ to determine what no-cost financial counseling resources are available to you.</span>}
                    />
                  )
              }
            </div>
            <div className="results-container">
              <h2>{`${!state.error ? 'Search' : 'No'} Results for “${searchTerm[0].state}”`}</h2>
              <p className="results-p">
                {
                  mapType === 'conus'
                      ? <span>Are you eligible? All active-duty, National Guard and reserve service members, their family members and survivors are eligible to receive no-cost financial counseling services. You are welcome to work with any PFC, regardless of the service branch affiliation listed.</span>
                      : <span>If your country is not listed, visit Military OneSource at <Link to="https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/">https://www.militaryonesource.mil/confidential-help/interactive-tools-services/financial-counseling/</Link> to determine what no-cost financial counseling resources are available to you.</span>
                }
              </p>
              { !state.error
                  && searchTerm.sort((a, b) => ((a.city > b.city) ? 1 : -1)).map((location, index) => (
                    <div className="result-list-item" key={index + location.phone}>
                      <p className="marker-header">{location.city}</p>
                      <ul className="results-list">
                        <li>{location.state}</li>
                        <li><a href={`mailto:${location.email}`}>{location.email}</a></li>
                        <li>{location.phone}</li>
                        <li>{getFullName(location.BOS)}</li>
                      </ul>
                      { index < searchTerm.length - 1 && <hr className="result-divider" />}
                    </div>
                  ))
              }
            </div>
          </section>
        )
      }
      </PFCPage>
    );
}
