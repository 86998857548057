/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function DisabilityRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Disability|${root}` : `Disability|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/disability/20-101 - Video 01 - [Facilitator] - Disabling Sickness or Condition Overview_v4 GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlansandEmergencyFunds', 'Spending Plans and Emergency Funds', crumbs,
                      '/assets/video/navy-resource/disability/20-102 - Video 02 - [CONTENT] – Spending Plan and Emergency Funds_v6 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ChangestoYourLifestyle', 'Changes to Your Lifestyle', crumbs,
                      '/assets/video/navy-resource/disability/20-103 - Video 04 - [Facilitator] – Changes to Your Lifestyle_v3 GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HousingandTransportation', 'Housing and Transportation', crumbs,
                      '/assets/video/navy-resource/disability/20-104 - Video 05 – [Content] - Housing and Transportation Needs_v6 GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EducationBenefits', 'Education Benefits', crumbs,
                      '/assets/video/navy-resource/disability/20-105 - Video 06 – [Content] – Education Benefits_v5 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CaringforAgingLovedOnes', 'Caring for Aging Loved Ones', crumbs,
                      '/assets/video/navy-resource/disability/20-106 - Video 07 – [Content] - Caring For Aging Loved Ones_v5 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PlanningfortheFuture', 'Planning for the Future', crumbs,
                      '/assets/video/navy-resource/disability/20-107 - Video 08 - [Facilitator] – TranstoInsuranceandPlanningfortheFuture_v3GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      '/assets/video/navy-resource/disability/20-109_-_video_10_–_[content]_–_life_insurance_v6_navy_1 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HealthInsurance', 'Health Insurance', crumbs,
                      '/assets/video/navy-resource/disability/20-108_-_video_09_-_[content]_-_health_insurance_v7_navy (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Taxes', 'Taxes', crumbs,
                      '/assets/video/navy-resource/disability/20-110 - Video 03 - [Content] - Taxes_v4 GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningandSurvivorBenefits', 'Estate Planning and Survivor Benefits', crumbs,
                      '/assets/video/navy-resource/disability/20-111 - Video 11 – [Content] – Estate Planning and Survivor Benefits_v4 GEN.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'Retirement', 'Retirement', crumbs,
                      '/assets/video/navy-resource/disability/20-112 - Video 12 – [Content] – Retirement Overview_v4 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/disability/20-113 - Video 13 - [Facilitator] - Final Thoughts_v6 NAVY.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-Disability-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
