/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import ContentBody from './contentBody';

export default function T1Content ({ content }) {
    return (
      <div className="dd-t1-content">
        {
            content.imageOrientation === 'right' && (
            <div className="content">
              <b>{content.content.header.text}</b>
              <ContentBody body={content.content.body} />
            </div>
            )
        }
        <div className="dd-image">
          <img src={content.image.src} alt={content.image.alt} />
        </div>
        {
            content.imageOrientation === 'left' && (
            <div className="content">
              <b>{content.content.header.text}</b>
              <ContentBody body={content.content.body} />
            </div>
            )
        }
      </div>
    );
}
