/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import Link from './link';
import Img from './img';
import MediaBlock from './mediaBlock';
import GraphicList from './graphicList';
import Section from './section';
import Event from './event';
import api from '../api';
import settings from '../settings';

export default class Events extends React.Component {
    constructor(props) {
        super(props);
    }



    renderEvents() {
        const events = [];
        for (const i in [0, 1, 2, 3]) {
            events.push(
              <Event i={i} />,
            );
        }
        return events;
    }

    render() {
        return (
          <Section heading="" dark noTop>
            <GraphicList>
              {this.renderEvents()}
            </GraphicList>
          </Section>
        );
    }
}

