/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
const { produce } = require('immer');

const objectPath = require('object-path');

const defaultMarkdownSection = {
    type: 'Markdown',
    key: '0',
    markdown: `
## Knowing Your Benefits and Entitlements
As a military member, you receive a wide range of benefits and entitlements. Make sure you understand and take full advantage of all of them. They can include housing, moving, health care, education and pension. Talk to a personal financial manager, personal financial counselor or leader if you have questions about your benefits.
`,
};
const defaultFeaturedAssetsSection = {
    type: 'FeaturedAssets',
    key: '1',
    assets: [
        {
            img: '/assets/img/FINRED-WebsiteImage-BRS-BK.jpg',
            title: 'Uniformed Services Blended Retirement System',
            href: '/assets/downloads/FINRED-BRS-BK-5.5x8.5.pdf',
            type: 'Booklet',
            link: 'Download',
            body: ` Explore all aspects of the Uniformed Services Blended Retirement
        System, from eligibility to benefits to resources, in this handy,
        comprehensive guide.`,
        },
        {
            img: '/assets/img/FINRED-WebsiteImage-Coffee-F.jpg',
            title: 'Coffee Cup ',
            href: '/assets/downloads/FINRED-Coffee-F.pdf',
            type: 'Flyer',
            link: 'Download',
            body: `Replace your coffee shop routine with home brewing starting today
        and watch as the balance on your retirement fund percolates at an
        even faster pace.`,
        },
        {
            img: '/assets/img/FINRED-WebsiteImage-BRSDefinedBenefit-FS.jpg',
            title: 'Defined Benefit',
            href: '/assets/downloads/FINRED-BRSDefinedBenefit-FS-5.5x8.5.pdf',
            type: 'Fact Sheet',
            link: 'Download',
            body: `Learn about the defined benefit (pension) and how it works under
        the Blended Retirement System. We’ve gathered the details about it
        for you here.`,
        },
    ],
};
const defaultResourceList = {
    type: 'ResourceList',
    key: '2',
    resources: [
        {
            title: 'Continuation Pay Fact Sheet',
            href: '/assets/downloads/FINRED-BRSContinuationPay-FS-5.5x8.5.pdf',
            subtext: "What's continuation pay and how can it affect retirement? Explore this midcareer bonus that Blended Retirement System members may qualify to receive.",
        },
        {
            title: 'Defined Contribution Fact Sheet',
            href: '/assets/downloads/FINRED-BRSDefinedContribution-FS-5.5x8.5.pdf',
            subtext: 'What is a defined contribution and how do automatic and matching Thrift Savings Plan contributions add up under the Blended Retirement System? Find out now.',
        },
        {
            title: 'Eligibility Fact Sheet',
            href: '/assets/downloads/FINRED-GC-BRSEligibility-FS-5.5x8.5.pdf',
            subtext: "Unsure about where you or your partner stand on Blended Retirement System eligibility? We've outlined basics and opt-in details for current Service members.",
        },
    ],
};
const defaultArticles = [
    {
        _id: 'demo',
        sections: [
            JSON.parse(JSON.stringify(defaultMarkdownSection)),
            JSON.parse(JSON.stringify(defaultFeaturedAssetsSection)),
            JSON.parse(JSON.stringify(defaultResourceList)),
        ],
        key: 1,
        title: 'This is a demo title',
    },
];

const defaultState = {
    session: {
        isLoggedIn: false,
    },
    events: {
        loading: false,
        docs: [

        ],
    },
    blogs: {
        loading: false,
        docs: {

        },
    },
    articles: { loading: false,
        docs: defaultArticles,
    },
};

function events(state, action) {
    if (action.type == 'eventsLoaded') {
        return Object.assign({}, state, { docs: action.events });
    } if (action.type == 'eventUpdated') {
        for (const i in state.docs) {
            if (state.docs[i]._id === action.event._id) {
                state.docs[i] = Object.assign({}, state.docs[i], action.event);
                return state;
            }
        }

        return state;
    } return state;
}

function blog(state, action) {
    if (action.type == 'blogsLoaded') {
        console.log(action);
        return Object.assign({}, state, { docs: action.blogs });
    } if (action.type == 'blogUpdated') {
        for (const i in state.docs) {
            if (state.docs[i]._id === action.blog._id) {
                state.docs[i] = Object.assign({}, state.docs[i], action.blog);
                return state;
            }
        }

        return state;
    } return state;
}

function session(state, action) {
    if (action.type === 'login') {
        return Object.assign({}, state, { loggedIn: true });
    }
    if (action.type === 'logout') {
        console.log('got to logout');
        return Object.assign({}, state, { loggedIn: false });
    }
    return state;
}



function homepage(state, action) {
    if (action.type === 'homepageLoaded') {
        return Object.assign({}, state, action.homepage);
    }

    const nextState = produce(state, state => {
        if (action.type === 'homepageLoaded') {
            Object.assign(state, action.homepage);
        }
        if (action.type === 'homepageStartEdit') {
            Object.assign(state, { editing: true });

            if (!state.draft) state.draft = JSON.parse(JSON.stringify(state.published));
        }
        if (action.type === 'homepageStopEdit') {
            Object.assign(state, { editing: false });
        }
        if (action.type === 'homepageClearDraft') {
            Object.assign(state, { editing: false });
            const api = require('../api').default;
            this.state.draft = null;
            api.post('/homepage', state).then((res) => {
                console.log(res);
            });
        }
        if (action.type === 'homepageValueSet') {
            state.edited = true;
            objectPath.set(state, 'draft.' + action.key, action.value);
        }
        if (action.type === 'homepagePublish') {
            state.published = JSON.parse(JSON.stringify(state.draft));
            state.draft = null;
            const api = require('../api').default;
            api.post('/homepage', state).then((res) => {
                console.log(res);
            });
            Object.assign(state, { editing: false });
        }
        if (action.type === 'discardDraft') {
            state.draft = null;
            const api = require('../api').default;
            api.post('/homepage', state).then((res) => {
                console.log(res);
            });
            Object.assign(state, { editing: false });
        }

        if (action.type === 'homepageSave') {
            const api = require('../api').default;
            api.post('/homepage', state).then((res) => {
                console.log(res);
            });
        }
    });

    return nextState;
}
function article(state, action) {
    if (action.type === 'startEdit') {
        for (const i in state.docs) {
            if (state.docs[i]._id === action._id) {
                state.docs[i].backup = JSON.stringify(state.docs[i]);
                state.docs[i].editing = true;
            } else { state.docs[i].editing = false; }
        }
    }
    if (action.type === 'saveEdit') {
        for (const i in state.docs) {
            if (state.docs[i]._id === action._id && state.docs[i].editing) {
                state.docs[i].editing = false;
                delete state.docs[i].backup;
                // post to server
            }
        }
    }

    if (action.type === 'cancelEdit') {
        for (const i in state.docs) {
            if (state.docs[i]._id === action._id && state.docs[i].editing) {
                state.docs[i] = JSON.parse(state.docs[i].backup);
                state.docs[i].editing = false;
                delete state.docs[i].backup;
            }
        }
    }

    if (action.type === 'startSectionEdit') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    const section = state.docs[i].sections[j];

                    if (j + '' === action.i) {
                        section.sectionEditing = true;
                    } else {
                        section.sectionEditing = false;
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }
    if (action.type === 'endSectionEdit') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    const section = state.docs[i].sections[j];

                    if (j + '' === action.i) {
                        section.sectionEditing = false;
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }

    if (action.type === 'featuredAssetRemoved') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    const section = state.docs[i].sections[j];
                    if (j + '' === action.sectionIndex) {
                        for (const k in section.assets) {
                            if (k == action.index) {
                                console.log('removeing ');
                                section.assets.splice(k, 1);
                                section.assets = section.assets.concat([]);
                            }
                        }
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }
    if (action.type === 'featuredAssetEdited') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    const section = state.docs[i].sections[j];
                    if (j + '' === action.sectionIndex) {
                        for (const k in section.assets) {
                            if (k == action.index) {
                                section.assets[k] = Object.assign({}, action.payload);
                            }
                        }
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }
    if (action.type === 'addFeaturedAsset') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    const section = state.docs[i].sections[j];


                    if (section.type == 'FeaturedAssets') {
                        section.assets = section.assets.concat([{
                            img: '/assets/img/FINRED-WebsiteImage-BRS-BK.jpg',
                            title: 'Uniformed Services Blended Retirement System',
                            href: '/assets/downloads/FINRED-BRS-BK-5.5x8.5.pdf',
                            type: 'Booklet',
                            link: 'Download',
                            body: ` Explore all aspects of the Uniformed Services Blended Retirement
                            System, from eligibility to benefits to resources, in this handy,
                            comprehensive guide.`,
                        }]);
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }
    if (action.type === 'sectionEdited') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    const section = state.docs[i].sections[j];

                    if (j + '' === action.index) {
                        section.sectionEditing = false;
                        if (section.type == 'Markdown') {
                            section.markdown = action.payload;
                        }
                        if (section.type == 'ResourceList') {
                            section.resources = action.payload.concat([]);
                        }
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }
    if (action.type === 'addSection') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                let newSection;
                if (action.sectionType == 'Markdown') {
                    console.log('adding section');
                    newSection = JSON.parse(JSON.stringify(defaultMarkdownSection));
                }
                if (action.sectionType == 'FeaturedAssets') {
                    newSection = JSON.parse(JSON.stringify(defaultFeaturedAssetsSection));
                }
                if (action.sectionType == 'ResourceList') {
                    newSection = JSON.parse(JSON.stringify(defaultResourceList));
                }
                newSection.key = state.docs[i].sections.length;
                state.docs[i].sections = state.docs[i].sections.concat([newSection]);
                console.log(state.docs[i].sections);
            }
        }
    }
    if (action.type === 'sectionRemoved') {
        for (const i in state.docs) {
            if (state.docs[i].editing === true) {
                for (const j in state.docs[i].sections) {
                    if (j + '' === action.i) {
                        state.docs[i].sections.splice(j, 1);
                    }
                }
                state.docs[i].sections = state.docs[i].sections.concat([]);
            }
        }
    }

    return state;
}
module.exports = function(state, action) {
    if (!state) { return defaultState; }
    return {
        events: events(state.events, action),
        blogs: blog(state.blogs, action),
        articles: article(state.articles, action),
        session: session(state.session, action),
        homepage: homepage(state.homepage, action),
    };
};
