/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';
import Page from '../../components/toolsAndResourcesPage';

import MarkLink from '../../components/markLink';
import CalculatorLink from '../../components/calculatorLink';
import ReactMarkdown from '../../components/markdown';

export default function Loan (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let calculator;

    if (props.isCalculator) {
        calculator = props.match && props.match.params.calculator;
    }

    return (
      <Page
        crumbs={`${props.crumbs},Loan Calculators`}
        toc={props.toc}
      >

        <ReactMarkdown
          source={`
## Loan Calculators
![Couple signing papers](/assets/img/FINRED-WebsiteImage-Investing-C.jpeg "Couple signing papers")

Take the guesswork out of managing and repaying loans. By understanding the numbers from the start, you can ensure the terms of your loan fit with your finances, allowing you to follow a clear repayment strategy.

These calculators are for educational purposes only and should not be considered financial or investment advice. Contact a [personal financial manager](https://installations.militaryonesource.mil/search?program-service=30/view-by=ALL) or [counselor](/pfcMap) for an explanation of your results.
       `}
          renderers={{
              link: MarkLink,
          }}
        />
        <ul>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Amortizing Loan"
              calculator="/assets/calc/SimpleLoan.html"
              title="1"
              resource={calculator}
              resourceLabel="Amortizing-Loan"
            >
            Get a clear picture of the cost of your loan. View an estimated monthly payment cost depending on the loan amount you need — and vice versa!
            </CalculatorLink>
          </li>
          <li>
            <CalculatorLink
              {...props}
              to={url}
              text="Line of Credit Pay Off"
              calculator="/assets/calc/PayoffLine.html"
              title="2"
              resource={calculator}
              resourceLabel="Line-of-Credit-Pay-Off"
            >
            With this calculator, you can see what it will take to pay off a line of credit, so you can strategize the best repayment plan.
            </CalculatorLink>
          </li>
        </ul>
      </Page>
    );
}


