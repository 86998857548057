/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Sense from '../pages/sense/sense';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import Oops404Page from '../pages/oops-404';

export default function SenseRoutes({ root, crumbs, toc }) {
    const thisCrumbs = (crumbs ? `${crumbs},` : '') + `Sen$e Your Financial Tool|${root}`;

    return (
      <div>
        <Switch>
          <Route
            exact
            path={root}
            render={(routerProps) => <Sense {...routerProps} crumbs={thisCrumbs} toc={toc} />}
          />

          {/* <Route
            path={`${root}/video`}
            render={(rps) => (
              <Switch>
                {[
                    HookUpSiteVideo(
                        rps.match.path, 'This-Is-Sense', 'This Is Sen$e', thisCrumbs,
                        '/assets/video/sense/FINRED-ThisIsSense-V.mp4',
                        null, '/assets/img/FINRED-WebsiteImage-ThisIsSense-V.jpg',
                    ),
                ]}
              </Switch>
            )}
          /> */}
          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
