/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
export const reducer = (state, action) => {
    // console.log(state, action);
    switch (action.type) {
    case 'SEARCH':
        return {
            ...state,
            page: 'result',
            searchTerm: action.payload,
            conusTabExpanded: false,
            oconusTabExpanded: false,
            // error: null,
        };
    case 'START_SEARCHING':
        return;
    case 'SET_MAP_TYPE':
        return {
            ...state,
            mapType: action.payload,
            mapHistory: action.payload,
        };
    case 'CONUS_TAB_EXPANDED':
        return {
            ...state,
            conusTabExpanded: action.payload,
            oconusTabExpanded: false,
        };
    case 'OCONUS_TAB_EXPANDED':
        return {
            ...state,
            oconusTabExpanded: action.payload,
            conusTabExpanded: false,
        };
    case 'ERROR':
        return {
            ...state,
            error: action.payload,
        };
    case 'RESET':
        return {
            ...state,
            searchTerm: null,
            page: 'search',
            conusTabExpanded: false,
            oconusTabExpanded: false,
            mapType: null,
            error: null,
        };
    default:
        return state;
    }
};

export const initialState = {
    searchTerm: null,
    page: 'search',
    searching: false,
    conusTabExpanded: false,
    oconusTabExpanded: false,
    mapType: null,
    error: null,
    mapHistory: null,
};
