/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Img from './img';
export default class MediaBlock extends React.Component {
    render() {
        let style = {};
        if (this.props.fit) {
            style = { objectFit: 'contain;', objectPosition: 'center;' };
        }
        if (this.props.square) {
            return (
              <div className="usa-width-one-half usa-media_block ">
                <div className="square">

                  <Img src={'' + this.props.src} alt={this.props.alt} style={style} />
                  <div className="usa-media_block-body">
                    {this.props.children}
                  </div>
                </div>
              </div>
            );
        }
        return (
          <div className="usa-width-one-half usa-media_block ">
            <Img className="usa-media_block-img circle" src={'' + this.props.src} alt={this.props.alt} />
            <div className="usa-media_block-body">
              {this.props.children}
            </div>
          </div>
        );
    }
}

