/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../../components/hookUpSiteVideo';
import SplTOC from '../../../components/tocs/splTOC';
import Oops404Page from '../../../pages/oops-404';
import HookUpOopsPage from '../../../components/hookUpOopsPage';


export default function PreDeploymentRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Pre-deployment|${root}` : `Pre-deployment|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'Overview', 'Overview', crumbs,
                      '/assets/video/navy-resource/pre-deployment/10_101_navy_video_1_-overview_v6 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinancialPlanningforDeployment', 'Financial Planning for Deployment', crumbs,
                      '/assets/video/navy-resource/pre-deployment/42-106_-_navy_pre-deployment_20_-_financial_planning_for_deployment_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, '11ThingstoKnowBeforeYouGo', 'Set Up 11 Things to Know Before You Go: Overview', crumbs,
                      '/assets/video/navy-resource/pre-deployment/10_103_navy_video_3_-set_up_11_things_to_know_before_you_go_videos_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingPaymentsWhileDeployed', 'Managing Payments While Deployed', crumbs,
                      '/assets/video/navy-resource/pre-deployment/10_104_navy_pre-deployment20_-managing_payments_while_deployed_v7_(1080p) (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MLAandSCRA', 'MLA and SCRA', crumbs,
                      '/assets/video/navy-resource/pre-deployment/10_105_navy_pre-deployment20_-scra_&_mla_v7_(1080p) (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ActiveDutyAlerts', 'Active Duty Alerts', crumbs,
                      '/assets/video/navy-resource/pre-deployment/10_108_navy_pre-deployment20_-active_duty_alerts_v6_(1080p) (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingsDepositPrograms', 'Savings Deposit Programs', crumbs,
                      '/assets/video/navy-resource/pre-deployment/42-109_-_navy_pre-deployment_20_-_savings_deposit_program_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CombatZoneTaxExclusion', 'Combat Zone Tax Exclusion', crumbs,
                      '/assets/video/navy-resource/pre-deployment/42-105_-_navy_pre-deployment_20_-_combat_zone_tax_exclusion_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TSPContributionsDuringDeployment', 'TSP Contributions During Deployment', crumbs,
                      '/assets/video/navy-resource/pre-deployment/42-110_-_navy_pre-deployment_20_-_tsp_contributions_during_deployment_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      '/assets/video/navy-resource/pre-deployment/42-107_-_navy_pre-deployment_20_-_life_insurance_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'InsuranceMedicalAutoandProperty', 'Other Insurances (Health Auto Property)', crumbs,
                      '/assets/video/navy-resource/pre-deployment/42-108_-_navy_pre-deployment_20_-_other_insurances_(health,_auto,_property)_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LegalDocumentsandEstatePlanning', 'Legal Documents and Estate Planning', crumbs,
                      '/assets/video/navy-resource/pre-deployment/10_113_navy_pre-deployment20_-legal_documents_estate_planning_v4_(1080p) (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FinalThoughts', 'Final Thoughts', crumbs,
                      '/assets/video/navy-resource/pre-deployment/Navy Pre-Deployment Video 15 - Wrap Up.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USN-PreDeployment-R.jpeg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
