/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import BaseTOC from './baseTOC';

export default function ToolsTOC(props) {
    return (
      <BaseTOC
        title="Tools and Additional Resources"
        tocEntries={[
            { link: '/ToolsAndAddRes/ShareableResources', text: 'Shareable Resources' },
            { link: props.rootAdditionalRes, text: 'Additional Resources' },
            { link: props.rootCalculators, text: 'Calculators' },
            { link: '/FWBA', text: 'Financial Well-Being Assessment' },
            { link: '/ToolsAndAddRes/HowToVideos', text: 'How-To Videos' },
            { link: '/ToolsAndAddRes/Sen$e', text: 'Sen$e, Your Financial Tool' },
            { link: '/ToolsAndAddRes/NetWorth', text: 'Track Your Personal Net Worth' },
        ]}
      />
    );
}
