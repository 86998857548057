/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import MarkLink from './markLink';
import Img from './img';
const ReactMarkdown = require('react-markdown/with-html');

const ImgLg = (props) => <Img {...props} large />;
export default class Markdown extends React.Component {
    render() {
        return (
          <ReactMarkdown
            source={this.props.source}
            escapeHtml={false}
            renderers={{
                link: (props) => <MarkLink {...props} />,
                image: ImgLg,
            }}
          />
        );
    }
}
