/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route } from 'react-router-dom';

import PrintReadyRenderer from './printReadyRenderer';

function Thing(props) {
    return <p>stuff</p>;
}

export default function HookUpPrPage (root, crumbs, pr) {
    return (
      <Route
        exact
        path={`${root}/${pr.href}`}
        render={routeProps => (
          <PrintReadyRenderer
            key={`${pr.href}`}
            crumbs={crumbs}
            title={pr.title}
            type={pr.type}
            img={pr.img}
            alt={pr.alt}
            abstract={pr.abstract}
            files={pr.files}
            prSpecFile={pr.prSpecFile}
            prSpecLabel={pr.prSpecLabel}
            {...routeProps}
          />
        )}
      />
    );
}
