/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdditionalResources from '../pages/additional-resources/additional-resources';
import Benefits from '../pages/additional-resources/benefits';
import Money from '../pages/additional-resources/money';
import Saving from '../pages/additional-resources/saving';
import Planning from '../pages/additional-resources/planning';
import Protections from '../pages/additional-resources/protections';
import Service from '../pages/additional-resources/service';
import Collaborators from '../pages/additional-resources/collaborators';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';

export default function AdditionalResourcesRoutes({ root, crumbs, toc }) {
    const thisCrumbs = (crumbs ? `${crumbs},` : '') + `Additional Resources|${root}`;

    return (
      <div>
        <Switch>
          <Route
            exact
            path={root}
            render={(routerProps) => <AdditionalResources {...routerProps} crumbs={thisCrumbs} toc={toc} />}
          />

          <Route
            path={`${root}/video`}
            render={(rps) => (
              <Switch>
                {[
                    HookUpSiteVideo(
                        rps.match.path, 'This-Is-Sense', 'This Is Sen$e', thisCrumbs,
                        '/assets/video/sense/FINRED-ThisIsSense-V.mp4',
                        null, '/assets/img/FINRED-WebsiteImage-ThisIsSense-V.jpg',
                    ),
                    HookUpOopsPage(),
                ]}
              </Switch>
            )}
          />

          <Route exact path={`${root}/Benefits`}>
            <Benefits crumbs={thisCrumbs} root={`${root}/Benefits`} toc={toc} />
          </Route>

          <Route exact path={`${root}/Money`}>
            <Money crumbs={thisCrumbs} root={`${root}/Money`} toc={toc} />
          </Route>

          <Route exact path={`${root}/Saving`}>
            <Saving crumbs={thisCrumbs} root={`${root}/Saving`} toc={toc} />
          </Route>

          <Route exact path={`${root}/Planning`}>
            <Planning crumbs={thisCrumbs} root={`${root}/Planning`} toc={toc} />
          </Route>

          <Route exact path={`${root}/Protections`}>
            <Protections crumbs={thisCrumbs} root={`${root}/Protections`} toc={toc} />
          </Route>

          <Route exact path={`${root}/Services`}>
            <Service crumbs={thisCrumbs} root={`${root}/Services`} toc={toc} />
          </Route>

          <Route exact path={`${root}/Collaborators`}>
            <Collaborators crumbs={thisCrumbs} root={`${root}/Collaborators`} toc={toc} />
          </Route>

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
