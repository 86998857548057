/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HookUpSiteVideo from '../../components/hookUpSiteVideo';
import SplTOC from '../../components/tocs/splTOC';
import Oops404Page from '../../pages/oops-404';
import HookUpOopsPage from '../../components/hookUpOopsPage';


export default function MarriageRoutes(props) {
    const root = props.match.path;
    const crumbs = props.crumbs ? `${props.crumbs},Pre-Deployment|${root}` : `Pre-Deployment|${root}`;
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <Switch>
        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'CourseOverview', 'Course Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-101_-_af_pre-deployment_20_-_course_overview_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SpendingPlanConsiderations', 'Spending Plan Considerations', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-102_-_af_pre-deployment_20_-_spending_plan_considerations_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'PotentialDeployment-RelatedIncomeChanges', 'Potential Deployment-Related Income Changes', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-103_-_af_pre-deployment_20_-_special_pay,_entitlements,_and_other_income_changes_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SavingsandEmergencyFunds', 'Savings and Emergency Funds', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-104_-_af_pre-deployment_20_-_savings_and_emergency_funds_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TheSavingsDepositProgram', 'The Savings Deposit Program', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-105_-_af_pre-deployment_20_-_the_savings_deposit_program_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'WarningSignsofFinanciallyAt-RiskServiceMembers', 'Warning Signs of Financially At-Risk Service Members', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-106_-_af_pre-deployment_20_-_warning_signs_of_financially_at-risk_service_members_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoIntermediatePersonalFinanceTopics', 'Transition to Intermediate Personal Finance Topics', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-107_-_af_pre-deployment_20_-_transition_to_intermediate_personal_finance_topics_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'FundamentalsofDebtandCredit', 'Fundamentals of Debt and Credit', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-108_-_af_pre-deployment_20_-_debt_and_credit_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'DeploymentandStudentLoans', 'Deployment and Student Loans', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-109_-_af_pre-deployment_20_-_deployment_and_student_loans_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'IncomeTaxFundamentals', 'Income Tax Fundamentals', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-110_-_af_pre-deployment_20_-_income_tax_basics_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TaxesandDeployment', 'Taxes and Deployment', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-111_-_af_pre-deployment_20_-_taxes_and_deployment_v5 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'UnderstandingtheThriftSavingsPlan', 'Understanding the Thrift Savings Plan', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-112_-_af_pre-deployment_20_-_understanding_the_thrift_savings_plan_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'ManagingYourTSPAccount', 'Managing Your TSP Account', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-113_-_af_pre-deployment_20_-_managing_your_tsp_account_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoInsurance', 'Transition to Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-114_-_af_pre-deployment_20_-_transition_to_insurance_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'InsuranceOverview', 'Insurance Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-115_-_af_pre-deployment_20_-_insurance_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'LifeInsurance', 'Life Insurance', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-116_-_af_pre-deployment_20_-_life_insurance_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TRICAREOverview', 'TRICARE Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-117_-_af_pre-deployment_20_-_tricare_overview_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoMilitaryConsumerProtections', 'Transition to Military Consumer Protections', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-118_-_af_pre-deployment_20_-_transition_to_military_consumer_protection_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SCRAMLAandCreditProtection', 'SCRA, MLA and Credit Protection', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-119_-_af_pre-deployment_20_-_scra,_mla_and_credit_protection_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'MisleadingConsumerPracticesandIdentityTheft', 'Misleading Consumer Practices and Identity Theft', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-120_-_af_pre-deployment_20_-_misleading_consumer_practices_and_identity_theft_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'TransitiontoSurvivorBenefitsandEstatePlanning', 'Transition to Survivor Benefits and Estate Planning', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-121_-_af_pre-deployment_20_-_transition_to_survivor_benefits_and_estate_planning_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'SurvivorandDependentBenefits', 'Survivor and Dependent Benefits', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-122_-_af_pre-deployment_20_-_survivor_and_dependent_benefits_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningOverview', 'Estate Planning Overview', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-123_-_af_pre-deployment_20_-_estate_planning_overview_v4 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'EstatePlanningTasks', 'Estate Planning Tasks', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-124_-_af_pre-deployment_20_-_estate_planning_tasks_v3 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'CourseWrap-up', 'Course Wrap-up', crumbs,
                      'https://finred.usalearning.gov/assets/video/air-force/pre-deployment/43-125_-_af_pre-deployment_20_-_course_wrap-up_v2 (720p).mp4',
                      null, '/assets/img/FINRED-WebsiteImage-USAFPreDeployment-R.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
