/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useEffect, useState } from 'react';

import Page from '../page';
import Template1 from './templates/template1';
import Template2 from './templates/template2';
import Template3 from './templates/template3';

export default function DigitalDesignRenderer ({ root, fileName, crumbs, toc, title }) {
    const [elements, setElements] = useState(null);
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        if (fileName) {
            // eslint-disable-next-line no-undef
            fetch(`/assets/digital-design/${fileName}.json`)
                .then(res => res.text())
                .then(info => {
                    const infoJson = JSON.parse(info);
                    setTemplate(infoJson.template);
                    setElements(infoJson.elements);
                });
        }
    }, []);

    return (
        elements && (
        <Page crumbs={`${crumbs},${title.replace(',', '')}|${root}}`}>
          <div className="usa-grid no-left-pad no-right-pad">
            <div className="usa-width-one-fourth TOC">
              {toc}
            </div>
            <div className="usa-width-three-fourths">
              { template === 'template1' && <Template1 elements={elements} /> }
              { template === 'template2' && <Template2 elements={elements} /> }
              { template === 'template3' && <Template3 elements={elements} /> }
            </div>
          </div>
        </Page>
        )
    );
}
