import Page from "../../components/page";

import React from "react";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.rendered = "";
    this.format = this.format.bind(this);
  }
  render() {
    return (
      <Page crumbs={"Home|/,utils|resourceRenderer"}>
        <textarea style={{ maxWidth: "100%" }} onChange={this.format} />
        <textarea
          style={{
            maxWidth: "100%",
            fontFamily: "monospace",
            fontSize: "12px",
            whitespace: "pre"
          }}
          value={this.state.rendered}
        />
      </Page>
    );
  }
  format(e) {
    let value = e.target.value;
    let rendered = '<div className="usa-grid featured-assets">\n';
    let tabs = value.split(/[\n\t]/);

    for (let i = 0; i < tabs.length-6; i) {
      rendered += `   
                <FeaturedAsset 
                    img="/assets/img/${tabs[i+5]}"
                    title="${tabs[i+0]}"
                    href="${tabs[i+4]}"
                    type="${tabs[i+2]}"
                    link="Download">
                    <p>
                    ${tabs[i+1]}    
                    </p>
                </FeaturedAsset>
              `;

      i += 6;
    }
    rendered += "</div>\n";

    this.setState(() => {
      return { rendered: rendered };
    });
  }
}
