/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { renderers } from 'react-markdown';
import { connect } from 'react-redux';
import Markdown from '../markdown';
import FeaturedAsset from '../editableFeaturedArticle';
class MarkSection extends React.Component {
    constructor(props) {
        super(props);
    }

    renderEditButtons() {
        const buttons = [];
        if (this.props.editing) {
            if (!this.props.sectionEditing) {
                buttons.push(<span className="fa fa-edit" onClick={() => this.props.onStartEditSection(this.props.i)} />);
            }
            if (!this.props.sectionEditing) {
                buttons.push(<span className="fa fa-close" onClick={() => this.props.onRemoveSection(this.props.i)} />);
            }
        }
        if (this.props.sectionEditing) {
            buttons.push(<span onClick={(e) => this.props.onAddFeaturedAsset()} className="fa fa-plus" />);
            buttons.push(<span onClick={(e) => this.props.onEndEditSection(this.props.i)} className="fa fa-save" />);
        }

        if (this.props.editing || this.props.sectionEditing) {
            return (
              <>
                <div className="sectionEdit">{buttons}

                </div>
                <div className="clearfix" />
              </>
            );
        }
    }

    render() {
        return (
          <>
            { this.renderEditButtons()}
            <div className="usa-grid featured-assets">
              {this.props.assets.map((i, j) => (
                <FeaturedAsset
                  {...i}
                  i={j}
                  sectionIndex={this.props.i}
                  sectionEditing={this.props.sectionEditing}
                >
                  <Markdown source={i.body} />
                </FeaturedAsset>
              ))}
            </div>
          </>
        );
    }
}


const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = dispatch => ({
    onStartEditSection: (i) => dispatch({ type: 'startSectionEdit', i }),
    onRemoveSection: (i) => dispatch({ type: 'sectionRemoved', i }),
    onEndEditSection: (i) => dispatch({ type: 'endSectionEdit', i }),
    onAddFeaturedAsset: () => dispatch({ type: 'addFeaturedAsset' }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarkSection);
