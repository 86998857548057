/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import HeroSlide from './heroSlide';

export default function HeroCarousel({ setSite }) {
    return (
      <div className="hero-container">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          totalSlides={3}
          interval={5000}
          isPlaying
        >
          <Slider>
            <Slide index={0}>
              <HeroSlide
                // setSite={setSite}
                title="PCS = New School Transitions"
                img="/assets/img/FINRED-WebsiteImage-Aug2024-H-PCS-School.jpg"
                imgAlt="Kids getting off the bus"
                buttonLink="/Trending/Blog/EnrollingAfterPCS"
                buttonText="Learn More"
              >
                <p>
                MilParents: Check out our money and sanity saving tips to ease post-PCS adjustments to a new school.
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={1}>
              <HeroSlide
                // setSite={setSite}
                title="Retirement and Dependent Care"
                img="/assets/img/FINRED-WebsiteImage-Aug2024-H-Retirement.jpg"
                imgAlt="Hands holding"
                buttonLink="/Benefits/Retirement/RetirementIncomeFactorsDC"
                buttonText="Plan Ahead"
              >
                <p>
                Master dependent care benefits for post-military life.
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={2}>
              <HeroSlide
                title="Financial Checkup Time?"
                img="/assets/img/FINRED-WebsiteImage-Aug2024-H-FWBA.jpg"
                imgAlt="Mobile phone with digital version of the Financial Well-Being Assessment on screen"
                buttonLink="/Money/FinancialWellBeing"
                buttonText="Assess Your Financial Wellness"
                // buttonCentered
              >
                <p>
                Four reasons to check your financial health this month, plus a quick quiz.
                </p>
              </HeroSlide>
            </Slide>

            {/* <Slide index={3}>
              <HeroSlide
                setSite={setSite}
                title="Join Us on LinkedIn!"
                img="/assets/img/FINRED-WebsiteImage-February2024-HomepageHero-LinkedIn.jpg"
                imgAlt="LinkedIn logo"
                buttonLink="http://linkedin.com/company/dodfinred"
                buttonText="Get Connected"
                // buttonCentered
              >
                <p>
                Follow the DoD Office of Financial Readiness for fresh financial tips and news.
                </p>
              </HeroSlide>
            </Slide> */}
          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
