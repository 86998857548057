/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Page from '../../../components/splPage';
import MarkLink from '../../../components/markLink';

export default function OnlineFinancialCalculators (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={`${props.crumbs},Online Financial Calculators`}
        toc={props.toc}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Online Financial Calculators
Use these calculators to simplify retirement savings projections, monthly budgeting, major purchase considerations and more! Click through to find the right calculator for your service member’s situation.

* [TSP Calculators](https://www.tsp.gov/calculators/)
* [PowerPay](https://extension.usu.edu/powerpay/index)
* [Office of Financial Readiness Calculators](/ToolsAndAddRes/Calculators)
`}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}


