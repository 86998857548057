/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Link from './link';

export default function PrintReadyCard(props) {
    return (
      <div className="print-ready-card">
        <div className="pr-card-body">
          <h4 className="pr-card-title">{props.title}</h4>
          <p className="pr-card-type">{props.type}</p>
          {props.children}
        </div>
        <table className="pr-card-table" style={{ width: '100%' }}>
          {
            props.files.map((file, index) => (
              <tr>
                <td style={{ width: '50%' }}>
                  <Link to={file.prFile}>{file.prFileLabel}</Link>
                </td>
                { file.stdFile && (
                  <td style={{ width: '50%' }}>
                    <Link to={file.stdFile}>{file.stdFileLabel}</Link>
                  </td>
                )}
              </tr>
            ))
          }
          { props.prSpecFile
            && (
            <tr>
              <td><Link to={props.prSpecFile}>{props.prSpecLabel}</Link></td>
            </tr>
            )
          }
        </table>
      </div>
    );
}
