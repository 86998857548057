/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Img from './img';
import Link from './link';

export default function HeroBanner({ setSite, title, img, imgAlt, buttonLink, buttonText, additionalComponent, children }) {
    return (
      <div className="hero-box static">
        <div className="hero-message-box usa-section-dark">
          <h3>{title}</h3>
          <p>{children}{' '}
            {(setSite && buttonLink) && (
            <a
              // className="usa-button"
              href={`#${buttonLink}`}
              onClick={() => setSite(buttonLink)}
            >{buttonText}</a>
            )}
            {(!setSite && buttonLink) && <Link to={buttonLink}>{buttonText}</Link>}
          </p>
        </div>
        <div className="hero-image">
          <Img src={img} alt={imgAlt} title={imgAlt} />
        </div>
      </div>
    );
}
