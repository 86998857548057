/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../components/page';

import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import Link from '../components/link';

import ReactMarkdown from '../components/markdown';

export default function Future(props) {
    const root = props.match.path;

    return (
      <Page
        articleClass="launch-page"
        {...props}
      >
        <ReactMarkdown
          source={`
## Planning for the Future
Invest in yourself and your future by planning ahead starting today. Use all of the resources that are available to you as a member of the military community and start increasing your wealth. You’ll be glad you did!

[Estate-Planning]()
### Estate Planning
It’s never too soon to start estate planning. Learn the facts so you can make sure you are prepared to take care of your loved ones now and in the future, even in the event of your absence. Talk with an accredited personal financial manager or counselor at your nearest Military and Family Support Center or use the resources you will find here to learn more.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SBP-R.jpg"
            alt="The Survivor Benefit Plan logo."
            title="Survivor Benefit Plan"
            href={`${root}/SurvivorBenefitPlan`}
            type="Resources"
            link="Learn More"
          >
            <p>
                Learn how to provide additional income for your loved ones with the Survivor Benefit Plan — a continuous lifetime annuity plan available to eligible service members upon retirement.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-POAMilitary-A.jpg"
            alt="Service member reviews paperwork"
            title="How Powers of Attorney Serve the Military"
            href={`${root}/POAMilitary`}
            type="Fact Sheet"
            link="Read Now"
          >
            <p>
                Learn how a power of attorney can safeguard your health, estate, legal and financial affairs. This article breaks down the different options for POAs and how to set one up.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ConsiderTrusts-A.jpg"
            alt="Couple reviewing documents in their home"
            title="Consider a Trust in Your Estate Plan"
            href={`${root}/ConsiderTrusts`}
            type="Article"
            link="Read Now"
          >
            <p>
                To further protect your assets, consider adding a trust to your estate plan. This article explains the different types of trusts to help you decide if one is right for you.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [An Introduction to Estate Planning](/assets/downloads/FINRED-EstatePlanning-FS.pdf)
  Work on your estate plan to cover and care for the people and things you value most. Here's how you can get started or update your plan today.
             `}
          renderers={{
              link: MarkLink,
          }}
        />

        {/* We had to put this outside the markdown component because it seems that italicizing links does not work. */}
        <ul><li>
          <span>
            <a href={`${root}/FuturePlanning`}>
                Don't Just Plan for the Future — Plan for <em>Your</em> Future
            </a>
          </span>
              Your financial future depends on what you do today. Learn how to make your goals a reality.
        </li></ul>

        <ReactMarkdown
          source={`
* [Estate Planning for the Service Member](https://youtu.be/7WmIzSmXeew)
  Estate planning doesn’t need to be a mysterious process. Familiarize yourself with services and techniques that can help make the process a smooth one.
* [Estate Planning Overview](${root}/EstatePlanningOverview)
  Estate planning takes careful consideration. In this article, you’ll learn about planning your estate and creating a legacy you and your family will be pleased with.
* [It Pays to Visit a Personal Financial Counselor](/assets/downloads/FINRED-PFC-F.pdf)
  Find out how no-cost, trusted personal support from personal financial counselors can help service members and their families make the most of their money.
* [The Importance of Having a Will](${root}/ImportanceofHavingaWill)
  Provide your family the greatest gift of all. In this article, you’ll learn how to create a will and keep it updated as your life situation changes.
 
[Readiness]()
### Readiness
It’s important to pause along your financial journey to see how far you’ve come and where you are heading. We’ll help you chart your course with resources that will help you plan and develop your savings and retirement goals today, so you’ll be ready for whatever the future holds.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-GetRetirementReadyChecklist-A_1.jpg"
            alt="Air Force retirement ceremony"
            title="Get Retirement Ready With This Checklist"
            href={`${root}/GetRetirementReadyChecklist`}
            type="Article"
            link="Read Now"
          >
            <p>
            Retiring from the military is a big transition, but planning ahead can help make it a smooth one. Use this checklist to begin your preparations.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-ChildrenFinances-A.jpg"
            alt="Pregnant service member and service member spouse posing on the ladder of a B-1B Lancer"
            title="Financial Considerations Before Welcoming Children"
            href={`${root}/ChildrenFinances`}
            link="Read Now"
          >
            <p>
            Finding a budgeting tool that fits your style can be a challenge. Read what worked for one blogger and learn how you can take control of the process.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PostServiceTaxImplications-A.jpg"
            alt="Man at computer on the phone"
            title="Understanding Post-Service Tax Implications"
            href={`${root}/PostServiceTaxImplications`}
            link="Read Now"
          >
            <p>
            As you prepare for military retirement, it’s important to consider how post-service tax changes will impact your spending power. This article explains what you can expect.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [A Road Map for Your Financial Future: Directions to Plan, Save and Prepare](/assets/downloads/FINRED-RoadmapOverview-I.pdf)
  Creating a financial road map during your military career can have a big impact. Discover how to plan, save and prepare for a financially secure future.
* [A Road Map for Your Financial Future: Plan for Your Road Trip](/assets/downloads/FINRED-RoadmapPlan-I.pdf)
  Planning your financial road trip now will keep you on track. See how to prepare for a financially solid future.
* [A Road Map for Your Financial Future: Prepare Your Emergency Travel Kit](/assets/downloads/FINRED-RoadmapPrepare-I.pdf)
  Mapping your financial journey now will keep you on track. See how to plan for a financially solid future.
* [Certification Process Eases for Student Recipients of Survivor Benefit Plan](${root}/CertificationProcessEases)
  There is good news for Survivor Benefit Plan child annuitants who go to school full time after turning 18: The certification process for continuing benefits is now easier.
* [Ease Military Transitions Through Financial Planning](${root}/EaseTransitions)
  As a service member, you’ll face many changes in your military career. With proper planning, your finances can remain steady through every transition.
* [Financial Considerations for Military Medical Retirement](${root}/MilitaryMedicalRetirement)
  A military medical retirement presents major concerns, money being one of them. This article offers tips on preparing your finances for an unexpected departure.
* [Financial Planning for Transition: Compensation, Benefits and Entitlement Considerations for Military Spouses](${root}/CompBenefitsEntitlements)
Transitioning from the military is a family affair. This article features some questions military spouses can ask themselves as they navigate their family’s new normal.
* [Financial Planning for Transition: TRICARE](${root}/FinancialPlanningforTransition)
  Transitioning from the military is a big step that you need to financially prepare for, especially when it comes to securing health insurance coverage for the future.
* [Financial Planning for Transition: TRICARE for Reserve Component Members](${root}/FinancialPlanningTricareReserve)
  Make your transition from the Reserve as smooth as possible by planning for how changes in TRICARE benefits will affect you and your family financially.
* [Invest in Yourself With the DoD’s Career Ready Portal](${root}/CareerReadyPortal)
  Investing in your future is investing in yourself. With the DoD's Career Ready Portal, find support for your post-military career.
* [Money Matters: The Connection Between Financial Health and Mental Health](${root}/FinancialHealth)
  When your finances aren’t as healthy as you’d like, you may naturally feel more stressed. Learn about how your financial health can impact your mental health in this article.
* [Prepare Your Finances for the Post-Retirement Job Hunt](${root}/PostRetirementJobs)
  Thinking about a new career after retiring from the military? Prepare your finances for the job hunt with helpful advice for making the most of your money.
* [Retiring From the Military: Expectations vs. Reality](${root}/RetirementExpectationsVsReality)
  Read lessons learned about the financial reality of retirement from three recently transitioned service members — each with unique post-retirement plans — all in this article.
* [Road to Financial Readiness](/assets/downloads/FINRED-RTFR-F.pdf)
  Take the road to financial readiness and see where it can lead you. Use this map to learn some of the key steps to take at each point in your career.
* [Survivor Benefit Plan - Student Eligibility 101](/assets/downloads/FINRED-SurvivorBenefitPlan-I.pdf)
  See if you qualify to receive Survivor Benefit Plan education benefits. If you do, the steps required to certify your eligibility have gotten easier and more convenient.            

[Insurance]()
### Insurance
Sometimes you need to spend money now to save money in the future. Purchasing insurance coverage, or enhancing your current coverage, can help protect you from financial distress should an unforeseen emergency occur. Learn more about the various types of insurance coverage available to you — like auto, home/renters, health, disability and more — and why it’s important to be prepared.
  `}
          renderers={{ link: MarkLink }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/_FINRED-WebsiteImage-HomeInsurance-FS.jpg"
            alt="Male service member looking at computer near wife and child"
            title="Understanding Home and Renters Insurance"
            href="/assets/downloads/FINRED-HomeInsurance-FS.pdf"
            link="Download"
          >
            <p>
            Home or renters insurance is essential to protect your assets against disaster or theft. Here’s what you need to know to get covered.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SGLI-FS.jpg"
            alt="Servicemembers' Group Life Insurance Benefit Fact Sheet"
            title="Get to Know Your Servicemembers' Group Life Insurance Benefit"
            href="/assets/downloads/FINRED-SGLI-FS.pdf"
            link="Download"
          >
            <p>
            While you serve, protect your loved ones from unexpected financial worries with SGLI coverage. Learn more about this valuable service member benefit in this fact sheet.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FSGLI-I.jpg"
            alt="Understanding Family Servicemembers’ Group Life Insurance Coverage"
            title="Understanding Family Servicemembers' Group Life Insurance Coverage"
            href="/assets/downloads/FINRED-FSGLI-I.pdf"
            link="Download"
          >
            <p>
              Elijah and Sarah just married and want her to have military life insurance. This infographic shows the options they discovered and the type of coverage she’s eligible to receive.
            </p>
          </FeaturedAsset>
        </div>
        <ReactMarkdown
          source={`
* [How To Read — and Understand! — Your Insurance Contracts](${root}/InsuranceContracts)
  Insurance paperwork can be overwhelming, but it’s important to take the time to understand exactly what your policy covers — and what it doesn’t.
* [Managing Your Servicemembers’ Group Life Insurance](/assets/downloads/FINRED-SGLI-I.pdf)
  Your life insurance needs may change as you move through your military career. This infographic shows how one newly enlisted sailor manages her SGLI benefits.
* [Planning Ahead With the Survivor Benefit Plan](/assets/downloads/FINRED-SBP-I.pdf)
  As you plan for retirement, it’s time to consider your options under the Survivor Benefit Plan. Here’s how one retiring soldier is making the decision.
* [TRICARE and the Military Family](/assets/downloads/FINRED-TRICARE-I.pdf)
  Military families have several health plan options through TRICARE. See how the Johnson family handles coverage choices through life changes.
* [Understanding Health Insurance](/assets/downloads/FINRED-HealthInsurance-FS.pdf)
  Health insurance is essential for avoiding high medical bills and medical debt. Learn more about health insurance and TRICARE plan options.
* [Understanding TSGLI](/assets/downloads/FINRED-TSGLI-FS.pdf)
  As part of your Servicemembers’ Group Life Insurance, you’re covered under TSGLI. This fact sheet explains the financial benefits it provides for qualifying traumatic injuries.

[Additional-Information]()
### Additional Information
Make your plan, save automatically, start small and increase contributions regularly. Take advantage of all available information and resources to invest in yourself and enhance your financial future. To find more resources on [planning for the future,](/ToolsAndAddRes/AdditionalRes/Planning) visit the collection of resources from our trusted and featured collaborators.
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TouchPointVideos-VS.jpg"
            title="Military Journey Touchpoints"
            href={'/MilitaryJourneyTouchpoints'}
            type="Video Series"
            link="Learn More"
          >
            <p>
                Each phase of your military career has unique financial considerations. These videos from FINRED’s Touchpoints series can help map your journey.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SenseAppDownload.jpg"
            title="Mobile App, Sen$e"
            alt="Sen$e on mobile phone with piggy bank"
          >
            <p>
                It’s personal finance — at your fingertips. With our no-cost app, Sen$e, you can take knowledge with you on the go.
            </p>
            <div className="featured-asset-button-group">
              <Link
                to="https://play.google.com/store/apps/details?id=com.powertrain.sense&hl=en_US&gl=US"
              >
                <img
                  className="button-image"
                  src="/assets/img/Google-play-badge-adjustedsize.png"
                  alt=" "
                  title=" "
                />
              </Link>
              <Link
                to="https://apps.apple.com/us/app/sen%24e/id1443911840"
              >
                <img
                  className="button-image"
                  src="/assets/img/Download_on_the_App_Store_Badge_US-UK_blk_092917.png"
                  alt=" "
                  title=" "
                />
              </Link>
            </div>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PlanningFuture-R.jpg"
            title="Planning for the Future Additional Resources"
            href={'/ToolsAndAddRes/AdditionalRes/Planning'}
            type="Collaborator Resources"
            link="Learn More"
          >
            <p>
                By taking steps now to plan for your financial future, you can meet your long-term goals and be better prepared to handle the unexpected.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
