/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import path from 'path';
import React from 'react';
import { Route } from 'react-router-dom';

import ArticleRenderer from './articleRenderer';

export default function HookUpArticle(root, crumbs, toc, mdFile, category, title) {
    const mds = require.context('../pages/blog', false, /\.md$/);
    const keys = mds.keys();
    const fetchpath = mds('./' + mdFile);
    const filename = path.basename(mdFile, path.extname(mdFile));

    return (
      <Route
        exact
        path={`${root}/${filename}`}
        render={props => (
          <ArticleRenderer
            key={`${category}.${filename}`}
            crumbs={crumbs}
            toc={toc}
            file={fetchpath}
            category={category}
            title={title}
            filename={filename}
            header=""
          />
        )}
      />
    );
}

