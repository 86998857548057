/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/toolsAndResourcesPage';
import MarkLink from '../../components/markLink';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';

export default function MediaKits (props) {
    const root = props.match.path;
    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="launch-page"
      >
        <ReactMarkdown
          source="
## Shareable Resources
Take advantage of standards, information and suggestions for developing your social media presence using the Financial Readiness style and approach. You’ll have access to branding information, frequently asked questions, flyers and handouts and a Visual Style Guide that explains logo usage and more. Our office looks forward to providing you with information to assist you in your mission of providing financial information to service members and families.
"
          renderers={{ link: MarkLink }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-2024CollabAnnualPlan.jpg"
            alt="Group of service member chatting"
            title="2024 Annual Plan"
            href="/assets/downloads/FINRED-StratCommPlan-2024.pdf"
            link="Get the Annual Plan"
          >
            <p>
            This comprehensive guide encourages DoD collaborators to bolster their communication plans and strategies with financial readiness resources found on the FINRED website.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FWBA2022-MK.jpg"
            alt="Service member leaning over laptop with Financial Well-Being Assessment on screen."
            title="Financial Well-Being Assessment Media Kit"
            href={`${root}/FWBA2022MK`}
            type="Media Kit"
            link="Open Media Kit"
          >
            <p>
            Access all the information, key messages and imagery needed to support the promotion of the assessment.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SenseLaunch-P.jpg"
            title="Make Sen$e of Financial Resources on the Go"
            href="/assets/downloads/FINRED-SenseLaunch-P-8.5x11.pdf"
            type="Poster/Flyer"
            link="Download"
          >
            <p>
            Access financial information wherever you are. Download our mobile app, Sen$e, and enjoy up-to-date financial resources on your mobile device.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-OneOpWebinars-W.jpg"
            alt="OneOp — formerly Military Families Learning Network logo"
            title="OneOp Webinars"
            href="https://oneop.org/upcoming-webinars/"
            type="Link"
            link="Visit Link"
          >
            <p>
            Finance professionals, check out these free webinars and events for CEU opportunities and personal finance information specific to military families.
            </p>
          </FeaturedAsset>
        </div>

      </Page>
    );
}
