/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/trendingPage';
import MarkLink from '../components/markLink';
import ReactMarkdown from '../components/markdown';
import BaseTOC from '../components/tocs/baseTOC';

export default function FWBA (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    const toc = () => (
      <BaseTOC
        title="Financial Well-Being Assessment Resources"
        tocClass="trending"
        tocEntries={[
            { link: '#PRESENT-FINANCIAL-SECURITY', text: 'Present Financial Security' },
            { link: '#FUTURE-FINANCIAL-SECURITY', text: 'Future Financial Security' },
            { link: '#PRESENT-FREEDOM-OF-CHOICE', text: 'Present Freedom of Choice' },
            { link: '#FUTURE-FREEDOM-OF-CHOICE', text: 'Future Freedom of Choice' },
        ]}
      />
    );

    return (
      <Page
        crumbs={props.crumbs}
        toc={toc()}
        articleClass="subpage"
      >
        <ReactMarkdown
          source={`
## Financial Well-Being Assessment Resources

How did you score on the Financial Well-Being Assessment? Do you have
opportunities to improve? Either way, know that you don't have to go it
alone. [Personal financial
managers](https://installations.militaryonesource.mil/?looking-for-a=program/program-service=30/focus=program)
and [counselors](/pfcMap) who understand
military life are available for no-cost help at your local Military and
Family Support Center.

Visit the [Financial Well-Being Assessment Service
Resources](${root}/Services) page for a list of resources curated by your
service.

[PRESENT-FINANCIAL-SECURITY]()
#### PRESENT FINANCIAL SECURITY: *How well you manage your day-to-day and month-to-month finances*

Managing your finances so you can pay for necessities like food and
clothing, make debt payments and pay other monthly bills helps to ensure
your financial security. Learn more about these topics using the
following resources:

**Find Food Security Resources:**

-   [Assistance and
    Support](https://download.militaryonesource.mil/12038/MOS/Economic-Security/FoodSecurityResources-SupportPrograms.pdf)
-   [Helpful
    Tips](/Money/BudgetingUncertainTimes)

**Take Charge of Your Credit and Manage Your Debt:**

-   [Improving
    Credit](/Money/CreditHistory)
-   [Credit
    Videos](/Consumer-Protections/ConsumerCredit)
-   [Avoid the Debt Trap
    Cycle](/Money/DebtTraps)

**Build a Spending Plan:**

-   [Spending Plan
    Worksheet](/assets/downloads/FINRED-Spendingplan-TK.pdf)
-   [Create Your Spending
    Plan](/MilitaryJourneyTouchpoints/video/Creating-a-Spending-Plan)

**Work With Relief Societies:**

-   [Army Emergency Relief, or
    AER](https://www.armyemergencyrelief.org/)
-   [Navy-Marine Corps Relief Society, or NMCRS](https://www.nmcrs.org/)
-   [Air Force Aid Society, or AFAS](https://afas.org/)
-   [Coast Guard Mutual Assistance, or CGMA](https://www.cgmahq.org/)

[FUTURE-FINANCIAL-SECURITY]()
#### FUTURE FINANCIAL SECURITY: *Your capacity to absorb a financial shock*

Your ability to pay for a financial emergency without going into debt is
important for your future financial security, health and family. Learn
more about these topics using the following resources:

-   [Create an Emergency
    Fund](/Trending/Blog/EmergencyFund)
-   [Prepare for Future
    Expenses](https://www.consumerfinance.gov/about-us/blog/how-save-emergencies-and-future/)
-   [Prepare Your Finances for a
    Deployment](/MilitaryJourneyTouchpoints/video/Deployment-and-Your-Finances)

[PRESENT-FREEDOM-OF-CHOICE]()
#### PRESENT FREEDOM OF CHOICE: *Your freedom to make financial choices without regret*

Financial freedom is easier to achieve when you monitor your credit and
create some breathing room in your spending plan to save, invest and be
able to purchase items beyond necessities. Learn more about these topics
using the following resources:

**Build Healthy Credit Habits:**

-   [Credit History](/Money/CreditHistory)
-   [Credit
    Scores](https://www.consumerfinance.gov/ask-cfpb/how-do-i-get-and-keep-a-good-credit-score-en-318/)

**Invest for Your Future:**

-   [Savings and Investing
    Strategies](/Saving/InvestmentOps)

**Plan for Major Purchases:**

-   [Major
    Purchases](/Money/MajPurchase-Sign)
-   [True Loan
    Cost](/assets/downloads/FINRED-TrueLoanCost-I.pdf)
-   [Car Buying
    Basics](/Money/CarBuyingBasics)
-   [U.S. Department of Housing and Urban
    Development](https://www.hud.gov/counseling)

[FUTURE-FREEDOM-OF-CHOICE]()
#### FUTURE FREEDOM OF CHOICE: *Your efforts toward future financial success*

Future financial success is easier to achieve when you follow a plan and
implement healthy financial habits like saving and investing. Take your
plan to the next level with the help of these resources:

**Build a Financial Plan and Stick to It:**

-   [Smart Money Tips](https://playmoneysmart.fdic.gov/tools/105)

**Invest for Your Future:**

-   [Thrift Savings Plan](https://www.tsp.gov/how-to-invest/)
-   [Investing
    Videos](/Saving/InvestingMicrolearning)

**Prepare Your Legal Documents (Will, Power of Attorney, Trust, etc.):**

-   [Legal Assistance Locator](https://legalassistance.law.af.mil/)
-   [Learn About
    POAs](/Planning/POAMilitary)
-   [Trust
    Basics](/Planning/ConsiderTrusts)          
            `}

          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
