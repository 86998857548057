/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Page from '../components/page';

import settings from '../settings';
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: null,
        };
        this.logout = this.logout.bind(this);
    }

    logout() {
        window.$.ajax({
            url: settings.api + '/logout',
            method: 'get',
            contentType: 'application/json',
            dataType: 'json',
            xhrFields: {
                withCredentials: true,
            },
            success: (body, status, xhr) => {
                console.log('Got Here', body);
                window.location.reload();
            },
        });
    }

    render() {
        return (
          <Page crumbs="Home|/,logout">
            <div
              className="usa-grid no-left-pad no-right-pad"
              style={{ minHeight: 1000 }}
            >
              <div className="usa-width-one-fourth" />
              <div className="usa-width-three-fourths">

                <p>logging out...</p>
                {this.logout()}
              </div>
            </div>
          </Page>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch({ type: 'logout' }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Login));


