/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

export default class Section extends React.Component {
    render() {
        const className = 'usa-section ';


        const style = { paddingBottom: '20px', paddingTop: '20px' };
        if (this.props.noBottom) { style.paddingBottom = '0'; }
        if (this.props.noTop) { style.paddingTop = '0'; }
        if (this.props.dark) { style.background = '#f8f8f8'; }
        return (
          <section className={className}>
            <div className="usa-grid bg0 " style={style}>

              {this.props.heading ? <h2>{this.props.heading}</h2> : null}

              {this.props.children}
            </div>
          </section>
        );
    }
}

