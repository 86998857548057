/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DCFSAVideos from '../pages/dcfsa/dcfsa-videos';
import HookUpSiteVideo from '../components/hookUpSiteVideo';
import Oops404Page from '../pages/oops-404';
import HookUpOopsPage from '../components/hookUpOopsPage';


export default function DCFSAVideosRoutes (props) {
    const root = props.match.path;
    const crumbs = `${props.crumbs},DCFSA Videos|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(rps) => <DCFSAVideos {...rps} crumbs={crumbs} toc={props.toc} />}
        />

        <Route
          path={`${root}/video`}
          render={(rps) => (
            <Switch>
              {[
                  HookUpSiteVideo(
                      rps.match.path, 'WhatIsaDCFSA', 'What Is a DCFSA?', crumbs,
                      '/assets/video/benefits/dcfsa/FINRED-DCFSA-WhatIsADCFSA-V.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-DCFSAvideo-GC.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'DCFSAEligibilityRequirements', 'DCFSA Eligibility Requirements', crumbs,
                      '/assets/video/benefits/dcfsa/FINRED-DCFSA-EligibilityRequirements-V.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-DCFSAvideo-GC.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'DCFSAEligibleExpenses', 'DCFSA Eligible Expenses', crumbs,
                      '/assets/video/benefits/dcfsa/FINRED-DCFSA-EligibleExpenses-V.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-DCFSAvideo-GC.jpg',
                  ),
                  HookUpSiteVideo(
                      rps.match.path, 'HowaDCFSAWorks', 'How a DCFSA Works', crumbs,
                      '/assets/video/benefits/dcfsa/FINRED-DCFSA-HowADCFSAWorks-V.mp4',
                      null, '/assets/img/FINRED-WebsiteImage-DCFSAvideo-GC.jpg',
                  ),
                  HookUpOopsPage(),
              ]}
            </Switch>
          )}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}
