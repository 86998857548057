/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/

import React from 'react';

import Page from './page';
import TrendingTOC from './tocs/trendingTOC';


export default function TrendingPage (props) {
    return (
      <Page crumbs={props.crumbs}>
        <div className="usa-grid no-left-pad no-right-pad">
          <div className="usa-width-one-fourth TOC">
            {props.toc || <TrendingTOC />}
          </div>
          <div className={`usa-width-three-fourths article trending ${props.articleClass}`}>
            {props.children}
          </div>
        </div>
      </Page>
    );
}


