/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../components/header';
import SiteTitle from '../components/siteTitle';
import LinkModal from '../components/linkModal';


import Home from '../components/home';
import SecondaryNav from '../components/secondaryNav';
import Hero from '../components/hero';
import ReadMore from '../components/readMore';

import HeroCarousel from '../components/heroCarousel';
import SocialCols from '../components/socialCols';
import Events from '../components/events';
import Link from '../components/link';
import api from '../api';
import settings from '../settings';
import AssetEditor from "../components/assetEditor";
import Img from '../components/img';

import NewsletterFormLarge from '../components/newsletterFormLarge';

function auto_grow(element) {
    // const element = e.target;
    // if ($(element).is(':focus')) {
    if (element.oldValue != element.value) {
        element.style.height = '5px';
        element.style.height = (element.scrollHeight) + 'px';
        element.oldValue = element.value;
    }
    //  }
}

class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            showAssets: false,
            showModal: false,
            modalSite: '',
        };
    }
    
    setShowModal(site) {
      this.setState({
        showModal: true,
        modalSite: site,
      });
    }

    showAssets = function()
    {
      this.setState( ()=> ({showAssets:true}))
    }
    hideAssets = function()
    {
      this.setState( ()=> ({showAssets:false}))
    }
    async componentWillMount() {
        let body = {};
        if (settings.useAPI) {
            body = await api.get('/homepage');
        }
        const homepage = body.data;

        this.props.onLoadHomepage(homepage);

        setInterval(() => {
            $('textarea.inlineEdit').each((i, v) => auto_grow(v));
        }, 200);
    }

    async saveEdit() {
     /*   let body = await api.post('/homepage', this.props.homepage);

        if (settings.useAPI) {
            body = await api.get('/homepage');
        }
        const homepage = body.data;

        this.props.onLoadHomepage(homepage);
        this.props.stopEditing(); */

        this.props.saveEditing();
        this.props.stopEditing(); 
        // window.location.reload();
    }

    set(key) {
        return function(e) {
         
            this.props.onValueSet(key, e.target.value);
        }.bind(this);
    }

    async cancelEditing() {
        let body;

        if (settings.useAPI) {
            body = await api.get('/homepage');
        }
        const homepage = body.data;

        this.props.onLoadHomepage(homepage);
        this.props.stopEditing();
    }

    render() {
        if (!this.props.homepage) { return ''; }

        return (
          <Home crumbs="">
            {this.state.showAssets && <AssetEditor onClose={ ()=>this.hideAssets()}  showInsert={false} _id="root" onSetLead={
              (img) => this.set("hero.img")({target:{value:settings.api+img}})
            }></AssetEditor>}
            <section>
              {this.props.session.loggedIn && (
                <div className="usa-grid homepage-edit-button-container">
                  <div className="homepage-edit-button-group">
                    {this.props.editing && <i onClick={this.props.discardDraft} title="Discard Draft" className="fa fa-icon fa-trash indexFloat homepage-edit-button" />}
                    {this.props.editing && <i onClick={this.props.publish} title="Publish Changes" className="fa fa-icon fa-rss-square indexFloat homepage-edit-button" />}
                    {!this.props.editing && <i onClick={this.props.startEditing} style={{ float: 'right', color: 'white' }} title="Edit Site" className="fa fa-icon fa-edit indexFloat homepage-edit-button" />}
                    {this.props.editing && <i onClick={() => this.cancelEditing()} title="Discard Edits" className="fa fa-icon fa-close indexFloat homepage-edit-button" />}
                    {/* {this.props.editing && <i onClick={() => this.showAssets()} title="Manage Images" className="fa fa-icon fa-image indexFloat homepage-edit-button" />} */}
                    {this.props.editing && this.props.edited && <i onClick={() => this.saveEdit()} title="Save Draft" className="fa fa-icon fa-save indexFloat homepage-edit-button" />}
                  </div>
                </div>
              )}
              <HeroCarousel setSite={(site) => this.setShowModal(site)}></HeroCarousel>
            </section>

            <section>
              <div className="usa-grid bg0 ">
                <div className="heroFlex welcomeMessage">
                  <div className="heroFlexImg welcomeMessage">
                    <p className="usa-font">
                    Welcome to the Department of Defense Office of Financial Readiness website! Look around, discover helpful tips and check back often for updates! <em>For the best viewing experience, we recommend using the web browsers Chrome or Safari.</em>
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <Events />
            </section>

            <section>
              <div className="usa-grid bg0 ">
                <div className="heroFlex welcomeMessage">
                  <div className="heroFlexImg welcomeMessage">
                    
                    <div className="social-media-container">
                      <div className="left-media-container">
                        <div className="mirl-social-media">
                          <div className="mirl-social-media-abstract">
                            Follow @DoDFINRED to ensure you never miss a moment!
                          </div>
                          <div className="mirl-social-media-images">
                            <div className="mirl-social-media-image">
                              <Link to="https://www.facebook.com/DoDFINRED/">
                                <img src="/assets/img/FINRED-SMIcon-FB.png" alt="Facebook icon" title="Facebook icon" />
                              </Link>
                            </div>
                            <div className="mirl-social-media-image">
                              <Link to="https://www.instagram.com/dodfinred">
                                <img src="/assets/img/FINRED-SMIcon-IG.png" alt="Instagram icon" title="Instagram icon" />
                              </Link>
                            </div>
                            <div className="mirl-social-media-image">
                              <Link to="https://www.linkedin.com/company/dodfinred">
                                <img src="/assets/img/FINRED-SMIcon-LinkedIn.png" alt="LinkedIn icon" title="LinkedIn icon" />
                              </Link>
                            </div>
                            <div className="mirl-social-media-image">
                              <Link to="https://twitter.com/DoDFINRED">
                                <img src="/assets/img/FINRED-SMIcon-X.png" alt="X icon" title="X icon" />
                              </Link>
                            </div>
                            <div className="mirl-social-media-image">
                              <Link to="https://www.youtube.com/dodfinred">
                                <img src="/assets/img/FINRED-SMIcon-YT.png" alt="YouTube icon" title="YouTube icon" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="left-media-container">
                        <div className="sense-media-container">
                          <div className="title">
                          Download the DoD's free financial literacy app, Sen$e.
                          </div>
                          <div className="app-download">
                            <div className="mirl-social-media-image">
                              <Img
                                small src="/assets/img/FINRED-WebsiteImage-Homepage-Sense.png"
                                alt="Sen$e dollar sign icon"
                                title="Sen$e dollar sign icon"
                              />
                            </div>
                              <Link
                                to="https://play.google.com/store/apps/details?id=com.powertrain.sense&hl=en_US&gl=US"
                              >
                                <img
                                  className="button-image"
                                  src="/assets/img/Google-play-badge-adjustedsize.png"
                                  alt=" "
                                  title=" "
                                />
                              </Link>
                              <Link
                                to="https://apps.apple.com/us/app/sen%24e/id1443911840"
                              >
                                <img
                                  className="button-image"
                                  src="/assets/img/Download_on_the_App_Store_Badge_US-UK_blk_092917.png"
                                  alt=" "
                                  title=" "
                                />
                              </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
            {
              this.state.showModal && (
                <LinkModal
                  dismiss={() => this.setState((state) => ({ showModal: false }))}
                  href={this.state.modalSite}
                />
              )
            }
          </Home>
        );
    }
}

function getHomepage(state)
{
  if(state && state.homepage && state.homepage.editing)
      return state.homepage.draft;
  if(state && state.homepage && !state.homepage.editing)
      return state.homepage.published;
    
}

const mapStateToProps = state => ({
    homepage:  getHomepage(state),
    editing: state.homepage && state.homepage.editing,
    edited: state.homepage && state.homepage.edited,
    session: state.session,
});

const mapDispatchToProps = dispatch => ({
    onLoadHomepage: (homepage) => dispatch({ type: 'homepageLoaded', homepage }),
    startEditing: () => dispatch({ type: 'homepageStartEdit' }),
    stopEditing: () => dispatch({ type: 'homepageStopEdit' }),
    saveEditing: () => dispatch({ type: 'homepageSave' }),
    publish: () => dispatch({ type: 'homepagePublish' }),
    discardDraft: () => dispatch({ type: 'discardDraft' }),
    onValueSet: (key, value) => dispatch({ type: 'homepageValueSet', key, value }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Index);

